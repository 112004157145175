import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { any, bool, func, node } from 'prop-types';
import cx from 'classnames';

import { BACKGROUND, BORDER, ROUTE, TEXT } from 'common/const';
import { useCart, useOrders, useShop } from 'app/context';
import {
  Button,
  Content,
  Icon,
  Scrollbar,
  Toast,
  NewFeatureTooltip,
  Dialog,
  TextLink,
} from 'common/components';

import { Drawer, Header, Footer, Sidebar } from '../components';
import ToggleCartButton from './ToggleCartButton';

import styles from './ShoppingPage.module.css';
import { decoratePhone, getEventFaq, track } from 'common/utils';

const ShoppingPage = ({
  Cart,
  eventInfo,
  faqPdf,
  filters,
  isLoading,
  children,
  openCart,
  backTo,
}) => {
  const [isCartOpen, setIsCartOpen] = useState(openCart);
  const [isReviseDialogOpen, setIsReviseDialogOpen] = useState(false);

  const history = useHistory();
  const { error, count, isUpdating } = useCart();
  const { reviseOoId, ooId, reset: resetShop } = useShop();
  const { isFetching, findOrder, toast, updateToast } = useOrders();
  const order = findOrder(ooId);

  useEffect(() => {
    setIsReviseDialogOpen(!count);
  }, [count]);

  useEffect(() => {
    if (Boolean(error)) {
      updateToast({ alert: 'error', message: error });
    }
  }, [error, updateToast]);

  return (
    <>
      <Header backTo={backTo}>
        <div className={styles.header}>
          <ToggleCartButton
            count={count}
            toggleCart={() => setIsCartOpen((isOpen) => !isOpen)}
            isLoading={isUpdating || isFetching}
          />
        </div>
      </Header>

      <Content isLoading={isLoading}>
        <Sidebar
          label="Categories"
          className={cx(BORDER.GREY_100, styles.sidebar)}
        >
          <div className={styles.nav}>
            <div className={cx(BORDER.GREY_100, styles.event)}>{eventInfo}</div>
            {faqPdf && (
              <NewFeatureTooltip
                feature="eventFAQ"
                title={
                  <p className={cx(styles.tooltip, TEXT.BODY_2)}>
                    <strong className={TEXT.SUBTITLE_2}>New! </strong>
                    Read facts and FAQs to know what you need to prepare for
                    events.
                  </p>
                }
                placement="right"
              >
                <div className={cx(BORDER.GREY_100, styles.faq)}>
                  <Button
                    href={getEventFaq(faqPdf)}
                    text
                    fullWidth
                    color="info"
                    className={styles.pdfButton}
                  >
                    <Icon name="quiz" size="small" />
                    Show Facts & FAQs
                  </Button>
                </div>
              </NewFeatureTooltip>
            )}
            <Scrollbar component="nav" className={styles.scrollbar}>
              {filters}
            </Scrollbar>
          </div>
        </Sidebar>

        <div className={cx(styles.content)}>
          <main className={styles.main}>{children}</main>
          <Footer className={styles.footer} />
        </div>
      </Content>

      {/* Off-screen content */}
      <Drawer
        anchor="right"
        close={() => setIsCartOpen(false)}
        isOpen={isCartOpen}
        open={() => setIsCartOpen(true)}
        title="My Cart"
      >
        <Cart />
      </Drawer>

      <Toast
        alert={toast.alert}
        anchor={{ vertical: 'top', horizontal: 'center' }}
        isOpen={Boolean(toast.alert)}
        close={() => updateToast({})}
      >
        {toast.message}
      </Toast>

      {/* Revise Removing all items */}
      {reviseOoId && count === 0 && (
        <Dialog
          id="modify-order"
          title="Modify Order"
          size="xs"
          isOpen={isReviseDialogOpen}
          close={() => {
            track('revise_empty_cart_exit');
            setIsReviseDialogOpen(false);
          }}
          onCancel={() => {
            if (order.isCancellationAllowed) {
              track('revise_empty_cart_cancel');
              setIsReviseDialogOpen(false);
              resetShop();
              history.push(`${ROUTE.CANCEL}/${ooId}`);
            }
          }}
          onSubmit={() => {}}
          submitText="Continue"
          cancelText={order.isCancellationAllowed ? 'Cancel Order' : null}
        >
          You must have at least one (1) item in your cart to modify your order.
          {order.isCancellationAllowed
            ? ' You can also go back to request a cancellation instead.'
            : ' To cancel your order, contact your branch'}
          {!order.isCancellationAllowed && (
            <div className={cx(BACKGROUND.GREY_50, styles.branch)}>
              <p className={TEXT.BOLD}>{order?.branchName}</p>
              <TextLink href={'tel:+1' + order?.branchPhone}>
                <p>{decoratePhone(order?.branchPhone)}</p>
              </TextLink>
              <TextLink href={'mailto:' + order?.branchEmail}>
                <p>{order?.branchEmail}</p>
              </TextLink>
            </div>
          )}
        </Dialog>
      )}
    </>
  );
};

ShoppingPage.propTypes = {
  Cart: func,
  eventInfo: node,
  filters: node,
  isLoading: bool,
  chilren: any,
  openCart: bool,
};

ShoppingPage.defaultProps = {
  openCart: false,
};

export default ShoppingPage;
