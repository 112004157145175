import { func } from 'prop-types';

import { VALID, ROUTE } from 'common/const';
import {
  Content,
  Error,
  Form,
  Input,
  NewPassword,
  Password,
  SubmitButton,
  TextLink,
} from 'common/components';
import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getIsEmailRegistered } from 'data/customers';

import styles from './Signup.module.css';

const SignupCredentials = ({ onSubmit }) => {
  const [{ email, password, isFailedOnce, isPasswordValid, error }, setState] =
    useState({
      email: undefined,
      password: undefined,
      isPasswordValid: false,
      isFailedOnce: false,
      error: false,
    });

  const setIsPasswordValid = useCallback(
    (isPasswordValid) =>
      setState((state) => ({
        ...state,
        isPasswordValid,
      })),
    [setState],
  );

  const { mutateAsync, isLoading } = useMutation(({ emailAddress: email }) =>
    getIsEmailRegistered({ email }),
  );

  const checkEmail = useCallback(
    async (data) => {
      const isEmailRegistered = await mutateAsync(data);
      if (isEmailRegistered) {
        setState((state) => ({
          ...state,
          error: true,
          password: undefined,
        }));
      } else {
        onSubmit(data);
      }
    },
    [mutateAsync, onSubmit],
  );

  return (
    <Content isLoading={isLoading}>
      {error && (
        <div className={styles.error}>
          <Error>
            This email is already associated with a registered account.{' '}
            <TextLink to={ROUTE.LOGIN}>Log in instead</TextLink>
          </Error>
        </div>
      )}
      <Form
        validation={{
          emailAddress: VALID.EMAIL.required('Please enter a valid email.'),
          password: VALID.PASSWORD.required('Please enter a valid password.'),
        }}
        onPass={(data) => {
          setState((state) => ({ ...state, email: data.emailAddress }));
          if (isPasswordValid) {
            checkEmail(data);
          } else {
            setState((state) => ({
              ...state,
              error: false,
              isFailedOnce: true,
            }));
          }
        }}
        onFail={(errors) => {
          setState((state) => ({ ...state, isFailedOnce: true }));
        }}
        defaultValues={{ emailAddress: email }}
      >
        <Input name="emailAddress" />

        <NewPassword
          isFailedOnce={isFailedOnce}
          password={password}
          setIsValid={setIsPasswordValid}
        >
          <Password
            name="password"
            onChange={({ target }) =>
              setState((state) => ({ ...state, password: target.value }))
            }
            error={isFailedOnce && !isPasswordValid ? ' ' : undefined}
          />
        </NewPassword>

        <SubmitButton name="submit" fullWidth>
          Next
        </SubmitButton>
      </Form>
    </Content>
  );
};

SignupCredentials.propTypes = {
  onSubmit: func,
};

export default SignupCredentials;
