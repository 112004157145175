import { oneOf } from 'prop-types';
import { CircularProgress } from '@mui/material';
import cx from 'classnames';
import { LOADING_COLORS, LOADING_SIZES } from '../../const';

import styles from './Loading.module.css';

const Loading = ({ color, size }) => {
  return (
    <div className={cx({ [styles.grow]: size === 'grow' }, styles.wrap)}>
      <CircularProgress
        color={color}
        size={LOADING_SIZES[size]}
        aria-label="Loading"
      />
    </div>
  );
};

Loading.propTypes = {
  color: oneOf(LOADING_COLORS),
  size: oneOf(Object.keys(LOADING_SIZES)),
};

Loading.defaultProps = {
  color: 'primary',
  size: 'grow',
};

export default Loading;
