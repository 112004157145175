import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useOrders, useShop } from 'app/context';
import { PAYMENT_METHOD } from 'common/const';
import { useCartTotals, useEvent } from 'common/hooks';
import { currency, getSiteOwnership, getSiteProps } from 'common/utils';
import { getMaxCreditCard } from 'data/lookup';

const { PAYMENT_METHODS: authorizedPaymentMethods } = getSiteProps();
const ownership = getSiteOwnership();

const useEventPaymentMethods = () => {
  const { recalculateTotals, error: totalsError } = useCartTotals();
  const { eventId, reviseOoId, ooId } = useShop();

  const { findOrder } = useOrders();
  const order = findOrder(ooId);

  const { isWireTransferTaxable, isServiceFeeTaxable, salesTax } =
    useEvent(eventId).data;

  const [maxAlert, setMaxAlert] = useState(null);

  const calculatedTotals = useMemo(
    () =>
      recalculateTotals({
        paymentMethod: PAYMENT_METHOD.CARD,
        totals: order?.itemDifferencesTotals,
        isWireTransferTaxable,
        isServiceFeeTaxable,
        salesTax,
      }),
    [
      recalculateTotals,
      order,
      isWireTransferTaxable,
      isServiceFeeTaxable,
      salesTax,
    ],
  );

  const totalsForCard = reviseOoId
    ? calculatedTotals.total + order?.totals?.balanceDue
    : calculatedTotals.total;

  const {
    data: maxCreditCard,
    isLoading,
    error,
  } = useQuery(['getMaxCreditCard'], () => getMaxCreditCard(), {
    onSuccess: (max) => {
      if (totalsForCard > max) {
        ownership === 'Cajun'
          ? setMaxAlert(
              `We do not accept credit card payments for orders over ${currency(
                max,
              )}. Contact support for questions or concerns.`,
            )
          : setMaxAlert(
              `Credit card is not available for orders with a grand total that is more than
            ${currency(max)}.`,
            );
      }
    },
    refetchOnMount: true,
  });

  const shouldDisplayPaymentMethod = useCallback(
    (paymentMethod) => {
      if (!authorizedPaymentMethods.includes(paymentMethod)) {
        return false;
      }

      if (
        paymentMethod === PAYMENT_METHOD.CARD &&
        totalsForCard > maxCreditCard
      ) {
        return false;
      }

      return true;
    },
    [
      // authorizedPaymentMethods,
      totalsForCard,
      maxCreditCard,
    ],
  );

  return {
    eventId,
    authorizedPaymentMethods,
    shouldDisplayPaymentMethod,
    isLoading,
    error,
    totalsError,
    maxAlert,
  };
};

export default useEventPaymentMethods;
