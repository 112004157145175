import cx from 'classnames';

import { BORDER, ROUTE, TEXT } from 'common/const';
import {
  Accordion,
  AccordionGroup,
  Button,
  TextLink,
  UploadFloorPlan,
} from 'common/components';
import { getBaseUrl } from 'common/utils';
import { useWindowSize } from 'common/hooks';

import styles from './FloorPlan.module.css';

const BASE_URL = getBaseUrl(2);

const FloorPlan = ({
  customerId,
  documentId,
  isFloorPlanUploaded,
  order,
  setState,
}) => {
  // console.log('<FloorPlan>', { order, isFloorPlanUploaded, documentId, customerId });

  const { width: windowWidth } = useWindowSize();

  const { showFileUpload, isFloorPlanBuilderAvailable } = order;
  const isMobile = windowWidth < 704;

  return (
    showFileUpload && (
      <li>
        {isFloorPlanBuilderAvailable ? (
          <p className={TEXT.BODY_1}>
            Build your own booth floor plan or upload a PDF. Your booth floor
            plan must show locations of all services ordered. Labor and material
            charges apply. You can upload a booth floor plan later in 'Order
            Details'.
          </p>
        ) : (
          <p>
            Build your own booth floor plan or upload a PDF. Your booth floor
            plan must show locations of all services ordered. Labor and material
            charges apply. You can upload a booth floor plan later in 'Order
            Details'.
          </p>
        )}

        <AccordionGroup
          radios
          defaultActive={isFloorPlanBuilderAvailable ? 'builder' : 'pdf'}
        >
          {isFloorPlanBuilderAvailable && !isMobile && (
            <Accordion
              id="builder"
              summary="Build a floor plan with our Floor Plan Builder"
            >
              {isFloorPlanUploaded ? (
                <span className={cx(TEXT.BODY_1, styles.links)}>
                  <TextLink
                    href={`${BASE_URL}document/get_document/` + documentId}
                  >
                    View Floor Plan
                  </TextLink>
                  <div className={cx(BORDER.GREY_200, styles.divider)} />
                  <TextLink to={ROUTE.FLOOR_PLAN_BUILDER}>
                    Build New Floor Plan
                  </TextLink>
                </span>
              ) : (
                <Button to={ROUTE.FLOOR_PLAN_BUILDER} outlined>
                  Build My Floor Plan
                </Button>
              )}
            </Accordion>
          )}

          <Accordion id="pdf" summary="Upload a PDF">
            <UploadFloorPlan
              buttonText={'Upload PDF'}
              ooId={order.id}
              customerId={customerId}
              eventId={order.eventId}
              setIsFloorPlanUploaded={setState}
              isFloorPlanUploaded={isFloorPlanUploaded}
              exhId={order.exhId}
            >
              Upload PDF
            </UploadFloorPlan>
          </Accordion>
        </AccordionGroup>
      </li>
    )
  );
};

export default FloorPlan;
