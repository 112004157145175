import MuseoSans300 from 'assets/fonts/MuseoSans/MuseoSans-300.otf';
import MuseoSans500 from 'assets/fonts/MuseoSans/MuseoSans-500.otf';
import MuseoSans700 from 'assets/fonts/MuseoSans/MuseoSans-700.otf';

const museoSans = `
  @font-face {
    font-family: 'MuseoSans';
    font-style: normal;
    font-weight: 300;
    src: url('${MuseoSans300}') format("opentype");
  }

  @font-face {
    font-family: 'MuseoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 400 500;
    src: url('${MuseoSans500}') format("opentype");
  }

  @font-face {
    font-family: 'MuseoSans';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('${MuseoSans700}') format("opentype");
  }
`;

export default museoSans;
