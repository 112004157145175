import { oneOf, string } from 'prop-types';
import cx from 'classnames';

import {
  CREDIT_CARD,
  PAYMENT_METHOD,
  PAYMENT_DETAIL_DESCRIPTION,
  TEXT,
  COLOR,
} from 'common/const';

import styles from './PaymentMethod.module.css';
import { DATE_FORMAT, currency, date } from 'common/utils';

const PaymentMethod = ({ amount, paymentDate, card, last4, method }) => {
  // console.log('<PaymentMethod>', { card, last4 });

  const description = PAYMENT_DETAIL_DESCRIPTION[method];
  const { alt, logo } = card ?? {};

  return (
    <>
      {card && [PAYMENT_METHOD.CARD].includes(method) ? (
        <div className={cx(TEXT.BODY_2, styles.card)}>
          {description && (
            <p className={TEXT.BODY_2}>
              {description === PAYMENT_DETAIL_DESCRIPTION.CARD && amount < 0
                ? PAYMENT_DETAIL_DESCRIPTION.CARD_REFUND
                : PAYMENT_DETAIL_DESCRIPTION.CARD}
            </p>
          )}
          {alt && logo && <img alt={alt} src={logo} />}
          •••• {last4}
        </div>
      ) : (
        <div className={cx(styles.card, TEXT.BODY_2)}>
          {description && <p className={TEXT.BODY_2}>{description}</p>}
          {last4}
        </div>
      )}
      <div className={cx(styles.card, TEXT.BODY_2, COLOR.GREY_600)}>
        <p>{date(paymentDate).format(DATE_FORMAT.MEDIUM_DATE)}</p>
        <p>{currency(amount)}</p>
      </div>
    </>
  );
};

PaymentMethod.propTypes = {
  card: oneOf(Object.values(CREDIT_CARD)),
  last4: string,
  method: oneOf(Object.values(PAYMENT_METHOD)),
};

export default PaymentMethod;
