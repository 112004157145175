import { Modal } from '@mui/material';

import styles from './HowToVideo.module.css';

const HowToVideo = ({ isOpen, setIsOpen, link }) =>
  isOpen && (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className={styles.container}>
        <iframe
          src={link}
          title="How to Use Edlen Online Ordering"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className={styles.video}
        />
      </div>
    </Modal>
  );

export default HowToVideo;
