import { useQuery } from '@tanstack/react-query';

import { getCartTotals } from 'data/orders';
import { useCustomer, useShop } from 'app/context';
import { calculateCartTotals } from 'common/utils';

const useCartTotals = () => {
  const { customerId } = useCustomer();
  const { eventId, ooId, reviseOoId } = useShop();

  const params = {
    customerId,
    eventId,
    ooId,
  };
  const {
    data: totals = {},
    isLoading,
    error,
  } = useQuery(['cartTotals', params], () => getCartTotals(params), {
    retryOnMount: false,
    refetchOnMount: true,
  });

  const recalculateTotals = ({
    paymentMethod,
    isWireTransferTaxable,
    isServiceFeeTaxable,
    salesTax,
    totals: reviseTotals,
  }) =>
    calculateCartTotals({
      paymentMethod,
      totals: reviseOoId ? reviseTotals : totals,
      isWireTransferTaxable,
      isServiceFeeTaxable,
      salesTax,
    });

  return {
    ...params,
    totals,
    recalculateTotals,
    isLoading,
    error,
  };
};

export default useCartTotals;
