import { useMemo } from 'react';
import { string } from 'prop-types';

import { date, DATE_FORMAT } from 'common/utils';

const EventDates = ({
  className,
  end,
  start,
  format = DATE_FORMAT.MEDIUM_DATE,
}) => {
  // console.log('<EventDates>', { className, titleClass, facilityName, dateStart, dateEnd, title, id });

  const showEnd = useMemo(
    () => end && !date(start).isSame(end, 'day'),
    [start, end],
  );

  return start ? (
    <span className={className}>
      <span>{date(start).format(format)}</span>
      {showEnd && <span> - {date(end).format(format)}</span>}
    </span>
  ) : null;
};

EventDates.propTypes = {
  className: string,
  end: string,
  start: string,
  format: string,
};

export default EventDates;
