import axios from 'axios';

import { ENV, VALID } from 'common/const';
import { getBaseUrl, log } from 'common/utils';

import mock from './getEventCreditCardTypes.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get a list of credit card methods for an event
 * @param {string} eventId - ID for event
 * @returns array of credit card types
 */
const getEventCreditCardTypes = async ({ eventId }) => {
  await VALID.check(eventId, VALID.EVENT_ID.required('eventId is required'));

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}events/event_payment_methods/${eventId}`);

    return data.paymentTypes
      .filter(({ method }) => method === 'CC')
      .map(({ name }) => name);
  } catch (error) {
    log('getEventCreditCardTypes', {
      data: { eventId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getEventCreditCardTypes;
