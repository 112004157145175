import { VALID } from 'common/const';

const FIELD = {
  address1: {
    label: 'Address 1',
    validation: VALID.ADDRESS.required('Address is required'),
  },
  address2: {
    label: 'Address 2',
    optional: true,
    placeholder: 'Locality',
    validation: VALID.LOCALITY,
  },
  city: {
    label: 'Post Town',
    validation: VALID.TOWN.required('Post town is required'),
  },
  zip: {
    label: 'Postal Code',
    validation: VALID.POSTAL_CODE.required('Postal code is required'),
  },
};

export default FIELD;
