import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import cx from 'classnames';

import { ConfirmationImage } from 'assets/images';
import { Button, Content } from 'common/components';
import { BORDER, EVENT, ROUTE, TEXT } from 'common/const';
import { Page } from 'common/site';
import { useOrders, useShop } from 'app/context';

import styles from './Confirmation.module.css';
import { useAppInfo } from 'common/hooks';

const Confirmation = () => {
  const { id } = useParams();
  const { refetch: refetchOrders, findOrder } = useOrders();
  const order = useMemo(() => findOrder(id), [id, findOrder]);
  const { reset: resetShop, ...shop } = useShop();
  const { isLoading, data } = useAppInfo();

  useEffect(() => {
    if (shop.ooId || shop.eventId) {
      refetchOrders();
      resetShop();
    }
  }, [refetchOrders, resetShop, shop]);

  return (
    <Page variant="centered" className={styles.page} title="Order Confirmation">
      <Content isLoading={isLoading}>
        <div className={styles.image}>
          <img src={ConfirmationImage} alt="Confirmation" />
        </div>
        <h1 className={cx(TEXT.H4, styles.title)}>Thank you for your order!</h1>
        <p className={styles.body}>
          You will receive an order confirmation via email within the next 30
          minutes. If you don't receive it check your spam/junk email folder.
        </p>
        <p className={styles.body}>
          You can also click the 'View My Orders' button below. From there you
          can print or download a copy of your order statement.
        </p>
        <p className={styles.body}>
          If we have questions regarding your order, we will contact you for
          clarification.
        </p>

        <Button outlined fullWidth to={ROUTE.ORDERS}>
          View My Orders
        </Button>
        {data?.showFeedbackForm && (
          <div className={cx(BORDER.GREY_200, styles.feedback)}>
            <h2 className={cx(TEXT.H6, styles.header)}>Help us improve</h2>
            <p className={styles.body}>
              We really want to improve your experience with Online Ordering.
              Let us know what you think by taking this 5-minute survey.
            </p>
            <Button
              color="primary"
              fullWidth
              href={EVENT.getConfirmationForm(order?.eventId)}
            >
              Take survey
            </Button>
          </div>
        )}
      </Content>
    </Page>
  );
};

export default Confirmation;
