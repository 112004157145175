import { useState } from 'react';
import { string } from 'prop-types';
import { InputAdornment } from '@mui/material';
import { startCase } from 'lodash';

import { IconButton, Input } from 'common/components';

const Password = ({ error, helperText, id, label, name, ...rest }) => {
  // console.log('<Password>', { name, error, rest });
  const [show, setShow] = useState(false);

  const handleClickShow = () => {
    setShow((current) => !current);
  };

  return (
    <Input
      error={error}
      helperText={error ?? helperText}
      name={name}
      id={id ?? name}
      label={label ?? startCase(name)}
      type={show ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShow}
              edge="end"
              icon={show ? 'visibility-off' : 'visibility'}
            ></IconButton>
          </InputAdornment>
        ),
      }}
      {...rest} // goes last so you can override defaults
    />
  );
};
Password.propTypes = {
  error: string,
  helperText: string,
  id: string,
  name: string,
  label: string,
};

Password.defaultProps = {
  name: 'password',
};

export default Password;
