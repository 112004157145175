import { forwardRef, useMemo, useState } from 'react';
import { any, bool, func, object, string } from 'prop-types';
import Payment from 'payment';

import { VALID } from 'common/const';
import { Form, Input } from 'common/components';
import { formatExp, getCreditCard } from 'common/utils';

import styles from './PaymentForm.module.css';

const PaymentForm = forwardRef(
  ({ isSubmitting, onSubmit, defaultValues, type, ccTypes }, ref) => {
    // console.log('<PaymentForm>', { buttonText, defaultValues, isSubmitting });

    const [cardNum, setCardNum] = useState(defaultValues?.accountNumber ?? '');
    const [exp, setExp] = useState(defaultValues?.expiration ?? '');

    const { alt, logo } = useMemo(() => {
      const cc = getCreditCard(cardNum);
      return ccTypes?.some((card) => card === cc.db) ? cc : {};
    }, [cardNum, ccTypes]);

    return (
      <Form
        validation={{
          firstName: VALID.FULL_NAME.required(
            'Cardholder first name is required',
          ),
          lastName: VALID.FULL_NAME.required(
            'Cardholder last name is required',
          ),
          accountNumber: VALID.CARD_NUMBER.required(
            'Card number is required',
          ).test(
            'type',
            'Your card does not support this type of purchase.',
            (num) =>
              ccTypes.some(
                (cc) => cc.toLowerCase() === Payment.fns.cardType(num),
              ),
          ),
          expiration: VALID.EXPIRATION_DATE.required(
            'Expiration date is required',
          ),
          cvv: VALID.CVV.required('CVV is required'),
        }}
        onPass={(data) => {
          onSubmit(data);
        }}
        onFail={(errors) => {
          // console.error({ errors });
        }}
        defaultValues={defaultValues}
        isSubmitting={isSubmitting}
        ref={ref}
      >
        <fieldset className={styles.fieldset}>
          {/* PAYMENT */}
          <ol>
            <li className={styles.line}>
              <Input
                id={type + 'firstName'}
                name="firstName"
                label="Cardholder First Name *"
                required
              />
              <Input
                id={type + 'lastName'}
                name="lastName"
                label="Cardholder Last Name *"
                required
              />
            </li>
            <li>
              <Input
                id={type + 'accountNumber'}
                name="accountNumber"
                label="Card Number *"
                maxlength={19}
                suffix={alt ? <img alt={alt} src={logo} /> : undefined}
                onChange={(event) => setCardNum(event.target.value)}
                value={Payment.fns.formatCardNumber(cardNum)}
                required
              />
            </li>

            <li className={styles.line}>
              <Input
                id={type + 'expiration'}
                name="expiration"
                label="Expiration Date (MM/YY) *"
                maxlength={5}
                required
                onChange={(event) => setExp(event.target.value)}
                value={formatExp(exp)}
              />
              <Input
                id={type + 'cvv'}
                name="cvv"
                label="Security Code *"
                required
                maxlength={4}
                pattern="/^[0-9]+$/"
              />
            </li>
          </ol>
        </fieldset>
      </Form>
    );
  },
);

PaymentForm.propTypes = {
  defaultValues: object,
  isSubmitting: bool,
  onSubmit: func,
  buttonText: string,
  children: any,
};

export default PaymentForm;
