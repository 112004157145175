import axios from 'axios';
import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getDocumentIdByEmpExhId.mock';

const baseUrl = getBaseUrl(2);

/**
 * Gets documents for a given EMP order
 * @param eventId - id for event
 * @param exhId - emp exh id
 * @returns document id
 */
const getDocumentIdByEmpExhId = async ({ eventId, exhId }) => {
  await VALID.checkAll(
    ['eventId', eventId, VALID.EVENT_ID],
    ['exhId', exhId, VALID.UUID],
  );
  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(
          `${baseUrl}document/list_by_emp_order/${exhId}/${eventId}`,
        );

    return data.documents;
  } catch (error) {
    log('getDocumentIdByEmpExhId', {
      data: { eventId, exhId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getDocumentIdByEmpExhId;
