import { node, oneOfType, string } from 'prop-types';
import { Alert } from 'common/components';

const Error = ({ title, children }) => {
  // console.log('<Error>', { title, children });

  return (
    <Alert severity="error" title={title}>
      {children}
    </Alert>
  );
};

Error.propTypes = {
  children: oneOfType([node, string]).isRequired,
  title: string,
};

export default Error;
