import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { string, any, number } from 'prop-types';
import { Box } from '@mui/material';

import { TextLink, Icon } from 'common/components';
import { BORDER, COLOR, TEXT } from 'common/const';
import { currency } from 'common/utils';
import useHeaderHeight from 'common/site/useHeaderHeight';

import styles from './SummaryDisplay.module.css';
import cx from 'classnames';

const SummaryDisplay = ({ className, children, total }) => {
  const [isOpen, setIsOpen] = useState(false);

  const CONTENT_ID = 'Summary';

  const headerHeight = useHeaderHeight();

  // close when url changes
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  // Go to top of page when opened/closed
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isOpen]);

  return (
    <Box
      className={cx(styles.wrap, className)} /* z-index for mobile */
      style={{ top: headerHeight }}
      component="section"
    >
      {/* Mobile */}
      <div className={cx(BORDER.GREY_100, styles.group)}>
        <TextLink
          aria-controls={CONTENT_ID}
          aria-expanded={isOpen}
          color="inherit"
          onClick={() => {
            setIsOpen((isOpen) => !isOpen);
          }}
        >
          <div className={cx(COLOR.PRIMARY, styles.align)}>
            <div className={styles.cart}>
              <Icon name={'cart'} size="medium" />
            </div>
            <span className={cx(TEXT.BODY_2)}>
              {isOpen ? 'Hide Order Summary' : 'Show Order Summary'}
            </span>
            <Icon name={isOpen ? 'caret-up' : 'caret-down'} size="small" />
          </div>
        </TextLink>

        <div className={cx(TEXT.BOLD, styles.currency)}>{currency(total)}</div>
      </div>
      {/* Content */}
      <div
        id={CONTENT_ID}
        className={cx(styles.content, {
          [styles.open]: isOpen,
        })}
      >
        {children}
      </div>
    </Box>
  );
};

SummaryDisplay.propTypes = {
  className: string,
  children: any,
  total: number,
};

export default SummaryDisplay;
