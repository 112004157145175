import axios from 'axios';
import { getBaseUrl, getSiteOwnership, log } from 'common/utils';

import { ENV, VALID } from 'common/const';
const baseUrl = getBaseUrl(2);
const ownership = getSiteOwnership();

/**
 * resets the password
 * @param {string} email
 * @returns "Successful" string
 */
const resetPassword = async ({ email }) => {
  try {
    await VALID.check(email, VALID.EMAIL.required('email is required.'));

    if (!ENV.IS_MOCK) {
      await axios.post(`${baseUrl}customer/reset_password`, {
        email: `${email}`,
        ownership: `${ownership}`,
      });
    }
  } catch (error) {
    log('resetPassword', {
      data: { email },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default resetPassword;
