import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './initializeOrder.mock';

const baseUrl = getBaseUrl(2);

/**
 * Initialize an order
 * @param {string} customerId - customerId
 * @param {string} eventId - eventId
 * @param {integer} boothNumber - eventId
 * @returns {integer} OrderId
 */
const initializeOrder = async ({ customerId, eventId, boothNumber }) => {
  // console.log('$ initializeOrder', { customerId, eventId, boothNumber });

  await VALID.checkAll(
    ['customerId', customerId, VALID.CUSTOMER_ID],
    ['eventId', eventId, VALID.EVENT_ID],
    ['boothNumber', boothNumber, VALID.STRING],
  );

  try {
    const postData = {
      customerID: customerId,
      eventID: eventId,
      boothNum: boothNumber,
    };

    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.post(`${baseUrl}orders/initialize_order`, postData);

    return { orderId: data.orderId };
  } catch (error) {
    log('initializeOrders', {
      data: { customerId, eventId, boothNumber },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default initializeOrder;
