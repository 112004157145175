import { useEffect, useMemo, useReducer } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useCustomer, useOrders, useShop } from 'app/context';
import { Breadcrumbs, Require, Scrollbar } from 'common/components';
import { ROUTE } from 'common/const';
import { Page } from 'common/site';
import { useCartTotals, useEvent } from 'common/hooks';
import { calculateCartTotals } from 'common/utils';
import {
  getDocumentIdByEmpOrderId,
  getDocumentIdByOrder,
} from 'data/documents';

import { BoothQuestions, Contact, Payment, Review } from '../Steps';
import { Summary, ReviseSummary } from './Summary';
import reducer, { init, TYPE } from './reducer';

import styles from './Checkout.module.css';
import PrivateRoute from 'routes/PrivateRoute';

const Checkout = () => {
  const { ooId, eventId, reviseOoId } = useShop();
  const { merchantId } = useEvent(eventId).data;
  const orders = useOrders();
  const { customerId } = useCustomer();
  const order = useMemo(() => orders.findOrder(ooId), [orders, ooId]);

  const { data: documents, isLoading: isLoadingDocumentId } = useQuery(
    ['getDocumentIdByOrder'],
    () =>
      order?.exhId
        ? getDocumentIdByEmpOrderId({ eventId, exhId: order?.exhId })
        : getDocumentIdByOrder({ eventId, ooId }),

    { refetchOnMount: true },
  );

  const [state, dispatch] = useReducer(
    reducer,
    {
      isThirdParty: order?.isThirdParty,
      thirdPartyCompanyName: order?.thirdPartyCompanyName,
      thirdPartyCompanyResponseId: order?.assessmentResponseData?.find(
        ({ assessmentId }) => assessmentId === 12,
      )?.id,
      eventId: eventId,
      ooId,
      customerId,
      merchantId,
      isFloorPlanUploaded: Boolean(documents?.length),
      contactAddress: order?.billingInformation,
      reviseOoId,
      itemDifferencesTotals: order?.itemDifferencesTotals,
    },
    init,
  );

  // const setError = (err) => dispatch({ type: TYPE.ERROR, payload: err });

  const updateState = (payload) => {
    dispatch({
      type: TYPE.UPDATE,
      payload,
    });
  };

  const { isWireTransferTaxable, isServiceFeeTaxable, salesTax } =
    useEvent(eventId).data;

  // TODO!!! We don't need to get this data on revise
  const { totals } = useCartTotals();

  const totalsData = useMemo(
    () =>
      calculateCartTotals({
        paymentMethod: state.paymentMethod,
        totals: reviseOoId ? order?.itemDifferencesTotals : totals,
        isWireTransferTaxable,
        isServiceFeeTaxable,
        salesTax,
      }),
    [
      state,
      totals,
      isWireTransferTaxable,
      isServiceFeeTaxable,
      salesTax,
      reviseOoId,
      order,
    ],
  );

  useEffect(() => {
    if (!isLoadingDocumentId) {
      updateState({
        isFloorPlanUploaded: Boolean(documents.length),
        documentId: documents[0]?.id ?? undefined,
      });
    }
  }, [isLoadingDocumentId, documents]);

  return (
    <Require shop>
      <PrivateRoute path={ROUTE.CHECKOUT}>
        <Page
          variant="checkout"
          backTo={ROUTE.CATALOG}
          title="Checkout"
          summary={
            <Scrollbar className={styles.summary}>
              {reviseOoId ? (
                <ReviseSummary
                  order={order}
                  paymentMethod={state.paymentMethod}
                  itemDifferencesTotals={totalsData}
                />
              ) : (
                <Summary
                  order={order}
                  paymentMethod={state.paymentMethod}
                  totals={totalsData}
                />
              )}
            </Scrollbar>
          }
          total={totalsData.total}
        >
          <div className={styles.steps}>
            <Breadcrumbs
              steps={state.steps}
              currentStep={state.currentStep}
              setCurrentStep={updateState}
            />

            <PrivateRoute
              exact
              path={ROUTE.BOOTH_WORK}
              render={() => (
                <BoothQuestions
                  setState={updateState}
                  isFloorPlanUploaded={state?.isFloorPlanUploaded}
                  documentId={state?.documentId}
                />
              )}
            />

            <PrivateRoute
              exact
              path={ROUTE.CONTACT}
              render={() => <Contact setState={updateState} state={state} />}
            />

            <PrivateRoute
              exact
              path={ROUTE.PAYMENT}
              render={() => <Payment setState={updateState} state={state} />}
            />

            <PrivateRoute
              exact
              path={ROUTE.REVIEW}
              render={() => <Review state={state} setState={updateState} />}
            />
          </div>
        </Page>
      </PrivateRoute>
    </Require>
  );
};

export default Checkout;
