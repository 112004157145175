import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { VALID } from 'common/const';

const baseUrl = getBaseUrl(2);

/**
 * Gets totals for an incomplete order specified by parameters
 * @param {string} empOrderId - Id for EMP order
 * @returns
 */

const releaseReviseLock = async ({ empOrderId }) => {
  await VALID.check(empOrderId, VALID.UUID.required('empOrderId is required'));

  try {
    await axios.post(`${baseUrl}orders/release_revise_lock`, { empOrderId });
  } catch (error) {
    log('releaseReviseLock', {
      data: { empOrderId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default releaseReviseLock;
