import { eventCatalog, packageData } from 'data/mock-data';

const mock = () => {
  return {
    data: {
      categoryData: eventCatalog,
      packageData: packageData,
    },
  };
};

export default mock;
