import { isPlainObject } from 'lodash';

/**
 * Consistently update a string to an object for dropdown, select, etc
 * @param {string|number|Object} option - single option
 * @param {Object} [additionalProperties] - additional properties to add
 * @returns {Object} consistent object for dropdown, select, etc
 */
const makeOption = (option, additionalProperties = {}) => {
  // console.log('!makeOption', { option, additionalProperties });
  const isGoodOption =
    ['string', 'number'].includes(typeof option) || isPlainObject(option);

  if (!isGoodOption) {
    throw Error(
      `makeOption expects 'string' or 'object' as 'option', but received {${typeof option}}: ${option}`,
    );
  }

  const props = isPlainObject(additionalProperties) ? additionalProperties : {};

  return typeof option === 'object'
    ? { ...option, ...props }
    : { label: option, value: option, ...props };
};

export default makeOption;
