import { any, bool, oneOf } from 'prop-types';
import cx from 'classnames';

import { Button, Loading } from 'common/components';

import { BUTTON_TYPES } from '../../const';

import styles from './SubmitButton.module.css';

const SubmitButton = ({
  isLoading, // alias
  isSaving, // alias
  isSpinning, // alias
  isSubmitting,
  className, // for concatenation
  type,
  disabled,
  children,
  ...rest
}) => {
  const showLoading = isLoading || isSaving || isSpinning || isSubmitting;

  return (
    <Button
      {...rest}
      type={type}
      className={cx(styles.button, className)}
      disabled={disabled || showLoading}
    >
      <span
        className={cx({
          [styles.submitting]: showLoading,
        })}
      >
        {children}
      </span>

      {showLoading && (
        <span className={styles.loading}>
          <Loading size="button" color="inherit" />
        </span>
      )}
    </Button>
  );
};

SubmitButton.propTypes = {
  isLoading: bool,
  isSaving: bool,
  isSpinning: bool,
  isSubmitting: bool,
  type: oneOf(BUTTON_TYPES),
  children: any,
};

SubmitButton.defaultProps = {
  type: 'submit',
};

export default SubmitButton;
