import { createContext, useCallback, useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { authCookie, jwtCookie } from 'common/utils';

const INIT_STATE = {
  customerId: undefined,
  isAuthenticated: false,
};

const Auth = createContext();

export const AuthProvider = ({ customerId: initCustomerId, children }) => {
  const queryClient = useQueryClient();
  const customerId = initCustomerId ?? authCookie.get();
  const jwt = jwtCookie.get();

  const [state, setState] = useState({
    customerId, // added for easy ref around the site
    isAuthenticated: Boolean(customerId) && Boolean(jwt),
  });

  const login = useCallback(({ customerId, jwtToken }) => {
    // console.log({ customerId, jwtToken });

    // use cookie to set token to expire on session close
    jwtCookie.set(jwtToken);

    // use cookie to set auth to expire after a specific time
    authCookie.set(customerId);

    setState({
      customerId,
      isAuthenticated: Boolean(customerId) && Boolean(jwtToken),
    });
  }, []);

  const logout = useCallback(() => {
    jwtCookie.remove();
    authCookie.remove();
    setState(INIT_STATE);
    queryClient.clear();
  }, [queryClient]);

  const update = useCallback(() => {
    const isLoggedIn = authCookie.exists() && jwtCookie.exists();

    return isLoggedIn ? authCookie.set(customerId) : logout();
  }, [logout, customerId]);

  return (
    <Auth.Provider
      value={{
        ...state,
        login,
        logout,
        update,
      }}
    >
      {children}
    </Auth.Provider>
  );
};

export const useAuth = () => useContext(Auth);
