import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getEventAssessmentLocation.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get assessment data for locations questions
 * @param {string} eventId
 * @param {string} ooId
 * @returns Array of response options
 */
const getEventAssessmentLocation = async ({ eventId, ooId }) => {
  await VALID.checkAll(
    ['eventId', eventId, VALID.EVENT_ID],
    ['ooId', ooId, VALID.OO_SUMMARY_ID],
  );
  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(
          `${baseUrl}events/assessment_location_responses?eventId=${eventId}&ooId=${ooId}`,
        );

    return data.responseOptions;
  } catch (error) {
    log('getEventAssessmentList', {
      data: { eventId, ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getEventAssessmentLocation;
