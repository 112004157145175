import { sortedUniq } from 'lodash';

/**
 * Get a sorted list of strings, from the filtered property
 * @param {array} data - list of event objects
 * @param {array} filterNames - list of filter properties to pull
 * @returns {array} sorted list of strings, for options
 */
const getOptions = (data, filterNames) => {
  // console.log('!getOptions', { data, filterNames });

  const list = data
    .reduce((arr, item) => {
      return [...arr, ...filterNames.map((filter) => item[filter])];
    }, [])
    .filter((item) => item) // ensure we have a value
    .map((item) => item.toString()); // ensure we have a string

  const sortedList = list.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  return sortedUniq(sortedList);
};

export default getOptions;
