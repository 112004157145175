import cx from 'classnames';

import { COLOR, CREDIT_CARD, TEXT } from 'common/const';

import styles from './CreditCardInfo.module.css';

const CreditCardInfo = ({ ccTypes }) => (
  <p className={styles.wrap}>
    <span className={cx(TEXT.CAPTION, COLOR.GREY_800)}>
      We only accept U.S. based financial institutions (USD $)
    </span>
    <span className={styles.cards}>
      {Object.entries(CREDIT_CARD).map(
        ([key, { logo, alt }]) =>
          ccTypes?.some((cc) => key === cc) && (
            <img src={logo} alt={alt} key={key} />
          ),
      )}
    </span>
  </p>
);

export default CreditCardInfo;
