import { useEffect, useMemo, useState } from 'react';
import { Redirect, matchPath, useHistory } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { flatten } from 'lodash';

import { ROUTE, TEXT } from 'common/const';
import { getEventCatalog } from 'data/events';
import { CartProvider, useOrders, useShop } from 'app/context';
import { Error, EventOfflineDialog, Require } from 'common/components';
import { Page } from 'common/site';
import PrivateRoute from 'routes/PrivateRoute';
import { useEvent, useEventStatus } from 'common/hooks';

import ProductDetail from './ProductDetail';
import Catalog from './Catalog';
import Packages from './Catalog/Packages';
import Filters from './Catalog/Filters';
import Cart from './Cart';
import EventInfo from './EventInfo';

import styles from './Shopping.module.css';

const Shopping = ({ location }) => {
  const item = location.state;

  // console.log('<Shopping>', { location, item });
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const { eventId, ooId, scrollTo, update: updateShop, ...shop } = useShop();

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.replace(ROUTE.DASHBOARD);
      }
    };
  }, [history]);

  useEffect(() => {
    const isCatalog = matchPath(location.pathname, {
      path: ROUTE.CATALOG + '/:selected',
    });

    if (isCatalog && scrollTo) {
      window.scrollTo({ left: 0, top: scrollTo ?? 0, behavior: 'auto' });
      updateShop({ scrollTo: 0 });
    }
  }, [location, scrollTo, updateShop]);

  const { current: order = {} } = useOrders();
  const { isOffline } = useEventStatus(eventId);

  const {
    isLoading: isLoadingCatalog,
    error: catalogError,
    data = { catalog: [], packageData: [] },
  } = useQuery(
    ['getEventCatalog', { eventId, ooId }],
    () =>
      getEventCatalog({
        eventId,
        ooId,
        boothType: order?.booth?.type,
        boothLocation: order?.booth?.location,
      }),
    { enabled: Boolean(eventId), cacheTime: 0 },
  );

  const { catalog = [], packageData = [] } = useMemo(() => data, [data]);

  const error = useMemo(() => catalogError?.message, [catalogError]);

  // get filters from response data
  const filters = useMemo(() => {
    const getFilter = ({ description }, options) =>
      Object.assign({}, options, { name: description });
    const packagesFilter = data?.packageData?.categories?.length
      ? getFilter(data?.packageData, { isPackage: true })
      : undefined;

    return [...data?.catalog.map(getFilter), packagesFilter].filter(
      (filter) => filter !== undefined,
    );
  }, [data]);

  // update our shop context
  useEffect(() => {
    // only do this once
    if (
      shop.categories.length ||
      Object.keys(shop.inventory).length ||
      Object.keys(shop.powerPackage).length
    ) {
      return;
    }

    // wait for catalog
    if (isLoadingCatalog) {
      // show spinner
      if (!shop.isLoading) {
        updateShop({ isLoading: true });
      }

      return;
    }

    // get categories from catalog
    const categories = catalog.map(({ description }) => description) ?? [];

    // get inventory from catalog
    const inventory = catalog.reduce((invObj, { categories }) => {
      const categoryItems = flatten(categories.map(({ items }) => items));

      return {
        ...invObj,
        ...categoryItems.reduce(
          (categoryObject, item) => ({
            ...categoryObject,
            [item.id]: item,
          }),
          {},
        ),
      };
    }, {});

    // get package data
    const powerPackage = packageData.categories?.reduce(
      (packageObj, { items = [] }) => ({
        ...packageObj,
        ...items.reduce(
          (itemObj, item) => ({
            ...itemObj,
            [item.id]: item,
          }),
          {},
        ),
      }),
      {},
    );

    updateShop({ categories, inventory, powerPackage, isLoading: false });
  }, [shop, updateShop, catalog, packageData, isLoadingCatalog]);

  const { data: event = {} } = useEvent(eventId);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (isLoadingCatalog) {
      return;
    }

    if (!selected && filters[0]) {
      setSelected(filters[0].name);
    }
  }, [isLoadingCatalog, filters, selected, setSelected]);

  useEffect(() => {
    if (order.isOffline || isOffline) {
      setIsOpen(true);
    }
  }, [isOffline, order]);

  return (
    <Require shop>
      <CartProvider>
        <Page
          variant="shopping"
          Cart={Cart}
          title="Shopping"
          isLoading={isLoadingCatalog}
          eventInfo={<EventInfo className={TEXT.CAPTION} />}
          filters={
            <Filters
              filters={filters}
              selected={selected}
              onChange={setSelected}
            />
          }
          openCart={
            location?.state?.from === ROUTE.CHECKOUT ||
            shop?.reviseOoId ||
            (location?.state?.from === ROUTE.REUSE_ORDER && order.itemCount > 0)
          }
          faqPdf={event?.faqPdf}
          backTo={
            matchPath(location.pathname, {
              path: ROUTE.PRODUCT + '/:id',
            })?.isExact
              ? ROUTE.CATALOG
              : ROUTE.DASHBOARD
          }
        >
          {/* Redirect /shopping & /shopping/catalog & /shopping/product */}
          <PrivateRoute
            exact
            path={[ROUTE.SHOPPING, ROUTE.CATALOG, ROUTE.PRODUCT]}
          >
            {selected && <Redirect to={ROUTE.CATALOG + '/' + selected} />}
          </PrivateRoute>

          {/* Catalog */}
          <PrivateRoute path={ROUTE.CATALOG}>
            {error && (
              <div className={styles.error}>
                <Error>{error}</Error>
              </div>
            )}
          </PrivateRoute>

          {/* Catalog selected item */}
          <PrivateRoute path={ROUTE.CATALOG + '/:selected'}>
            <Packages
              packageData={packageData}
              isEarlyPricing={event.isEarlyPricing}
            />

            <Catalog catalog={catalog} isEarlyPricing={event.isEarlyPricing} />
          </PrivateRoute>

          {/* ProductDetail */}
          <PrivateRoute path={ROUTE.PRODUCT + '/:id'}>
            <ProductDetail
              itemId={item?.id}
              isEarlyPricing={event.isEarlyPricing}
            />
          </PrivateRoute>

          <EventOfflineDialog isOpen={isOpen} setIsOpen={setIsOpen} />
        </Page>
      </CartProvider>
    </Require>
  );
};

export default Shopping;
