import { useMemo } from 'react';
import { array, func, string } from 'prop-types';
import cx from 'classnames';
import { merge } from 'lodash';

import { BORDER, COLOR, TEXT } from 'common/const';

import styles from './ProductList.module.css';

const FREE = 'free-items';
const REQUIRED = 'required-items';
const STANDARD = 'standard-items';
const INIT_CATEGORY = {
  [FREE]: [],
  [REQUIRED]: [],
  [STANDARD]: [],
};

const ProductList = ({ categories, items, renderItem, groupClassName }) => {
  const list = useMemo(() => {
    const categorized = items.reduce((obj, item) => {
      const name = item.associatedCategory ?? item.category;
      const group = item.isFree ? FREE : item.isRequired ? REQUIRED : STANDARD;
      const category = obj[name] ?? INIT_CATEGORY;

      return {
        ...obj,
        [name]: {
          ...category,
          [group]: [...category[group], item],
        },
      };
    }, {});

    return categories
      .map((name) => {
        const category = merge({}, INIT_CATEGORY, categorized[name]);

        return {
          name,
          items: [...category[FREE], ...category[STANDARD]],
          required: category[REQUIRED],
        };
      })
      .filter(({ items, required }) => items?.length || required?.length);
  }, [items, categories]);

  return (
    <ul className={styles.list}>
      {list.map(({ name, items, required }) => (
        <li
          className={cx(BORDER.DIVIDER, groupClassName, styles.group)}
          key={'group' + name}
        >
          {items.length ? (
            <>
              <header className={cx(styles.header, TEXT.SUBTITLE_1)}>
                {name}
              </header>

              <ul>
                {items.map((item) => (
                  <li key={name + item.id + item.price} className={styles.item}>
                    {renderItem(item)}
                  </li>
                ))}
              </ul>
            </>
          ) : null}

          {required.length ? (
            <>
              <header className={cx(styles.header, TEXT.SUBTITLE_1)}>
                Required Items
                <p
                  className={cx(COLOR.GREY_600, TEXT.BODY_2, styles.subheading)}
                >
                  Included based on your booth setup or the items in your cart
                </p>
              </header>

              <ul>
                {required.map((item) => (
                  <li key={name + item.id + 'required'} className={styles.item}>
                    {renderItem(item)}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

ProductList.propTypes = {
  groupClassName: string,
  items: array,
  renderItem: func,
};

export default ProductList;
