import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';
import { getCart } from 'data/utils';

import mock from './addItemToCart.mock';

const baseUrl = getBaseUrl(2);

/**
 * Reuses an order
 * @param {string} origainlEmpOrderId - order who's items are to be added to new order
 * @param {string} eventId - new eventId to be reused
 * @param {string} customerId - customerId of the account
 * @param {int} newOoId - id of new incomplete order
 * @returns Cart of new order
 */
const reuseOrder = async ({
  originalEmpOrderId,
  eventId,
  customerId,
  newOoId,
}) => {
  await VALID.checkAll(
    ['originalEmpOrderId', originalEmpOrderId, VALID.UUID],
    ['eventId', eventId, VALID.EVENT_ID],
    ['customerId', customerId, VALID.UUID],
    ['newOoId', newOoId, VALID.OOID],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.post(`${baseUrl}orders/reuse_order`, {
          originalEmpOrderId,
          eventId,
          customerId,
          newOoId,
        });

    return getCart(data.cartData);
  } catch (error) {
    log('reuseOrder', {
      data: { originalEmpOrderId, eventId, customerId, newOoId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default reuseOrder;
