import { ListItemButton } from '@mui/material';

const ListButton = ({
  className,
  component,
  href,
  onClick,
  selected,
  children,
}) => (
  <ListItemButton
    component={component}
    href={href}
    onClick={onClick}
    className={className}
    selected={selected}
    sx={{
      '&.Mui-selected': { backgroundColor: 'grey.100' },
    }}
  >
    {children}
  </ListItemButton>
);

export default ListButton;
