import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

const baseUrl = getBaseUrl(2);

/**
 * Initiates cancellation process for an order
 * @param {number} ooId
 * @param {string} reason
 * @returns Success, Not Found, or Error
 */
const cancelOrder = async ({ ooId, reasonId, reason, eventId, email }) => {
  await VALID.checkAll(
    ['ooId', ooId, VALID.OO_SUMMARY_ID],
    ['reason', reasonId, VALID.STRING],
    ['eventId', eventId, VALID.EVENT_ID],
    ['email', email, VALID.EMAIL],
  );

  // console.log({ ooId, reasonId, reason, eventId, email });
  try {
    if (!ENV.IS_MOCK) {
      await axios.post(`${baseUrl}orders/cancel`, {
        email,
        eventId,
        ooId,
        reason,
        reasonId,
      });
    }
  } catch (error) {
    log('cancelOrder', {
      data: { ooId, reason, reasonId, eventId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default cancelOrder;
