// enum to apply color classNames
const COLOR = {
  BLACK: 'mui-color-black',
  WHITE: 'mui-color-white',
  PRIMARY: 'mui-color-primary',
  PRIMARY_LIGHT: 'mui-color-primary-light',
  PRIMARY_DARK: 'mui-color-primary-dark',
  PRIMARY_TEXT: 'mui-color-primary-text',
  SECONDARY: 'mui-color-secondary',
  SECONDARY_LIGHT: 'mui-color-secondary-light',
  SECONDARY_DARK: 'mui-color-secondary-dark',
  SECONDARY_TEXT: 'mui-color-secondary-text',
  ERROR: 'mui-color-error',
  ERROR_LIGHT: 'mui-color-error-light',
  ERROR_DARK: 'mui-color-error-dark',
  ERROR_TEXT: 'mui-color-error-text',
  WARNING: 'mui-color-warning',
  WARNING_LIGHT: 'mui-color-warning-light',
  WARNING_DARK: 'mui-color-warning-dark',
  WARNING_TEXT: 'mui-color-warning-text',
  INFO: 'mui-color-info',
  INFO_LIGHT: 'mui-color-info-light',
  INFO_DARK: 'mui-color-info-dark',
  INFO_TEXT: 'mui-color-info-text',
  SUCCESS: 'mui-color-success',
  SUCCESS_LIGHT: 'mui-color-success-light',
  SUCCESS_DARK: 'mui-color-success-dark',
  SUCCESS_TEXT: 'mui-color-success-text',
  GREY_50: 'mui-color-grey-50',
  GREY_100: 'mui-color-grey-100',
  GREY_200: 'mui-color-grey-200',
  GREY_300: 'mui-color-grey-300',
  GREY_400: 'mui-color-grey-400',
  GREY_500: 'mui-color-grey-500',
  GREY_600: 'mui-color-grey-600',
  GREY_700: 'mui-color-grey-700',
  GREY_800: 'mui-color-grey-800',
  GREY_900: 'mui-color-grey-900',
  GREY_A100: 'mui-color-a-100',
  GREY_A200: 'mui-color-a-200',
  GREY_A400: 'mui-color-a-400',
  GREY_A700: 'mui-color-a-700',
  TEXT: 'mui-color-text',
  TEXT_SECONDARY: 'mui-color-text-secondary',
  TEXT_DISABLED: 'mui-color-disabled',
  DIVIDER: 'mui-color-divider',
  PAPER: 'mui-color-paper',
  BACKGROUND: 'mui-color-background',
  ACTION_ACTIVE: 'mui-color-action-active',
  ACTION_HOVER: 'mui-color-action-hover',
  ACTION_SELECTED: 'mui-color-action-selected',
  ACTION_DISABLED: 'mui-color-action-disabled',
  ACTION_DISABLED_BACKGROUND: 'mui-color-action-disabled-background',
  ACTION_FOCUS: 'mui-color-action-focus',

  // Custom colors
  HEADER: 'mui-color-header',
  HEADER_BUTTON: 'mui-color-header-button',
  BOOTH_PACKAGE: 'mui-color-booth-package',
  BOOTH_PACKAGE_LIGHT: 'mui-color-booth-package-light',
  BOOTH_PACKAGE_DARK: 'mui-color-booth-package-dark',
  BOOTH_PACKAGE_TEXT: 'mui-color-booth-package-text',
  POWER_PACKAGE: 'mui-color-power-package',
  POWER_PACKAGE_LIGHT: 'mui-color-power-package-light',
  POWER_PACKAGE_DARK: 'mui-color-power-package-dark',
  POWER_PACKAGE_TEXT: 'mui-color-power-package-text',
};

export default COLOR;
