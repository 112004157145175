import axios from 'axios';

import { ENV } from 'common/const';
import { getBaseUrl, log } from 'common/utils';
import { getFaqGrouping } from 'data/utils/getItem/utils';

import mock from './getFaqs.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get list of FAQs
 * @returns array of FAQs
 */
const getFaqs = async () => {
  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}lookup/faqs`);

    return getFaqGrouping(data.faqs);
  } catch (error) {
    log('getFaqs', {
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getFaqs;
