import { bool, func } from 'prop-types';
import cx from 'classnames';
import { Dialog, TextLink } from 'common/components';
import { ROUTE } from 'common/const';
import styles from './EventOfflineDialog.module.css';

const EventOfflineDialog = ({ isOpen, setIsOpen }) => {
  // console.log('<EventOfflineDialog> ', { setIsOpen, isOpen });

  return (
    <Dialog
      id="event-offline"
      title="Event Offline"
      size="xs"
      isOpen={isOpen}
      close={() => {}}
      onSubmit={() => setIsOpen(false)}
      submitText="Return to Dashboard"
      to={ROUTE.DASHBOARD}
    >
      <p className={styles.content}>
        This event for your order is offline right now. Here's what may have
        caused this:
      </p>
      <ul className={cx(styles.list, styles.content)}>
        <li>Site maintenance</li>
        <li>Event updates</li>
      </ul>
      <p className={styles.content}>
        Please check back in 30 minutes to resume your order; however, your
        order will be cleared. We apologize for the inconvenience.
      </p>
      <p>
        If you have questions,{' '}
        <TextLink href="https://edlen.com/contact/">contact us</TextLink>.
      </p>
    </Dialog>
  );
};

EventOfflineDialog.propTypes = {
  isOpen: bool,
  setIsOpen: func,
};

export default EventOfflineDialog;
