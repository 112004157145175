import { any, object, string } from 'prop-types';

import { Button } from 'common/components';
import { TEXT } from 'common/const';

import styles from './SectionHeader.module.css';

const SectionHeader = ({ action, el: El = 'h3', children }) => {
  const { children: actionChildren = 'Edit', ...actionProps } = action ?? {};

  return (
    <div className={styles.header}>
      <El className={TEXT.SUBTITLE}>{children}</El>

      {action && (
        <Button text size="medium" {...actionProps}>
          {actionChildren}
        </Button>
      )}
    </div>
  );
};

SectionHeader.propTypes = {
  action: object,
  el: string,
  children: any,
};

export default SectionHeader;
