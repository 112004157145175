import { deepmerge } from '@mui/utils';
import common from './common';

const cajun = deepmerge(common, {
  palette: {
    primary: {
      main: '#7b0015',
      light: '#b03c3b',
      dark: '#4a0000',
    },
  },
});

export default cajun;
