import { useMemo, useState } from 'react';
import { bool, node, oneOfType, string } from 'prop-types';
import cx from 'classnames';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import { COLOR } from 'common/const';
import { Icon } from 'common/components';

import styles from './Accordion.module.css';

const Accordion = ({
  active,
  disabled,
  expandIcon,
  id,
  onChange,
  radios,
  summary,
  children,
  ...rest
}) => {
  // console.log('<Accordion>', { active, id, onChange, children, rest });

  const [isActive, setIsActive] = useState(active ?? false);
  const expanded = useMemo(() => active ?? isActive, [active, isActive]);

  const handleChange = (panel) => (event, isExpanded) => {
    onChange(panel);
    setIsActive(() => isExpanded);
  };

  return (
    <MuiAccordion
      onChange={handleChange(id)}
      disabled={disabled}
      expanded={disabled ? false : expanded} // can be controlled or uncontrolled
      disableGutters
      square
      elevation={0}
      sx={{
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: 'grey.600',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          radios ? null : expandIcon ?? <Icon name="caretDown" color="info" />
        }
        id={`${id}-summary`}
        aria-controls={`${id}-content`}
        sx={{ '&.Mui-disabled': { opacity: 1 } }}
      >
        {radios && (
          <span
            className={cx(styles.radio, {
              [COLOR.PRIMARY]: expanded,
              [COLOR.GREY_200]: disabled,
            })}
          >
            <Icon
              name={
                disabled
                  ? 'radio-filled'
                  : expanded
                  ? 'radio-checked'
                  : 'radio-empty'
              }
              size="medium"
            />
          </span>
        )}
        {summary}
      </AccordionSummary>

      {children && (
        <AccordionDetails id={`${id}-details`}>{children}</AccordionDetails>
      )}
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  active: bool,
  radios: bool,
  expandIcon: oneOfType([node, string]),
  id: string.isRequired,
  summary: oneOfType([node, string]).isRequired,
  children: node,
};

Accordion.defaultProps = {
  onChange: () => null,
};

export default Accordion;
