import cx from 'classnames';

import { TEXT } from 'common/const';

import styles from './SectionTitle.module.css';

const SectionTitle = ({ children }) => (
  <h2 className={cx(TEXT.SUBTITLE, styles.title)}>{children}</h2>
);

export default SectionTitle;
