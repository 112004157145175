import { Alert, Icon } from 'common/components';

import styles from './NewFeature.module.css';

const NewFeature = ({ image, text, children }) => {
  return (
    <>
      <p>{text}</p>
      <div className={styles.image}>{image}</div>
      <div className={styles.alert}>
        <Alert icon={<Icon name="tip" color="info" />}>{children}</Alert>
      </div>
    </>
  );
};

export default NewFeature;
