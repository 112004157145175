import axios from 'axios';

import { getBaseUrl, getProductImage, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getFloorPlanItems.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get a list of completed orders
 * @param {string} customerId
 * @returns Array of completed orders
 */
const getFloorPlanItems = async ({ ooId }) => {
  await VALID.check(ooId, VALID.OOID.required('ooId is required'));

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}floorplan/items/${ooId}`);

    const { floorPlanItems } = data;

    return floorPlanItems.flatMap((item) =>
      [...Array(item.quantity)].map((_, i) => ({
        itemID: item.itemId,
        txt: item.name,
        electricianText: item.shortDescription,
        img: getProductImage(item.image),
        count: i + 1,
      })),
    );
  } catch (error) {
    log('getFloorPlanItems', {
      data: { ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getFloorPlanItems;
