import Cookies from 'js-cookie';

export const NAME = 'jwtToken';

const jwtCookie = {
  exists: () => Boolean(Cookies.get(NAME)),
  get: () => Cookies.get(NAME),
  remove: () => Cookies.remove(NAME),
  // Set no expiry time so it ends on session
  set: (value) => Cookies.set(NAME, value),
};

export default jwtCookie;
