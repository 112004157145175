import { useState } from 'react';
import { string } from 'prop-types';
import cx from 'classnames';
import { AppBar, Toolbar } from '@mui/material';
import { useTheme } from '@mui/system';

import { BACKGROUND, COLOR, ROUTE } from 'common/const';
import { Dialog, IconButton } from 'common/components';

import Logo from 'common/site/Logo';

import styles from './Header.module.css';

export const HEADER_ID = 'app-header';

const Header = ({ backTo, children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogRoute, setDialogRoute] = useState(backTo);
  const { header } = useTheme();

  return (
    <>
      <AppBar id={HEADER_ID} position="sticky" color="transparent">
        <Toolbar
          className={cx(BACKGROUND.HEADER, COLOR.HEADER, styles.toolbar)}
        >
          <IconButton
            icon="arrowBack"
            onClick={() => setIsDialogOpen(true)}
            edge="start"
            color="inherit"
            aria-label="Go Back"
          />

          <div className={styles.logo}>
            <Logo
              name={header?.logo}
              onClick={() => {
                setDialogRoute(ROUTE.DASHBOARD);
                setIsDialogOpen(true);
              }}
            />
          </div>

          <div className={styles.children}>{children}</div>
        </Toolbar>
      </AppBar>

      <Dialog
        id="leave-booth-builder"
        title="Leave Booth Builder"
        size="xs"
        isOpen={isDialogOpen}
        close={() => setIsDialogOpen(false)}
        onCancel={() => setIsDialogOpen(false)}
        onSubmit={() => {}}
        submitText="Yes, Leave Builder"
        cancelText="No, Stay"
        to={dialogRoute}
      >
        Are you sure you want to leave the Booth Builder? You will lose progress
        on this page.
      </Dialog>
    </>
  );
};

Header.propTypes = {
  backTo: string,
};

export default Header;
