import { createRef, useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { v4 } from 'uuid';
import { useHistory } from 'react-router';

import { ADDRESS_TYPE, PAYMENT_METHOD, ROUTE, TEXT, VALID } from 'common/const';
import { useOrders } from 'app/context';
import { remoteSubmit, track } from 'common/utils';
import {
  useAddress,
  useCartTotals,
  useEvent,
  useEventStatus,
} from 'common/hooks';
import {
  Accordion,
  AccordionGroup,
  Alert,
  Button,
  Content,
  CountryInput,
  CreditCardInfo,
  EventOfflineDialog,
  Form,
  PaymentForm,
  SubmitButton,
  TransferInfo,
} from 'common/components';

import useEventPaymentMethods from './useEventPaymentMethods';

import cx from 'classnames';
import styles from './Payment.module.css';
import getEventCreditCardTypes from 'data/events/getEventCreditCardTypes';

const { BILLING, COMPANY } = ADDRESS_TYPE;
const { CARD, ACH, WIRE } = PAYMENT_METHOD;
const UUID = v4();

const Payment = ({ setState, state }) => {
  // console.log('<Payment>', state, setState)
  const history = useHistory();
  const { isOffline } = useEventStatus(state.eventId);

  const { findOrder } = useOrders();
  const order = findOrder(state.ooId);

  const [isOpen, setIsOpen] = useState(false);
  const [isAddressSubmitted, setIsAddressSubmitted] = useState(false);

  const [addressType, setAddressType] = useState(
    state.billingAddId ? BILLING : COMPANY,
  );
  const [paymentData, setPaymentData] = useState(undefined);

  const ref = {
    [CARD]: createRef(),
    [ACH]: createRef(),
    [WIRE]: createRef(),
  };

  const { isWireTransferTaxable, isServiceFeeTaxable, salesTax } = useEvent(
    state.eventId,
  ).data;

  const { recalculateTotals } = useCartTotals();

  const { data: ccTypes, error: ccTypeError } = useQuery(
    ['getEventCCTypes', state.eventId],
    () => getEventCreditCardTypes({ eventId: state.eventId }),
  );

  const { total, balanceDue } = recalculateTotals({
    paymentMethod: state.paymentMethod,
    totals: state.itemDifferencesTotals,
    isWireTransferTaxable,
    isServiceFeeTaxable,
    salesTax,
  });

  const {
    shouldDisplayPaymentMethod,
    error: eventPaymentError,
    totalsError,
    maxAlert,
    isLoading: isLoadingMethods,
  } = useEventPaymentMethods();

  const [method, setMethod] = useState(state?.paymentMethod);

  useEffect(() => {
    if (!method && !isLoadingMethods) {
      setMethod(shouldDisplayPaymentMethod(CARD) ? CARD : ACH);
    }
  }, [method, isLoadingMethods, setMethod, shouldDisplayPaymentMethod]);

  const {
    addAddress: submitBillingAddress,
    addAddressIsLoading: isSubmittingAddress,
    addAddressError,
    AddressItem,
    buildValidation,
    countries,
    countryCode,
    STYLE,
    updateCountry,
  } = useAddress(
    { ...state.contactAddress, ...state.billingAddress },
    {
      onSuccess: ({ addressId, address }) => {
        setIsAddressSubmitted(true);
        setState({ billingAddId: addressId, billingAddress: address });
        track('add_payment_info');
      },
    },
  );

  useEffect(() => {
    if (
      !addAddressError &&
      !isSubmittingAddress &&
      paymentData &&
      (isAddressSubmitted || addressType === COMPANY)
    ) {
      setState({ paymentMethod: method, paymentData, currentStep: 4 });
      history.push(ROUTE.REVIEW);
    }
  }, [
    paymentData,
    isAddressSubmitted,
    addressType,
    setState,
    history,
    method,
    addAddressError,
    isSubmittingAddress,
  ]);

  const guaranteeAlert =
    total === 0 && !state.reviseOoId && order.items.length
      ? 'A credit card is required as a guarantee and will not process.'
      : undefined;

  const BuildAddressValidation = () => {
    // No validation since form is not active
    if (addressType === COMPANY) {
      return {};
    } else {
      return {
        ...buildValidation(),
        countryCode: VALID.COUNTRY.required('Country is required'),
        firstName: VALID.FIRST_NAME.required('First name is required'),
        lastName: VALID.LAST_NAME.required('Last name is required'),
      };
    }
  };

  useEffect(() => {
    if (isOffline) {
      setIsOpen(true);
    }
  }, [isOffline]);

  // Go to top of page on intial load
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      {Boolean(state.reviseOoId && balanceDue <= 0) ? (
        <div className={styles.alert}>
          <Alert>
            No payment is required at this time. Continue to review and complete
            your order.
          </Alert>
        </div>
      ) : null}
      <h6 className={TEXT.H6}>Payment</h6>
      <Content isLoading={isSubmittingAddress}>
        {/* PAYMENT */}
        <p className={cx(TEXT.SUBTITLE_1, styles.section)}>Payment Method</p>
        <Content
          error={
            eventPaymentError?.message ||
            totalsError ||
            state.error ||
            ccTypeError
          }
          notification={maxAlert || guaranteeAlert}
          isLoading={isLoadingMethods}
        >
          <CreditCardInfo ccTypes={ccTypes} />
          <AccordionGroup
            radios
            defaultActive={
              (method
                ? method
                : shouldDisplayPaymentMethod(CARD)
                ? CARD
                : ACH) + UUID
            }
            onChange={(id) => {
              setMethod(id.replace(UUID, ''));
              setState({ paymentMethod: id.replace(UUID, '') });
            }}
          >
            {/* CARD */}
            {shouldDisplayPaymentMethod(PAYMENT_METHOD.CARD) && (
              <Accordion
                id={CARD + UUID}
                summary="Credit Card"
                disabled={Boolean(state.reviseOoId && balanceDue <= 0)}
              >
                <PaymentForm
                  onSubmit={(data) => {
                    setPaymentData(data);
                  }}
                  defaultValues={paymentData ?? state.paymentData}
                  ref={ref[CARD]}
                  type={CARD}
                  ccTypes={ccTypes}
                />
              </Accordion>
            )}

            {/* ACH */}
            {shouldDisplayPaymentMethod(PAYMENT_METHOD.ACH) && (
              <Accordion
                id={ACH + UUID}
                summary="ACH Transfer"
                disabled={Boolean(state.reviseOoId && balanceDue <= 0)}
              >
                <TransferInfo transferType={PAYMENT_METHOD.ACH} />
                <PaymentForm
                  onSubmit={(data) => {
                    setPaymentData(data);
                  }}
                  defaultValues={paymentData ?? state.paymentData}
                  ref={ref[ACH]}
                  type={ACH}
                  ccTypes={ccTypes}
                />
              </Accordion>
            )}

            {/* WIRE */}
            {shouldDisplayPaymentMethod(PAYMENT_METHOD.WIRE) && (
              <Accordion
                id={WIRE + UUID}
                summary="Wire Transfer ($50 Fee)"
                disabled={Boolean(state.reviseOoId && balanceDue <= 0)}
              >
                <TransferInfo transferType={PAYMENT_METHOD.WIRE} />
                <PaymentForm
                  onSubmit={(data) => {
                    setPaymentData(data);
                  }}
                  defaultValues={paymentData ?? state.paymentData}
                  ref={ref[WIRE]}
                  type={WIRE}
                  ccTypes={ccTypes}
                />
              </Accordion>
            )}
          </AccordionGroup>
        </Content>

        {/* ADDRESS */}
        <p className={cx(TEXT.SUBTITLE_1, styles.section)}>Billing Address</p>
        <Content error={addAddressError}>
          <Form
            validation={BuildAddressValidation()}
            onPass={(address) => {
              // If new address and the payment data is valid, submit address
              if (addressType === BILLING && paymentData) {
                submitBillingAddress({
                  ooId: state.ooId,
                  type: ADDRESS_TYPE.BILLING,
                  address,
                  customerId: state.customerId,
                });
              }
            }}
            defaultValues={{ ...state.contactAddress, ...state.billingAddress }}
            isLoading={isSubmittingAddress}
          >
            <AccordionGroup
              radios
              defaultActive={addressType}
              onChange={(type) => setAddressType(type)}
            >
              <Accordion
                id={COMPANY}
                summary="Same as my Company's address"
                disabled={Boolean(state.reviseOoId && balanceDue <= 0)}
              />
              <Accordion
                id={BILLING}
                summary="Use a different address"
                disabled={Boolean(state.reviseOoId && balanceDue <= 0)}
              >
                <ol>
                  <li>
                    <CountryInput
                      label="Country / Region *"
                      name="countryCode"
                      options={countries}
                      defaultValue={countryCode}
                      onChange={updateCountry}
                      required
                    />
                  </li>
                </ol>

                <ol className={cx(STYLE.address)}>
                  <AddressItem name="address1" />
                  <AddressItem name="address2" />
                  <AddressItem name="city" />
                  <AddressItem name="state" />
                  <AddressItem name="zip" />
                </ol>
              </Accordion>
            </AccordionGroup>
            <div className={styles.actions}>
              <SubmitButton
                name="submit"
                onClick={() => {
                  if (state.reviseOoId && balanceDue <= 0) {
                    setState({ currentStep: 4 });
                    history.push(ROUTE.REVIEW);
                  }

                  if (addressType === COMPANY) {
                    setState({ billingAddress: state.contactAddress });
                  }
                  // Submit address form and remote submit payment form
                  remoteSubmit(ref[method].current);
                  track('payment_next_click');
                }}
                disabled={totalsError}
              >
                {state.reviseOoId ? 'Continue to Revise' : 'Review'}
              </SubmitButton>
              <Button
                text
                name="back"
                onClick={() => {
                  setState({ currentStep: 2 });
                  history.push(ROUTE.CONTACT);
                  track('payment_back_click');
                }}
              >
                Return to Contact
              </Button>
            </div>
          </Form>
          <EventOfflineDialog isOpen={isOpen} setIsOpen={setIsOpen} />
        </Content>
      </Content>
    </div>
  );
};

Payment.propTypes = {
  setState: func,
  state: object,
};

export default Payment;
