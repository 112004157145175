import { useEffect, useState } from 'react';
import { func, string } from 'prop-types';

import { Dialog, Button, Toast } from 'common/components';
import { useDeleteOrder } from 'common/hooks';

import styles from './DeleteOrder.module.css';

const DeleteOrder = ({ id, onSuccess = () => null, fullWidth, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toast, setToast] = useState({ alert: undefined, message: undefined });

  const { deleteOrder, deleteError } = useDeleteOrder({
    onSuccess,
    id,
  });

  useEffect(() => {
    if (deleteError) {
      setToast({ alert: 'error', message: deleteError?.message });
    }
  }, [deleteError]);

  return (
    <>
      <Button
        className={styles.button}
        size="medium"
        outlined
        color="info"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        fullWidth={fullWidth}
      >
        {children}
      </Button>

      <Dialog
        id="delete-order"
        title="Delete Order"
        close={() => setIsOpen(false)}
        isOpen={isOpen}
        onSubmit={() => deleteOrder(id)}
        onCancel={() => setIsOpen(false)}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        size="xs"
        submitColor="primary"
        submitText="Delete Order"
        cancelText="Go Back"
      >
        <p>
          This will permanently delete your progress for this order, such as
          booth details and items in your cart. Do you want to continue?
        </p>
      </Dialog>

      <Toast
        alert={toast.alert}
        anchor={{ vertical: 'top', horizontal: 'right' }}
        isOpen={Boolean(toast.message)}
        close={() => setToast({})}
        showDuration={5}
      >
        {toast.message}
      </Toast>
    </>
  );
};

DeleteOrder.propTypes = {
  id: string.isRequired,
  onSuccess: func,
};

export default DeleteOrder;
