import { bool, number, shape, any } from 'prop-types';
import cx from 'classnames';

import { currency } from 'common/utils';
import { COLOR, TEXT } from 'common/const';
import { Tooltip, Icon } from 'common/components';

import styles from './OrderSummary.module.css';

const Line = ({ children, value, showZero }) =>
  typeof value === 'number' && (value !== 0 || showZero) ? (
    <div className={cx(styles.line, TEXT.BODY_2)}>
      <dt className={cx(COLOR.GREY_600, styles.label)}>{children}</dt>
      <dd>{currency(value)}</dd>
    </div>
  ) : null;

Line.propTypes = {
  value: number,
  children: any.isRequired,
};

const OrderSummary = ({ totals, isPast }) => {
  // console.log('<OrderSummary>', { totals, isPast });

  return (
    <>
      <dl>
        <Line showZero value={totals?.subtotal}>
          Order Subtotal
        </Line>

        <Line
          value={
            totals?.tax + totals?.wireFee + totals?.ccFee + totals?.serviceFee
          }
        >
          <Tooltip
            arrow
            className={styles.info}
            hover
            placement="right"
            title={
              <p className={TEXT.SUBTITLE_2}>
                Includes sales tax and necessary fees
              </p>
            }
            width="9rem"
          >
            <span>Taxes & Fees</span>
            <Icon name="info" color="info" />
          </Tooltip>
        </Line>
      </dl>

      <div className={cx(COLOR.GREY_400, styles.divider)} />

      <dl>
        <div className={cx(TEXT.SUBTITLE_2, TEXT.BOLD, styles.line)}>
          <dt>Grand Total (USD)</dt>
          <dd>{currency(totals?.total)}</dd>
        </div>

        <Line
          showZero
          value={
            totals?.amountPaid === 0
              ? totals?.amountPaid
              : totals?.amountPaid * -1
          }
        >
          Amount Paid
        </Line>
      </dl>

      <div className={cx(COLOR.GREY_400, styles.divider)} />

      <dl>
        <div className={cx(TEXT.SUBTITLE_2, styles.line)}>
          <dt>Balance Due</dt>
          <dd>{currency(totals?.balanceDue)}</dd>
        </div>
      </dl>

      <p className={cx(TEXT.CAPTION, COLOR.GREY_600, styles.caption)}>
        View your {isPast ? 'invoice' : 'statement'} to see the{' '}
        {isPast && 'final'} cost breakdown of your order.
      </p>
    </>
  );
};

OrderSummary.propTypes = {
  isPast: bool,
  totals: shape({
    amountPaid: number,
    balanceDue: number,
    ccFee: number,
    discount: number,
    serviceFee: number,
    subtotal: number,
    tax: number,
    total: number,
    wireFee: number,
  }),
};

export default OrderSummary;
