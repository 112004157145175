import localizedFormat from 'dayjs/plugin/localizedFormat';

const DEFAULT = 'en';

// if we find a "locale" in localStorage, set it
const locale = localStorage.getItem('locale') || DEFAULT;

const setLocale = (dayjs) => {
  dayjs.extend(localizedFormat); // i18n

  if (locale) {
    dayjs.locale(locale);
  }

  return dayjs;
};

export default setLocale;
