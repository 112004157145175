import AT from './austria';
import BE from './belgium';
import CA from './canada';
import CH from './switzerland';
import CN from './china';
import DE from './germany';
import DK from './denmark';
import ES from './spain';
import FR from './france';
import GB from './great-britain';
import HK from './hong-kong';
import IL from './israel';
import IN from './india';
import IT from './italy';
import JP from './japan';
import KR from './korea';
import MX from './mexico';
import NL from './netherlands';
import OTHER from './other';
import SE from './sweden';
import TR from './turkey';
import TW from './taiwan';
import US from './united-states';

const ADDRESS = {
  AT,
  BE,
  CA,
  CH,
  CN,
  DE,
  DK,
  ES,
  FR,
  GB,
  HK,
  IL,
  IN,
  IT,
  JP,
  KR,
  MX,
  NL,
  OTHER,
  SE,
  TR,
  TW,
  US,
};

export default ADDRESS;
