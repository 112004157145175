import { deepmerge } from '@mui/utils';
import common from './common';

const edlen = deepmerge(common, {
  palette: {
    primary: {
      main: '#1E73BA',
      light: '#2499E2',
      dark: '#195497',
    },
  },
  header: {
    background: '#1E73BA',
    text: '#FFF',
    button: {
      background: '#195497',
      text: '#FFF',
    },
    logo: 'edlenWhite',
  },
});

export default edlen;
