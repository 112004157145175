import { useMemo, useState } from 'react';
import { any, func } from 'prop-types';
import cx from 'classnames';

import { useCustomer } from 'app/context';
import { TEXT, VALID } from 'common/const';
import {
  AddressDisplay,
  Button,
  Content,
  CountryInput,
  Form,
  SubmitButton,
  SectionHeader,
  Input,
} from 'common/components';
import { useAddress } from 'common/hooks';

import styles from './Company.module.css';
import { decoratePhone } from 'common/utils';

const Company = ({ onSubmit, children }) => {
  // console.log('<Company>', {});
  const customer = useCustomer();
  const [active, setActive] = useState(false);

  const {
    buildValidation,
    countries,
    countryCode,
    updateCountry,
    FIELD,
    STYLE,
    AddressItem,
    INIT_ADDRESS,
  } = useAddress(customer);

  const defaultValues = useMemo(
    () => ({
      ...customer,
      countryCode,
    }),
    [customer, countryCode],
  );

  return active ? (
    <Content isLoading={customer.isUpdating}>
      <Form
        validation={{
          ...buildValidation(),
          countryCode: VALID.COUNTRY.required('Country is required'),
          company: VALID.STRING.required('Company is required'),
          phone: VALID.PHONE_NUMBER.required('Office phone number is required'),
        }}
        onPass={(data) => {
          const address = Object.keys(FIELD).reduce(
            (previous, current) => ({
              ...previous,
              [current]: data[current] ?? '',
            }),
            {
              ...INIT_ADDRESS,
              countryCode: data.countryCode,
              phone: data.phone,
            },
          );

          customer.changeAddress({
            address: { ...defaultValues, ...address },
          });

          if (data.company !== customer.company) {
            customer.changeCompany({
              customerId: customer.customerId,
              company: data.company,
            });
          }
        }}
        onFail={(errors) => {
          // console.error({ errors });
        }}
        isSubmitting={customer.isUpdating}
        defaultValues={defaultValues}
      >
        <SectionHeader el="legend">Edit Company Information</SectionHeader>

        <fieldset className={styles.fieldset}>
          <Input
            label="Company Name *"
            name="company"
            defaultValue={customer.company}
            required
          />

          <CountryInput
            label="Country / Region *"
            name="countryCode"
            options={countries}
            defaultValue={countryCode}
            onChange={updateCountry}
            required
          />

          <ol className={cx(STYLE.address)}>
            <AddressItem name="address1" />
            <AddressItem name="address2" />
            <AddressItem name="city" />
            <AddressItem name="state" />
            <AddressItem name="zip" />
          </ol>

          <Input
            name="phone"
            label="Office Phone Number *"
            maxlength={25}
            required
          />
        </fieldset>

        {children}

        <div className={styles.actions}>
          <SubmitButton name="submit" isSubmitting={customer.isUpdating}>
            Update
          </SubmitButton>
          <Button text name="cancel" onClick={() => setActive(false)}>
            Cancel
          </Button>
        </div>
      </Form>
    </Content>
  ) : (
    <Content isLoading={customer.isUpdating}>
      <SectionHeader action={{ onClick: () => setActive(true) }}>
        <div className={TEXT.BODY}>
          <p>{customer.company}</p>
          <AddressDisplay address={customer} />
          <p>{decoratePhone(customer.phone)}</p>
        </div>
      </SectionHeader>
    </Content>
  );
};

Company.propTypes = {
  onSubmit: func,
  children: any,
};

export default Company;
