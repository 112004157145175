import { SITE } from 'common/const';

/**
 * Sniffs browser URL for location to determine site env
 * @param {object} location - current browser URL
 * @returns {object}
 */
const getSiteEnv = (location = document.location) => {
  const { host } = location;
  const { env } = SITE[host] ?? SITE.default;

  return env;
};

export default getSiteEnv;
