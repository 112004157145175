import {
  createContext,
  useContext,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import { any, number, string } from 'prop-types';
import { useQuery } from '@tanstack/react-query';

import {
  getCompletedOrder,
  getCompletedOrders,
  getIncompleteOrders,
} from 'data/orders';
import { useAuth, useShop } from 'app/context/';

import reducer, { TYPE, INIT_STATE } from './reducer';

const Orders = createContext();

export const OrdersProvider = ({ children }) => {
  const { customerId } = useAuth();
  const { empOrderId } = useShop();

  const [{ completed, incomplete, orders, toast }, dispatch] = useReducer(
    reducer,
    INIT_STATE,
  );

  // helper to setup consistent callbacks, etc, for queries
  const setupQuery = ({ onSuccess, onError, enabled, ...rest }) => ({
    ...rest,
    onSuccess: (data) => dispatch({ type: onSuccess, payload: data }),
    onError: (error) => dispatch({ type: onError, payload: error }),
    enabled,
  });

  const { refetch: refetchCompleted, isFetching: isFetchingCompleted } =
    useQuery(
      ['getCompletedOrders', customerId],
      () => getCompletedOrders(customerId),
      setupQuery({
        onSuccess: TYPE.LOAD_COMPLETED,
        onError: TYPE.COMPLETED_ERROR,
        enabled: Boolean(customerId),
      }),
    );

  const { refetch: refetchReviseOrder, isFetching: isFetchingReviseOrder } =
    useQuery(
      ['getCompletedOrder', { customerId, empOrderId }],
      () => getCompletedOrder({ customerId, empOrderId }),
      setupQuery({
        onSuccess: TYPE.LOAD_REVISE,
        onError: TYPE.REVISE_ERROR,
        enabled: Boolean(empOrderId) && Boolean(customerId),
      }),
    );

  const { refetch: refetchIncomplete, isFetching: isFetchingIncomplete } =
    useQuery(
      ['getIncompleteOrders', customerId],
      () => getIncompleteOrders(customerId),
      setupQuery({
        onSuccess: TYPE.LOAD_INCOMPLETE,
        onError: TYPE.INCOMPLETE_ERROR,
        enabled: Boolean(customerId),
      }),
    );

  const updateOrderItems = ({ cartItems, empOrderId }) =>
    dispatch({
      type: TYPE.UPDATE_ORDER_ITEMS,
      payload: { cartItems, empOrderId },
    });

  const findOrder = useCallback(
    (orderId, ordersList = orders.data) =>
      ordersList.length
        ? ordersList.find(({ id }) => [orderId, orderId + ''].includes(id))
        : ordersList,
    [orders.data],
  );

  const { ooId } = useShop();
  const current = useMemo(() => findOrder(ooId), [ooId, findOrder]);

  const refetch = async () => {
    refetchCompleted();
    refetchIncomplete();
  };

  const past = useMemo(
    () => completed.data.filter(({ isPast }) => isPast),
    [completed],
  );

  const upcoming = useMemo(
    () => completed.data.filter(({ isPast }) => !isPast),
    [completed],
  );

  const cancelled = useMemo(
    () =>
      completed.data.filter(
        ({ cancellationStatus }) => cancellationStatus === 'A',
      ),
    [completed],
  );

  const updateToast = (toast) =>
    dispatch({ type: TYPE.SET_TOAST, payload: toast });

  const resetOrders = () => {
    dispatch({ type: TYPE.RESET });
  };

  return (
    <Orders.Provider
      value={{
        ...orders, // return normal api
        cancelled,
        completed,
        past,
        upcoming,
        incomplete,
        findOrder,
        current, // current order (from shop context), so we don't have to use findOrder everywhere anymore
        refetch,
        refetchCompleted,
        refetchIncomplete,
        refetchReviseOrder,
        resetOrders,
        updateOrderItems,
        isFetching:
          isFetchingCompleted || isFetchingIncomplete || isFetchingReviseOrder,
        toast,
        updateToast,
      }}
    >
      {children}
    </Orders.Provider>
  );
};

Orders.propTypes = {
  boothNumber: string,
  eventId: string,
  ooId: number,
  children: any,
};

export const useOrders = () => useContext(Orders);
