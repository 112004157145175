import { FLAG } from 'common/const';

const getItemFlag = ({ isPackage, price }) => {
  if (isPackage) {
    if (price) {
      return FLAG.PACKAGE;
    } else {
      return FLAG.FREE_PACKAGE;
    }
  }
};

export default getItemFlag;
