const QuestionsPostOrder = () => {
  return (
    <>
      <h3>Questions PostOrder</h3>
      <ul>
        <li>Question 1</li>
        <li>Question 2</li>
        <li>...</li>
        <li>Question N</li>
      </ul>
    </>
  );
};

export default QuestionsPostOrder;
