import common from './common';
import bjcc from './bjcc';
import cajun from './cajun';
import cajunTest from './cajun-test';
import edlen from './edlen';
import edlenTest from './edlen-test';
import edlenTraining from './edlen-training';

const THEME = {
  bjcc,
  cajun,
  cajunTest,
  common,
  edlen,
  edlenTest,
  edlenTraining,
};

export const themeNames = Object.keys(THEME);

export default THEME;
