import { useState, useRef, useEffect, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import cx from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

import { ROUTE, TEXT, VALID } from 'common/const';
import { getBaseUrl, track } from 'common/utils';
import {
  Alert,
  Checkbox,
  Error,
  Form,
  Input,
  Password,
  SubmitButton,
  TextLink,
} from 'common/components';
import { Page } from 'common/site';
import { authenticate } from 'data/customers';
import { useAuth } from 'app/context';
import { useAppInfo, useSearchParams } from 'common/hooks';

import styles from './Login.module.css';
import axios from 'axios';

const EMAIL = 'emailAddress';
const REMEMBER = 'rememberEmail';
const baseUrl = getBaseUrl(2);

const Login = () => {
  const [recaptchaValidated, setRecaptchaValidated] = useState(true);
  const recaptcha = useRef();
  const { isAuthenticated, login } = useAuth();
  const history = useHistory();
  const {
    mutate: submitLogin,
    error,
    isLoading: isSubmitting,
  } = useMutation(authenticate, {
    onSuccess: (data) => {
      track('login');
      login(data);
    },
  });

  const { data } = useAppInfo();

  // Get the query parameter for the redirect
  const nextRoute = useSearchParams().redirect ?? ROUTE.DASHBOARD;

  const remember = useMemo(
    () => sessionStorage.getItem(REMEMBER) !== 'false',
    [],
  );
  const email = useMemo(
    () => (remember ? localStorage.getItem(EMAIL) : undefined),
    [remember],
  );

  const handleSubmit = async ({ email, password, remember }) => {
    // console.log('!Login.handleSubmit', { email, password, remember });

    const captchaValue = recaptcha.current.getValue();
    // const captchaValue = 'fakeValue';
    if (!captchaValue) {
      setRecaptchaValidated(false);
    } else {
      setRecaptchaValidated(true);

      const response = await axios.post(`${baseUrl}recaptcha/verify`, {
        captchaValue,
      });
        if (response.data.resultInformation.actionResult === 'Successful') {
        recaptcha.current.reset();
        sessionStorage.setItem(REMEMBER, remember);

        if (remember) {
          localStorage.setItem(EMAIL, email);
        } else {
          localStorage.removeItem(EMAIL);
        }

        submitLogin({ email, password });
      }
    }
  };

  // handle success (after context updates)
  useEffect(() => {
    if (isAuthenticated) {
      history.push(nextRoute);
    }
  }, [isAuthenticated, history, nextRoute]);

  return (
    <Page variant="island" title="Log into Online Ordering">
      {data?.loginAlert && (
        <div className={styles.alert}>
          <Alert>{data.loginAlert}</Alert>
        </div>
      )}

      <Form
        defaultValues={{
          email,
        }}
        validation={{
          email: VALID.EMAIL.required('Please enter your email.'),
          password: VALID.PASSWORD.required('Please enter your password.'),
        }}
        onPass={(data) => {
          handleSubmit(data);
        }}
        onFail={(errors) => {
          // console.log(errors);
        }}
        isSubmitting={isSubmitting}
      >
        {error && (
          <div className={styles.error}>
            <Error>{error?.message}</Error>
          </div>
        )}

        <div className={styles.form}>
          <Input name="email" />
          <Password name="password" />
        </div>
        <div className={cx(TEXT.BODY_1, styles.group)}>
          <Checkbox
            name="remember"
            label="Save email"
            defaultChecked={remember}
          />
          <TextLink to={ROUTE.FORGOT_PASSWORD}>Forgot Password</TextLink>
        </div>

        <ReCAPTCHA
          className={styles.recaptcha}
          ref={recaptcha}
          sitekey={process.env.REACT_APP_SITE_KEY}
        />

        {!recaptchaValidated && (
          <div className={styles.errorMessage}>
            Please verify that you are not a robot.
          </div>
        )}

        <SubmitButton
          className={styles.button}
          name="submit"
          fullWidth
          isSubmitting={isSubmitting}
        >
          Login
        </SubmitButton>
        <p className={cx(TEXT.BODY_1, styles.footer)}>
          New to Online Ordering? <TextLink to={ROUTE.SIGNUP}>Sign Up</TextLink>
        </p>
      </Form>
    </Page>
  );
};

export default Login;
