import axios from 'axios';
import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getDocumentIdByOrder.mock';

const baseUrl = getBaseUrl(2);

/**
 * Gets documents for a given order
 * @param eventId - id for event
 * @param ooId - order id
 * @returns file data
 */
const getDocumentIdByOrder = async ({ eventId, ooId }) => {
  await VALID.checkAll(
    ['eventId', eventId, VALID.EVENT_ID],
    ['ooId', ooId, VALID.OOID],
  );
  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(
          `${baseUrl}document/list_by_order/${eventId}/${ooId}`,
        );

    return data.documents;
  } catch (error) {
    log('getDocumentIdByOrder', {
      data: { eventId, ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getDocumentIdByOrder;
