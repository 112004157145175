import axios from 'axios';

import { ENV, VALID } from 'common/const';
import { getBaseUrl, log } from 'common/utils';

const baseUrl = getBaseUrl(2);

/**
 * Sends a log to our API
 */
const logToApi = async (message) => {
  await VALID.check(message, VALID.STRING);

  try {
    if (!ENV.IS_MOCK) {
      await axios.post(`${baseUrl}adapter/log`, { message });
    }
  } catch (error) {
    log('logToApi', { error, api: false });
  }
};

export default logToApi;
