import { createRef, useState } from 'react';

import { object } from 'prop-types';

import { BOOTH_SETUP, TEXT } from 'common/const';
import { remoteSubmit } from 'common/utils';
import { Content, Form, Require, OptionButton, Input } from 'common/components';

import cx from 'classnames';

import ErrorMessage from '../ErrorMessage';
import Step from './Step';

import { useParams } from 'react-router';
import styles from './Binary.module.css';

const ANSWER = {
  YES: { display: 'YES', value: 'Yes' },
  NO: { display: 'NO', value: 'No' },
};

const INIT_STATE = {
  response: undefined,
  subQuestionResponse: undefined,
  error: undefined,
};

const Binary = ({ findStep }) => {
  // console.log('<<Binary>>', { steps });
  const [hasError, setHasError] = useState();

  const { assessmentId } = useParams();
  const step = findStep(BOOTH_SETUP.BINARY, assessmentId);

  const {
    response: resp,
    question,
    required,
    submit,
    error,
    isLoading, //TODO: check if it works
    isSubmitting,
    back,
    buttonText,
  } = step ?? {};

  const [userResponse, subQResponse] = resp ? resp.split(';') : [null, null];

  const [state, setState] = useState({
    ...INIT_STATE,
    response: userResponse,
    subQuestionResponse: subQResponse,
    error: error,
  });

  const form = createRef();

  const getSubQuestion = (response) =>
    step?.boothSetupResponseSelections?.binaryResponseOptions?.find(
      (responseOption) =>
        response?.toUpperCase() ===
        responseOption?.subQuestions[0]?.responseTrigger.toUpperCase(),
    )?.subQuestions[0];

  // const lines = (text) => {
  //   return text?.split('<BR>');
  // };

  return (
    <Require data={[question]}>
      <Content isLoading={!step || isLoading} error={error?.message}>
        <Step
          submit={() => {
            if (required && !state.response) {
              setHasError(true);
            } else {
              if (
                step.boothSetupResponseSelections.binaryResponseOptions.some(
                  (responseOption) =>
                    state.response.toUpperCase() ===
                      responseOption?.subQuestions[0]?.responseTrigger.toUpperCase() &&
                    !responseOption?.subQuestions[0]?.isRepsonseOptional &&
                    !state.subQuestionResponse,
                )
              ) {
                setState((data) => ({
                  ...data,
                  error: 'Enter ' + getSubQuestion(state.response)?.subtitle,
                }));
              } else {
                remoteSubmit(form.current);
              }
            }
          }}
          back={back}
          buttonText={buttonText}
          isSubmitting={isSubmitting}
        >
          <div className={styles.p}>
            <h1 className={styles.subtext}>
              <div dangerouslySetInnerHTML={{ __html: question }}></div>
            </h1>
          </div>

          <Form
            ref={form}
            onPass={async () => {
              const binaryPartyResponse =
                state.response === ANSWER.YES.value
                  ? state.response + ';' + state.subQuestionResponse
                  : state.response;

              submit(binaryPartyResponse);
            }}
          >
            <div className={styles.buttons}>
              {Object.values(ANSWER).map(({ display, value }) => (
                <OptionButton
                  value={value}
                  selected={value === state.response}
                  key={value}
                  label={value}
                  onClick={(response) => {
                    setState({
                      ...INIT_STATE,
                      response,
                    });
                  }}
                  className={styles.option}
                  name={'response'}
                >
                  {display}
                </OptionButton>
              ))}
            </div>

            {state.response?.length &&
              getSubQuestion(state.response)?.responseTrigger.toUpperCase() ===
                state.response?.toUpperCase() && (
                <>
                  <h6 className={cx(TEXT.SUBTITLE, styles.exhibitor)}>
                    {getSubQuestion(state.response)?.header}
                  </h6>
                  <Input
                    error={state.error}
                    label={getSubQuestion(state.response)?.subtitle}
                    name="inputName"
                    defaultValue={state.subQuestionResponse}
                    required
                    onChange={({ target }) =>
                      setState((data) => ({
                        ...data,
                        error: undefined,
                        subQuestionResponse: target.value,
                      }))
                    }
                  />
                </>
              )}
          </Form>

          <ErrorMessage isOpen={hasError} close={() => setHasError(false)}>
            Please select an option to continue.
          </ErrorMessage>
        </Step>
      </Content>
    </Require>
  );
};

Binary.propTypes = {
  step: object,
};

export default Binary;
