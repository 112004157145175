import { useState } from 'react';

import { Tooltip } from 'common/components';

const NewFeatureTooltip = ({ feature, title, placement, children }) => {
  const [isTooltipOpen, setIsToolTipOpen] = useState(
    !localStorage.getItem(feature),
  );

  setTimeout(() => {
    if (isTooltipOpen === true) {
      setIsToolTipOpen(false);
      localStorage.setItem(feature, true);
    }
  }, 6000);

  return (
    <Tooltip isOpen={isTooltipOpen} title={title} arrow placement={placement}>
      {children}
    </Tooltip>
  );
};

export default NewFeatureTooltip;
