import { Button } from 'common/components';
import { ROUTE, TEXT } from 'common/const';
import { Page } from 'common/site';

import styles from './NewOrder.module.css';

const NewOrder = () => {
  return (
    <Page
      className={styles.page}
      backTo={ROUTE.DASHBOARD}
      title="Start New Order"
    >
      <h1 className={TEXT.H5}>Let's power up your booth!</h1>
      <p className={styles.p}>
        We will ask questions to determine your booth setup so we can provide
        the necessary equipment, labor and power.
      </p>

      <p className={styles.p}>
        <Button to={ROUTE.EVENT_SEARCH}>Get started</Button>
      </p>
    </Page>
  );
};

export default NewOrder;
