import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import {
  addCustomerAssessmentResponse,
  updateCustomerAssessmentResponse,
} from 'data/orders';

const useSubmitResponse = (ooId, options = {}) => {
  // console.log('!useSubmitResponse', { ooId, options });
  const [error, setError] = useState(undefined);

  const { refetchResponses, refetchQuestions } = options;

  const submitOptions = {
    onSuccess: () => {
      setError(undefined);
      refetchResponses(); // reload responsed
      refetchQuestions();
    },
    onError: (err) => setError(err),
  };

  const { mutateAsync: add, isLoading: isAdding } = useMutation(
    ({ question, response }) =>
      addCustomerAssessmentResponse({
        assessmentResponseData: {
          assessmentId: question.assessmentId,
          response,
        },
        ooId,
        isOptional: !question.required,
      }),
    submitOptions,
  );

  const { mutateAsync: update, isLoading: isUpdating } = useMutation(
    ({ question, response }) =>
      updateCustomerAssessmentResponse({
        assessmentResponseData: {
          assessmentId: question.assessmentId,
          response,
          id: question.id,
        },
        ooId,
        isOptional: !question.required,
      }),
    submitOptions,
  );

  const submit = useCallback(
    ({ question, response, onSuccess }) => {
      // console.log('!useSubmitResponse.submit', { question, response });

      // id changes from number to string after updating
      const fn = typeof question.id === 'number' ? add : update;

      return fn({ question, response }).then(() => {
        onSuccess({ question, response });
      });
    },
    [add, update],
  );

  return {
    submit,
    error,
    isSubmitting: isAdding || isUpdating,
  };
};

export default useSubmitResponse;
