import cx from 'classnames';
import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import {
  Button,
  Content,
  Form,
  Input,
  Require,
  Select,
  SubmitButton,
} from 'common/components';
import { BORDER, ROUTE, TEXT, VALID } from 'common/const';
import { Page } from 'common/site';
import { useCancelOrder, useEvent } from 'common/hooks';
import { decoratePhone, track } from 'common/utils';

import styles from './CancelOrder.module.css';
import { useCustomer, useOrders } from 'app/context';

const Line = () => <div className={cx(BORDER.GREY_200, styles.line)} />;

const CancelOrder = () => {
  const [reason, setReason] = useState(-1);
  const [error, setError] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const { findOrder, isFetching } = useOrders();
  const { email } = useCustomer();
  const order = useMemo(() => findOrder(id) ?? {}, [findOrder, id]);

  const eventId = order?.eventId;

  const { data: event, isLoading: isEventLoading } = useEvent(eventId);

  const {
    cancel,
    responseOptions = [],
    isLoadingCancelOptions,
    isCancelling,
    cancelError,
  } = useCancelOrder({
    ooId: id,
    eventId,
    onError: () => setReason(-1),
  });

  const buildValidation = () =>
    reason === 3
      ? {
          message: VALID.STRING.min(1, 'Message is required'),
        }
      : {};

  return (
    <Require
      data={[order?.id, order?.cancellationStatus === 'R' ? undefined : true]}
    >
      <Page className={styles.page}>
        <Content
          isLoading={
            isCancelling ||
            isLoadingCancelOptions ||
            isFetching ||
            isEventLoading
          }
          error={cancelError?.message}
        >
          <Form
            onPass={({ message }) =>
              reason === -1
                ? setError(true)
                : cancel({
                    reasonId: reason,
                    ooId: id,
                    reason: message,
                    eventId,
                    email,
                  })
            }
            validation={buildValidation()}
          >
            <div className={styles.heading}>
              <h1 className={cx(TEXT.H6, styles.title)}>
                Entire Order Cancellation Request
              </h1>
              <p className={cx(styles.subtitle)}>
                If you need to modify your order contact us at{' '}
                {event.branchInfo?.email} or{' '}
                {decoratePhone(event.branchInfo?.phone)}
              </p>
            </div>
            <h2 className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>
              How will my request be handled?
            </h2>
            <ol className={styles.steps}>
              <li>We will review your request.</li>
              <li>
                An Event Services representative will contact you in 3-5
                business days.
              </li>
              <li>
                If your request is approved, funds will be credited to your
                account. It can take up to 30 days from the event closing date
                to receive a refund.
              </li>
            </ol>
            <Line />
            <h2 className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>
              Why do you want to cancel?
            </h2>
            <p>
              This won't affect your refund. It's just to help us improve our
              service.
            </p>
            <div className={styles.dropdown}>
              <Select
                label="Cancellation Reason*"
                name="reason"
                options={responseOptions}
                onChange={({ target }) => {
                  setReason(target.value);
                  setError(false);
                }}
                defaultValue={-1}
                error={error}
                helperText={error && 'Please select a reason'}
              />
            </div>

            {reason === 3 && (
              <Input
                name="message"
                label="Message*"
                helperText="Maximum 1200 characters"
                maxlength={1200}
                multiline={true}
              />
            )}

            <Line />

            <div className={styles.actions}>
              <SubmitButton className={styles.button} size="large">
                Cancel Order
              </SubmitButton>
              <Button
                className={styles.button}
                size="large"
                text
                onClick={() => {
                  track('exit_cancel', { ooId: id });
                  history.push(ROUTE.ORDER_DETAILS + `/${id}`);
                }}
              >
                Return to Order Details
              </Button>
            </div>
          </Form>
        </Content>
      </Page>
    </Require>
  );
};

export default CancelOrder;
