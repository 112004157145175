import { useMemo } from 'react';
import cx from 'classnames';

import { useShop } from 'app/context';
import { BORDER, TEXT } from 'common/const';
import {
  decoratePhone,
  getItemFlag,
  getItemTotals,
  getProductImage,
  getSiteProps,
} from 'common/utils';
import { Button, Faqs, ProductPrices } from 'common/components';
import { useEvent } from 'common/hooks';

import CatalogGrid from '../CatalogGrid';
import AddToCart from './AddToCart';

import styles from './ProductDetail.module.css';

const { PHONE } = getSiteProps();

const Heading = ({ children }) => (
  <h2 className={cx(TEXT.H6, styles.heading)}>{children}</h2>
);

const Section = ({ heading, children }) => (
  <section className={cx(BORDER.DIVIDER, styles.section)}>
    <Heading>{heading}</Heading>
    {children}
  </section>
);

const ProductDetail = ({ itemId, isEarlyPricing }) => {
  // console.log('<ProductDetail>', { itemId });

  // const { isOnline, open: openChat } = useChat();
  const { eventId, inventory } = useShop();
  const event = useEvent(eventId);

  const item = inventory[itemId];

  const phoneNumber =
    useMemo(() => event.data.branchInfo.phone, [event]) ?? PHONE.MAIN;

  const { currentPrice, originalPrice, image, flag, required, suggested } =
    useMemo(() => {
      const { currentPrice, originalPrice } = getItemTotals({
        item,
        isEarlyPricing,
      });
      const requiredItems = item.requiredItems ?? [];
      const suggestedItems = item.suggestedItems ?? [];

      return {
        currentPrice,
        originalPrice,
        image: getProductImage(item.image),
        flag: getItemFlag({ ...item, price: currentPrice }),
        required: {
          items: requiredItems,
          hasSome: Boolean(requiredItems.length),
          isPlural: requiredItems.length > 1,
        },
        suggested: {
          items: suggestedItems.map((suggested) => inventory[suggested.id]),
          hasSome: Boolean(suggestedItems.length),
        },
      };
    }, [item, isEarlyPricing, inventory]);

  return (
    <div className={styles.wrap}>
      <article className={cx(styles.product, styles.columns)}>
        <div className={cx(styles.spotlight)}>
          {flag && (
            <h1
              className={cx(
                TEXT.SUBTITLE_2,
                styles.flag,
                {
                  [styles.accompaniment]: Boolean(image),
                  [styles.solo]: !Boolean(image),
                },
                flag.className,
              )}
            >
              {flag.content}
            </h1>
          )}
          {image && <img src={image} alt={item.name} />}
        </div>
        <div className={styles.details}>
          <h1 className={cx(styles.name, TEXT.H5)}>{item.name}</h1>
          <ProductPrices
            className={styles.subSection}
            inline
            currentPrice={currentPrice}
            originalPrice={originalPrice}
          />

          <p className={cx(TEXT.BODY_2, styles.subSection)}>{item.blurb}</p>

          <AddToCart item={item} />

          {item.description && (
            <Section heading="Description">
              <p>{item.description}</p>
            </Section>
          )}

          {required.hasSome && (
            <Section heading={`Required Item${required.isPlural ? 's' : ''}`}>
              <div className={styles.subSection}>
                <p>
                  The following item
                  {required.isPlural ? 's are' : ' is'} required with this
                  product and/or service. We will automatically calculate and
                  add {required.isPlural ? 'them' : 'it'} to your cart.
                </p>
              </div>

              <ul>
                {required.items.map(({ image, name }) => (
                  <li key={'required' + name} className={styles.required}>
                    <span className={styles.thumb}>
                      <img src={getProductImage(image)} alt={name} />
                    </span>
                    <span>{name}</span>
                  </li>
                ))}
              </ul>
            </Section>
          )}
        </div>
      </article>

      <Faqs faqs={item.faqs} className={styles.faqs} suffix=" FAQs" />

      <Heading>Have more question?</Heading>
      <p className={styles.subText}>We're here to help.</p>
      <div className={styles.questions}>
        <div className={styles.questionButton}>
          <Button startIcon="call" outlined href={'tel:+1' + phoneNumber}>
            {decoratePhone(phoneNumber)}
          </Button>
        </div>

        <div className={styles.questionButton}>
          <Button startIcon="chat" outlined href="https://edlen.com/contact/">
            Contact Us
          </Button>
        </div>
      </div>
      {suggested.hasSome && (
        <div className={styles.suggested}>
          <Heading>Frequently Rented Together</Heading>
          <CatalogGrid
            items={suggested.items}
            isEarlyPricing={isEarlyPricing}
            recommended={true}
          />
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
