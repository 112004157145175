import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { resetPassword } from 'data/customers';
import cx from 'classnames';

import {
  Input,
  Form,
  SubmitButton,
  TextLink,
  Error,
  Button,
} from 'common/components';
import { ROUTE, TEXT, VALID } from 'common/const';
import { Page } from 'common/site';
import styles from './ForgotPassword.module.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { getBaseUrl } from 'common/utils';
import axios from 'axios';

const baseUrl = getBaseUrl(2);

const ForgotPassword = ({ location }) => {
  const [recaptchaValidated, setRecaptchaValidated] = useState(true);
  const recaptcha = useRef();
  const {
    mutate,
    isLoading: isSubmitting,
    error,
    isSuccess,
  } = useMutation(resetPassword);
  const validateError = location?.state?.error;

  async function handleSubmit({ email }) {
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setRecaptchaValidated(false);
    } else {
      setRecaptchaValidated(true);
      const response = await axios.post(`${baseUrl}recaptcha/verify`, {
        captchaValue,
      });

      if (response.data.resultInformation.actionResult === 'Successful') {
        mutate({ email });
      }
    }
  }

  return !isSuccess ? (
    // Show the email input page
    <Page
      variant="island"
      title="Reset Password"
      subtitle="We will send you instructions and a link to reset your password."
    >
      {(error || validateError) && (
        <div className={styles.error}>
          <Error>{error?.message || validateError?.message}</Error>
        </div>
      )}
      <Form
        validation={{
          email: VALID.EMAIL.required('Please enter your email.'),
        }}
        onPass={({ email }) => {
          handleSubmit({ email });
        }}
        onFail={(errors) => {
          console.log(errors);
        }}
        isSubmitting={isSubmitting}
      >
        <Input name="email" />

        <ReCAPTCHA
          className={styles.recaptcha}
          ref={recaptcha}
          sitekey={process.env.REACT_APP_SITE_KEY}
        />

        {!recaptchaValidated && (
          <div className={styles.errorMessage}>
            Please verify that you are not a robot.
          </div>
        )}

        <SubmitButton name="submit" fullWidth isSubmitting={isSubmitting}>
          Reset Password
        </SubmitButton>
        <p className={cx(TEXT.BODY_1, styles.footer)}>
          Remember your password? <TextLink to={ROUTE.LOGIN}>Login</TextLink>
        </p>
      </Form>
    </Page>
  ) : (
    // When temp password is successfully sent show redirect page
    <Page
      variant="island"
      title="Check Your Email"
      subtitle="It may take a few seconds for your email to show up in your inbox.
          Please check your spam folder if you do not see an email."
    >
      <Button name="submit" fullWidth to={ROUTE.LOGIN}>
        Return to login
      </Button>
    </Page>
  );
};

export default ForgotPassword;
