import { flattenDeep, isPlainObject } from 'lodash';

/**
 * Helper to reduce an array to a mirror object.
 * Values will be set as both the key & the value.
 * Also "deep flattens" sub-arrays
 * @param {Array} arr - array to reduce
 * @param {Object} [initial] - optional initial value for the object
 * @return {Object} key-value mirror, from array
 */
const mirror = (arr, initial = {}) => {
  if (!Array.isArray(arr)) {
    throw Error('mirror expects `arr` to be an array');
  }

  if (!isPlainObject(initial)) {
    throw Error('mirror expects `initial` value to be an object');
  }

  return flattenDeep(arr).reduce(
    (obj, current) => ({
      ...obj,
      [current]: current,
    }),
    initial,
  );
};

export default mirror;
