import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

const baseUrl = getBaseUrl(2);

/**
 * Deletes an order
 * @param {number} ooId
 * @returns Success, Not Found, or Error
 */
const deleteOrder = async (ooId) => {
  await VALID.check(ooId, VALID.OO_SUMMARY_ID.required('ooId is required.'));

  try {
    if (!ENV.IS_MOCK) {
      await axios.delete(`${baseUrl}orders/delete/${ooId}`);
    }
  } catch (error) {
    log('deleteOrder', {
      data: { ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default deleteOrder;
