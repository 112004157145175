import { Select } from 'common/components';

import { FILTERS } from './const';

const Filter = ({ defaultValue, onChange }) => (
  <Select
    label="Search by"
    options={FILTERS}
    defaultValue={defaultValue}
    onChange={({ target }) => onChange(target.value)}
    name="search-by"
    helperText="" // remove space underneath
  />
);

export default Filter;
