import { Children, cloneElement, useState } from 'react';
import { arrayOf, bool, node, string } from 'prop-types';

const AccordionGroup = ({
  defaultActive,
  expandIcon,
  onChange,
  radios,
  children,
  ...rest
}) => {
  const [active, setActive] = useState(defaultActive);

  const handleChange = (panelId) => {
    if (panelId === active && !radios) {
      setActive(undefined);
    } else {
      setActive(panelId);
    }
    onChange(panelId);
  };

  return (
    <div>
      {Children.map(children, (Child) => {
        if (!Child) {
          return null;
        }

        const { id, onChange: childOnChange, ...props } = Child.props;

        return cloneElement(Child, {
          ...rest, // pass in the rest of group props as defaults
          active: active === id,
          expandIcon: radios ? null : expandIcon,
          onChange: (panelId) => {
            childOnChange(panelId);
            handleChange(panelId);
          },
          radios,
          ...props, // allow Accordion to override group props
        });
      })}
    </div>
  );
};

AccordionGroup.propTypes = {
  defaultActive: string,
  radios: bool,
  children: arrayOf(node),
};

AccordionGroup.defaultProps = {
  onChange: () => null,
};

export default AccordionGroup;
