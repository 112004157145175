import { TEXT } from 'common/const';

const text = (theme) => ({
  [`.${TEXT.H1}`]: theme.typography.h1,
  [`.${TEXT.H2}`]: theme.typography.h2,
  [`.${TEXT.H3}`]: theme.typography.h3,
  [`.${TEXT.H4}`]: theme.typography.h4,
  [`.${TEXT.H5}`]: theme.typography.h5,
  [`.${TEXT.H6}`]: theme.typography.h6,
  [`.${TEXT.SUBTITLE}`]: theme.typography.subtitle1,
  [`.${TEXT.SUBTITLE_1}`]: theme.typography.subtitle1,
  [`.${TEXT.SUBTITLE_2}`]: theme.typography.subtitle2,
  [`.${TEXT.BODY}`]: theme.typography.body1,
  [`.${TEXT.BODY_1}`]: theme.typography.body1,
  [`.${TEXT.BODY_2}`]: theme.typography.body2,
  [`.${TEXT.BUTTON}`]: theme.typography.button,
  [`.${TEXT.CAPTION}`]: theme.typography.caption,
  [`.${TEXT.OVERLINE}`]: theme.typography.overline,
  [`.${TEXT.BOLD}`]: { fontWeight: theme.typography.fontWeightBold },
  [`.${TEXT.LIGHT}`]: { fontWeight: theme.typography.fontWeightLight },
  [`.${TEXT.REGULAR}`]: { fontWeight: theme.typography.fontWeightRegular },
});

export default text;
