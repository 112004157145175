import { Controller } from 'react-hook-form';

const withController =
  (Component) =>
  ({ control, name, ...props }) => {
    return control ? (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Component {...props} name={name} field={field} />
        )}
      />
    ) : (
      <Component {...props} name={name} />
    );
  };

export default withController;
