import { func, node, oneOf, oneOfType, string } from 'prop-types';
import { Chip as MuiChip } from '@mui/material';

import { CHIP_COLORS } from '../../const';

const Chip = ({ color, onDelete, children }) => {
  return <MuiChip color={color} onDelete={onDelete} label={children} />;
};

Chip.propTypes = {
  color: oneOf(CHIP_COLORS),
  children: oneOfType([string, node]),
  onDelete: func,
};

Chip.defaultProps = {
  color: 'default',
};

export default Chip;
