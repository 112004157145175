import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './initializeRevision.mock';
import { getCart } from 'data/utils';

const baseUrl = getBaseUrl(2);

/**
 * Initialize an order
 * @param {string} customerId - customerId
 * @param {string} eventId - eventId
 * @param {string} empOrderId - empOrderId
 * @param {string} exhId - exhibitorId
 * @returns {integer} OrderId
 */
const initializeRevision = async ({
  customerId,
  eventId,
  empOrderId,
  exhId,
}) => {
  // console.log('$ initializeOrder', { customerId, eventId, empOrderId, exhId });

  await VALID.checkAll(
    ['customerId', customerId, VALID.CUSTOMER_ID],
    ['eventId', eventId, VALID.EVENT_ID],
    ['empOrderId', empOrderId, VALID.UUID],
    ['exhId', exhId, VALID.UUID],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.post(`${baseUrl}orders/initialize_order_revision`, {
          customerId,
          eventId,
          exhId,
          orderId: empOrderId,
        });

    return {
      reviseOoId: data.reviseOoId,
      cartItems: getCart(data.items).items,
    };
  } catch (error) {
    log('initializeRevision', {
      data: { customerId, eventId, empOrderId, exhId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default initializeRevision;
