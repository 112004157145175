import { any, bool, func } from 'prop-types';

import { Toast } from 'common/components';

const ErrorMessage = ({ close, isOpen, children }) => (
  <Toast
    anchor={{ horizontal: 'center', vertical: 'top' }}
    alert="error"
    close={close}
    isOpen={isOpen}
  >
    {children}
  </Toast>
);

ErrorMessage.propTypes = {
  close: func,
  isOpen: bool,
  children: any,
};

export default ErrorMessage;
