import { node, oneOf, string } from 'prop-types';
import cx from 'classnames';

import { ROUTE } from 'common/const';

import { Header, Sidebar } from '../components';
import AccountNav from '../AccountPage/AccountNav';
import styles from './OrderPage.module.css';

const OrderPage = ({ backTo, className, children }) => {
  return (
    <>
      <Header backTo={backTo} />
      <Sidebar label="Account Menu" className={styles.sidebar}>
        <AccountNav />
      </Sidebar>

      <main className={cx(styles.main, className)}>{children}</main>

      {/* No Footer (it's in the UserMenu) */}
    </>
  );
};

OrderPage.propTypes = {
  backTo: oneOf(Object.values(ROUTE)),
  className: string,
  children: node,
};

export default OrderPage;
