import { any, bool, func, string } from 'prop-types';
import cx from 'classnames';
import { Box } from '@mui/system';

import { TEXT } from 'common/const';
import { Button } from 'common/components';

import styles from './OptionButton.module.css';

const OptionButton = ({
  className,
  onClick,
  selected,
  value,
  children,
  ...rest
}) => {
  // console.log('<OptionButton>', { className, selected, children, rest });

  return (
    <Button
      outlined
      color={selected ? 'primary' : 'inherit'}
      className={cx(TEXT.SUBTITLE, styles.button, className)}
      sx={{ borderColor: selected ? 'primary.main' : undefined }}
      onClick={() => onClick(value)}
      {...rest}
    >
      {/* Hack to add a background with opacity & a theme color */}
      <Box
        sx={{ backgroundColor: 'primary.main' }}
        className={cx(styles.background, { [styles.selected]: selected })}
        component="span"
      />
      {children ?? value}
    </Button>
  );
};

OptionButton.propTypes = {
  className: string,
  onClick: func,
  selected: bool,
  value: string,
  children: any,
};

export default OptionButton;
