import { Divider, List } from '@mui/material';
import cx from 'classnames';

import { useAuth, useCustomer } from 'app/context';
import { COLOR, ROUTE, TEXT } from 'common/const';
import { ListItem } from 'common/components';
import { useAppInfo } from 'common/hooks';

import styles from './UserNav.module.css';

const UserNav = ({ close, openVideo }) => {
  const { isLoading, error } = useAppInfo();

  const Item = (props) => (
    <ListItem className={styles.item} onClick={close} {...props} />
  );
  const { logout } = useAuth();
  const { displayName: customerName } = useCustomer();

  return (
    <nav aria-label="User Menu" className={styles.nav}>
      <p className={cx(TEXT.H5, styles.name)}>{customerName}</p>

      <List>
        <Item icon="home" text="Dashboard" to={ROUTE.DASHBOARD} />
        <Item icon="receipt" text="My Orders" to={ROUTE.ORDERS} />
        <Item icon="person" text="My Account" to={ROUTE.ACCOUNT} />

        <li className={styles.item}>
          <Divider />
        </li>

        <p className={cx(COLOR.GREY_600, TEXT.CAPTION, styles.item)}>
          Help & Support
        </p>

        <Item icon="quiz" text="FAQs" to={ROUTE.FAQ} />
        {!isLoading && !error && (
          <Item
            icon="video"
            text="How to Use Online Ordering"
            onClick={() => {
              close();
              openVideo();
            }}
          />
        )}

        <li className={styles.item}>
          <Divider />
        </li>

        <Item icon="logout" text="Log Out" onClick={logout} to={ROUTE.LOGIN} />
      </List>
    </nav>
  );
};

export default UserNav;
