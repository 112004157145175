import { oneOf } from 'prop-types';
import cx from 'classnames';

import { FLAG, TEXT } from 'common/const';

import styles from './Flag.module.css';

const Flag = ({ flag, children }) => {
  return (
    <div className={cx(TEXT.SUBTITLE_2, styles.flag, flag.className)}>
      {flag.content ?? children}
    </div>
  );
};

Flag.propTypes = {
  flag: oneOf(Object.values(FLAG)),
};

export default Flag;
