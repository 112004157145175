import { PAYMENT_METHOD } from 'common/const';
import { round } from 'lodash';

/**
 * Recalculates cart totals for dynamic wire and service fees
 * @param {object} totals - raw totals data to refine
 * @returns updated totals
 */
const calculateCartTotals = ({
  paymentMethod,
  totals,
  isWireTransferTaxable,
  salesTax,
}) => {
  let { tax, total, subtotal, ccFee, wireFee, serviceFee, balanceDue } = totals;

  total = subtotal + serviceFee;

  if (paymentMethod === PAYMENT_METHOD.WIRE) {
    total += wireFee;
    balanceDue += wireFee;
  }

  if (paymentMethod === PAYMENT_METHOD.CARD) {
    total += ccFee;
    balanceDue += ccFee;
  }

  const taxRate = salesTax / 100;

  if (paymentMethod === PAYMENT_METHOD.WIRE && isWireTransferTaxable) {
    tax += round(wireFee * taxRate, 2);
    balanceDue += round(wireFee * taxRate);
  }

  total += tax;

  return {
    ...totals,
    total,
    balanceDue,
    tax,
  };
};

export default calculateCartTotals;
