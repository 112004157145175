/**
 * Helper function for outputting
 * all hashed CSS module `style` keys,
 * keeping consistent keys for use in the template.
 */
const getStyles = (styles) => ({
  address1: styles.address1,
  address2: styles.address2,
  city: styles.city,
  state: styles.state,
  zip: styles.zip,
  country: styles.country,
});

export default getStyles;
