// Use localized formats wherever possible, for future i18n
// https://day.js.org/docs/en/display/format#list-of-localized-formats
// https://day.js.org/docs/en/i18n/i18n

export const FORMAT = {
  SHORT_DATE: 'l', // 6/9/07
  MEDIUM_DATE: 'll', // Jan 9, 2007
  LONG_DATE: 'LL', // January 9, 2007
  FULL_DATE: 'ddd, ll', // Sat, Jun 9, 2007

  SHORT_TIME: 'HH:mm', // 17:46
  MEDIUM_TIME: 'LT z', // 5:46 PM EST
  LONG_TIME: 'LTS z', // 5:46:21 PM EST
  FULL_TIME: 'LTS zzz', // 5:46:21 PM Eastern Standard Time

  SHORT: 'l LT z', // 6/9/07 5:46 PM EST
  MEDIUM: 'll LT', // Jun 9, 2007 5:46 PM EST
  LONG: 'LLL z', // June 9, 2007 5:46 PM EST
  FULL: 'LLLL z', // Saturday, June 9, 2007 8:02 PM EST

  ZONE: 'z', // EST
  ZONE_LONG: 'zzz', // Eastern Standard Time

  SERVER_DATE: 'YYYY-MM-DD', // 2007-06-09
};
