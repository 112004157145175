import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getEvent.mock';

const baseUrl = getBaseUrl(2);

/**
 * Tells if an event is offline
 * @param {string} eventId
 * @returns object with the event data
 */
const getEventStatus = async (eventId) => {
  await VALID.check(eventId, VALID.EVENT_ID.required('eventId is required'));

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}events/is_online/${eventId}`);

    return {
      isOffline: data.isOnline === false,
      isOnline: data.isOnline,
    };
  } catch (error) {
    log('getEventStatus', {
      data: { eventId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getEventStatus;
