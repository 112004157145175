import { sortBy } from 'lodash';

export const SORT_KEYS = ['dateStart', 'name'];
export const sort = (list) => sortBy(list, SORT_KEYS);

const format = (value) => value.toLowerCase().trim();

/**
 * Get a sorted list of objects whose filtered properties match the value
 * @param {array} data - list of event objects
 * @param {array} filterNames - list of filter properties to pull
 * @returns {array} sorted list of objects, for results
 */
const getResults = (data, filterNames, value) => {
  // console.log('!getResults', { data, filterNames, value });

  if (!value) {
    return sort(data);
  }

  // cache this value so we don't calculate every time
  const formattedValue = format(value);

  const list = data.filter((item) =>
    filterNames.some((filter) => format(item[filter]).includes(formattedValue)),
  );

  return sort(list);
};

export default getResults;
