import { VALID } from 'common/const';

const FIELD = {
  address1: {
    label: 'Address 1',
    placeholder: 'Street, Building, Unit, etc',
    validation: VALID.ADDRESS.required('Address is required'),
  },
  address2: {
    label: 'Address 2',
    placeholder: 'District',
    validation: VALID.DISTRICT.required('District is required'),
  },
  city: {
    label: 'City',
    placeholder: 'City, Province, Ward',
    validation: VALID.CITY.required('City is required'),
  },
  zip: {
    label: 'Postal Code',
    validation: VALID.POSTAL_CODE.required('Postal code is required'),
  },
};

export default FIELD;
