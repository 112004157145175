import { VALID } from 'common/const';

const FIELD = {
  address1: {
    label: 'Address 1',
    placeholder: 'Street Name, Street Number, etc',
    validation: VALID.ADDRESS.required('Address is required'),
  },
  address2: {
    label: 'Address 2',
    optional: true,
    placeholder: 'Building Number, Floor, Apartment Number, etc',
    validation: VALID.ADDRESS_2,
  },
  city: {
    label: 'Town',
    validation: VALID.TOWN.required('Town is required'),
  },
  state: {
    label: 'Province',
    validation: VALID.PROVINCE.required('Province is required'),
  },
  zip: {
    label: 'Postcode',
    validation: VALID.POSTAL_CODE.required('Postcode is required'),
  },
};

export default FIELD;
