import { merge } from 'lodash';
import { ROUTE } from 'common/const';

const INIT_STATE = {
  steps: [
    { label: 'Cart', link: ROUTE.SHOPPING },
    { label: 'Booth', link: ROUTE.BOOTH_WORK },
    { label: 'Contact', link: ROUTE.CONTACT },
    { label: 'Payment', link: ROUTE.PAYMENT },
    { label: 'Review', link: ROUTE.REVIEW },
  ],
  currentStep: 1,
  exhAddId: undefined,
  error: undefined,
  billingAddId: undefined,
  showFileUpload: undefined,
  isFloorPlanUploaded: undefined,
  documentId: undefined,
  boothQuestions: undefined,
  total: undefined,
  paymentMethod: undefined,
  eventId: undefined,
  customerId: undefined,
  merchantId: undefined,
  isThirdParty: undefined,
  thirdPartyCompanyName: undefined,
  thirdPartyCompanyResponseId: undefined,
  ooId: undefined,
  contactAddress: {},
  billingAddress: {},
};

export const init = (data) => merge({}, INIT_STATE, data);

export const TYPE = {
  CURRENT_STEP: 'current-step',
  UPDATE: 'update-data',
  ERROR: 'error',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case TYPE.UPDATE: {
      return {
        ...state,
        ...payload,
      };
    }

    case TYPE.ERROR:
      return {
        ...state,
        error: payload,
      };

    case TYPE.EXH_ADDRESS:
      return {
        ...state,
        exhibitorAddressId: payload,
      };

    default:
      throw new Error('Checkout reducer payload.type not recognized');
  }
};

export default reducer;
