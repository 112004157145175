import axios from 'axios';

import { getBaseUrl, getSiteOwnership, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

const baseUrl = getBaseUrl(2);
const ownership = getSiteOwnership();

/**
 * Updates the customer info
 * @param {string} customerId
 * @param {object} customerInfo
 * @returns "Successful" string
 */
const updateAccountInfo = async ({
  customerId,
  customerInfo,
  currentPassword,
}) => {
  try {
    await VALID.checkAll(
      ['customerId', customerId, VALID.CUSTOMER_ID],
      ['customerInfo', customerInfo, VALID.OBJECT],
    );

    if (!ENV.IS_MOCK) {
      await axios.post(`${baseUrl}customer/update_account_info`, {
        customerId,
        customerInfo,
        currentPassword,
        ownership,
      });
    }
  } catch (error) {
    log('updateAccountInfo', {
      data: { customerId, customerInfo },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default updateAccountInfo;
