import { arrayOf, func, shape, string, number } from 'prop-types';
import { useHistory } from 'react-router';
import { Icon, TextLink } from 'common/components';
import { track } from 'common/utils';
import { COLOR, ROUTE, TEXT } from 'common/const';
import cx from 'classnames';

import styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ steps, currentStep, setCurrentStep }) => {
  const history = useHistory();

  return (
    <nav className={styles.row}>
      <ol className={styles.breadcrumbs}>
        {steps.map(({ label, link }, index) => {
          const active = currentStep < index;

          return (
            <li key={index} className={cx(TEXT.CAPTION, styles.breadcrumb)}>
              {/* Current page */}
              {!active && index === currentStep ? (
                <p className={styles.link}>{label}</p>
              ) : active ? (
                // Future page (grey)
                <p className={cx(COLOR.GREY_600, styles.link)}>{label}</p>
              ) : (
                // Linked pages
                <TextLink
                  className={styles.link}
                  onClick={() => {
                    setCurrentStep({ currentStep: index });
                    history.push(link, { from: ROUTE.CHECKOUT });
                    track(`breadcrumb_${label}_click`);
                  }}
                >
                  {label}
                </TextLink>
              )}
              <div className={cx(COLOR.GREY_300, styles.icon)}>
                <Icon color="inherit" name={'caret-right'} size="small" />
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  steps: arrayOf(
    shape({
      label: string,
      link: string,
    }),
  ),
  currentStep: number,
  setCurrentStep: func,
};

export default Breadcrumbs;
