import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getEventSearchItems.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get search items for a specifc order
 * @param {string} eventId
 * @param {string} ooId
 * @param {string} user
 * @returns list of itemIds
 */
const getEventSearchItems = async ({ eventId, ooId, tagName }) => {
  await VALID.checkAll(
    ['eventId', eventId, VALID.EVENT_ID],
    ['ooId', ooId, VALID.OO_SUMMARY_ID],
    ['tagName', tagName, VALID.STRING],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(
          `${baseUrl}events/search_tags/${ooId}/${eventId}/${tagName}`,
        );

    return data.searchTagResults;
  } catch (error) {
    log('getEventSearchItems', {
      data: { eventId, ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getEventSearchItems;
