import { VALID } from 'common/const';

const FIELD = {
  address1: {
    label: 'Address 1',
    placeholder: 'House Number, Street, etc',
    validation: VALID.ADDRESS.required('Address is required'),
  },
  address2: {
    label: 'Address 2',
    placeholder: 'District',
    validation: VALID.DISTRICT.required('District is required'),
  },
  city: {
    label: 'City',
    placeholder: 'City, Rural Area',
    validation: VALID.CITY.required('City is required'),
  },
  state: {
    label: 'Metropolis/Prefecture',
    validation: VALID.CITY.required('Metropolis is required'),
  },
  zip: {
    label: 'Postal Code',
    validation: VALID.POSTAL_CODE.required('Postal code is required'),
  },
};

export default FIELD;
