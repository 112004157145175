export const COMPLETED = {
  DATE_LABEL: 'Date placed',
};

export const INCOMPLETE = {
  DATE_LABEL: 'Date started',
  MESSAGE: 'Shown After Checkout',
  ITEMS_MESSAGE: 'Items added to your order will appear here.',
};

export const STATUS = {
  CONFIRMED: 'Confirmed',
  FINALIZED: 'Finalized',
  PENDING: 'Pending',
};
