/* Buttons */
export const BUTTON_SIZES = ['small', 'medium', 'large'];
export const BUTTON_COLORS = [
  'inherit',
  'primary',
  'secondary',
  'error',
  'info',
  'success',
  'warning',
];
export const BUTTON_TYPES = ['button', 'submit', 'reset'];

/* Chips */
export const CHIP_COLORS = [
  'default',
  'primary',
  'secondary',
  'error',
  'info',
  'success',
  'warning',
];

/* Loading */
export const LOADING_COLORS = [
  'inherit',
  'primary',
  'secondary',
  'error',
  'info',
  'success',
  'warning',
];
export const LOADING_SIZES = {
  button: 16,
  grow: '50%',
  small: 40,
};

/* Icons */
export const ICON_SIZES = ['inherit', 'small', 'medium', 'large'];
export const ICON_COLORS = [
  'inherit',
  'action',
  'disabled',
  'primary',
  'secondary',
  'error',
  'info',
  'success',
  'warning',
];

/* Icon Buttons */
export const ICON_BUTTON_COLORS = [
  'inherit',
  'default',
  'primary',
  'secondary',
  'error',
  'info',
  'success',
  'warning',
];
