import cx from 'classnames';

import { useCustomer } from 'app/context';
import { BORDER, PHONE_TYPE, TEXT } from 'common/const';
import { Page } from 'common/site';
import { Content, Toast } from 'common/components';

import TitleCard from './TitleCard';
import { Company, Email, Name, Password, PhoneNumber } from './sections';

import styles from './Account.module.css';

const Account = () => {
  const customer = useCustomer();
  const { isUpdating, toast, closeToast } = useCustomer();

  return (
    <Page variant="account" title="My Account">
      <h1 className={TEXT.H5}>Account</h1>

      <Content isLoading={isUpdating}>
        <TitleCard title="My Password" className={styles.card}>
          <Password />
        </TitleCard>

        <TitleCard title="My Information" className={styles.card}>
          <div className={cx(BORDER.DIVIDER, styles.section)}>
            <Name />
          </div>
          <div className={cx(BORDER.DIVIDER, styles.section)}>
            <Email />
          </div>
          <div className={cx(BORDER.DIVIDER, styles.section)}>
            <PhoneNumber
              phoneType={PHONE_TYPE.MOBILE}
              defaultValue={customer.mobile}
            />
          </div>
        </TitleCard>

        <TitleCard title="Company" className={styles.card}>
          <div className={cx(BORDER.DIVIDER, styles.section)}>
            <Company />
          </div>
        </TitleCard>
      </Content>

      <Toast
        alert={toast.alert}
        anchor={{ vertical: 'top', horizontal: 'right' }}
        close={closeToast}
        isOpen={Boolean(toast.alert)}
      >
        {toast.message}
      </Toast>
    </Page>
  );
};

export default Account;
