import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getReusableOrders.mock';

const baseUrl = getBaseUrl(2);

/**
 * Gets reusableOrders for a given ooId
 * NOTE: This is only called after the type and location booth questions
 * @param {int} ooId - original orderId
 * @returns List of reusable orders
 */
const getReusableOrders = async ({ ooId }) => {
  await VALID.check(ooId, VALID.OOID.required('ooId is required'));

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}orders/reusable_orders/${ooId}`);

    return data.reusableOrders;
  } catch (error) {
    log('getReusableOrders', {
      data: { ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getReusableOrders;
