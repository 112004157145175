import { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';

import { BORDER } from 'common/const';
import { QuantityButton, Toast } from 'common/components';
import { useCart } from 'app/context';

import styles from './AddToCart.module.css';

const AddToCart = ({ item }) => {
  // console.log('<ProductDetail.AddToCart>', { item });
  const { items: cartItems = [], updateCart, error } = useCart();

  const { quantity = 0, requiredQuantity = 0 } = useMemo(
    () =>
      cartItems.reduce(
        ({ quantity, requiredQuantity }, cartItem) =>
          item.id === cartItem.id
            ? {
                quantity: quantity + cartItem.quantity,
                requiredQuantity: requiredQuantity + cartItem.requiredQuantity,
              }
            : { quantity, requiredQuantity },
        { quantity: 0, requiredQuantity: 0 },
      ),
    [item, cartItems],
  );

  const [toast, setToast] = useState({ alert: undefined, message: undefined });

  const handleSubmit = useCallback(
    (qty) => {
      // helper fn
      const submit = (qty, toast) => {
        // only update if newQuantity is different
        if (qty !== quantity) {
          updateCart({ ...item, quantity, requiredQuantity }, qty);
        }
        setToast(toast);
      };

      const remainder = (qty - item.addIncrement) % item.updateIncrement;

      // Set to min
      if (qty < item.min) {
        submit(item.min, {
          alert: 'warning',
          message: `You must have a minimum of ${item.min} ${item.name} per order. We've added them to your cart.`,
        });
      } else if (qty < requiredQuantity) {
        submit(requiredQuantity, {
          alert: 'warning',
          message: `You must have a minimum of ${requiredQuantity} ${item.name}. We've added them to your cart.`,
        });
      } else if (qty === 0) {
        submit(qty, {});
        // Set to max
      } else if (qty > item.max) {
        submit(item.max, {
          alert: 'warning',
          message: `You can only have a maximum of ${item.max} ${item.name} per order. We've added them to your cart.`,
        });
        // Set to min add increment
      } else if (qty < item.addIncrement) {
        submit(item.addIncrement, {
          alert: 'warning',
          message: `You must have a minimum of ${item.addIncrement} ${item.name} per order. We've added them to your cart.`,
        });
        // Round up to valid increment
      } else if (remainder) {
        setToast({
          alert: 'warning',
          message: item.isLabor
            ? 'You can only add increments of 15 mins (0.25h), 30 mins (0.50h), 45 mins (0.75h) and 60 mins (1h).'
            : `You can only add increments of ${item.updateIncrement}`,
        });
      } else {
        submit(qty, {});
      }
    },
    [item, updateCart, quantity, requiredQuantity],
  );

  return (
    <>
      <div className={cx(BORDER.DIVIDER, styles.quantity)}>
        <QuantityButton
          input
          onChange={handleSubmit}
          number={quantity}
          addIncrement={item.addIncrement}
          updateIncrement={item.updateIncrement}
          min={Math.max(item.min, requiredQuantity)}
          max={item.max}
          className={cx(BORDER.PAPER, styles.border)}
          disabled={Boolean(error)}
        />
      </div>

      <Toast
        alert={toast.alert}
        anchor={{ vertical: 'top', horizontal: 'right' }}
        isOpen={Boolean(toast.alert)}
        close={() => setToast({})}
        className={styles.toast}
        showDuration={5} // seconds
      >
        {toast.message}
      </Toast>
    </>
  );
};

export default AddToCart;
