import { TextLink } from 'common/components';
import cx from 'classnames';

import { ENV } from 'common/const';

import styles from './PrivacyPolicyLink.module.css';

const PrivacyPolicyLink = ({ className, ...props }) => (
  <TextLink
    href={ENV.PUBLIC_URL + '/LegalNotices/Terms-Edlen-GDPR.pdf'}
    className={cx(className, styles.link)}
    {...props}
  >
    Privacy Policy
  </TextLink>
);

export default PrivacyPolicyLink;
