import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { VALID } from 'common/const';

const baseUrl = getBaseUrl(2);

/**
 * Gets totals for an incomplete order specified by parameters
 * @param {string} empOrderId - Id for EMP order
 * @returns
 */
const getReviseLock = async ({ empOrderId }) => {
  await VALID.check(empOrderId, VALID.UUID.required('empOrderId is required'));
  try {
    const { data } = await axios.get(
      `${baseUrl}orders/revise_lock/${empOrderId}`,
    );
    return data?.locks.length > 0;
  } catch (error) {
    log('getReviseLock', {
      data: { empOrderId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getReviseLock;
