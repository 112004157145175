import axios from 'axios';
import { getBaseUrl, getSiteOwnership, log, scrubString } from 'common/utils';
import { ENV, VALID } from 'common/const';

const baseUrl = getBaseUrl(2);
const ownership = getSiteOwnership();
/**
 * Registers a user
 * @param {Object} details
 * @returns "Successful" string
 */
const register = async (details) => {
  const { emailAddress, password, firstName, lastName, phone, company } =
    details;
  await VALID.checkAll(
    ['emailAddress', emailAddress, VALID.EMAIL],
    ['password', password, VALID.PASSWORD],
    ['firstName', firstName, VALID.FIRST_NAME],
    ['lastName', lastName, VALID.LAST_NAME],
    ['phone', phone, VALID.PHONE_NUMBER],
    ['company', company, VALID.COMPANY_NAME],
  );

  const address = {
    address1: details.address1,
    address2: details.address2,
    city: details.city,
    countryCode: details.countryCode,
    mobile: scrubString(details.mobile),
    phone: scrubString(phone),
    state: details.state,
    type: 'Customer',
    zip: details.zip,
  };

  try {
    const payload = {
      theData: {
        addressInformation: address,
        companyInformation: { address: address, name: company, users: [] },
        emailAddress: emailAddress,
        firstName: firstName,
        lastName: lastName,
        password: password,
      },
      ownership,
    };

    if (!ENV.IS_MOCK) {
      await axios.post(`${baseUrl}customer/signup`, payload);
    }
  } catch (error) {
    log('register', {
      data: { emailAddress, firstName, lastName, phone, company },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default register;
