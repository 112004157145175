import { merge, omit } from 'lodash';

import { EMPTY_CART } from 'common/const';
import { findItem } from 'common/utils';

export const TYPE = {
  NEW_CART: 'new-cart',
  QUEUE: 'queue',
  REFRESH: 'refresh-started',
  REFRESH_SETTLED: 'refresh-settled',
  REVISE_UPDATE_SETTLED: 'revise-update-settled',
  UPDATE: 'update-item',
  UPDATE_SETTLED: 'update-item-settled',
  UPDATE_ERROR: 'update-item-error',
};

const INIT_STATE = {
  // cart data
  boothNumber: '',
  eventId: '',
  ooId: EMPTY_CART.ID,
  items: [],
  itemDifferences: [],
  error: '',
  totals: EMPTY_CART.TOTALS,

  // cart update handling
  isTouched: false,
  isRefreshing: false,
  queue: {}, // items by id { id: { item, quantity }}
  unsettled: [],
};

export const init = (currentState) => merge({}, INIT_STATE, currentState);

const reducer = (state, { type, payload }) => {
  // console.log('!reducer', { state, type, payload });

  const { ooId, eventId, order, item, quantity, error } = payload ?? {};

  switch (type) {
    case TYPE.NEW_CART:
      return {
        ...state,
        ooId,
        eventId,
      };

    case TYPE.QUEUE:
      return {
        ...state,
        isTouched: true,
        // TODO! - add isEarlyPricing
        items: findItem(state.items, item)
          ? state.items.map((cartItem) =>
              cartItem.id === item.id && cartItem.isRequired === item.isRequired // && cartItem.isEarlyPricing === item.isEarlyPricing
                ? merge({}, cartItem, { quantity })
                : cartItem,
            )
          : [...state.items, { ...item, quantity }],
        queue: {
          ...state.queue,
          // [item.id + '-' + item.isEarlyPricing]: { ...item, quantity },
          [item.id + '-' + item.isRequired]: { ...item, quantity },
        },
      };

    case TYPE.REFRESH:
      return {
        ...state,
        isTouched: false,
        isRefreshing: true,
      };

    case TYPE.REFRESH_SETTLED:
      return {
        ...state,
        ...order,
        items: error
          ? order.items // on error, fallback to refreshed cart items
          : order.items.map((item) => ({
              ...item,
              quantity: item.isRequired
                ? item.quantity // required items don't control their own quantity
                : findItem(order.items, item)?.quantity ?? item.quantity,
            })),
        isRefreshing: false,
        error: '', // clear error
      };

    case TYPE.REVISE_UPDATE_SETTLED:
      // TODO! - Add isEarlyPricing
      return {
        ...state,
        items: state.items.map((cartItem) =>
          cartItem.id === item.id && cartItem.isRequired === item.isRequired // && cartItem.isEarlyPricing === item.isEarlyPricing
            ? merge({}, item, { quantity: cartItem.quantity })
            : cartItem,
        ),
        unsettled: state.unsettled.filter(
          (id, isRequired) => id !== item?.id && isRequired === item.isRequired, // && isEarlyPricing === item?.isEarlyPricing,
        ),
        revisedItems: state.revisedItems.length
          ? state.revisedItems.reduce(
              (list, revisedItem) =>
                revisedItem.id === item.id &&
                revisedItem.isRequired === item.isRequired // && revisedItem.isEarlyPricing === item.isEarlyPricing
                  ? [...list, { ...revisedItem, quantity: item.quantity }]
                  : [...list, item],
              [],
            )
          : [item],
      };

    case TYPE.UPDATE:
      // TODO! - add isEarlyPricing
      return {
        ...state,
        // queue: omit(state.queue, item.id + '-' + item.isEarlyPricing),
        // unsettled: [...state.unsettled, item.id + '-' + item.isEarlyPricing],
        queue: omit(state.queue, item.id + '-' + item.isRequired),
        unsettled: [...state.unsettled, item.id + '-' + item.isRequired],
      };

    case TYPE.UPDATE_SETTLED:
      // TODO! - add isEarlyPricing
      return {
        ...state,
        items: state.items.map((cartItem) =>
          cartItem.id === item.id && cartItem.isRequired === item.isRequired // && cartItem.isEarlyPricing === item.isEarlyPricing
            ? merge({}, item, { quantity: cartItem.quantity })
            : cartItem,
        ),
        unsettled: state.unsettled.filter(
          (id, isRequired) => id !== item?.id && isRequired === item.isRequired, // && isEarlyPricing === item?.isEarlyPricing,
        ),
      };

    case TYPE.UPDATE_ERROR:
      return {
        ...state,
        queue: {}, // on error, empty the queue
        unsettled: state.unsettled.filter((id) => id !== item?.id),
        error,
      };

    default:
      throw new Error('Mismatched type for useCart dispatch');
  }
};

export default reducer;
