import { node } from 'prop-types';
import { Paper } from '@mui/material';
import cx from 'classnames';
import { useState } from 'react';

import { BACKGROUND, TEXT } from 'common/const';
import { Button, HowToVideo } from 'common/components';
import { useAppInfo } from 'common/hooks';

import { Logo, Footer } from '../components';

import styles from './IslandPage.module.css';

const IslandPage = ({ className, title, subtitle, children }) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const { data } = useAppInfo();

  return (
    <div className={cx(BACKGROUND.GREY_50, styles.page)}>
      <div className={styles.howTo}>
        <Button startIcon="video" onClick={() => setIsVideoOpen(true)}>
          How-To Guide
        </Button>
      </div>
      <Paper className={styles.paper}>
        <header className={styles.logo}>
          <Logo />
        </header>

        <main className={className}>
          {title ? (
            <div className={styles.heading}>
              <h1 className={TEXT.H5}>{title}</h1>
              <p className={cx(TEXT.BODY_1, styles.subtitle)}>{subtitle}</p>
            </div>
          ) : null}
          {children}
        </main>
      </Paper>

      <HowToVideo
        isOpen={isVideoOpen}
        setIsOpen={setIsVideoOpen}
        link={data?.howToVideoLink}
      />

      <Footer className={styles.footer} />
    </div>
  );
};

IslandPage.propTypes = {
  children: node,
};

export default IslandPage;
