import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV } from 'common/const';

import mock from './getMaxCreditCard.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get the max credit card value
 * @returns Max credit card value
 */
const getMaxCreditCard = async () => {
  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}lookup/max_cc_value`);

    return data.maxCreditCardAmount;
  } catch (error) {
    log('getMaxCreditCard', {
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getMaxCreditCard;
