import { getItem, getPayment } from 'data/utils';
import { getAssessment, getTotals } from 'data/utils';
import { date } from 'common/utils';

const getOrder = (data = {}) => {
  const { boothData } = data;

  const isPast =
    data?.eventCloseDate && data?.currentDate
      ? date(data?.eventCloseDate.split('T')[0]).isBefore(
          data?.currentDate.split('T')[0],
          'day',
        )
      : false;

  return {
    assessmentResponseData:
      data?.orderAssessmentResponseData?.map(getAssessment),
    billingInformation: data.personalInformation?.addressInformation,
    booth: {
      location:
        boothData.location && boothData.location.length
          ? boothData.location
          : 'blank',
      number: boothData.number,
      size: boothData.size,
      type: boothData.type && boothData.type.length ? boothData.type : 'blank',
    },
    branchEmail: data.branchEmail,
    branchName: data.branchName,
    branchPhone: data.branchPhone,
    cancellationProcessDate: data.cancellationProcessDate,
    cancellationStatus: data.cancellationStatus,
    cancelRequestDate: data.cancelRequestDate,
    company: data.companyName,
    date: data.date,
    empOrderId: data.empOrderId,
    eventCloseDate: data.eventCloseDate,
    eventId: data.eventId,
    eventImage: data.eventLogo,
    eventName: data.eventName,
    eventOpenDate: data.eventOpenDate,
    exhId: data.exhId,
    facilityId: data.facilityId,
    facilityLocation: data.facilityLocation,
    facilityName: data.facilityName,
    floorPlanExists: data.floorPlanExists,
    history: data.orderHistory,
    id: data?.id?.toString(),
    isCancellationAllowed: data.isCancellationAllowed,
    isCompleted: data.isCompleted,
    isEarlyPricing: data.isEarlyPricing,
    isFloorPlanBuilderAvailable: data.isFloorPlanBuilderAvailable,
    isOffline: data.isOOAvailable === false,
    isPast: isPast,
    isPending: data.orderStatus === 'Pending',
    isRevisionAvailable: data.isRevisionAvailable,
    isThirdParty: data.isThirdParty,
    itemCount: data.items.reduce(
      (count, { quantity, itemId }) =>
        !data.isCompleted || (itemId > '999' && itemId < '99000')
          ? count + quantity
          : count,
      0,
    ),
    itemDifferences: data?.itemDifferences?.map(getItem) ?? [],
    itemDifferencesTotals: getTotals(data?.itemDifferencesTotals) ?? {},
    items: data.items.map(getItem),
    payment: data?.paymentDetails?.map(getPayment),
    personalInformation: {
      firstName: data.personalInformation?.firstName,
      lastName: data.personalInformation?.lastName,
      mobile: data.personalInformation?.addressInformation?.mobile,
      email: data.personalInformation?.emailAddress,
    },
    relatedOrders: data.relatedOrders, // TODO!! - Remove once emp oo sync happens
    revisedItems: data?.revisedItems?.map(getItem),
    showFileUpload: data.showFileUpload,
    thirdPartyCompanyName: data.thirdPartyCompanyName,
    totals: getTotals(data.totals),
  };
};

export default getOrder;
