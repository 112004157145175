import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './ssoAuthenticate.mock';

const baseUrl = getBaseUrl(2);

/**
 * Authenticates a user
 * @param {string} user.email
 * @param {string} user.password
 * @returns Object with users data
 */
const ssoAuthenticate = async ({ partnerId, token }) => {
  await VALID.checkAll(
    ['partnerId', partnerId, VALID.STRING],
    ['token', token, VALID.STRING],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock({ partnerId, token })
      : await axios.post(`${baseUrl}customer/sso_authenticate`, {
          partnerId,
          token,
        });

    return {
      customerId: data.customerId,
      resultInformation: data.resultInformation,
      eventId: data.eventId,
      jwtToken: data.jwtToken,
    };
  } catch (error) {
    log('ssoAuthenticate', {
      data: { partnerId, token },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default ssoAuthenticate;
