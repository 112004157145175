import axios from 'axios';

import { ENV } from 'common/const';
import { getBaseUrl, getSiteOwnership, log } from 'common/utils';
import { getEventDetails } from 'data/utils';

import mock from './getEventsByOwnership.mock';

const baseUrl = getBaseUrl(2);
const ownership = getSiteOwnership();

/**
 * Get a list of all online events by ownership
 * @returns object with the event data
 */
const getEventsByOwnership = async () => {
  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}events/by_ownership/${ownership}`);

    const list = data.eventData;

    return list.map(getEventDetails);
  } catch (error) {
    log('getEventsByOwnership', {
      data: { ownership },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getEventsByOwnership;
