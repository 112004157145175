// https://mui.com/components/material-icons/
import {
  Add,
  ArrowBack,
  Call,
  CancelOutlined,
  ChatBubbleOutlineOutlined,
  CheckCircleOutline,
  Circle,
  Close,
  Delete,
  Event,
  FileUploadOutlined,
  Home,
  Info,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Logout,
  Menu,
  NotificationsNone,
  Person,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Receipt,
  Remove,
  Search,
  Settings,
  ShoppingCart,
  SmartDisplayOutlined,
  TipsAndUpdatesOutlined,
  Visibility,
  VisibilityOff,
  Quiz,
} from '@mui/icons-material';

const CONFIG = {
  // config keys should always use camelCase
  arrowBack: ArrowBack,
  bell: NotificationsNone,
  call: Call,
  caretDown: KeyboardArrowDown,
  caretLeft: KeyboardArrowLeft,
  caretRight: KeyboardArrowRight,
  caretUp: KeyboardArrowUp,
  cart: ShoppingCart,
  chat: ChatBubbleOutlineOutlined,
  close: Close,
  event: Event,
  fileUpload: FileUploadOutlined,
  home: Home,
  info: Info,
  logout: Logout,
  menu: Menu,
  minus: Remove,
  person: Person,
  plus: Add,
  receipt: Receipt,
  search: Search,
  settings: Settings,
  radioChecked: RadioButtonChecked,
  radioEmpty: RadioButtonUnchecked,
  radioFilled: Circle,
  trash: Delete,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  tip: TipsAndUpdatesOutlined,
  invalid: CancelOutlined,
  valid: CheckCircleOutline,
  video: SmartDisplayOutlined,
  quiz: Quiz,
};

export default CONFIG;
