import { useState } from 'react';

import { useCustomer } from 'app/context';
import { TEXT, VALID } from 'common/const';
import {
  Button,
  Content,
  Form,
  Input,
  SubmitButton,
  SectionHeader,
} from 'common/components';

import styles from './Name.module.css';

const Name = () => {
  const customer = useCustomer();
  const [active, setActive] = useState(false);

  return active ? (
    <Content isLoading={customer.isUpdating}>
      <Form
        validation={{
          firstName: VALID.FIRST_NAME.required('First name is required'),
          lastName: VALID.LAST_NAME.required('Last name is required'),
        }}
        onPass={({ firstName, lastName }) => {
          customer.changeName({
            firstName,
            lastName,
          });
        }}
        onFail={(errors) => {
          // console.error({ errors });
        }}
        isSubmitting={customer.isUpdating}
        defaultValues={{
          firstName: customer.firstName,
          lastName: customer.lastName,
        }}
      >
        <SectionHeader el="legend">Edit Full Name</SectionHeader>

        <fieldset className={styles.fieldset}>
          <Input name="firstName" label="First Name" required />
          <Input name="lastName" label="Last Name" required />
        </fieldset>

        <div className={styles.actions}>
          <SubmitButton name="submit" isSubmitting={customer.isUpdating}>
            Update
          </SubmitButton>
          <Button text name="cancel" onClick={() => setActive(false)}>
            Cancel
          </Button>
        </div>
      </Form>
    </Content>
  ) : (
    <Content isLoading={customer.isUpdating}>
      <SectionHeader action={{ onClick: () => setActive(true) }}>
        <p className={TEXT.SUBTITLE}>Full Name</p>
      </SectionHeader>

      <p>{customer.fullName}</p>
    </Content>
  );
};

export default Name;
