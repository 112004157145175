import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

const getQueries = (dayjs) => {
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);

  return dayjs;
};

export default getQueries;
