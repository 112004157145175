import cx from 'classnames';

import BACKGROUND from './background';
import COLOR from './color';
import TEXT from './text';

const FLAG = {
  FREE_PACKAGE: {
    content: 'Booth Package',
    className: cx(BACKGROUND.BOOTH_PACKAGE, COLOR.BOOTH_PACKAGE_TEXT),
  },
  PACKAGE: {
    content: 'Power Package',
    className: cx(BACKGROUND.POWER_PACKAGE, COLOR.POWER_PACKAGE_TEXT),
  },
  CATEGORY: {
    className: cx(BACKGROUND.GREY_50, COLOR.TEXT, TEXT.OVERLINE),
  },
  COMPLETED: {
    content: 'COMPLETED',
    className: cx(BACKGROUND.BOOTH_PACKAGE, COLOR.SUCCESS, TEXT.OVERLINE),
  },
  PENDING: {
    content: 'PENDING',
    className: cx(BACKGROUND.GREY_50, COLOR.TEXT, TEXT.OVERLINE),
  },
  CANCELLED: {
    content: 'CANCELLED',
    className: cx(BACKGROUND.GREY_100, COLOR.GREY_800, TEXT.OVERLINE),
  },
  CANCEL_REQUESTED: {
    content: 'CANCEL REQUESTED',
    className: cx(BACKGROUND.GREY_100, COLOR.GREY_600, TEXT.OVERLINE),
  },
};

export default FLAG;
