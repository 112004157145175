import { IMAGE } from 'common/const';
import { ProductDefault } from 'assets/images';

/**
 * Prepends "base image url" onto image path when image path exists,
 * or returns default product image.
 * @param {string} imageName - image name
 * @returns
 */
const getProductImage = (imageName) =>
  imageName ? [IMAGE.PRODUCT, imageName].join('/') : ProductDefault;

export default getProductImage;
