import { LOGO } from 'common/const';
import { getSiteOwnership, getEventImage } from 'common/utils';
import { Avatar } from 'common/components';

const EventLogo = ({ image, name }) => {
  const logoName = getSiteOwnership();
  const { src: EventDefault } = LOGO[logoName];

  return image ? (
    <img
      alt={name + ' logo'}
      src={getEventImage(image)}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = EventDefault;
      }}
    />
  ) : (
    <Avatar variant="rounded">{name}</Avatar>
  );
};

export default EventLogo;
