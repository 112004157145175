import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';
import { getIsEarlyPricing, getEventDetails } from 'data/utils';

import mock from './getEvent.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get data for a specified event
 * @param {string} user.eventId
 * @returns object with the event data
 */
const getEvent = async (eventId) => {
  await VALID.check(eventId, VALID.EVENT_ID.required('eventId is required'));

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}events/${eventId}`);

    const { eventData } = data;

    const isEarlyPricing = getIsEarlyPricing({
      deadlineDate: eventData.deadlineDate,
      serverDate: data.webServerDateTime,
    });

    return getEventDetails({ ...eventData, isEarlyPricing });
  } catch (error) {
    log('getEvent', {
      data: { eventId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getEvent;
