import { useCallback, useEffect, useState } from 'react';
import { func } from 'prop-types';
import { useHistory } from 'react-router';
import cx from 'classnames';

import { useCustomer, useShop } from 'app/context';
import { ADDRESS_TYPE, ROUTE, TEXT } from 'common/const';
import {
  Alert,
  ContactForm,
  Content,
  EventOfflineDialog,
} from 'common/components';
import { useEventStatus, useBoothAssessment, useAddress } from 'common/hooks';
import { track } from 'common/utils';

import styles from './Contact.module.css';

const Contact = ({ setState, state }) => {
  const [isOpen, setIsOpen] = useState(false);

  const shop = useShop();
  const { refetch, isUpdating, ...customer } = useCustomer();
  const history = useHistory();
  const { isOffline } = useEventStatus(state.eventId);
  const {
    submit: submitAssessment,
    isSubmitting: isSubmittingAssessment,
    error: assessmentError,
  } = useBoothAssessment(state.ooId, {
    questionType: 1,
  });

  useEffect(() => {
    if (isOffline) {
      setIsOpen(true);
    }
  }, [isOffline]);

  // Go to top of page on intial load
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const {
    addAddress: submit,
    addAddressError: error,
    addAddressIsLoading: isSubmitting,
  } = useAddress(
    {},
    {
      onSuccess: ({ addressId: exhAddId, type, address }) => {
        setState({ exhAddId });
        if (type === ADDRESS_TYPE.CUSTOMER) {
          refetch();
        }
        setState({ currentStep: 3, contactAddress: address });
        history.push(ROUTE.PAYMENT);
        track('contact_next_click');
      },
    },
  );

  const handleSubmit = useCallback(
    ({ data: address, thirdPartyData, saveAddress }) => {
      submit({
        ooId: shop.ooId,
        type: saveAddress ? ADDRESS_TYPE.CUSTOMER : ADDRESS_TYPE.COMPANY,
        address,
        customerId: customer.customerId,
      });

      if (thirdPartyData.updateThirdParty) {
        // Update the third party customer assessment response
        submitAssessment({
          question: { assessmentId: 12, id: state.thirdPartyCompanyResponseId },
          response: `Yes;${thirdPartyData.thirdPartyCompanyName}`,
          onSuccess: () => {
            setState({
              thirdPartyCompanyName: thirdPartyData.thirdPartyCompanyName,
            });
          },
        });
      }

      if (saveAddress) {
        customer.changeAccountInfo({
          firstName: address.firstName,
          lastName: address.lastName,
          companyName: address.company,
        });
      }
    },
    [submit, shop, customer, submitAssessment, state, setState],
  );

  return (
    <Content error={error?.message || assessmentError?.message}>
      <h6 className={cx(TEXT.H6, styles.heading)}>Contact</h6>

      {shop.reviseOoId ? (
        <div className={styles.heading}>
          <Alert severity="warning">
            We will update your order if any changes are made to your Contact
            Information. If you do not need to update, continue to Payment.
          </Alert>
        </div>
      ) : null}
      <ContactForm
        defaultValues={{
          ...customer,
          ...state.contactAddress,
          isThirdParty: state.isThirdParty,
          thirdPartyCompanyName: state.thirdPartyCompanyName,
        }}
        onSubmit={handleSubmit}
        buttonText={
          shop.reviseOoId ? 'Continue to Revise' : 'Continue to Payment'
        }
        isSubmitting={isSubmitting || isSubmittingAssessment || isUpdating}
        onBack={() => {
          setState({ currentStep: 1 });
          history.push(ROUTE.BOOTH_WORK);
          track('contact_back_click');
        }}
      />

      <EventOfflineDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Content>
  );
};

Contact.propTypes = {
  setStep: func,
  setExhAddId: func,
};

export default Contact;
