import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { merge } from 'lodash';

import { BORDER, ROUTE, TEXT } from 'common/const';
import { currency, findItem, track } from 'common/utils';
import {
  Content,
  Dialog,
  EventOfflineDialog,
  Loading,
  ProductList,
  Scrollbar,
  SubmitButton,
} from 'common/components';
import { useCart, useOrders, useShop } from 'app/context';
import { useEvent, useEventStatus } from 'common/hooks';
import { useHistory } from 'react-router';

import EventInfo from '../EventInfo';
import Item from './Item';

import styles from './Cart.module.css';

const Cart = () => {
  const { isFetching: isFetchingOrders } = useOrders();
  const {
    eventId,
    categories,
    isLoading,
    update: updateShop,
    inventory,
    reviseOoId,
  } = useShop();
  const { data: event = {} } = useEvent(eventId);
  const { refetch, isLoading: isOfflineLoading } = useEventStatus(eventId, {
    onSuccess: (data) => {
      if (data.isOffline) {
        setIsOpen(true);
      }
    },
  });
  const { isEarlyPricing } = useMemo(() => event, [event]);
  const history = useHistory();

  const {
    error,
    items = [],
    itemDifferences = [],
    totals: { subtotal },
    count,
    updateCart,
    isUpdating,
  } = useCart();

  // console.log('<Cart>', { items, subtotal });

  const [removingItem, setRemovingItem] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    track('view_cart', {
      currency: 'USD',
      value: subtotal,
      items: items.map(({ id, name }) => ({ item_id: id, item_name: name })),
    });
  }, [subtotal, items]);

  return (
    <div className={styles.wrap}>
      <Scrollbar>
        <div className={styles.content}>
          <div className={cx(BORDER.GREY_100, styles.event)}>
            <EventInfo className={TEXT.BODY_2} />
          </div>

          <div className={cx({ [styles.loading]: isLoading }, styles.list)}>
            <Content isLoading={isLoading}>
              <ProductList
                groupClassName={styles.group}
                categories={categories}
                // update cart's "item" with "item" object from inventory to provide min, max, etc
                items={items
                  .filter(({ quantity }) => quantity > 0)
                  .map((item) => {
                    const { requiredQuantity = 0 } =
                      findItem(items, {
                        ...item,
                        isRequired: true,
                      }) ?? {};

                    const totalQuantity = items.reduce(
                      (total, { id, quantity }) =>
                        item.id === id ? total + quantity : total,
                      0,
                    );
                    return merge({}, inventory?.[item?.id], {
                      ...item,
                      totalQuantity,
                      requiredQuantity,
                      // TODO! - isEarlyPricing max
                      // max:
                      //   item.isEarlyPricing &&
                      //   !isEarlyPricing &&
                      //   reviseOoId &&
                      //   item.advancedPrice !== item.floorPrice
                      //     ? originalQuantity
                      //     : item.max,
                    });
                  })
                  .filter(({ totalQuantity }) => totalQuantity > 0)}
                renderItem={(item) => (
                  <Item
                    item={item}
                    isEarlyPricing={isEarlyPricing}
                    updateCart={updateCart}
                    setRemovingItem={setRemovingItem}
                    hasError={Boolean(error)}
                    reviseOoId={reviseOoId}
                  />
                )}
              />
            </Content>
          </div>
        </div>
      </Scrollbar>

      <div className={cx(BORDER.GREY_100, styles.proceed)}>
        <dl className={cx(TEXT.SUBTITLE, styles.totals)}>
          <dt>Subtotal (USD)</dt>
          <dd>
            {isFetchingOrders || isUpdating ? (
              <Loading size="button" />
            ) : (
              currency(subtotal)
            )}
          </dd>
        </dl>

        <p className={cx(TEXT.CAPTION, styles.terms)}>
          Taxes, discounts and fees will be calculated at Checkout
        </p>

        <SubmitButton
          fullWidth
          disabled={
            !items.length ||
            (reviseOoId && itemDifferences.length === 0) ||
            (reviseOoId && !count)
          }
          isLoading={isOfflineLoading}
          onClick={async () => {
            const { data } = await refetch();

            if (data.isOnline) {
              updateShop({ scrollTo: 0 });
              history.push(ROUTE.BOOTH_WORK);
            }
          }}
        >
          {reviseOoId ? 'Continue to Revise' : 'Checkout'}
        </SubmitButton>
      </div>

      <Dialog
        id="remove-item"
        title="Remove Item"
        close={() => setRemovingItem(undefined)}
        onCancel={() => setRemovingItem(undefined)}
        isOpen={Boolean(removingItem)}
        onSubmit={() => {
          updateCart(
            { ...removingItem, quantity: removingItem.totalQuantity },
            removingItem.totalQuantity - removingItem.quantity,
          );
          track('remove_from_cart', {
            items: [{ item_id: removingItem.id, item_name: removingItem.name }],
          });
          setRemovingItem(undefined);
        }}
        size="xs"
        submitColor="error"
        submitText="Yes, Remove"
      >
        <p>
          Are you sure you want to remove "{removingItem?.name}" from your cart?
        </p>
      </Dialog>

      <EventOfflineDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

Cart.propTypes = {};

export default Cart;
