// enum to apply border-color classNames
const BORDER = {
  BLACK: 'mui-border-black',
  WHITE: 'mui-border-white',
  PRIMARY: 'mui-border-primary',
  PRIMARY_LIGHT: 'mui-border-primary-light',
  PRIMARY_DARK: 'mui-border-primary-dark',
  PRIMARY_TEXT: 'mui-border-primary-text',
  SECONDARY: 'mui-border-secondary',
  SECONDARY_LIGHT: 'mui-border-secondary-light',
  SECONDARY_DARK: 'mui-border-secondary-dark',
  SECONDARY_TEXT: 'mui-border-secondary-text',
  ERROR: 'mui-border-error',
  ERROR_LIGHT: 'mui-border-error-light',
  ERROR_DARK: 'mui-border-error-dark',
  ERROR_TEXT: 'mui-border-error-text',
  WARNING: 'mui-border-warning',
  WARNING_LIGHT: 'mui-border-warning-light',
  WARNING_DARK: 'mui-border-warning-dark',
  WARNING_TEXT: 'mui-border-warning-text',
  INFO: 'mui-border-info',
  INFO_LIGHT: 'mui-border-info-light',
  INFO_DARK: 'mui-border-info-dark',
  INFO_TEXT: 'mui-border-info-text',
  SUCCESS: 'mui-border-success',
  SUCCESS_LIGHT: 'mui-border-success-light',
  SUCCESS_DARK: 'mui-border-success-dark',
  SUCCESS_TEXT: 'mui-border-success-text',
  GREY_50: 'mui-border-grey-50',
  GREY_100: 'mui-border-grey-100',
  GREY_200: 'mui-border-grey-200',
  GREY_300: 'mui-border-grey-300',
  GREY_400: 'mui-border-grey-400',
  GREY_500: 'mui-border-grey-500',
  GREY_600: 'mui-border-grey-600',
  GREY_700: 'mui-border-grey-700',
  GREY_800: 'mui-border-grey-800',
  GREY_900: 'mui-border-grey-900',
  GREY_A100: 'mui-border-a-100',
  GREY_A200: 'mui-border-a-200',
  GREY_A400: 'mui-border-a-400',
  GREY_A700: 'mui-border-a-700',
  TEXT: 'mui-border-text',
  TEXT_SECONDARY: 'mui-border-text-secondary',
  TEXT_DISABLED: 'mui-border-disabled',
  DIVIDER: 'mui-border-divider',
  PAPER: 'mui-border-paper',
  BACKGROUND: 'mui-border-background',
  ACTION_ACTIVE: 'mui-border-action-active',
  ACTION_HOVER: 'mui-border-action-hover',
  ACTION_SELECTED: 'mui-border-action-selected',
  ACTION_DISABLED: 'mui-border-action-disabled',
  ACTION_DISABLED_BACKGROUND: 'mui-border-action-disabled-background',
  ACTION_FOCUS: 'mui-border-action-focus',
};

export default BORDER;
