import { useCallback, useState } from 'react';
import {
  SectionHeader,
  Content,
  Form,
  Button,
  SubmitButton,
  NewPassword,
  Password as PasswordCompnent,
} from 'common/components';
import { useCustomer } from 'app/context';
import { VALID, TEXT } from 'common/const';

import styles from './Password.module.css';

const Password = () => {
  const customer = useCustomer();
  const [active, setActive] = useState(false);

  const [{ password, isFailedOnce, isPasswordValid }, setState] = useState({
    password: undefined,
    isPasswordValid: false,
    isFailedOnce: false,
  });

  const setIsPasswordValid = useCallback(
    (isPasswordValid) =>
      setState((state) => ({
        ...state,
        isPasswordValid,
      })),
    [setState],
  );

  return active ? (
    <Content isLoading={customer.isUpdating}>
      <Form
        validation={{
          currentPassword: VALID.PASSWORD.required(
            'Please enter your current password.',
          ),
          newPassword: VALID.PASSWORD.required(
            'Please enter a valid new password.',
          ),
        }}
        onPass={({ currentPassword, newPassword }) => {
          if (isPasswordValid) {
            customer.changePassword({ newPassword, currentPassword });
          } else {
            setState((state) => ({
              ...state,
              error: false,
              isFailedOnce: true,
            }));
          }
        }}
        onFail={() => {
          setState((state) => ({ ...state, isFailedOnce: true }));
        }}
        isSubmitting={customer.isUpdating}
      >
        <SectionHeader el="legend">Change Password</SectionHeader>

        <fieldset className={styles.fieldset}>
          <PasswordCompnent name="currentPassword" label="Current Password" />

          <NewPassword
            isFailedOnce={isFailedOnce}
            password={password}
            setIsValid={setIsPasswordValid}
          >
            <PasswordCompnent
              name="newPassword"
              label="New Password"
              onChange={({ target }) =>
                setState((state) => ({ ...state, password: target.value }))
              }
              error={isFailedOnce && !isPasswordValid ? ' ' : undefined}
            />
          </NewPassword>
        </fieldset>

        <div className={styles.actions}>
          <SubmitButton name="submit" isSubmitting={customer.isUpdating}>
            Update
          </SubmitButton>
          <Button text name="cancel" onClick={() => setActive(false)}>
            Cancel
          </Button>
        </div>
      </Form>
    </Content>
  ) : (
    <Content isLoading={customer.isUpdating}>
      <SectionHeader
        action={{ onClick: () => setActive(true), children: 'Change' }}
      >
        <p className={TEXT.SUBTITLE}>Password</p>
      </SectionHeader>
      <p className={styles.password} aria-hidden="true" />
    </Content>
  );
};

export default Password;
