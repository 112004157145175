import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';
import { getIsEarlyPricing, getOrder } from 'data/utils';

import mock from './getCompletedOrder.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get a specific completed order
 * @param {string} customerId
 * @param {string} empOrderId
 * @returns Order object
 */
const getCompletedOrder = async ({ customerId, empOrderId }) => {
  await VALID.checkAll(
    ['customerId', customerId, VALID.CUSTOMER_ID],
    ['empOrderId', empOrderId, VALID.UUID],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(
          `${baseUrl}orders/emp_completed_order/${customerId}/${empOrderId}`,
        );

    const order = data.empOnlineOrderData;

    return getOrder({
      ...order,
      id: order.ooId,
      isCompleted: true,
      currentDate: data.webServerDateTime,
      isEarlyPricing: getIsEarlyPricing({
        deadlineDate: order.eventDeadlineDate,
        serverDate: data.webServerDateTime,
      }),
    });
  } catch (error) {
    log('getCompletedOrder', {
      data: { customerId, empOrderId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getCompletedOrder;
