import { useMutation, useQuery } from '@tanstack/react-query';

import { useCustomer, useOrders } from 'app/context';
import { getReusableOrders, reuseOrder } from 'data/orders';

const useReuseOrder = ({ ooId, list }) => {
  const { customerId } = useCustomer();
  const { refetchIncomplete, updateToast } = useOrders();

  // TODO! - Temp hardcoded values
  const typeAndLocationResponse = list?.filter(
    ({ assessmentId }) => assessmentId === 2 || assessmentId === 3,
  );

  const { data: reusableOrders = [], isLoading } = useQuery(
    [
      'getReusableOrders',
      [ooId, typeAndLocationResponse?.map(({ response }) => response)],
    ],
    () => getReusableOrders({ ooId }),
    {
      enabled: Boolean(
        typeAndLocationResponse?.every(({ response }) => response),
      ),
    },
  );

  const { mutate: reuse, isLoading: isReusingOrder } = useMutation(
    ({ originalEmpOrderId, eventId, newOoId }) =>
      reuseOrder({
        originalEmpOrderId,
        eventId,
        customerId,
        newOoId,
      }),
    {
      onSuccess: () => {
        refetchIncomplete();
        updateToast({
          alert: 'success',
          message: 'Items were added to your cart',
        });
      },
    },
  );

  return {
    reusableOrders,
    reuse,
    isLoadingReusableOrders: isLoading,
    isReusingOrder,
  };
};

export default useReuseOrder;
