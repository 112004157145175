import { useCallback, useEffect, useState } from 'react';
import { array, arrayOf, func, shape, string } from 'prop-types';

import { AutoComplete, Input } from 'common/components';
import { getCountries } from 'data/lookup';

const CountryInput = ({
  defaultValue,
  error,
  helperText,
  id,
  label,
  name,
  onChange,
  options,
  ...rest
}) => {
  // console.log('<CountryInput>', { defaultValue, error, helperText, id, label, name, options });

  const [countries, setCountries] = useState([]);

  const getOptions = useCallback(async () => {
    const data = await getCountries();
    setCountries(data);
  }, []);

  useEffect(() => {
    if (options) {
      setCountries(options);
    } else {
      getOptions();
    }
  }, [getOptions, options]);

  return countries.length ? (
    <AutoComplete
      {...rest}
      label={label}
      name={name}
      options={countries}
      error={Boolean(error)}
      helperText={error ?? helperText}
      defaultValue={
        defaultValue
          ? countries.find(({ value }) => value === defaultValue)
          : countries[0]
      }
      onChange={({ value }) => {
        onChange(value);
      }}
      disableClearable
    />
  ) : (
    <Input label={label} name={name} maxlength={2} />
  );
};

CountryInput.propTypes = {
  label: string,
  name: string.isRequired,
  options: arrayOf(
    shape({
      label: string,
      value: string,
      code: string,
      states: array,
    }),
  ),
  onChange: func,
  defaultValue: string,
};

CountryInput.defaultProps = {};

export default CountryInput;
