const ADD_INCREMENT = {
  4001: 1,
  4002: 1,
  4003: 1,
  4004: 1,
  4005: 1,
  4006: 1,
  4007: 1,
  4008: 1,
  4010: 1,
  4011: 1,
  4012: 0.5,
  4013: 0.5,
  4101: 0.5,
  4102: 0.5,
  4103: 0.5,
  4104: 0.5,
  4105: 0.5,
  4106: 0.5,
  4107: 0.5,
  4108: 0.5,
  4109: 0.5,
  4110: 1,
  4111: 1,
  4112: 1,
  4113: 1,
  4120: 1,
  4125: 1,
  4126: 1,
  4127: 1,
  4129: 1,
  4130: 1,
  4131: 0.5,
  4132: 1,
  4133: 0.5,
  4134: 1,
  4135: 0.5,
  4136: 1,
  4137: 0.5,
  4138: 1,
  4139: 0.5,
  4140: 1,
  4141: 0.5,
  4201: 1,
  4401: 4,
  4402: 4,
  4403: 4,
  4404: 4,
  4405: 4,
  4406: 4,
  4407: 4,
  4408: 4,
  4409: 4,
  4450: 1,
  4451: 0.5,
  4452: 0.5,
  4453: 0.5,
  4454: 1,
  4455: 0.5,
  4456: 1,
  4457: 0.5,
  4460: 1,
  4461: 1,
  4462: 0.5,
  4463: 0.5,
  4501: 1,
  4502: 1,
  4503: 1,
  4504: 1,
  4505: 0.5,
  4506: 0.5,
  4507: 0.5,
  4508: 0.5,
  4511: 4,
  4512: 4,
  4513: 4,
  4514: 4,
  4515: 4,
  4516: 4,
  4517: 1,
  4518: 0.5,
  4601: 1,
  4602: 1,
  4603: 1,
  4604: 1,
  4605: 0.5,
  4606: 0.5,
  4607: 0.5,
  4608: 0.5,
  4920: 1,
  4921: 1,
  4922: 1,
  4923: 1,
  4924: 1,
  4930: 1,
  4931: 1,
  4932: 1,
  4933: 1,
  4934: 1,
  8101: 1,
  8102: 1,
  8103: 1,
  8105: 1,
  8106: 1,
  8107: 0.5,
  8108: 0.5,
  8201: 0.5,
  8202: 0.5,
  8203: 0.5,
  8301: 1,
  8302: 1,
  8303: 1,
  8304: 1,
  8305: 1,
  8306: 0.5,
  8307: 1,
  8308: 0.5,
  8401: 1,
  8402: 1,
  8403: 1,
  8501: 1,
  8502: 1,
  8503: 1,
  11401: 1,
  11402: 1,
  11441: 0.5,
  11442: 0.5,
  11443: 1,
  11444: 1,
  11445: 0.5,
  11446: 0.5,
};

export default ADD_INCREMENT;
