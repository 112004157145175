import { VALID } from 'common/const';

const FIELD = {
  address1: {
    label: 'Address 1',
    placeholder: 'Number, Alley, Lane, Road/Street Name, etc',
    validation: VALID.ADDRESS.required('Address is required'),
  },
  address2: {
    label: 'Address 2',
    placeholder: 'Township, District',
    validation: VALID.ADDRESS_2.required('Address 2 is required'),
  },
  city: {
    label: 'City',
    validation: VALID.CITY.required('City is required'),
  },
  zip: {
    label: 'Postal Code',
    validation: VALID.POSTAL_CODE.required('Postal code is required'),
  },
};

export default FIELD;
