import { startCase } from 'lodash';

/**
 * Chrome offers auto-suggestions for commonly-named inputs,
 * even when autocomplete="off". To counter this, we can set
 * autocomplete="new-password" for those inputs. However it
 * seems neither of these solutions works across all names,
 * so here we've set up this list of "common input names".
 */
export const COMMON_NAMES = ['countryCode'];

const defaultInputProps = ({
  inputProps,
  label,
  name,
  placeholder,
  required,
  ...rest
}) => ({
  ...rest,
  inputProps: {
    ...inputProps,
    autoComplete: COMMON_NAMES.includes(name)
      ? 'new-password' // terrible hack for chrome
      : 'off',
    placeholder,
    required: false, // remove browser validation
    'aria-required': required, // add for a11y
  },
  label: label ?? startCase(name),
});

export default defaultInputProps;
