import { bool, shape, string } from 'prop-types';
import { useState } from 'react';

import { Input } from 'common/components';

const AddressInput = ({ field, name, ...props }) => {
  // console.log('<AddressInput>', { field, name, props });
  const [value, setValue] = useState(props.control._defaultValues[name] ?? '');

  const { label, optional, placeholder, format = (value) => value } = field;
  const required = props.required ?? !optional; // use override when it's there

  return (
    <Input
      {...props}
      name={name}
      label={required ? `${label} *` : `${label} (optional)`}
      required={required}
      placeholder={placeholder}
      onChange={(event) => setValue(event.target.value)}
      // Add any custom value formatting to the countries field file
      value={format(value)}
    />
  );
};

AddressInput.propTypes = {
  field: shape({
    label: string,
    placeholder: string,
    optional: bool,
  }),
  name: string.isRequired,
};

export default AddressInput;
