import { merge } from 'lodash';

import getProps from './getProps';

const PROPS = {
  BJCC: 'BJCC',
  CAJUN: 'CAJUN',
  EDLEN: 'EDLEN',
  TEST: 'TEST',
};

// Default site objects, to use for each host
const BJCC = {
  owner: 'BJCC',
  theme: 'BJCC',
  props: getProps(PROPS.BJCC),
};

const CAJUN = {
  owner: 'Cajun',
  theme: 'Cajun',
  props: getProps(PROPS.CAJUN),
};

const EDLEN = {
  owner: 'Edlen',
  theme: 'Edlen',
  props: getProps(PROPS.EDLEN),
};

const TEST = {
  owner: 'Edlen',
  theme: 'Edlen',
  props: getProps(PROPS.TEST),
};

const ENV = {
  PROD: 'production',
  TEST: 'test',
  DEV: 'development',
  //PROD2022: 'production'
};

const SITE = {
  // Cajundome
  'cajundomeordering.com': merge({}, CAJUN, { env: ENV.PROD }),
  'cajun2022.edlen.com': merge({}, CAJUN, { env: ENV.PROD }),
  'cdootest.edlen.com:8020': merge({}, CAJUN, {
    theme: 'cajun-test',
    props: getProps(PROPS.CAJUN, true),
    env: ENV.TEST,
  }),
  'cdootrain.edlen.com': merge({}, CAJUN, {
    props: getProps(PROPS.CAJUN, true),
    env: ENV.TEST,
  }),

  // BJCC
  'bjccordering.edlen.com': merge({}, BJCC, { env: ENV.PROD }),
  'bjcc2022.edlen.com': merge({}, BJCC, { env: ENV.PROD }),
  'bjccootest.edlen.com:8010': merge({}, BJCC, {
    props: getProps(PROPS.BJCC, true),
    env: ENV.TEST,
  }),
  'trainbjcc.edlen.com': merge({}, BJCC, {
    props: getProps(PROPS.BJCC, true),
    env: ENV.TEST,
  }),

  // Edlen
  'ordering.edlen.com': merge({}, EDLEN, { env: ENV.PROD }),
  'testoo.edlen.com': merge({}, TEST, {
    theme: 'edlen-test',
    props: getProps(PROPS.TEST, true),
    env: ENV.TEST,
  }),
  'trainoo.edlen.com': merge({}, EDLEN, {
    theme: 'edlen-training',
    props: getProps(PROPS.EDLEN, true),
    env: ENV.TEST,
  }),

  // Alternate/Legacy IP's
  '72.196.63.173:8010': EDLEN,
  '72.196.63.173:8020': CAJUN,
  '72.196.63.173:8030': BJCC,

  // Default to Edlen, for local development, QA, etc
  default: merge({}, TEST, {
    props: getProps(PROPS.TEST, true),
    env: ENV.DEV,
  }),
};

export default SITE;
