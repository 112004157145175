import { any, func, string } from 'prop-types';

const FileUpload = ({ children, onSuccess, fileType }) => {
  return (
    <label htmlFor="fileInput">
      <input
        name="fileInput"
        id="fileInput"
        accept={fileType}
        type="file"
        hidden
        onChange={onSuccess}
      />
      {children}
    </label>
  );
};

FileUpload.propTypes = {
  children: any,
  onSuccess: func,
  fileType: string,
};

export default FileUpload;
