import { useOrders, useShop } from 'app/context';
import { ROUTE } from 'common/const';
import { authCookie } from 'common/utils';
import { getReviseLock, releaseReviseLock } from 'data/orders';
import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

const useLocking = () => {
  const { ooId, reviseOoId } = useShop();
  const { findOrder = () => {}, refetchCompleted } = useOrders() ?? {};

  const { empOrderId = null } = useMemo(
    () => findOrder(ooId) ?? {},
    [findOrder, ooId],
  );

  const { refetch: getLock } = useQuery(
    ['getLock', empOrderId],
    () => getReviseLock({ empOrderId }),
    { enabled: false },
  );

  const { refetch: release } = useQuery(
    ['releaseLock', empOrderId],
    () => releaseReviseLock({ empOrderId }),
    { enabled: false },
  );

  const updateLock = useCallback(
    (to) => {
      const releaseLock = () => {
        refetchCompleted();
        release();
      };

      if (authCookie.exists() && reviseOoId) {
        to === ROUTE.DASHBOARD || to === '/' ? releaseLock() : getLock();
      }
    },
    [reviseOoId, getLock, release, refetchCompleted],
  );

  return {
    updateLock,
  };
};

export default useLocking;
