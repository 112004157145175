import { node, oneOf, string } from 'prop-types';
import cx from 'classnames';

import { ROUTE } from 'common/const';

import { Header, Footer } from '../components';
import SummaryDisplay from './SummaryDisplay';

import styles from './CheckoutPage.module.css';

const CheckoutPage = ({ backTo, className, children, summary, total }) => {
  return (
    <>
      <Header backTo={backTo} />

      <div className={styles.page}>
        <div className={cx(styles.main, className)}>
          {children}
          <Footer className={styles.footer} />
        </div>
        <div className={styles.summary}>
          <SummaryDisplay total={total}>{summary}</SummaryDisplay>
        </div>
      </div>
    </>
  );
};

CheckoutPage.propTypes = {
  backTo: oneOf(Object.values(ROUTE)),
  className: string,
  children: node,
};

export default CheckoutPage;
