import { arrayOf, elementType, shape, string } from 'prop-types';

import { OrderCard } from 'common/components';

import styles from './OrdersList.module.css';

const OrdersList = ({ to = 'ViewDetails', orders = [] }) => {
  // console.log('<OrdersList>', { onClick, orders });

  return (
    <ul>
      {orders.map(({ totals, ...order }) => (
        <li key={order.id} className={styles.order}>
          <OrderCard {...order} total={totals?.total} to={to} />
        </li>
      ))}
    </ul>
  );
};

OrdersList.propTypes = {
  action: elementType,
  orders: arrayOf(
    shape({
      id: string.isRequired,
    }),
  ),
};

export default OrdersList;
