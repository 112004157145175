import { node } from 'prop-types';
import cx from 'classnames';

import { Footer, Header, Sidebar } from '../components';

import styles from './InfoPage.module.css';
import { TEXT } from 'common/const';
import { Content } from 'common/components';

const InfoPage = ({
  className,
  error,
  isLoading,
  sidebar,
  title,
  children,
}) => {
  return (
    <>
      <Header />
      <Content isLoading={isLoading}>
        <main className={cx(styles.main)}>
          {!error && (
            <Sidebar label="Sections" className={styles.sidebar}>
              {sidebar}
            </Sidebar>
          )}
          <div className={cx(styles.content, className)}>
            <h1 className={cx(styles.title, TEXT.H4)}>{title}</h1>
            {children}
          </div>
        </main>
      </Content>
      <Footer className={styles.footer} />
    </>
  );
};

InfoPage.propTypes = {
  children: node,
};

export default InfoPage;
