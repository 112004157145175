import { createTheme } from '@mui/material/styles';

import THEME from '../config';
import getThemeName from './getThemeName';

const themeName = getThemeName();

/**
 * Get theme config object, by url
 * @param {string} name - theme name
 * @returns {object} MUI theme
 */
const getTheme = (name = themeName) => {
  const theme = THEME[name] ?? THEME.edlen;

  return createTheme(theme);
};

export default getTheme;
