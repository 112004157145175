// enum to apply text classNames
const TEXT = {
  H1: 'mui-text-h1',
  H2: 'mui-text-h2',
  H3: 'mui-text-h3',
  H4: 'mui-text-h4',
  H5: 'mui-text-h5',
  H6: 'mui-text-h6',
  SUBTITLE: 'mui-text-subtitle-1',
  SUBTITLE_1: 'mui-text-subtitle-1',
  SUBTITLE_2: 'mui-text-subtitle-2',
  BODY: 'mui-text-body-1',
  BODY_1: 'mui-text-body-1',
  BODY_2: 'mui-text-body-2',
  BUTTON: 'mui-text-button',
  CAPTION: 'mui-text-caption',
  OVERLINE: 'mui-text-overline',
  BOLD: 'mui-text-bold',
  LIGHT: 'mui-text-light',
  REGULAR: 'mui-text-regular',
};

export default TEXT;
