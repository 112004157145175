import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { getCustomer } from 'data/utils';
import { ENV, VALID } from 'common/const';
import mock from './getCustomerData.mock';

const baseUrl = getBaseUrl(2);

/**
 * Gets customer data
 * @param {string} customerId
 * @returns Object with requested customer data
 */
const getCustomerData = async (customerId) => {
  try {
    await VALID.check(
      customerId,
      VALID.CUSTOMER_ID.required('customerId is required'),
    );

    const { data } = ENV.IS_MOCK
      ? mock({ customerId })
      : await axios.get(`${baseUrl}customer/customer_by_id/${customerId}`);

    const { customerData } = data;

    return getCustomer(customerData);
  } catch (error) {
    log('getCustomerData', {
      data: { customerId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getCustomerData;
