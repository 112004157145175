import { debug } from '.';

/**
 * Helper to determine if an item is already in the cart
 * @param {array} itemsList - list of items from cart
 * @param {object} item - complete item object
 * @param {boolean} [log] - option to log in console (so we don't log for all items)
 * @returns {object|undefined} matching item from list, or undefined
 */
const findItem = (itemsList, item, log) => {
  if (log) debug({ itemsList, item });

  // TODO! - Add isEarlyPricing in search when feature is added
  return itemsList.find(
    ({ id, isRequired }) => id === item.id && isRequired === item.isRequired,
  );
};

export default findItem;
