const getSearchParams = (search = '') => {
  const string = search.replace(/^\?/, '');
  const pairs = string.split('&');
  return pairs.reduce((obj, cur) => {
    const [key, ...values] = cur.split('=');
    const value = values.length
      ? values.join('=') // rebuild values that included equals
      : true;

    return {
      ...obj,
      [key]: value,
    };
  }, {});
};

export default getSearchParams;
