import { customerData, companyData, resultData } from 'data/mock-data';

const mock = () => {
  return {
    data: {
      customerData: {
        ...customerData,
        companyInformation: companyData,
        resultInformation: resultData,
      },
    },
  };
};

export default mock;
