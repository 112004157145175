import { PAYMENT_METHOD_TX, CREDIT_CARD } from 'common/const';

/**
 * Build the payment object
 * @returns {object}
 */
const getPayment = (payment) => ({
  method: Object.keys(PAYMENT_METHOD_TX).includes(payment.type)
    ? PAYMENT_METHOD_TX[payment.type]
    : PAYMENT_METHOD_TX.CC,
  card: Object.values(CREDIT_CARD).find(({ db }) => db === payment?.card),
  last4: payment.referenceOrCcNum,
  amount: payment.amountPaid,
  date: payment.paymentDate,
});

export default getPayment;
