import { FILE } from 'common/const';

/**
 * Prepends "base pdf url" onto pdf path when pdf path exists,
 * or returns undefined.
 * @param {string} pdfName - pdf name
 * @returns
 */
const getEventFaq = (pdfName) =>
  pdfName ? [FILE.FAQ, pdfName].join('/') : undefined;

export default getEventFaq;
