import { IMAGE } from 'common/const';

/**
 * Prepends "base image url" onto image path when image path exists,
 * or returns undefined.
 * @param {string} imageName - image name
 * @returns
 */
const getEventImage = (imageName) =>
  imageName ? [IMAGE.EVENT, imageName].join('/') : undefined;

export default getEventImage;
