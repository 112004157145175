import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useCustomer, useOrders, useShop } from 'app/context';
import { EmptyCart } from 'assets/images';
import { COLOR, ROUTE, TEXT } from 'common/const';
import {
  Button,
  Card,
  Content,
  NewFeatureDialog,
  NewOrderDialog,
  Toast,
} from 'common/components';
import { Page } from 'common/site';
import { useDeleteOrder, useSetInterval } from 'common/hooks';

import { NewOrderButton, OrdersList } from '../components';
import AddressDialog from './AddressDialog';

import styles from './Dashboard.module.css';

const Dashboard = ({ location }) => {
  const customer = useCustomer();
  const history = useHistory();
  const { deleteOrder } = useDeleteOrder({
    onSuccess: () => history.push(ROUTE.NEW_ORDER),
  });
  const { firstName: customerName, closeToast, toast } = customer;
  const [isNewOrderDialogOpen, setIsNewOrderDialogOpen] = useState(false);

  const {
    incomplete: { isLoading, error, data: orders },
    upcoming: upcomingOrders,
    refetchIncomplete: refetchIncompleteOrders,
    refetchCompleted: refetchCompletedOrders,
  } = useOrders();

  const { reset: resetShop } = useShop();

  // refresh our context, once
  const [isFresh, setIsFresh] = useState();

  // scroll to previous position, on "back" button
  useEffect(() => {
    const scrollTo = location?.state?.scrollTo;

    if (scrollTo && upcomingOrders.length) {
      window.scrollTo(0, scrollTo);
    }
  }, [location.state, upcomingOrders]);

  // refetch for pending orers
  const fetchIfPending = useCallback(() => {
    if (upcomingOrders.some((order) => order.isPending === true)) {
      refetchCompletedOrders();
    }
  }, [refetchCompletedOrders, upcomingOrders]);

  useSetInterval(fetchIfPending, 10);

  useEffect(() => {
    if (!isFresh) {
      setIsFresh(true);

      refetchIncompleteOrders();
      resetShop();
    }
  }, [resetShop, isFresh, setIsFresh, refetchIncompleteOrders]);

  return (
    <Page className={styles.page} title="Dashboard">
      <aside className={cx(TEXT.H4, styles.hello)}>
        Hello{customerName && `, ${customerName}`}
      </aside>

      <nav className={styles.nav}>
        <Button outlined to={ROUTE.ORDERS}>
          My Orders
        </Button>

        <Button outlined to={ROUTE.ACCOUNT}>
          My Account
        </Button>
      </nav>

      <Content
        error={error?.message}
        isLoading={isLoading || customer.isUpdating}
      >
        <div className={styles.new}>
          <div className={styles.listHeading}>
            <p className={TEXT.H6}>Order in Progress</p>
            <p className={COLOR.GREY_600}>Pick up where you left off</p>
          </div>
          <div>
            <NewOrderButton
              onClick={() => {
                if (orders.length > 0) {
                  setIsNewOrderDialogOpen(true);
                } else {
                  history.push(ROUTE.NEW_ORDER);
                }
              }}
            />
          </div>
        </div>
        {orders.length ? (
          <section>
            <OrdersList orders={orders} to="ResumeOrder" />
          </section>
        ) : (
          <Card className={styles.empty} variant="outlined">
            <img src={EmptyCart} alt="EmptyCart" />
            <p className={styles.message}>No orders created at this time.</p>
          </Card>
        )}

        {upcomingOrders.length ? (
          <>
            <div className={styles.upcoming}>
              <div className={styles.listHeading}>
                <p className={TEXT.H6}>Orders for Upcoming Events</p>
                {/* <p className={COLOR.GREY_600}>
              Modify or cancel completed orders before end of show
            </p> */}
              </div>
              <Button size="medium" text to={ROUTE.ORDERS}>
                See all orders
              </Button>
            </div>

            <OrdersList orders={upcomingOrders} />
          </>
        ) : null}

        {!customer.isUpdating && customer.customerId.length && (
          <AddressDialog />
        )}

        <NewOrderDialog
          isOpen={isNewOrderDialogOpen}
          setIsOpen={setIsNewOrderDialogOpen}
          onSubmit={() => deleteOrder(orders[0].id)}
          orders={orders}
        />

        <NewFeatureDialog />

        <Toast
          alert={toast.alert}
          anchor={{ vertical: 'top', horizontal: 'right' }}
          close={closeToast}
          isOpen={Boolean(toast.alert)}
        >
          {toast.message}
        </Toast>
      </Content>
    </Page>
  );
};

export default Dashboard;
