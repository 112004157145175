import {
  arrayOf,
  bool,
  elementType,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { makeOption } from 'common/utils';

import withController from './withController';
import defaultInputProps from './defaultInputProps';

const AutoComplete = ({
  // for AutoComplete
  chipProps,
  filterOptions,
  getInputProps = defaultInputProps,
  id,
  InputComponent,
  matchFromStart,
  multiple,
  onChange = () => null,
  options,

  // for Input
  error,
  helperText = ' ',
  inputProps,
  label,
  placeholder,
  required,

  // for both
  field = {}, // from Controller
  name,

  // rest is for AutoComplete
  ...rest
}) => {
  // console.log('<AutoComplete>', { name, options });
  const Input = InputComponent ?? TextField;

  return (
    <MuiAutocomplete
      filterSelectedOptions={true}
      multiple={multiple}
      id={id ?? name}
      options={options.map(makeOption)}
      getOptionLabel={(opt) => opt.label || opt}
      getOptionDisabled={(opt) => opt.disabled}
      filterOptions={
        matchFromStart
          ? createFilterOptions({ matchFrom: 'start' })
          : createFilterOptions(filterOptions)
      }
      renderInput={({ inputProps, ...params }) => {
        const props = getInputProps({
          ...params,
          label,
          name,
          helperText,
          error,
          inputProps,
          required,
          placeholder,
        });

        return <Input inputRef={field.ref} {...props} />;
      }}
      ChipProps={chipProps}
      onChange={(event, selected) => {
        const getValue = (option) => (option ? option.value ?? option : '');
        const value = Array.isArray(selected)
          ? selected.map(getValue)
          : getValue(selected);

        if (typeof field.onChange === 'function') {
          field.onChange(value);
        }
        onChange({ ...event, name, selected, value });
      }}
      isOptionEqualToValue={(option, value) =>
        option.label === value.label && option.id === value.id
      }
      {...rest}
    />
  );
};

AutoComplete.propTypes = {
  chipProps: object,
  field: shape({
    // from Controller
    ref: oneOfType([object, func]),
    onChange: func,
  }),
  helperText: string,
  getInputProps: func,
  id: string,
  InputComponent: elementType,
  label: string,
  multiple: bool, // allow selection of multiple options
  name: string.isRequired,
  onChange: func, // optional onChange event
  options: oneOfType([
    arrayOf(string),
    arrayOf(
      shape({
        disabled: bool,
        label: string,
        labelProps: object,
        value: string,
      }),
    ),
  ]).isRequired,
  required: bool,
};

export default withController(AutoComplete);
