import { VALID } from 'common/const';

const FIELD = {
  address1: {
    label: 'Address 1',
    validation: VALID.ADDRESS.required('Address is required'),
  },
  address2: {
    label: 'Address 2',
    optional: true,
    validation: VALID.ADDRESS_2,
  },
  city: {
    label: 'Municipality',
    validation: VALID.TOWN.required('Municipality is required'),
  },
  state: {
    label: 'Province or Territory',
    validation: VALID.CAN_PROVINCE.required('Province is required'),
  },
  zip: {
    label: 'Postal Code',
    validation: VALID.CANDIAN_POSTAL.required('Postal code is required'),
    placeholder: 'A1A 1A1',
    format: (value) => {
      const trimmedValue = value.trim().substring(0, 7).toUpperCase();
      if (trimmedValue.length === 4) {
        return trimmedValue.slice(0, 3) + ' ' + trimmedValue.slice(3);
      } else {
        return trimmedValue;
      }
    },
  },
};

export default FIELD;
