import Cookies from 'js-cookie';

import { ENV } from 'common/const';

// 1 day for dev, or 15 minutes for prod
const MINUTES = ENV.IS_DEV ? 24 * 60 : 15;
const EXPIRATION = (1 / 24 / 60) * MINUTES;

export const NAME = 'customerId';

const authCookie = {
  exists: () => Boolean(Cookies.get(NAME)),
  get: () => Cookies.get(NAME),
  remove: () => Cookies.remove(NAME),
  set: (value) => Cookies.set(NAME, value, { expires: EXPIRATION }),
};

export default authCookie;
