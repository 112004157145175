/**
 * Submit a form by triggering an event outside of that form
 * (typically used with a react "ref.current")
 * @param {HTMLFormElement} form
 */
const remoteSubmit = (form) =>
  form.dispatchEvent(
    new Event('submit', {
      bubbles: true,
      cancelable: true,
    }),
  );

export default remoteSubmit;
