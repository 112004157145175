const Z_INDEX = {
  MOBILE_STEPPER: 'mui-z-index-mobile-stepper', // 1000
  SPEED_DIAL: 'mui-z-index-speed-dial', // 1050
  APP_BAR: 'mui-z-index-app-bar', // 1100
  DRAWER: 'mui-z-index-drawer', // 1200
  MODAL: 'mui-z-index-modal', // 1300
  SNACKBAR: 'mui-z-index-snackbar', // 1400
  TOOLTIP: 'mui-z-index-tooltip', // 1500
};

export default Z_INDEX;
