import axios from 'axios';
import { merge } from 'lodash';

import { findItem, getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import { getCart } from '../utils';
import mock from './updateReviseQuantity.mock';

const baseUrl = getBaseUrl(2);

/**
 * Update item quantity for revise order (must already be in the cart)
 * @param {object} cart - complete cart object
 * @param {object} item - complete item object
 * @param {number} [quantity] - if undefined, the item will be removed
 * @returns {object} updated cart
 */
const updateReviseQuantity = async (cart, item, quantity) => {
  // console.log('$ updateReviseQuantity', { cart, item, quantity });

  await VALID.checkAll(
    ['customerId', cart.customerId, VALID.CUSTOMER_ID],
    ['empOrderId', cart.empOrderId, VALID.UUID],
    ['eventId', cart.eventId, VALID.EVENT_ID],
    ['priceListId', item.priceListId, VALID.PRICE_LIST_ID],
    ['id', item.id, VALID.NUM],
    ['quantity', quantity, VALID.NUM],
    ['isAdvance', item.isEarlyPricing, VALID.BOOL],
    ['reviseOoId', cart.reviseOoId, VALID.NUM],
  );

  try {
    if (!findItem(cart.revisedItems, item)) {
      log('updateReviseQuantity.findItem', {
        data: { cart, item, quantity },
        throw: true,
        type: 'validation',
        error: `item with id === "${item.itemID}" not in cart.`,
      });
    }

    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.post(`${baseUrl}orders/update_revised_to_cart`, {
          customerId: cart.customerId,
          empOrderId: cart.empOrderId,
          eventId: cart.eventId,
          isAdvance: item.isEarlyPricing,
          itemId: item.id,
          priceListId: item.priceListId,
          quantity: quantity,
          reviseOoId: cart.reviseOoId,
        });

    const cartData = getCart(data.cartData);
    const itemData = findItem(cartData.items, item) ?? { ...item, quantity };

    return {
      item: merge({}, item, itemData),
    };
  } catch (error) {
    log('updateReviseQuantity', {
      data: { cart, item, quantity },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default updateReviseQuantity;
