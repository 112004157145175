import { useState } from 'react';
import { useHistory } from 'react-router';
import { node, number, string } from 'prop-types';
import cx from 'classnames';

import { useOrders, useShop } from 'app/context';
import { BORDER, COLOR, FLAG, ROUTE, TEXT } from 'common/const';
import { currency, date, DATE_FORMAT, track } from 'common/utils';
import {
  Alert,
  BoothSetup,
  Button,
  Card,
  DeleteOrder,
  EventDates,
  EventLogo,
  Flag,
  ReviseButton,
  TextLink,
} from 'common/components';

import styles from './OrderCard.module.css';

const OrderCard = ({
  assessmentResponseData,
  date: orderDate,
  eventCloseDate,
  eventName,
  eventOpenDate,
  hideButtons = false,
  isCompleted,
  isOffline,
  isPast,
  isPending,
  itemCount,
  to,
  total,
  ...order
}) => {
  // console.log('<OrderCard>', { to, assessmentResponseData, itemCount, eventName, isCompleted, orderDate, total });

  const { refetchIncomplete } = useOrders() ?? {}; // Need the default for storybook
  const { reset: resetShop } = useShop() ?? {}; // idk why, but this fallback is necessary for HMR (or useShop() is undefined)
  const history = useHistory();

  const [hoverItem, setHoverItem] = useState();

  const resumeOrder = () => {
    resetShop({
      boothNumber: order.booth.number,
      eventId: order.eventId,
      ooId: order.id,
    });
    history.push(ROUTE.RESUME_ORDER);
  };

  const viewDetails = () => {
    history.push({
      pathname: `${ROUTE.ORDER_DETAILS}/${order.id}`,
      state: { scrollTo: window.scrollY, from: history.location.pathname },
    });
    window.scrollTo(0, 0);
  };

  // $TODO: Remove !isCompleted check when we allow users to edit completed orders
  return (
    <>
      <div
        onClick={() => {
          if (isPending || (!isCompleted && isOffline)) {
            return null;
          }
          switch (to) {
            case 'ViewDetails':
              return viewDetails();
            case 'ResumeOrder':
              return resumeOrder();
            default:
              return null;
          }
        }}
        className={cx(styles.link, {
          [BORDER.PRIMARY]: hoverItem === order.id,
          [styles.inactive]: hoverItem !== order.id,
        })}
        onMouseLeave={() => setHoverItem(null)}
        onMouseEnter={() => {
          if (!isPending && (!isOffline || isCompleted)) {
            setHoverItem(order.id);
          }
        }}
      >
        <Card className={styles.wrap} variant="outlined">
          {isOffline && !isCompleted && (
            <div className={styles.alert}>
              <Alert severity="warning">
                We're updating this event. Please check back in 30 minutes. For
                questions or assistance,{' '}
                <TextLink href="https://edlen.com/contact/">
                  contact us
                </TextLink>
                .
              </Alert>
            </div>
          )}

          {isPending && (
            <div className={styles.alert}>
              <Alert severity="success">
                Your order is processing. Check back in a few minutes to view
                this order.
              </Alert>
            </div>
          )}

          <div className={styles.columns}>
            <div className={styles.eventImage}>
              <EventLogo name={eventName} image={order?.eventImage} />
            </div>
            <div className={styles.info}>
              <div className={styles.row}>
                {isCompleted && (
                  <p className={cx(TEXT.BODY_2, styles.date)}>
                    Order Date:{' '}
                    {date(orderDate).format(DATE_FORMAT.MEDIUM_DATE)}
                  </p>
                )}
                {order?.cancellationStatus === 'A' && (
                  <Flag flag={FLAG.CANCELLED} />
                )}
                {order?.cancellationStatus === 'R' && (
                  <Flag flag={FLAG.CANCEL_REQUESTED} />
                )}
              </div>
              <div className={styles.eventDetails}>
                <h3 className={cx(TEXT.SUBTITLE_1, styles.event)}>
                  {eventName}
                </h3>
                {isCompleted && (
                  <div className={COLOR.GREY_800}>
                    <EventDates
                      start={eventOpenDate}
                      end={eventCloseDate}
                      format={DATE_FORMAT.FULL_DATE}
                    />
                  </div>
                )}
                <BoothSetup
                  booth={order.booth}
                  showNumber={order?.booth?.number !== '1'}
                  inline
                  isOrder
                />
              </div>
              <div>
                {isCompleted && !isPending && (
                  <>
                    <span className={cx(TEXT.SUBTITLE_1)}>
                      {currency(total)}
                    </span>
                    <span className={cx(styles.divider, COLOR.GREY_400)} />
                  </>
                )}
                {!isPending && (
                  <span className={cx(TEXT.BODY_1, COLOR.GREY_800)}>
                    {itemCount <= 99 ? itemCount : '99+'} items
                  </span>
                )}
              </div>
            </div>
            <div className={styles.buttons}>
              {isCompleted
                ? !isPending && (
                    <>
                      {order.isRevisionAvailable && (
                        <ReviseButton
                          isEarlyPricing={order.isEarlyPricing}
                          id={order.id}
                        />
                      )}
                      {(order.isCancellationAllowed ||
                        order.cancellationStatus !== 'None') && (
                        <Button
                          size="medium"
                          outlined
                          color="info"
                          disabled={order.cancellationStatus !== 'None'}
                          onClick={(e) => {
                            e.stopPropagation();
                            track('request_to_cancel', { ooId: order.id });
                            history.push(`${ROUTE.CANCEL}/${order.id}`);
                          }}
                        >
                          Cancel Order
                        </Button>
                      )}
                    </>
                  )
                : !isOffline &&
                  !hideButtons && (
                    <>
                      <Button
                        size="medium"
                        outlined
                        color="info"
                        onClick={(e) => {
                          e.stopPropagation();
                          resumeOrder();
                        }}
                      >
                        Resume
                      </Button>
                      <DeleteOrder
                        id={order.id}
                        onSuccess={() => refetchIncomplete()}
                      >
                        Delete
                      </DeleteOrder>
                    </>
                  )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

OrderCard.propTypes = {
  action: node,
  itemCount: number,
  eventName: string,
  orderDate: string,
  total: number,
};

OrderCard.defaultProps = {};

export default OrderCard;
