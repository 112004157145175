import axios from 'axios';

import { getBaseUrl, getSiteOwnership, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getIsEmailRegistered.mock';

const baseUrl = getBaseUrl(2);
const ownership = getSiteOwnership();

/**
 * Tells if an email is already registered
 * @param {string} email
 * @returns isRegistered
 */
const getIsEmailRegistered = async ({ email }) => {
  await VALID.check(email, VALID.EMAIL.required('email is required'));

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(
          `${baseUrl}customer/is_email_registered?email=${encodeURIComponent(
            email,
          )}&ownership=${ownership}`,
        );

    return data.isRegistered;
  } catch (error) {
    log('getIsEmailOwnership', {
      data: { email, ownership },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getIsEmailRegistered;
