import { any, string } from 'prop-types';
import cx from 'classnames';

import { Card } from 'common/components';
import { TEXT } from 'common/const';

import styles from './TitleCard.module.css';

const TitleCard = ({ className, title, children }) => {
  return (
    <Card className={className} variant="outlined">
      {title && <h2 className={cx(TEXT.H6, styles.title)}>{title}</h2>}
      {children}
    </Card>
  );
};

TitleCard.propTypes = {
  className: string,
  title: string,
  children: any,
};

export default TitleCard;
