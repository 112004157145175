import { useMemo } from 'react';
import cx from 'classnames';

import { useShop } from 'app/context';
import { Alert, Content, Icon, ProductList, Tooltip } from 'common/components';
import { useCartTotals, useEvent, useCatalogCategoryNames } from 'common/hooks';
import { currency, track } from 'common/utils';
import { BORDER, COLOR, EVENT, PAYMENT_METHOD, TEXT } from 'common/const';
import { OrderItem } from 'routes/orders/components';

import styles from './ReviseSummary.module.css';

const Line = ({ children, value, showZero }) =>
  typeof value === 'number' && (value !== 0 || showZero) ? (
    <div className={cx(styles.line, TEXT.BODY_2)}>
      <dt className={cx(COLOR.GREY_600, styles.label)}>{children}</dt>
      <dd>{currency(value)}</dd>
    </div>
  ) : null;

const ReviseSummary = ({ paymentMethod, order, itemDifferencesTotals }) => {
  // console.log('<ReviseSummary>', { paymentMethod, itemDifferencesTotals });

  const { eventId } = useShop();

  const { facilityId } = useEvent(eventId).data;

  const { isLoading, error } = useCartTotals();
  const { id: ooId, booth, items } = useMemo(() => order, [order]);

  const { categories, isCatalogNamesLoading, categoryNamesError } =
    useCatalogCategoryNames({
      ooId: ooId,
      eventId,
      boothLocation: booth?.location,
      boothType: booth?.type,
      items: items,
    });

  const { totals } = order;

  return (
    <Content isLoading={isLoading} error={error}>
      <h5 className={cx(TEXT.H6, styles.heading)}>Order Summary</h5>
      <div className={styles.section}>
        <dl>
          <Line showZero value={totals?.subtotal}>
            Order Subtotal
          </Line>

          <Line
            value={
              totals?.tax + totals?.wireFee + totals?.ccFee + totals?.serviceFee
            }
          >
            <Tooltip
              arrow
              className={styles.info}
              hover
              placement="right"
              title={
                <p className={TEXT.SUBTITLE_2}>
                  Includes sales tax and necessary fees
                </p>
              }
              tracking={() => track('revise_taxes_tooltip')}
              width="9rem"
            >
              <span>Taxes & Fees</span>
              <Icon name="info" color="info" />
            </Tooltip>
          </Line>

          <div className={cx(BORDER.GREY_200, styles.divider)} />

          <div className={styles.line}>
            <dt className={cx(TEXT.SUBTITLE_2, styles.label)}>
              Grand Total (USD)
            </dt>
            {/* add any additional taxes based on payment type fees */}

            <dd className={styles.align}>
              <p className={TEXT.SUBTITLE_2}>{currency(totals?.total)}</p>
            </dd>
          </div>
          <Line showZero value={itemDifferencesTotals.amountPaid * -1}>
            Amount Paid
          </Line>

          <Line value={itemDifferencesTotals.subtotal}>
            {itemDifferencesTotals.subtotal < 0
              ? 'Credit (Items)'
              : 'Additional Item Subtotal'}
          </Line>

          <Line value={itemDifferencesTotals.tax}>
            {itemDifferencesTotals.tax < 0 ? 'Credit (Sales Tax)' : 'Sales Tax'}
          </Line>

          {paymentMethod === PAYMENT_METHOD.CARD && (
            <Line value={itemDifferencesTotals.ccFee}>Credit Card Fee</Line>
          )}

          {paymentMethod === PAYMENT_METHOD.WIRE && (
            <Line value={itemDifferencesTotals?.wireFee}>
              Wire Transfer Fee
            </Line>
          )}

          <Line value={itemDifferencesTotals.serviceFee}>
            {EVENT.getServiceFeeCopy(facilityId)}
          </Line>

          <div className={cx(BORDER.GREY_200, styles.divider)} />

          <div className={styles.line}>
            <dt className={cx(TEXT.SUBTITLE_2, styles.label)}>Balance Due</dt>
            {/* add any additional taxes based on payment type fees */}

            <dd className={styles.align}>
              <p className={TEXT.SUBTITLE_2}>
                {currency(itemDifferencesTotals.total + totals.balanceDue)}
              </p>
            </dd>
          </div>
        </dl>
      </div>

      {itemDifferencesTotals.balanceDue < 0 ? (
        <div className={styles.alert}>
          <Alert>Refunds are processed up to 30 days post-event.</Alert>
        </div>
      ) : null}

      <section className={styles.section}>
        <p className={cx(TEXT.H6, styles.itemHeader)}>Changes to Your Order</p>
        <Content
          isLoading={isCatalogNamesLoading}
          error={categoryNamesError?.message}
        >
          <ProductList
            groupClassName={styles.group}
            categories={categories}
            items={order.itemDifferences}
            renderItem={(item) => <OrderItem item={item} variant="revise" />}
          />
        </Content>
      </section>

      <section className={styles.section}>
        <div className={styles.itemHeader}>
          <p className={TEXT.H6}>Your Order</p>
          <p className={cx(TEXT.CAPTION, COLOR.GREY_600, styles.itemSubHeader)}>
            Includes current changes made to your order
          </p>
        </div>
        <Content
          isLoading={isCatalogNamesLoading}
          error={categoryNamesError?.message}
        >
          <ProductList
            groupClassName={styles.group}
            categories={categories}
            items={order.items.filter(({ quantity }) => quantity > 0)}
            renderItem={(item) => <OrderItem item={item} variant="checkout" />}
          />
        </Content>
      </section>
    </Content>
  );
};

export default ReviseSummary;
