import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { VALID, PAYMENT_METHOD, ENV } from 'common/const';

import mock from './processCartPayment.mock';

const baseUrl = getBaseUrl(2);

/**
 * Process a card payment
 * @param {object} order
 * @returns "Successful"
 */
const processCartPayment = async (order) => {
  const {
    accountNumber,
    billingAddId,
    customerId,
    empOrderId,
    eventId,
    exhAddId,
    isThirdParty,
    last4,
    merchantId,
    ooId,
    paymentData,
    paymentMethod,
    reviseOoId,
    reviseTotal,
    thirdPartyCompanyName,
    total,
    type,
    totals,
  } = order;

  const address = order.billingAddress;

  await VALID.checkAll(
    ['customerId', customerId, VALID.CUSTOMER_ID],
    ['exhAddId', exhAddId, VALID.EXHIBITOR_ADDRESS_ID],
    ['total', total, VALID.NUMBER],
  );

  const [expirationMonth = null, expirationYear = null] =
    paymentData?.expiration?.split('/') ?? [null, null];

  if (!reviseOoId && (!accountNumber || accountNumber.length < 4)) {
    throw new Error('Card Number is required!');
  }

  const isGuaranteedCard = paymentMethod !== PAYMENT_METHOD.CARD || total === 0;

  try {
    const orderDetails = {
      billingAddId: billingAddId ?? exhAddId,
      customerId,
      empOrderId,
      eventId,
      exhAddId,
      isGuaranteedCard,
      isThirdParty,
      merchantId,
      ooId,
      paymentData: {
        cvv: paymentData?.cvv,
        firstName: paymentData?.firstName,
        lastName: paymentData?.lastName,
        nameOnCard: paymentData?.firstName + ' ' + paymentData?.lastName,
        address1: address?.address1,
        address2: address?.address2,
        city: address?.city,
        state: address?.state,
        zip: address?.zip,
        country: address?.countryCode,
        email: address?.email,
        accountNumber: accountNumber,
        companyName: address?.company,
        accountNumberExpMM: expirationMonth,
        accountNumberExpYY: expirationYear,
        type,
        last4Digit: last4,
        amount: total,
        status: 'active',
        isGuaranteedCard,
        paymentType:
          paymentMethod === PAYMENT_METHOD.CARD ? type : paymentMethod,
      },
      reviseOoId,
      reviseTotal: reviseTotal,
      thirdPartyName: thirdPartyCompanyName,
      totals,
    };

    ENV.IS_MOCK
      ? mock()
      : await axios.post(`${baseUrl}orders/process_cart_payment`, orderDetails);
  } catch (error) {
    log('processCartPayment', {
      data: {
        billingAddId,
        customerId,
        eventId,
        exhAddId,
        merchantId,
        ooId,
        total,
      },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default processCartPayment;
