import { bool, node, shape } from 'prop-types';
import cx from 'classnames';
import { COLOR } from 'common/const';

import styles from './BoothSetup.module.css';

const BoothSetup = ({ booth = {}, inline, showNumber, isOrder }) => {
  const { number, size, type, location } = booth;

  const display = { [styles.inline]: inline, [styles.block]: !inline };

  if (!showNumber && !size && !type && !location) {
    return <div>Booth specs unknown</div>;
  }

  return (
    <div className={cx(COLOR.GREY_800)}>
      {showNumber && number && (
        <div className={cx(styles.item, display)}>
          <span className={styles.label}>Number</span>
          <span className={styles.value}>Booth #{number} · </span>
        </div>
      )}

      {size && (
        <div className={cx(styles.item, display)}>
          <span className={styles.label}>Size</span>
          <span className={styles.value}>{size} </span>
        </div>
      )}

      {type && type !== 'blank' && (
        <div className={cx(styles.item, display)}>
          <span className={styles.label}>Type</span>
          <span className={styles.value}>{type}</span>
        </div>
      )}

      <div className={cx(styles.item, display)}>
        <span className={styles.value}> Booth</span>
      </div>

      {location &&
        location !== 'blank' &&
        (isOrder ? (
          <p>{location.replace(';', ' - ')}</p>
        ) : (
          <div className={cx(styles.item, display)}>
            <span className={styles.label}>Location</span>
            <span className={styles.value}>{location.replace(';', ' - ')}</span>
          </div>
        ))}
    </div>
  );
};

BoothSetup.propTypes = {
  booth: shape({
    location: node,
    number: node,
    size: node,
    type: node,
  }),
  inline: bool,
  showNumber: bool,
};

export default BoothSetup;
