import { any, bool } from 'prop-types';
import cx from 'classnames';

import { COLOR, TEXT } from 'common/const';
import { Icon } from 'common/components';

import styles from './Requirement.module.css';

const getColor = (isFailedOnce, isMet) => {
  if (isMet) {
    return 'success';
  } else if (isFailedOnce) {
    return 'error';
  } else {
    return undefined;
  }
};

const Requirement = ({ isFailedOnce, isMet, children }) => {
  // console.log({ isFailedOnce, isMet, children });

  return (
    <div className={cx(COLOR.GREY_400, styles.requirement)}>
      <Icon
        size="medium"
        name={isMet ? 'valid' : 'invalid'}
        color={getColor(isFailedOnce, isMet)}
      />
      <span className={cx(COLOR.TEXT, TEXT.BODY_2, styles.text)}>
        {children}
      </span>
    </div>
  );
};

Requirement.propTypes = {
  isFailedOnce: bool,
  isMet: bool,
  children: any,
};

Requirement.defaultProps = {};

export default Requirement;
