import { oneOf } from 'prop-types';
import { camelCase, kebabCase } from 'lodash';

import { ICON_COLORS, ICON_SIZES } from '../../const';
import CONFIG from './config';

const Icon = ({ color, name, size }) => {
  // console.log('<Icon>', { color, name, size });

  // config keys should always use camelCase
  const IconComponent = CONFIG[camelCase(name)];

  return <IconComponent color={color} fontSize={size} />;
};

Icon.propTypes = {
  color: oneOf(ICON_COLORS),
  name: oneOf(
    Object.keys(CONFIG).reduce(
      // accept kebabCase (common in react props) or camelCase (used in the config)
      (arr, cur) => [...arr, cur, kebabCase(cur)],
      [],
    ),
  ).isRequired,
  size: oneOf(ICON_SIZES),
};

Icon.defaultProps = {
  color: 'inherit',
  size: 'inherit',
};

export default Icon;
