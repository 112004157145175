import { EMPTY_CART } from 'common/const';

import getItem from './getItem';
import getTotals from 'data/utils/getTotals';

const getCart = (cart) => {
  // console.log('!getCart', { cart });

  const { ooId = EMPTY_CART.ID, totals } = cart?.[0] ?? {};

  return {
    items: cart?.map(getItem) ?? [],
    ooId,
    totals: totals ? getTotals(totals) : EMPTY_CART.TOTALS,
  };
};

export default getCart;
