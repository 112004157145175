import { merge } from 'lodash';

import { ALL, FILTERS } from './const';
import getOptions from './getOptions';
import getResults from './getResults';

export const TYPE = {
  CHANGE_FILTER: 'change-filter',
  CHANGE_VALUE: 'change-value',
  LOAD: 'load',
  UPDATE: 'update',
};

const getIsAll = (filter) => (filter ? filter === ALL : true);
export const allFilters = FILTERS.filter(({ value }) => value !== ALL).map(
  ({ value }) => value,
);

const INIT_STATE = {
  filter: ALL,
  isAll: true,
  selectedFilters: allFilters,
  value: '',
  options: undefined,
  results: undefined,
  loadedList: undefined,
  isUpdating: false,
};

export const init = ({ filter, value }) =>
  merge({}, INIT_STATE, {
    filter,
    isAll: getIsAll(filter),
    selectedFilters: getIsAll(filter) ? allFilters : [filter],
    value,
  });

const update = ({ loadedList, selectedFilters, isAll, value }) => ({
  options: getOptions(loadedList, selectedFilters, value),
  results: getResults(
    loadedList,
    isAll ? [...selectedFilters, 'id'] : selectedFilters,
    value,
  ),
});

const reducer = (state, { type, payload }) => {
  // console.log({ type, payload, state });

  switch (type) {
    case TYPE.LOAD:
      return {
        ...state,
        loadedList: payload,
        ...update({ ...state, loadedList: payload }),
      };
    case TYPE.CHANGE_FILTER:
      return {
        ...state,
        filter: payload,
        isAll: getIsAll(payload),
        selectedFilters: getIsAll(payload) ? allFilters : [payload],
      };
    case TYPE.CHANGE_VALUE:
      return {
        ...state,
        value: payload,
      };
    case TYPE.UPDATE:
      return {
        ...state,
        ...update(state),
      };
    default:
      throw Error('Mismatched type for EventSearch dispatch');
  }
};

export default reducer;
