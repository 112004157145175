import { string } from 'prop-types';

import { Content, EventInfo as EventInfoComponent } from 'common/components';
import { useOrders, useShop } from 'app/context';
import { useEvent } from 'common/hooks';

import styles from './EventInfo.module.css';

const EventInfo = ({ className }) => {
  const { ooId, eventId } = useShop();
  const { isLoading, data: event = {}, error } = useEvent(eventId);

  // console.log('<EventInfo>', { eventId, event, error });

  const { findOrder } = useOrders();
  const order = findOrder(ooId);

  return (
    <div className={styles.wrap}>
      <Content error={error?.message} isLoading={isLoading}>
        <EventInfoComponent
          className={className}
          image={event.image}
          name={event.name}
          facilityName={event.facilityName}
          start={event.dateStart}
          end={event.dateEnd}
          advancedPricing={
            event.isEarlyPricing ? event.deadlineDate : undefined
          }
          order={order}
          booth={order?.booth}
          id={eventId}
        />
      </Content>
    </div>
  );
};

EventInfo.propTypes = {
  className: string,
};

export default EventInfo;
