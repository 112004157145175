import { useEffect, useMemo } from 'react';

import { useOrders } from 'app/context';

const useResponseData = (ooId) => {
  // console.log('!useResponseData', { ooId });

  const {
    findOrder,
    isFetching,
    refetchIncomplete,
    refetchReviseOrder,
    error,
  } = useOrders();

  const responses = useMemo(
    () => findOrder(ooId)?.assessmentResponseData,
    [findOrder, ooId],
  );

  useEffect(() => {
    if (!responses && ooId) {
      refetchIncomplete();
    }
  }, [responses, ooId, refetchIncomplete, isFetching]);

  return {
    responses,
    isFetching,
    refetch: findOrder(ooId)?.isCompleted
      ? refetchReviseOrder
      : refetchIncomplete,
    error,
  };
};

export default useResponseData;
