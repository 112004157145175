import { useQuery } from '@tanstack/react-query';

import { getBankInfo } from 'data/lookup';
import { useShop } from 'app/context';

const useBankInfo = () => {
  const { ooId } = useShop();

  const { isLoading, data, error } = useQuery(['getBankInfo', ooId], () =>
    getBankInfo({ ooId }),
  );

  return {
    isLoading,
    data,
    error,
  };
};

export default useBankInfo;
