import { deepmerge } from '@mui/utils';
import common from './common';

const edlenTest = deepmerge(common, {
  palette: {
    primary: {
      main: '#1E73BA',
      light: '#2499E2',
      dark: '#195497',
    },
  },
  header: {
    background: '#A73C33',
    text: '#FFF',
    button: {
      background: '#72050D',
      text: '#FFF',
    },
    logo: 'edlenWhite',
  },
});

export default edlenTest;
