import { useCallback, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router';
import { merge } from 'lodash';

import { useShop } from 'app/context';
import { ROUTE } from 'common/const';
import { Page } from 'common/site';
import { useBoothAssessment, useReuseOrder } from 'common/hooks';
import PrivateRoute from 'routes/PrivateRoute';
import { ResumeOrder, StartOrder } from 'routes/booth-setup';

import { getStep, Input, GroupList, List, RadioButton, Binary } from './steps';

import styles from './BoothSetup.module.css';
// import { Require } from 'common/components';

const BoothSetup = () => {
  // console.log('<BoothSetup>');

  // console.log(assessmentId);

  const { ooId } = useShop();
  const { list, submit, isLoading, isSubmitting, error } = useBoothAssessment(
    ooId,
    {
      questionType: 1,
    },
  );
  const history = useHistory();

  const { reusableOrders, isLoadingReuseable } = useReuseOrder({ ooId, list });

  // combine list with config & nav
  const steps = useMemo(
    () =>
      list?.map((item, index) =>
        getStep({ item, list, index, history, submit, reusableOrders }),
      ),
    [history, list, submit, reusableOrders],
  );

  // find specific step in the list, by config
  const findStep = useCallback(
    (config, id) => {
      const step = steps?.find(
        ({ templateId, assessmentId }) =>
          templateId === config.templateId && id === assessmentId.toString(),
      );

      return merge({}, step, {
        error,
        isLoading: isLoading || isLoadingReuseable,
        isSubmitting,
      });
    },
    [steps, error, isLoading, isLoadingReuseable, isSubmitting],
  );

  return (
    <Page className={styles.page} backTo={ROUTE.DASHBOARD} title="Booth Setup">
      {/* Enter Order */}
      <PrivateRoute path={ROUTE.START_ORDER + '/:eventId'}>
        <StartOrder steps={steps} />
      </PrivateRoute>

      <PrivateRoute path={ROUTE.RESUME_ORDER}>
        <ResumeOrder steps={steps} />
      </PrivateRoute>

      {/* Redirect /booth */}
      <PrivateRoute exact path={ROUTE.BOOTH_SETUP}>
        <Redirect to={ROUTE.DASHBOARD} />
      </PrivateRoute>

      {/* Booth Setup Steps */}
      <PrivateRoute
        path={ROUTE.BOOTH_BINARY + '/:assessmentId'}
        render={() => <Binary findStep={findStep} />}
      />

      <PrivateRoute
        path={ROUTE.BOOTH_INPUT + '/:assessmentId'}
        render={() => <Input findStep={findStep} />}
      />

      <PrivateRoute
        path={ROUTE.BOOTH_LIST + '/:assessmentId'}
        render={() => <List findStep={findStep} />}
      />

      <PrivateRoute
        path={ROUTE.BOOTH_GROUP_LIST + '/:assessmentId'}
        render={() => <GroupList findStep={findStep} />}
      />

      <PrivateRoute
        path={ROUTE.BOOTH_RADIO + '/:assessmentId'}
        render={() => <RadioButton findStep={findStep} />}
      />
    </Page>
  );
};

export default BoothSetup;
