import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import cx from 'classnames';

import { useOrders } from 'app/context';

import { Page } from 'common/site';
import {
  Content,
  Collapse,
  Dialog,
  Icon,
  Require,
  Scrollbar,
  SubmitButton,
} from 'common/components';
import { useBoothAssessment, useReuseOrder } from 'common/hooks';
import { BOOTH_TYPE, COLOR, ROUTE, TEXT } from 'common/const';
import { getProductImage, track } from 'common/utils';

import { getReusableOrderItems } from 'data/orders';

import ReuseCard from './ReuseCard';

import styles from './ReuseOrder.module.css';

const ReuseOrder = () => {
  const [dialogState, setDialogState] = useState({
    isDialogOpen: false,
    empOrderId: null,
  });
  const [showAll, setShowAll] = useState(false);
  const [isOrderReused, setIsOrderReused] = useState(false);

  const { current: order = {}, isFetching } = useOrders();
  const { id, eventId } = order;

  const history = useHistory();

  const { list } = useBoothAssessment(id, {
    questionType: 1,
  });

  const { reusableOrders, reuse, isReusingOrder } = useReuseOrder({
    ooId: id,
    list,
  });

  const image = Object.values(BOOTH_TYPE).find(
    ({ value }) => value === order?.booth?.type,
  )?.image;

  const {
    data: reusableItems = [],
    isFetching: isFetchingReusableItems,
    refetch: refetchReusableItems,
  } = useQuery(
    ['getReusableOrderItems'],
    () => {
      return getReusableOrderItems({
        empOrderId: dialogState.empOrderId,
        newEventId: eventId,
      });
    },
    { enabled: false },
  );

  const reusableQuantity = useMemo(
    () => reusableItems.reduce((total, { quantity }) => (total += quantity), 0),
    [reusableItems],
  );

  useEffect(() => {
    if (dialogState.empOrderId) {
      refetchReusableItems();
    }
  }, [dialogState, refetchReusableItems]);

  // Route to catalog after order is reused and incomplete orders are fetched
  useEffect(() => {
    if (isOrderReused && !isReusingOrder && !isFetching) {
      history.push(ROUTE.CATALOG, { from: ROUTE.REUSE_ORDER });
    }
  }, [isOrderReused, isReusingOrder, isFetching, history]);

  return (
    <Require shop>
      <Page className={styles.page} title="Copy Order from Previous Event">
        <Content isLoading={isReusingOrder}>
          <h1 className={cx(TEXT.H6, TEXT.BOLD, styles.title)}>
            Copy Order from Previous Event
          </h1>

          <div className={styles.align}>
            <img src={image} alt="Booth Type" className={styles.icon} />
            <div className={styles.center}>
              <p>{order?.booth?.type}</p>
              {order?.booth?.location !== 'blank' && (
                <p>{order?.booth?.location}</p>
              )}
            </div>
          </div>

          <p className={styles.subText}>
            Select the order you want to copy. Otherwise start a new order.
          </p>

          {/* First 3 orders */}
          <ul>
            {reusableOrders.slice(0, 3).map((order) => (
              <li key={order.ooId} className={styles.order}>
                <ReuseCard
                  order={order}
                  onCardClick={() => {
                    track('view_reuse_details');
                    setDialogState({
                      isDialogOpen: true,
                      empOrderId: order.empOrderId,
                      ooId: order.id,
                      quantity: order.orderItemCount,
                    });
                  }}
                  onReuse={() => {
                    track('reuse_order', { eventId });
                    reuse({
                      originalEmpOrderId: order.empOrderId,
                      eventId: eventId,
                      newOoId: id,
                    });
                    setIsOrderReused(true);
                  }}
                />
              </li>
            ))}
          </ul>

          {/* Order overflow */}
          <Collapse expand={showAll}>
            <ul>
              {reusableOrders.length > 3 && showAll
                ? reusableOrders
                    .slice(3, reusableOrders.length)
                    .map((order) => (
                      <li key={order.ooId} className={styles.order}>
                        <ReuseCard
                          order={order}
                          onCardClick={() => {
                            track('view_reuse_details');
                            setDialogState({
                              isDialogOpen: true,
                              empOrderId: order.empOrderId,
                              ooId: order.id,
                              quantity: order.orderItemCount,
                            });
                          }}
                          onReuse={() => {
                            track('reuse_order', { eventId });
                            reuse({
                              originalEmpOrderId: order.empOrderId,
                              eventId: eventId,
                              newOoId: id,
                            });
                            setIsOrderReused(true);
                          }}
                        />
                      </li>
                    ))
                : null}
            </ul>
          </Collapse>

          {reusableOrders.length > 3 && !showAll && (
            <div
              className={cx(COLOR.PRIMARY, styles.align, styles.showAll)}
              onClick={() => setShowAll(true)}
            >
              <p>Show All</p>
              <div className={styles.center}>
                <Icon size="small" name="caretDown" />
              </div>
            </div>
          )}

          <SubmitButton
            className={styles.button}
            onClick={() => {
              track('reuse_start_new_order');
              history.push(ROUTE.CATALOG);
            }}
          >
            Start New Order
          </SubmitButton>
        </Content>

        <Dialog
          id="reusable-items"
          title="Previous Order Details"
          close={() => {
            track('close_reuse_details');
            setDialogState({ isDialogOpen: false });
          }}
          isOpen={dialogState.isDialogOpen}
          isClosable
          onSubmit={() => {
            track('reuse_order', { eventId });
            reuse({
              originalEmpOrderId: dialogState.empOrderId,
              eventId: eventId,
              newOoId: id,
            });
            setIsOrderReused(true);
          }}
          size="xs"
          submitText="Copy Previous Order"
        >
          <p className={styles.subText}>
            Copy your order by clicking the button below. You can add additional
            items or revise the quantities in your cart before finalizing the
            order.
          </p>
          <Content isLoading={isFetchingReusableItems}>
            <Scrollbar className={styles.scrollbar}>
              <ul>
                {reusableItems?.map((item, index) => (
                  <li
                    key={item.id + index}
                    className={cx(styles.align, styles.item)}
                  >
                    <img
                      src={getProductImage(item.image)}
                      alt={item.name}
                      className={styles.itemImage}
                    />
                    <div>
                      <p>{item.name}</p>
                      <p className={COLOR.GREY_600}>Qty: {item.quantity}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </Scrollbar>
            {dialogState.quantity - reusableQuantity ? (
              <p className={styles.subText}>
                {dialogState.quantity - reusableQuantity} items are not
                available to copy.
              </p>
            ) : null}
          </Content>
        </Dialog>
      </Page>
    </Require>
  );
};

export default ReuseOrder;
