import { useMemo } from 'react';
import { any, bool, func, object, string } from 'prop-types';
import cx from 'classnames';

import { TEXT, VALID } from 'common/const';
import { Input, SubmitButton, Form, CountryInput } from 'common/components';
import { useAddress } from 'common/hooks';

import styles from './AddressForm.module.css';

const AddressForm = ({
  buttonText,
  defaultValues: values,
  isSubmitting,
  onSubmit,
  children,
}) => {
  // console.log('<AddressForm>', { buttonText, defaultValues, isSubmitting });

  const {
    STYLE,
    countryCode,
    buildValidation,
    AddressItem,
    countries,
    updateCountry,
  } = useAddress(values);

  const defaultValues = useMemo(
    () => ({ ...values, countryCode }),
    [values, countryCode],
  );

  return (
    <Form
      validation={{
        ...buildValidation(),
        countryCode: VALID.COUNTRY.required('Country is required'),
        firstName: VALID.FIRST_NAME.required('First name is required'),
        lastName: VALID.LAST_NAME.required('Last name is required'),
        mobile: VALID.PHONE_NUMBER.required('Mobile phone number is required'),
        phone: VALID.PHONE_NUMBER.required('Office phone number is required'),
        company: VALID.STRING.required('Company name is required'),
      }}
      onPass={(data) => {
        onSubmit(data);
      }}
      onFail={(errors) => {
        // console.error({ errors });
      }}
      isSubmitting={isSubmitting}
      defaultValues={defaultValues}
    >
      <p className={cx(TEXT.BODY_2, styles.subheading)}>
        <sup>*</sup>Required information
      </p>

      <ol>
        <li className={styles.line}>
          <Input name="firstName" label="First Name *" required />
          <Input name="lastName" label="Last Name *" required />
        </li>

        <li>
          <Input
            name="mobile"
            label="Mobile Number *"
            maxlength={25}
            required
          />
        </li>

        <li>
          <Input name="company" label="Company Name *" required />
        </li>

        <li>
          <Input
            name="phone"
            label="Office Phone Number *"
            maxlength={25}
            required
          />
        </li>

        <li>
          <CountryInput
            label="Country / Region"
            name="countryCode"
            options={countries}
            defaultValue={countryCode}
            onChange={updateCountry}
          />
        </li>
      </ol>
      <ol className={cx(STYLE.address)}>
        <AddressItem name="address1" />
        <AddressItem name="address2" />
        <AddressItem name="city" />
        <AddressItem name="state" />
        <AddressItem name="zip" />
      </ol>

      {children}

      <SubmitButton name="submit" fullWidth>
        {buttonText}
      </SubmitButton>
    </Form>
  );
};

AddressForm.propTypes = {
  buttonText: string,
  defaultValues: object,
  isSubmitting: bool,
  onSubmit: func,
  children: any,
};

AddressForm.defaultProps = {
  buttonText: 'Submit',
  defaultValues: {},
};

export default AddressForm;
