function validatePassword(
  password,
  setAtLeastEight,
  setOneUppercase,
  setOneLowercase,
  setOneNumber,
  setOneSpecialCh,
) {
  let atLeastEight = false;
  let oneUppercase = false;
  let oneLowercase = false;
  let oneNumber = false;
  let oneSpecialCh = false;

  if (password?.length >= 8) {
    atLeastEight = true;
  }

  for (let i = 0; i < password?.length; i++) {
    let ch = password[i];

    if (isCharacterALetter(ch)) {
      if (ch === ch.toUpperCase()) {
        oneUppercase = true;
      }

      if (ch !== ch.toUpperCase()) {
        oneLowercase = true;
      }
    }

    if (isNumber(ch)) {
      oneNumber = true;
    }
    if (isSpecial(ch)) {
      oneSpecialCh = true;
    }
  }

  setAtLeastEight(atLeastEight);
  setOneUppercase(oneUppercase);
  setOneLowercase(oneLowercase);
  setOneNumber(oneNumber);
  setOneSpecialCh(oneSpecialCh);

  return isValid(
    atLeastEight,
    oneUppercase,
    oneLowercase,
    oneNumber,
    oneSpecialCh,
  );
}

function isCharacterALetter(character) {
  return /[a-zA-Z]/.test(character);
}

function isSpecial(character) {
  return /^[_@#.?!/]$/.test(character);
}

function isNumber(val) {
  return /^-?\d+$/.test(val);
}

function isValid(
  atLeastEight,
  oneUppercase,
  oneLowercase,
  oneNumber,
  oneSpecialCh,
) {
  return (
    atLeastEight && oneUppercase && oneLowercase && oneNumber && oneSpecialCh
  );
}

export { validatePassword };
