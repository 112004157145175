import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { updateAddress } from 'data/orders';
import { getCustomerData, updateAccountInfo } from 'data/customers';
import { ADDRESS_TYPE, PHONE_TYPE } from 'common/const';
import { useAuth } from '..';
import reducer, { init, TYPE } from './reducer';

// Helper functions
const Customer = createContext();

export const CustomerProvider = ({ children }) => {
  const { customerId } = useAuth();
  const [state = {}, dispatch] = useReducer(reducer, init({ customerId }));

  const getMutationOptions = (payload) => ({
    onSuccess: () => dispatch({ type: TYPE.SUCCESS, payload }),
    onError: () => dispatch({ type: TYPE.ERROR, payload }),
  });

  const { mutate: changeName, isLoading: isUpdatingName } = useMutation(
    ({ firstName, lastName }) => {
      dispatch({ type: TYPE.CHANGE_NAME, payload: { firstName, lastName } });
      return updateAccountInfo({
        customerId,
        customerInfo: { firstName, lastName },
      });
    },
    getMutationOptions('Name'),
  );

  const { mutate: changeCompany, isLoading: isUpdatingCompany } = useMutation(
    ({ company }) => {
      dispatch({ type: TYPE.CHANGE_COMPANY, payload: { company } });
      updateAccountInfo({ customerId, customerInfo: { companyName: company } });
    },
  );

  const { mutate: changePhone, isLoading: isUpdatingPhone } = useMutation(
    ({ address, phone, phoneType }) => {
      const payload =
        phoneType === PHONE_TYPE.MOBILE ? { mobile: phone } : { phone: phone };

      dispatch({ type: TYPE.CHANGE_PHONE, payload });
      return updateAddress({
        type: 'Customer',
        address: { ...address, ...payload },
        customerId,
        ooId: 0,
      });
    },
    getMutationOptions('Phone number'),
  );

  const { mutate: changeAddress, isLoading: isUpdatingAddress } = useMutation(
    ({ address }) => {
      dispatch({ type: TYPE.CHANGE_ADDRESS, payload: address });
      return updateAddress({
        type: ADDRESS_TYPE.CUSTOMER,
        address,
        customerId,
        ooId: 0,
      });
    },
    getMutationOptions('Office Address'),
  );

  // For changing FirstName, LastName, Company in a group or individually
  const { mutate: changeAccountInfo, isLoading: isChangingAccountInfo } =
    useMutation((data) => {
      dispatch({ type: TYPE.CHANGE_ACCOUNT, payload: data });
      return updateAccountInfo({
        customerId,
        customerInfo: { ...data },
      });
    });

  const { mutate: changeEmail, isLoading: isChangingEmail } = useMutation(
    ({ email }) => {
      return updateAccountInfo({
        customerId,
        customerInfo: { emailAddress: email },
      });
    },
    {
      onError: (err) =>
        dispatch({ type: TYPE.UPDATE_ERROR, payload: err?.message }),
      onSuccess: (data, { email }) => {
        dispatch({ type: TYPE.CHANGE_EMAIL, payload: { email } });
        dispatch({ type: TYPE.SUCCESS, payload: 'Email' });
      },
    },
  );

  const { mutate: changePassword, isLoading: isChangingPassword } = useMutation(
    ({ currentPassword, newPassword }) =>
      updateAccountInfo({
        customerId,
        customerInfo: { password: newPassword },
        currentPassword,
      }),
    {
      onError: (err) =>
        dispatch({ type: TYPE.UPDATE_ERROR, payload: err?.message }),
      onSuccess: () => dispatch({ type: TYPE.SUCCESS, payload: 'Password' }),
    },
  );

  const reset = (payload) => dispatch({ type: TYPE.RESET, payload });
  const closeToast = () => dispatch({ type: TYPE.CLOSE_TOAST });

  useEffect(() => {
    if (!customerId) {
      reset(); // clear customer data
    }
  }, [customerId]);

  // get customer data (for page refresh, etc)
  const { isLoading, refetch } = useQuery(
    ['getCustomerData', customerId],
    () => getCustomerData(customerId),
    {
      onSuccess: (payload) => dispatch({ type: TYPE.LOAD, payload }),
      enabled: Boolean(customerId),
    },
  );

  const isUpdating = useMemo(
    () =>
      isChangingEmail ||
      isChangingPassword ||
      isChangingAccountInfo ||
      isUpdatingCompany ||
      isUpdatingName ||
      isUpdatingPhone ||
      isLoading ||
      isUpdatingAddress,
    [
      isChangingEmail,
      isChangingPassword,
      isChangingAccountInfo,
      isUpdatingCompany,
      isUpdatingName,
      isUpdatingPhone,
      isLoading,
      isUpdatingAddress,
    ],
  );

  return (
    <Customer.Provider
      value={{
        ...state,
        isUpdating,
        changeEmail,
        changeAccountInfo,
        changeName,
        changeCompany,
        changePhone,
        changeAddress,
        changePassword,
        reset,
        refetch,
        closeToast,
      }}
    >
      {children}
    </Customer.Provider>
  );
};

export const useCustomer = () => useContext(Customer);
