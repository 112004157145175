import { PAYMENT_METHOD } from 'common/const';

export const email = 'test@example.com';
export const customerId = '8c3c3a01-5535-4ee8-ab13-ca07b1a51fe5';
export const eventId = '032001ZZ';
export const itemId = 1001;
export const priceListId = '1de0cc05-5e8a-4c70-bb57-0b954e07f096';
export const ooId = 7;
export const boothNumber = '7';
export const responseId = '3fa85f64-5717-4562-b3fc-2c963f66afa6';
export const cartId = '3fa85f64-5717-4562-b3fc-2c963f66afa6';
export const documentId = '3fa85f64-5717-4562-b3fc-2c963f66afa6';
export const packageItemId = 27;
export const countryId = 1; // United States
export const facilityId = 7;
export const linkCode =
  '498510C4-4733-4FB9-8C1A-638C4C220EF7;CB125744-EE63-4191-84A0-390F8C62C5A7';
export const ownership = 'Edlen';
export const boothType = 'Inline';
export const boothLocation = 'blank';
export const searchTagName = 'TV';

export const webServerDateTime = '2022-01-26T00:00:00-7:00';

export const jwtToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTIzNDU2Nzg5LCJuYW1lIjoiSm9zZXBoIn0.OpOSSw7e485LOP5PrzScxHb7SR6sAOMRckfFwi4rp7o';

// Params
export const registerParams = {
  emailAddress: 'codingscapejoetest1@gmail.com',
  password: 'Passw0rd!',
  firstName: 'Test',
  lastName: 'User',
  company: 'CodingScape',
  phone: '555-867-5309',
  mobile: '123-456-7890',
  countryCode: 'USA',
  address1: '123 First St.',
  address2: null,
  city: 'Las Vegas',
  state: 'NV',
  zip: '89000',
  isSystemAccount: false,
  subscribeNewsletter: false,
  lastIpAddress: '127.0.0.1',
  ownership: 'Edlen',
};

export const registerExpectedParams = {
  theData: {
    emailAddress: 'codingscapejoetest1@gmail.com',
    password: 'Passw0rd!',
    firstName: 'Test',
    lastName: 'User',
    addressInformation: {
      address1: '123 First St.',
      address2: null,
      city: 'Las Vegas',
      countryCode: 'USA',
      phone: '5558675309',
      mobile: '1234567890',
      state: 'NV',
      type: 'Customer',
      zip: '89000',
    },
    companyInformation: {
      address: {
        address1: '123 First St.',
        address2: null,
        city: 'Las Vegas',
        countryCode: 'USA',
        phone: '5558675309',
        mobile: '1234567890',
        state: 'NV',
        type: 'Customer',
        zip: '89000',
      },
      name: 'CodingScape',
      users: [],
    },
  },
  ownership: 'Edlen',
};

// Responses
export const merchantId = 'edlenelect';
export const exhAddId = '90f1ca84-ee1b-4296-ae76-e681c2b63d20';
export const billingAddId = '90f1ca84-ee1b-4296-ae76-e681c2b63d20';
export const addressId = '076d0884-967a-45c5-bfde-52b3b792d4af';

// Results
export const resultData = {
  actionResult: 'Successful',
  actionText: 'string',
  displayMessage: 'string',
};

export const boothData = {
  boothID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  dimensions: 'string',
  groupLocation: 'string',
  location: 'string',
  number: 'string',
  orderID: 0,
  size: 'string',
  type: 'string',
};

export const cartTotal = {
  ccFee: 5,
  total: 120,
  serviceFee: 5,
  subtotal: 100,
  tax: 10,
  wireFee: 0,
  balanceDue: 20,
  amountPaid: 100,
};

export const wireTransferFee = 9.75;

export const suggestedItems = [
  {
    id: 0,
  },
];

export const requiredItems = [
  {
    image: 'string',
    name: 'string',
  },
];

export const pricesData = [
  {
    itemPrice: 10,
    priceType: 'string',
  },
  {
    itemPrice: 11,
    priceType: 'string',
  },
];

export const catalogItem = {
  categoryId: 1,
  deptNum: 2,
  discountPercentage: 3,
  includeServiceFee: true,
  isPrimaryImageFlag: true,
  itemDescription: 'outlet',
  itemId: itemId,
  itemLongDescription: 'big outlet',
  minQty: 0,
  maxQty: 1,
  picturePath: 'picturePath',
  price: pricesData,
  priceListId: priceListId,
  quantity: 0,
  requiredItems: requiredItems,
  subCategoryId: 0,
  suggestedItems: suggestedItems,
  isTaxable: true,
  onlinePriceLevel: 'string',
};

export const subCategoryData = {
  creationDate: '2022-02-09T00:04:26.193Z',
  deletionDate: '2022-02-09T00:04:26.193Z',
  description: 'string',
  id: 0,
  items: [catalogItem],
  longDescription: 'string',
  name: 'string',
};

export const eventCatalog = [
  {
    creationDate: '2022-02-09T00:04:26.193Z',
    deletionDate: '2022-02-09T00:04:26.193Z',
    description: 'string',
    id: 0,
    longDescription: 'string',
    name: 'string',
    subCategories: [subCategoryData],
  },
];

export const eventCatalogCategoryNames = [
  'Power Packages',
  'Electrical Outlets',
  '24-Hour Power Labor',
];

export const branchData = {
  addressLine1: '1 St. Clair Avenue NE',
  addressLine2: '',
  assigned: false,
  city: 'Cleveland',
  code: 'CL',
  comments: '',
  company: 'Edlen',
  country: 'US',
  dateCreated: '2013-02-19T14:49:51.49',
  dateLastModified: '2021-11-18T14:57:54.497',
  description: null,
  emotLaborRate: 0,
  emstLaborRate: 0,
  elecOTLaborRate: 0,
  elecSTLaborRate: 0,
  email: 'exhibitorservices-cleveland@edlen.com',
  fax: '2169281541',
  hasPositionsAvail: false,
  id: 23,
  merchantID: merchantId,
  name: 'Edlen Electrical Exhibition Services Cleveland',
  overheadPerc: 0,
  phone: '2169281540',
  providerSource: 'Undefined',
  state: 'OH',
  wareOTLaborRate: 0,
  wareSTLaborRate: 0,
  zip: '44114',
};

export const facilityData = {
  addressLine1: '1 St. Clair Avenue NE',
  addressLine2: '',
  branchID: 23,
  branchInfo: branchData,
  city: 'Cleveland',
  city_Tax: 0,
  comments: '',
  country: 'USA',
  dateCreated: '2013-02-19T14:54:05.197',
  dateLastModified: '2013-02-19T14:54:05.197',
  deactivationDate: '0001-01-01T00:00:00',
  facilityAKA: 'HCC',
  facilityImageURL: null,
  facilityName: 'Huntington Convention Center of Cleveland',
  facilityTypes: null,
  facility_Contact: '',
  facility_Contact_Email: '',
  facility_Contact_Phone: '',
  facility_Contact_Title: '',
  fax: '',
  fee: 0,
  id: 620,
  isPremierFacility: false,
  isValid: false,
  phone: '(216) 928-1600',
  providerSource: 'Undefined',
  removedFromWEbSite: false,
  sales_Tax: 8,
  serviceFee: 0,
  serviceFeeInfo: null,
  state: 'OH',
  status: null,
  website: 'http://www.clevelandconventions.com',
  yearEnd: '04/30',
  yearStart: '05/01',
  zip: '44114',
};

export const eventData = [
  {
    isEarlyPricing: true,
    webServerDateTime: '2022-01-01T00:00:00',
    aka: '',
    cityTax: 0,
    dateEnd: '2022-02-20T00:00:00',
    closeDay: null,
    deadlineDate: '2022-01-26T00:00:00',
    facilityInfo: facilityData,
    id: '022001CL',
    image: 'event-logo.png',
    miscTax1: 0,
    miscTax2: 0,
    name: 'NBA 2022',
    dateStart: '2022-02-16T00:00:00',
    openDay: null,
    parent: false,
    providerSource: 'SQL',
    salesTax: 8,
    serviceFeeRate: 0,
    status: 'Active    ',
    tnCPath: null,
    websiteUrl: null,
    isOOAvailable: true,
    isOOFormsAvailable: false,
    isServiceFeeTaxable: false,
    isTaxExempt: false,
  },
];

export const paymentData = [
  {
    referenceOrCcNum: '1117',
    type: 'ACH',
    card: 'DISCOVER',
    amountPaid: 303,
    paymentDate: '2022-08-18T00:00:00',
    balance: 391.38,
  },
  {
    referenceOrCcNum: '1117',
    type: 'WIRE TRANSFER',
    card: 'DISCOVER',
    amountPaid: 189.4,
    paymentDate: '2022-08-08T00:00:00',
    balance: 201.98,
  },
  {
    referenceOrCcNum: '1111',
    type: 'VISA',
    card: 'VISA',
    amountPaid: 241.25,
    paymentDate: '2023-01-05T10:55:19.603',
    balance: 0,
  },
];

export const addressData = {
  address1: 'string',
  address2: 'string',
  city: 'string',
  countryCode: 'string',
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  phone: '0987654321',
  mobile: '1234567890',
  state: 'string',
  type: 'All',
  zip: 'string',
};

export const companyData = {
  address: {
    address1: 'string',
    address2: 'string',
    city: 'string',
    countryCode: 'string',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    phone: 'string',
    state: 'string',
    type: 'All',
    zip: 'string',
  },
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  name: 'string',
  users: [
    {
      addressInformation: addressData,
      companyInformation: 'string',
      creationDate: '2022-02-11T00:04:10.228Z',
      deletionDate: '2022-02-11T00:04:10.228Z',
      emailAddress: 'string',
      failedAttempts: 0,
      firstName: 'string',
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      isTempPassword: true,
      lastLoginDate: '2022-02-11T00:04:10.228Z',
      lastName: 'string',
      modificationDate: '2022-02-11T00:04:10.228Z',
      password: 'string',
      resultInformation: {
        actionResult: 'Successful',
        actionText: 'string',
        displayMessage: 'string',
      },
      siteOwnership: 'Undefined',
      tempPasswordExpirationDate: '2022-02-11T00:04:10.228Z',
    },
  ],
};

export const customerData = {
  addressInformation: addressData,
  companyInformation: companyData,
  creationDate: '2022-02-11T00:04:10.228Z',
  deletionDate: '2022-02-11T00:04:10.228Z',
  emailAddress: 'string',
  failedAttempts: 0,
  firstName: 'string',
  id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  isTempPassword: true,
  lastLoginDate: '2022-02-11T00:04:10.228Z',
  lastName: 'string',
  modificationDate: '2022-02-11T00:04:10.228Z',
  password: 'string',
  resultInformation: resultData,
  siteOwnership: 'Undefined',
  tempPasswordExpirationDate: '2022-02-11T00:04:10.228Z',
};

export const cartData = [
  {
    cityTaxRate: 0,
    customerID: customerId,
    eventId: eventId,
    eventItemDependencyID: '00000000-0000-0000-0000-000000000000',
    eventItemGroupID: 0,
    id: '00000000-0000-0000-0000-000000000000',
    includeServiceFee: false,
    isAutoAdded: false,
    itemDescription: 'Item Name',
    itemID: itemId,
    miscTaxRate1: 0,
    miscTaxRate2: 0,
    onlineOrderID: 0,
    date: '0001-01-01T00:00:00',
    ordersDetailID: '00000000-0000-0000-0000-000000000000',
    price: 0,
    priceListID: priceListId,
    prices: pricesData,
    quantity: 1,
    salesTaxRate: 0,
    serviceFeeRate: 0,
    taxRate: 0,
    taxable: false,
  },
];

export const orderAssessmentResponseData = [
  {
    assessmentId: 0,
    assessmentQ_ID: 0,
    assessmentType: 'string',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    question: 'string',
    response: '1',
    templateTypeId: 1,
  },
  {
    assessmentId: 0,
    assessmentQ_ID: 0,
    assessmentType: 'string',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    question: 'string',
    response: 'unsure',
    templateTypeId: 2,
  },
  {
    assessmentId: 0,
    assessmentQ_ID: 0,
    assessmentType: 'string',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    question: 'string',
    response: '',
    templateTypeId: 3,
  },
  {
    assessmentId: 0,
    assessmentQ_ID: 0,
    assessmentType: 'string',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    question: 'string',
    response: '4 x 8',
    templateTypeId: 4,
  },
];

export const itemsList = [
  {
    // 1st item
    categoryDescription: 'Electrical',
    isAutoAdded: false,
    itemDescription: 'Small Outlet',
    itemID: 1001,
    price: 10,
    prices: [{ itemPrice: 10 }, { itemPrice: 11 }],
    quantity: 1,
    image: '120v.png',
  },
  {
    // 2nd item
    categoryDescription: 'Electrical',
    isAutoAdded: false,
    itemDescription:
      'Giant Outlet with Required Labor and a Really Long Name and Not On Sale',
    itemID: 1002,
    price: 10,
    prices: [{ itemPrice: 10 }, { itemPrice: 10 }],
    quantity: 1,
    image: '120v.png',
  },
  {
    // required labor
    categoryDescription: 'Labor',
    associatedCategoryDescription: 'Electrical',
    isAutoAdded: true, // will this be true?
    itemDescription: 'Big Old Labor',
    itemID: 4001,
    price: 10,
    prices: [{ itemPrice: 10 }, { itemPrice: 11 }],
    quantity: 1,
    image: 'labor-electrical-2.png',
  },
  {
    // 2nd category
    categoryDescription: 'Lighting',
    isAutoAdded: false,
    itemDescription: 'Big Lamp',
    itemID: 2001,
    price: 199,
    prices: [{ itemPrice: 199 }, { itemPrice: 250 }],
    quantity: 789,
    image: 'arm-light.png',
  },
  {
    // 2nd category required
    categoryDescription: 'Labor',
    associatedCategoryDescription: 'Lighting',
    isAutoAdded: true,
    itemDescription: 'Labor Required for Big Lamp',
    itemID: 4001, // the same as the other labor item, to ensure we set a key properly
    price: 10,
    prices: [{ itemPrice: 10 }, { itemPrice: 11 }],
    quantity: 1,
    image: 'labor-electrical-2.png',
  },
  {
    // package (1st category)
    categoryDescription: 'Electrical',
    isAutoAdded: false,
    itemDescription: 'Package of Things',
    itemID: 99001,
    price: 10,
    prices: [{ itemPrice: 10 }, { itemPrice: 11 }],
    quantity: 1,
    image: '120v.png',
  },
  {
    // free package (1st category)
    categoryDescription: 'Electrical',
    isAutoAdded: true, // will this be true?
    itemDescription: 'Free Package of Things',
    itemID: 99002,
    price: 0,
    prices: [{ itemPrice: 0 }, { itemPrice: 0 }],
    quantity: 1,
    image: '120v.png',
  },
];

export const orderHistory = [
  {
    line: 'You requested to cancel the order.',
    date: '2023-01-09T17:26:06.28',
  },
  {
    line: 'Order was placed.',
    date: '2023-01-09T17:22:06.207',
  },
];

export const incompleteOrders = [
  {
    boothData,
    items: cartData,
    totals: cartTotal,
    eventID: eventId,
    eventName: 'string',
    ooId: ooId,
    orderAssessmentResponseData,
    date: '2022-02-11T00:21:33.551Z',
  },
];

export const completedOrders = [
  {
    boothData,
    empOrderID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    eventId: eventId,
    eventName: 'string',
    facilityId: facilityId,
    facilityName: 'Convention Center',
    facilityLocation: 'Las Vegas',
    floorPlanExists: true,
    isThirdParty: true,
    ooId: 0,
    orderAssessmentResponseData,
    date: '2022-02-11T00:04:10.228Z',
    totals: cartTotal,
    items: cartData,
    paymentDetails: paymentData,
    orderStatus: 'string',
    personalInformation: customerData,
    companyName: 'Company',
    thirdPartyCompanyName: 'Third Party',
    eventOpenDate: '2023-02-11T00:04:10.228Z',
    eventCloseDate: '2023-02-12T00:04:10.228Z',
    eventDeadlineDate: '2023-02-12T00:04:10.228Z',
    eventLogo: 'logo',
    cancellationStatus: 'None',
    isCancellationAllowed: true,
    statusReason: '',
    cancelRequestDate: '1900-01-01T00:00:00',
    cancellationProcessDate: '1900-01-01T00:00:00',
    exhId: exhAddId,
    isModifyAvailable: true,
    orderHistory: orderHistory,
  },
];

export const paymentResponse = {
  resultInformation: resultData,
  paymentResult: 'Successful',
};

// Params
export const paymentInputParams = {
  billingAddress: {
    address1: '111 las vegas blvd',
    address2: '',
    city: 'Las Vegas',
    company: 'Codingscape',
    countryCode: 'US',
    creationDate: '2021-11-22T09:23:49.827',
    customerID: '7ac415d4-5ce0-42be-974b-681e8259e854',
    deletionDate: '0001-01-01T00:00:00',
    failedAttempts: 0,
    fax: null,
    firstName: 'Test',
    isAuthenticated: false,
    isSystemAccount: false,
    isTempPassword: false,
    lastIPAddress: '::1',
    lastLoginDate: '2021-11-22T09:23:49.827',
    lastName: 'User',
    mobile: null,
    modificationDate: '2021-11-22T09:23:49.827',
    ownership: 'Edlen',
    password:
      '"ALcPGRVXsE+htOKjhRdwCQEAAADuaYPbC8c+N0JR/MTLAQFA/yAEq4ZpKhavPBoME+l61A=="',
    phone: '1111111111',
    state: 'NV',
    subscribeToNewsletter: false,
    tempPwdExpirationDate: '0001-01-01T00:00:00',
    zip: '88888',
    email: 'testing1@gmail.com',
    companyName: 'Codingscape',
    type: 'Exhibitor',
    customerId: '7ac415d4-5ce0-42be-974b-681e8259e854',
    addressId: 'df9296bb-9dd3-40f1-8186-c08b8e0d02b8',
  },
  completedSteps: {},
  isDone: true,
  buttonText: 'Save',
  paymentData: {
    countryCode: 'US',
    accountNumber: '4111111111111111',
    expiration: '12/24',
    cvv: '123',
    firstName: 'Test',
    lastName: 'User',
    address1: '111 las vegas blvd',
    address2: '',
    city: 'Las Vegas',
    state: 'NV',
    zip: '88888',
    method: PAYMENT_METHOD.CARD,
    type: 'Billing',
    customerId,
    addressId,
    isGuaranteedCard: false,
  },
  ooId: ooId,
  customerId,
  merchantId,
  eventId,
  isThirdParty: false,
  thirdPartyCompanyName: 'Acme Inc.',
  isGuaranteedCard: false,
  exhAddId,
  billingAddId: billingAddId,
  total: 497,
  date: '2022-02-11',
  paymentMethod: PAYMENT_METHOD.CARD,
  accountNumber: '4111111111111111',
  last4: '1111',
  type: 'VISA',
};

export const expectedProcessPaymentParams = {
  paymentData: {
    country: 'US',
    accountNumber: '4111111111111111',
    accountNumberExpMM: '12',
    accountNumberExpYY: '24',
    cvv: '123',
    firstName: 'Test',
    lastName: 'User',
    nameOnCard: 'Test User',
    address1: '111 las vegas blvd',
    address2: '',
    email: 'testing1@gmail.com',
    companyName: 'Codingscape',
    city: 'Las Vegas',
    state: 'NV',
    zip: '88888',
    paymentType: 'VISA',
    type: 'VISA',
    last4Digit: '1111',
    status: 'active',
    amount: paymentInputParams.total,
    isGuaranteedCard: false,
  },
  ooId,
  customerId,
  eventId,
  merchantId,
  exhAddId,
  billingAddId,
  thirdPartyName: 'Acme Inc.',
  isThirdParty: false,
  isGuaranteedCard: false,
};

export const getEventAssessmentList = [
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: '5642b507-617c-4404-a9de-fa9f0b1477f6',
    id: 1,
    isDeleted: false,
    isResponseOptional: true,
    isVisible: true,
    itemInclusions: [],
    question: 'What is your booth number?',
    responseSelections: null,
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 1,
    sortOrder: 1,
    templateTypeId: 1,
    type: 'Booth Set-up',
    typeID: 1,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: 'f360afbf-7e45-4902-87b3-3affac097884',
    id: 2,
    isDeleted: false,
    isResponseOptional: false,
    isVisible: true,
    itemInclusions: [],
    question: 'What type of booth are you building?',
    responseSelections: [
      {
        id: '651d86ef-f06e-4a90-8b1a-e99971c9c7c2',
        text: 'Inline',
      },
      {
        id: '6b8c3730-c526-4dd3-949f-631d5f43c020',
        text: 'Island',
      },
      {
        id: '9f14e95d-8b2a-40b2-bfe1-57ace89effaa',
        text: 'Peninsula',
      },
      {
        id: '6103c868-8b5e-43b8-a892-133ca9d9082f',
        text: 'Lobby',
      },
      {
        id: 'c2eb4c45-2c5d-4ea6-a694-457903f8601b',
        text: 'Table Top',
      },
      {
        id: '3540fa01-5706-4822-b8d3-b4f2b89de19f',
        text: 'Meeting Room',
      },
      {
        id: 'e0c848ee-7ee4-46cc-b081-94778c097270',
        text: 'Outside',
      },
      {
        id: 'f240ac46-d7f6-48fd-aa7a-36538575a367',
        text: 'Do Not Know',
      },
    ],
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 2,
    sortOrder: 2,
    templateTypeID: 2,
    type: 'Booth Set-up',
    typeID: 1,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
    response: 'Inline',
    assessmentQ_ID: 1,
  },
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: 'f7fb76be-4dfc-4b76-8d3d-4c150cb808e6',
    id: 3,
    isDeleted: false,
    isResponseOptional: false,
    isVisible: true,
    itemInclusions: [],
    question: 'Where is your booth located?',
    responseSelections: [],
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 2,
    sortOrder: 3,
    templateTypeID: 3,
    type: 'Booth Set-up',
    typeID: 1,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: '6509b1f8-310e-4f8c-8f31-c46ccd9f374e',
    id: 4,
    isDeleted: false,
    isResponseOptional: true,
    isVisible: true,
    itemInclusions: [],
    question: 'What is the size of your booth?',
    responseSelections: null,
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 1,
    sortOrder: 4,
    templateTypeID: 4,
    type: 'Booth Set-up',
    typeID: 1,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
];
export const getEventAssessmentQuestions = [
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: '00000000-0000-0000-0000-000000000000',
    id: 5,
    isDeleted: false,
    isResponseOptional: true,
    isVisible: false,
    itemInclusions: [],
    question:
      'Do you require power at a location other than the rear of your booth?',
    responseSelections: [
      {
        id: 'bc0746fc-3e1b-4d2d-9c8b-377e90029aea',
        text: 'Yes',
      },
      {
        id: '0aa6140b-cc36-458f-ae2a-69542780daae',
        text: 'No',
      },
    ],
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 3,
    sortOrder: 1,
    templateTypeID: 5,
    type: 'Booth Work',
    typeID: 2,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: '00000000-0000-0000-0000-000000000000',
    id: 6,
    isDeleted: false,
    isResponseOptional: true,
    isVisible: false,
    itemInclusions: [],
    question: 'Do you have monitors to hang?',
    responseSelections: [
      {
        id: '589aab5b-eebe-4db6-83b1-71e3665e7d34',
        text: 'Yes',
      },
      {
        id: '0542b60a-e344-43da-8c04-886f9beb6c9e',
        text: 'No',
      },
    ],
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 3,
    sortOrder: 2,
    templateTypeID: 5,
    type: 'Booth Work',
    typeID: 2,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: '00000000-0000-0000-0000-000000000000',
    id: 7,
    isDeleted: false,
    isResponseOptional: true,
    isVisible: false,
    itemInclusions: [],
    question: 'Are you renting monitors?',
    responseSelections: [
      {
        id: '068a58ee-c6ed-4a14-9bbc-005083a7aa26',
        text: 'Yes',
      },
      {
        id: '4e9afa94-3d43-4a2e-9895-22205b162fb5',
        text: 'No',
      },
    ],
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 3,
    sortOrder: 3,
    templateTypeID: 5,
    type: 'Booth Work',
    typeID: 2,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: '00000000-0000-0000-0000-000000000000',
    id: 8,
    isDeleted: false,
    isResponseOptional: true,
    isVisible: false,
    itemInclusions: [],
    question: 'Are you renting A/V equipment?',
    responseSelections: [
      {
        id: '3d50ed77-b4e1-4cc5-9e58-eda60048b7e6',
        text: 'Yes',
      },
      {
        id: 'a7b95f56-5901-4ab1-b51a-ef040ba0f896',
        text: 'No',
      },
    ],
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 3,
    sortOrder: 4,
    templateTypeID: 5,
    type: 'Booth Work',
    typeID: 2,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: '00000000-0000-0000-0000-000000000000',
    id: 10,
    isDeleted: false,
    isResponseOptional: true,
    isVisible: false,
    itemInclusions: [],
    question: 'Do you have truss or motors?',
    responseSelections: [
      {
        id: 'a059e614-7167-43a3-a90e-f33985453928',
        text: 'Yes',
      },
      {
        id: '24a6008d-8593-4418-b4ba-590dc6c92c64',
        text: 'No',
      },
    ],
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 3,
    sortOrder: 5,
    templateTypeID: 5,
    type: 'Booth Work',
    typeID: 2,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
  {
    defaultBranchAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    defaultFacilityAssessmentQVisibilityRulesID:
      '00000000-0000-0000-0000-000000000000',
    eventAssessmentQVisibilityRuleID: '00000000-0000-0000-0000-000000000000',
    id: 11,
    isDeleted: false,
    isResponseOptional: true,
    isVisible: false,
    itemInclusions: [],
    question: 'Do you have a hanging sign?',
    responseSelections: [
      {
        id: '3912b0ef-4f3f-4632-872e-1ab9ad36bfc4',
        text: 'Yes',
      },
      {
        id: 'd86a56d0-c2f0-4185-b679-f70298cf8582',
        text: 'No',
      },
    ],
    responseStoredProcedure: null,
    responseTypeDesc: null,
    responseTypeID: 3,
    sortOrder: 6,
    templateTypeID: 5,
    type: 'Booth Work',
    typeID: 2,
    userResponse: null,
    userResponseDate: '0001-01-01T00:00:00',
  },
];

export const bankData = {
  achAba: '39483042',
  achAccountNumber: '3226364846',
  achAddress: '123 Las Vegas Blvd. Las Vegas, NV 89169',
  achBank: 'Bank',
  achPhone: 'Phone: 123.456.7890',
  wireAba: '34283802',
  wireAccountNumber: '4823048495',
  wireBank: 'Bank',
  wireSwift: 'WFRGE34253',
};

export const packageData = [
  {
    autoAdd: false,
    categoryGroup: 'Electrical Labor',
    categoryID: 4,
    id: 99002,
    isAvailableOnline: true,
    isEnabled: true,
    longDescription:
      'One (1) 500 Watt Outlet, distribution material and labor included. One package per location must be ordered. Power cannot be split.',
    maxQty: 999999,
    minQty: 1,
    name: '500 Watt Outlet Distribution Package',
    packageItems: [],
    picturePath: '500-watt-package.png',
    priceListID: priceListId,
    prices: [
      {
        itemPrice: 0.16,
        priceType: 'Advance',
      },
      {
        itemPrice: 0.32,
        priceType: 'Floor',
      },
    ],
    shortDescription:
      'One (1) 500 Watt Outlet, distribution material and labor included.',
    subCategoryGroup: 'Electrical Labor',
    subCategoryID: 18,
  },
];

export const itemData = [
  {
    categoryID: 0,
    deptNum: 0,
    discountPercentage: 0,
    includeServiceFee: true,
    isPrimaryImageFlag: true,
    itemDescription: 'string',
    itemID: 0,
    itemLongDescription: 'string',
    itemShortDescription: 'string',
    picturePath: 'string',
    price: pricesData,
    priceListID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    quantity: 0,
    requiredItems,
    subCategoryID: 0,
    suggestedItems,
    isTaxable: true,
    onlinePriceLevel: 'string',
  },
];

export const eventCreditCardTypes = [
  {
    id: 1,
    method: 'CC',
    name: 'VISA',
  },
  {
    id: 2,
    method: 'CC',
    name: 'MASTERCARD',
  },
  {
    id: 4,
    method: 'CC',
    name: 'AMEX',
  },
  {
    id: 6,
    method: 'CC',
    name: 'DINERS',
  },
  {
    id: 7,
    method: 'CC',
    name: 'DISCOVER',
  },
];
export const paymentMethodData = [
  ...eventCreditCardTypes,
  {
    id: 3,
    method: 'CHECK',
    name: 'CHECK',
  },

  {
    id: 5,
    method: 'CASH',
    name: 'CASH',
  },
  {
    id: 8,
    method: 'WOFF',
    name: 'WRITE OFF',
  },
  {
    id: 9,
    method: 'CR',
    name: 'CREDIT',
  },
  {
    id: 10,
    method: 'SETTLE',
    name: 'SETTLEMENT',
  },
  {
    id: 11,
    method: 'COMMISSION',
    name: 'COMMISSION',
  },
  {
    id: 12,
    method: 'ACH',
    name: 'ACH',
  },
  {
    id: 13,
    method: 'WIRE TRANSFER',
    name: 'WIRE TRANSFER',
  },
  {
    id: 14,
    method: 'FEE',
    name: 'PROCESSING FEE',
  },
];

export const states = [
  {
    abbreviation: 'AA',
    id: 1,
    name: 'AA (Armed Forces Americas)',
  },
  {
    abbreviation: 'AE',
    id: 2,
    name: 'AE (Armed Forces Europe)',
  },
];

export const countries = [
  {
    id: 2,
    name: 'Canada',
    numericIsoCode: 124,
    states: [
      {
        abbreviation: 'AB',
        id: 63,
        name: 'Alberta',
      },
      {
        abbreviation: 'BC',
        id: 64,
        name: 'British Columbia',
      },
    ],
    threeLetterIsoCode: 'CAN',
    twoLetterIsoCode: 'CA',
  },
  {
    id: 3,
    name: 'Argentina',
    numericIsoCode: 32,
    states: [],
    threeLetterIsoCode: 'ARG',
    twoLetterIsoCode: 'AR',
  },
];

export const totals = {
  ccFee: 12,
  discount: 13.1,
  isTaxable: true,
  serviceFee: 11,
  subtotal: 3333,
  tax: 4.99,
  total: 55555.55,
  wireFee: 50,
};

export const appVersion = 'M.O.C.K';

export const maxCreditCardAmount = 99999.99;

export const doesShoppingCartExist = 0;
export const expectedDocuments = {
  documents: [
    {
      id: documentId,
    },
  ],
};

export const boothLocationData = [
  {
    id: '616d4d59-c90a-431d-9873-2cd6f06d69b9',
    text: 'Inside;Central Hall 1-5',
  },
  {
    id: '1ca96e32-2bec-4325-9dbe-acff9c7d7c19',
    text: 'Inside;North Hall',
  },
  {
    id: '063a3619-44a2-4b17-9603-cabe6b32f271',
    text: 'Inside;South Hall 1st Floor',
  },
  {
    id: '4957244e-f9c2-4911-8f51-4174d9f82966',
    text: 'Inside;West Hall',
  },
  {
    id: '7c0eb791-7a53-44b6-acf3-802cabc0a3fc',
    text: 'Inside;South Hall 2nd Floor',
  },
  {
    id: '85011a41-d54e-4408-bdbc-eafe0f073085',
    text: 'Inside;Grand Lobby',
  },
  {
    id: 'de1232c5-787a-40e1-b51d-289b504fe4d1',
    text: 'Outside;Silver Lot 1, 2 & 3',
  },
  {
    id: '883b82f6-518a-4a7a-b75c-9af8758172dd',
    text: 'Outside;Diamond Lot',
  },
  {
    id: 'cb34e894-2d58-466c-bf36-0517857b4f95',
    text: 'Outside;Festival Lot',
  },
  {
    id: 'c3c057eb-b031-4646-acff-23d47663c105',
    text: 'Other;Do Not Know',
  },
];

export const floorPlanItems = [
  {
    itemId: 1001,
    name: '500 Watt Outlet That Has a super duper long name',
    quantity: 1,
    symbol: 'ElectricalOutlets.svg',
    image: '120v.png',
    shortDescription: '5',
  },
  {
    itemId: 1002,
    name: '1000 Watt Outlet',
    quantity: 2,
    symbol: 'ElectricalOutlets.svg',
    image: '120v.png',
    shortDescription: '10',
  },
];

export const empInvoiceData = {
  subStatementTotals: [
    {
      subtotalNoFee: 329,
      subtotal: 329,
      amountPaid: 329,
      fee: 0,
      cityTaxAmount: 0,
      miscTax1Amount: 0,
      miscTax2Amount: 0,
      balanceDue: 0,
      salesTaxAmount: 0,
      serviceAmount: 0,
    },
  ],
  invoiceItems: [
    {
      itemQuantity: 1,
      itemDescription: '15 Ft Extension Cord',
      itemPrice: 26,
      thirdPartyFlag: false,
      orderTotal: 26,
      additionalInfo: 'Online Order #182833',
      itemId: '3001',
    },
    {
      itemQuantity: 1,
      itemDescription: '500 Watt Outlet',
      itemPrice: 137,
      thirdPartyFlag: false,
      orderTotal: 137,
      additionalInfo: 'Online Order #182833',
      itemId: '1001',
    },
  ],
};

export const searchTags = [
  {
    tagName: 'AirCompressor',
    tagImage: 'none.jpg',
  },
  {
    tagName: 'BeltSander',
    tagImage: 'none.jpg',
  },
];

export const searchItems = [
  {
    itemId: 1001,
    tagDescription: "TV greater than 55'",
    tagName: 'TV',
  },
  {
    itemId: 1002,
    tagDescription: "TV larger than 55'",
    tagName: 'TV',
  },
];

export const cancellationReasons = [
  {
    id: 1,
    reason: 'No longer need power.',
  },
  {
    id: 2,
    reason: 'Not participating in the event.',
  },
  {
    id: 3,
    reason: 'Other',
  },
];

export const Faqs = [
  {
    question: 'Is the cost of an outlet a daily charge?',
    answer:
      'No, the price listed is the total cost for the duration of the show.',
    htmlAnswer:
      '<p>No, the price listed is the total cost for the duration of the show.</p>',
    generalHeader: 'Ordering & Payment',
    sortOrder: 1,
  },
];

export const reusableOrders = [
  {
    eventId: '1234001ZZ',
    eventName: 'Reusable event 1',
    empOrderId: cartId,
    exhId: customerId,
    ooId: 12345,
    orderDate: '2023-02-09T00:00:00.000Z',
    companyName: '',
    orderItemCount: 4,
  },
  {
    eventId: '1234001ZZ',
    eventName: 'Reusable event 2',
    empOrderId: cartId,
    exhId: customerId,
    ooId: 12346,
    orderDate: '2023-03-09T00:00:00.000Z',
    companyName: 'Third Party Name',
    orderItemCount: 4,
  },
];

export const reusableItems = [
  {
    itemId: 1001,
    itemDescription: '500 Watt Outlet',
    quantity: 1,
    itemImageName: '120v.png',
  },
  {
    itemId: 1002,
    itemDescription: '1000 Watt Outlet',
    quantity: 2,
    itemImageName: '120v.png',
  },
  {
    itemId: 1003,
    itemDescription: '1500 Watt Outlet',
    quantity: 3,
    itemImageName: '120v.png',
  },
];
