import { useQuery } from '@tanstack/react-query';

import { getEventStatus } from 'data/events';

const useEventStatus = (eventId, options = {}) => {
  const {
    isLoading,
    data: status = {},
    error,
    refetch,
  } = useQuery(['eventOffline', eventId], () => getEventStatus(eventId), {
    enabled: Boolean(eventId),
    cacheTime: 0,
    refetchOnMount: true,
    ...options,
  });

  return {
    isLoading,
    isOffline: status.isOffline,
    isOnline: status.isOnline,
    error,
    refetch,
  };
};

export default useEventStatus;
