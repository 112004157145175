import { useMemo } from 'react';
import cx from 'classnames';

import { COLOR } from 'common/const';
import { currency } from 'common/utils';

import styles from './ProductPrices.module.css';

const ProductPrices = ({ className, currentPrice, originalPrice, inline }) => {
  const isOnSale = useMemo(
    () => currentPrice < originalPrice,
    [currentPrice, originalPrice],
  );

  return (
    <ul
      className={cx(className, {
        [styles.inline]: inline,
        [styles.column]: !inline,
      })}
    >
      <li
        className={cx({
          [COLOR.ERROR]: isOnSale,
          [styles.sale]: isOnSale,
        })}
        aria-label="current price"
      >
        {currency(currentPrice)}
      </li>

      {isOnSale && (
        <li
          className={cx(COLOR.GREY_600, styles.retail)}
          aria-label="original price"
        >
          <s>{currency(originalPrice)}</s>
        </li>
      )}
    </ul>
  );
};

export default ProductPrices;
