import scrubString from './scrubString';

const decoratePhone = (phoneNumber) => {
  // console.log('!decoratePhone', { phoneNumber });

  // if it's not a 10-digit string or number, return the original number
  const str =
    typeof phoneNumber === 'number' ? phoneNumber.toString() : phoneNumber;

  if (typeof str !== 'string') {
    return phoneNumber;
  }

  // if it's not a 10-digit string after cleaning, return the original number
  const cleanNumber = scrubString(str);

  // return decorated string based on length
  switch (cleanNumber.length) {
    case 10:
      const areaCode = cleanNumber.substring(0, 3);
      const exchangeCode = cleanNumber.substring(3, 6);
      const lineNumber = cleanNumber.substring(6);

      return `(${areaCode}) ${exchangeCode}-${lineNumber}`;
    default:
      return phoneNumber;
  }
};

export default decoratePhone;
