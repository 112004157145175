import { useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';

import { useAuth, useOrders, useShop } from 'app/context';
import { Content, Require } from 'common/components';
import { Page } from 'common/site';
import { ROUTE } from 'common/const';
import { initializeRevision } from 'data/orders';

import styles from './ReviseOrder.module.css';

const ReviseOrder = () => {
  const { id } = useParams();
  const { customerId } = useAuth();
  const { findOrder, isFetching, updateOrderItems } = useOrders();
  const shop = useShop();

  const order = useMemo(() => findOrder(id) ?? {}, [findOrder, id]);

  // initialize revision
  const { mutate: initialize, error } = useMutation(
    () =>
      initializeRevision({
        customerId,
        eventId: order.eventId,
        empOrderId: order.empOrderId,
        exhId: order.exhId,
      }),
    {
      onSuccess: ({ reviseOoId, cartItems }) => {
        updateOrderItems({ empOrderId: order.empOrderId, cartItems });
        shop.reset({
          eventId: order.eventId,
          reviseOoId,
          ooId: id,
          empOrderId: order.empOrderId,
        });
      },
    },
  );

  useEffect(() => {
    if (!shop.reviseOoId) initialize();
  }, [shop, initialize]);

  return (
    <Require data={[id]}>
      {shop.reviseOoId && !isFetching ? (
        <Redirect to={ROUTE.CATALOG} />
      ) : (
        <Page className={styles.page}>
          <Content error={error?.message} isLoading={!error || isFetching} />
        </Page>
      )}
    </Require>
  );
};

export default ReviseOrder;
