import { Carousel, Dialog } from 'common/components';
import { useState } from 'react';

import { TEXT } from 'common/const';
import { NewFeature } from 'common/components';

import CancelReviseFeature from './CancelReviseFeature.png';
import ReuseFeature from './ReuseFeature.png';

import styles from './NewFeatureDialog.module.css';

const NEW_FEATURE = 'hideCancelReviseReuse';
// const OLD_FEATURE = '';

const NewFeatureDialog = () => {
  // Delete Old Feature from session storage
  // localStorage.removeItem(OLD_FEATURE);

  const [isOpen, setIsOpen] = useState(!localStorage.getItem(NEW_FEATURE));
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Dialog
      id="new-order"
      title="What's New"
      size="sm"
      isOpen={isOpen}
      close={() => {}}
      onSubmit={() => {
        localStorage.setItem(NEW_FEATURE, true);
        setIsOpen(false);
      }}
      submitText="Dismiss"
      submitTextButton
    >
      <Carousel
        stepCount={2}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        onSubmit={() => {
          localStorage.setItem(NEW_FEATURE, true);
          setIsOpen(false);
        }}
      >
        {activeStep === 0 && (
          <NewFeature
            text="Need to change your order? You can revise a portion of your order or cancel it entirely."
            image={
              <img
                src={CancelReviseFeature}
                alt="CancelImage"
                className={styles.featureImage}
              />
            }
          >
            <div className={styles.alertText}>
              <p>Steps to get started:</p>
              <ol className={styles.list}>
                <li>Find the order you want to change.</li>
                <li>
                  Click either the{' '}
                  <span className={TEXT.BOLD}>Revise Order</span> button or the{' '}
                  <span className={TEXT.BOLD}>Cancel Order</span> button and
                  follow the prompts.
                </li>
              </ol>
              <p>Note: This feature only applies to Active Events.</p>
            </div>
          </NewFeature>
        )}
        {activeStep === 1 && (
          <NewFeature
            text="Place orders often? Now you can copy an order from a previous event."
            image={
              <img
                src={ReuseFeature}
                alt="CancelImage"
                className={styles.featureImage}
              />
            }
          >
            <div className={styles.alertText}>
              <p>How to reorder:</p>
              <ol className={styles.list}>
                <li>Start a New Order and enter your booth information.</li>
                <li>Eligible previous orders will be displayed.</li>
                <li>
                  Select the order you want to copy and it will automatically be
                  added to your cart.
                </li>
              </ol>
            </div>
          </NewFeature>
        )}
      </Carousel>
    </Dialog>
  );
};

export default NewFeatureDialog;
