import { useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import { useMutation, useQuery } from '@tanstack/react-query';
import cx from 'classnames';

import { BORDER, TEXT } from 'common/const';
import { Button, TextLink, FileUpload, Content } from 'common/components';
import { getBaseUrl } from 'common/utils';
import {
  updateFloorPlan,
  getDocumentIdByOrder,
  getDocumentIdByEmpOrderId,
} from 'data/documents';

import styles from './UploadFloorPlan.module.css';

const BASE_URL = getBaseUrl(2);

const UploadFloorPlan = ({
  customerId,
  exhId,
  eventId,
  ooId,
  setIsFloorPlanUploaded,
  isFloorPlanUploaded,
  reupload,
}) => {
  const [error, setError] = useState(null);
  const [{ floorPlanExists, documentId }, setState] = useState({
    floorPlanExists: isFloorPlanUploaded,
    documentId: null,
  });

  const { refetch: getDocumentId, isFetching: isLoadingDocumentId } = useQuery(
    ['getDocumentIdByOrder'],
    () =>
      exhId
        ? getDocumentIdByEmpOrderId({ eventId, exhId })
        : getDocumentIdByOrder({ eventId, ooId }),
    {
      onSuccess: (documents) => {
        if (documents[0]?.id) {
          setState({ floorPlanExists: true, documentId: documents[0].id });
          if (setIsFloorPlanUploaded)
            setIsFloorPlanUploaded({ isFloorPlanUploaded: true });
        } else {
          setState((data) => ({ ...data, floorPlanExists: false }));
          if (setIsFloorPlanUploaded)
            setIsFloorPlanUploaded({ isFloorPlanUploaded: false });
        }
      },
      onError: (err) => setError(err?.message),
      enabled: false,
    },
  );

  const { mutate: upload, isLoading: isUploading } = useMutation(
    ({ file }) => updateFloorPlan({ customerId, eventId, ooId, file, exhId }),
    {
      onSuccess: () => {
        getDocumentId();
      },
      onError: (err) => setError(err?.message),
    },
  );

  const handleFile = async (input) => {
    const file = input.target.files[0];
    upload({ file });
  };

  useEffect(() => {
    if (floorPlanExists && !documentId) {
      getDocumentId();
    }
  }, [floorPlanExists, documentId, getDocumentId]);

  return (
    <div className={styles.upload}>
      <Content error={error} isLoading={isUploading || isLoadingDocumentId}>
        {floorPlanExists ? (
          <span className={cx(TEXT.BODY_1, styles.links)}>
            <TextLink href={`${BASE_URL}orders2/get_document/` + documentId}>
              View Floor Plan
            </TextLink>
            {reupload && (
              <>
                <div className={cx(BORDER.GREY_200, styles.divider)} />
                <FileUpload onSuccess={handleFile} fileType="application/pdf">
                  <TextLink>Reupload PDF</TextLink>
                </FileUpload>
              </>
            )}
          </span>
        ) : (
          <>
            <FileUpload onSuccess={handleFile} fileType="application/pdf">
              <Button
                className={styles.button}
                outlined
                startIcon="fileUpload"
                component="span"
              >
                Upload PDF
              </Button>
            </FileUpload>
            <div className={cx(TEXT.BODY_2, styles.description)}>
              <p>We accept PDF files under 20 MB.</p>
              <TextLink
                className={cx(TEXT.BOLD, styles.link)}
                href={`https://edlen.com/exhibitor-resources/?exhibitor=floor-plans`}
              >
                Download our floor plans.
              </TextLink>
            </div>
          </>
        )}
      </Content>
    </div>
  );
};

UploadFloorPlan.propTypes = {
  existingUrl: string,
  onSuccess: func,
  setIsFloorPlanUplaoded: func,
  isFloorPlanUploaded: bool,
  isUploading: bool,
  reupload: bool,
};

UploadFloorPlan.defaultProps = {
  reupload: true,
};

export default UploadFloorPlan;
