import { VALID } from 'common/const';

const FIELD = {
  address1: {
    label: 'Address 1',
    validation: VALID.ADDRESS.required('Address is required'),
  },
  address2: {
    label: 'Address 2',
    optional: true,
    placeholder: 'Apartment, Suite, Unit, etc',
    validation: VALID.ADDRESS_2,
  },
  city: {
    label: 'City',
    validation: VALID.CITY.required('City is required'),
  },
  state: {
    label: 'State',
    validation: VALID.US_STATE.required('State is required'),
  },
  zip: {
    label: 'Zip Code',
    validation: VALID.ZIP.required('Zip code is required'),
  },
};

export default FIELD;
