/**
 * This debug is meant to help with debugging. Imagine that!
 * It logs arguments to the console, optionally at various levels.
 *
 * These are meant to only output in "development" environment.
 * If you want to output when not in "dev", then use console instead.
 *
 * This lets us leave helpful debug messages in place when committing.
 * A good example is our validation, which throws an unhelpful error.
 * We don't want this "debug" logging in our tests, or on prod.
 *
 * Note: this destroys the helpful file/line reference in the console,
 * so make sure you include a unique identifier/label for your log.
 */

import { ENV } from 'common/const';

const setup = (level) =>
  ENV.IS_DEV ? (...args) => console[level](...args) : () => null;

// shorthand, for simple log
const debug = setup('log');

// additional levels, for better debugging
['log', 'warn', 'error'].map((level) => (debug[level] = setup(level)));

export default debug;
