import { any, bool, func, oneOf, string } from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import cx from 'classnames';

import { Button, IconButton } from 'common/components';
import { BUTTON_COLORS } from '../../const';
import { COLOR } from 'common/const';

import styles from './Dialog.module.css';

const Dialog = ({
  cancelText = 'Cancel',
  close,
  href,
  id,
  isClosable,
  isOpen,
  onCancel,
  onSubmit,
  size,
  submitColor,
  submitText = 'Continue',
  submitTextButton,
  title,
  to,
  children,
  ...rest
}) => {
  // console.log('<Dialog>', { id, title, submitText, submitColor, isOpen });

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }

    close();
  };

  const handleSubmit = () => {
    onSubmit();
    close();
  };

  return (
    <MuiDialog
      open={isOpen}
      onClose={handleCancel}
      maxWidth={size}
      {...rest}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      {title && <DialogTitle id={`${id}-title`}>{title}</DialogTitle>}

      {children && (
        <DialogContent id={`${id}-description`}>{children}</DialogContent>
      )}

      {close && isClosable ? (
        <IconButton
          aria-label="close"
          className={cx(COLOR.GREY_500, styles.close)}
          onClick={close}
          icon="close"
        />
      ) : null}

      {onCancel || onSubmit ? (
        <DialogActions>
          {onCancel && (
            <Button onClick={handleCancel} size="large" text>
              {cancelText}
            </Button>
          )}

          {onSubmit && (
            <Button
              href={href}
              to={to}
              onClick={handleSubmit}
              size="large"
              color={submitColor}
              autoFocus
              text={submitTextButton}
            >
              {submitText}
            </Button>
          )}
        </DialogActions>
      ) : null}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  cancelText: string,
  close: func,
  href: string,
  id: string.isRequired,
  isOpen: bool,
  onCancel: func,
  onSubmit: func,
  size: oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  submitText: string,
  submitColor: oneOf(BUTTON_COLORS),
  title: string,
  to: string,
  children: any,
};

export default Dialog;
