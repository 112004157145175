import axios from 'axios';
import { useEffect } from 'react';
import { oneOf, string } from 'prop-types';

import { getSiteProps, jwtCookie } from 'common/utils';

import {
  AccountPage,
  BuilderPage,
  CenteredPage,
  CheckoutPage,
  InfoPage,
  IslandPage,
  OrderPage,
  Print,
  ShoppingPage,
} from './variants';
// import withChat from './withChat';

const { TITLE: siteTitle } = getSiteProps();

export const VARIANT = {
  ACCOUNT: 'account',
  BUILDER: 'builder',
  CENTERED: 'centered', // default
  CHECKOUT: 'checkout',
  INFO: 'info',
  ISLAND: 'island',
  ORDER: 'order',
  PRINT: 'print',
  SHOPPING: 'shopping',
};

const Page = ({ variant, title, ...props }) => {
  // console.log('<Page>', { variant, props });

  const jwtToken = jwtCookie.get();
  axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;

  useEffect(() => {
    document.title = ['Online Ordering', siteTitle, title]
      .filter((txt) => Boolean(txt)) // remove undefined & empty strings
      .join(' - ');
  }, [title]);

  switch (variant) {
    case VARIANT.ACCOUNT:
      return <AccountPage {...props} />;
    case VARIANT.BUILDER:
      return <BuilderPage {...props} />;
    case VARIANT.CHECKOUT:
      return <CheckoutPage {...props} />;
    case VARIANT.ISLAND:
      return <IslandPage {...props} title={title} />;
    case VARIANT.ORDER:
      return <OrderPage {...props} />;
    case VARIANT.SHOPPING:
      return <ShoppingPage {...props} />;
    case VARIANT.PRINT:
      return <Print {...props} hideChat="print" />;
    case VARIANT.INFO:
      return <InfoPage {...props} title={title} />;
    default:
      return <CenteredPage {...props} />;
  }
};

Page.propTypes = {
  title: string,
  variant: oneOf(Object.values(VARIANT)),
};

export default Page;
