import { string } from 'prop-types';
import cx from 'classnames';

import { useClick } from 'common/hooks';
import { getSiteOwnership } from 'common/utils';
import { LOGO } from 'common/const';

import styles from './Logo.module.css';

const Logo = ({ name, className, ...rest }) => {
  const logoName = name ?? getSiteOwnership();
  const { src, alt } = LOGO[logoName];

  const { component: Component, ...props } = useClick(rest);

  return (
    <Component {...props} className={cx(styles.wrap, className)}>
      <img className={styles.image} src={src} alt={alt} />
    </Component>
  );
};

Logo.propTypes = {
  name: string,
  className: string,
};

export default Logo;
