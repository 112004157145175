import { useMemo, useState } from 'react';
import { any, bool, func, object, string } from 'prop-types';
import cx from 'classnames';

import { TEXT, VALID } from 'common/const';
import {
  Checkbox,
  CountryInput,
  Form,
  Input,
  SubmitButton,
  Button,
} from 'common/components';
import { useAddress } from 'common/hooks';

import styles from './ContactForm.module.css';

const ContactForm = ({
  buttonText,
  defaultValues: values,
  isSubmitting,
  onSubmit,
  onBack,
  children,
}) => {
  const [saveAddress, setSaveAddress] = useState(false);
  // console.log('<ContactForm>', { buttonText, defaultValues, isSubmitting });

  const {
    AddressItem,
    buildValidation,
    countries,
    countryCode,
    STYLE,
    FIELD,
    INIT_ADDRESS,
    updateCountry,
  } = useAddress(values);
  const { isThirdParty } = values;

  const defaultValues = useMemo(
    () => ({ ...values, countryCode }),
    [values, countryCode],
  );

  const buildThirdPartyValidation = () =>
    isThirdParty
      ? {
          thirdPartyCompanyName: VALID.STRING.required(
            'Company name is required',
          ),
        }
      : {};

  return (
    <Form
      validation={{
        ...buildValidation(),
        ...buildThirdPartyValidation(),
        countryCode: VALID.COUNTRY.required('Country is required'),
        firstName: VALID.FIRST_NAME.required('First name is required'),
        lastName: VALID.LAST_NAME.required('Last name is required'),
        phone: VALID.PHONE_NUMBER.required('Office phone number is required'),
        mobile: VALID.PHONE_NUMBER.required('Mobile number is required'),
        company: VALID.STRING.required('Company is required'),
      }}
      onPass={(data) => {
        // Removing excess fields from forms that do not have them
        // e.g. US state -> Hong Kong
        const address = Object.keys(FIELD).reduce(
          (previous, current) => ({
            ...previous,
            [current]: data[current] ?? '',
          }),
          {
            ...INIT_ADDRESS,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data.mobile,
            phone: data.phone,
            company: data.company,
            countryCode: data.countryCode,
          },
        );

        const thirdPartyData = {
          updateThirdParty:
            data.thirdPartyCompanyName !== defaultValues.thirdPartyCompanyName,
          thirdPartyCompanyName: data.thirdPartyCompanyName,
        };

        // Add built address to default address
        onSubmit({
          data: { ...defaultValues, ...address },
          thirdPartyData,
          saveAddress,
        });
      }}
      onFail={(errors) => {
        // console.error({ errors });
      }}
      isSubmitting={isSubmitting}
      defaultValues={defaultValues}
    >
      {children}

      <fieldset className={styles.fieldset}>
        <legend className={cx(TEXT.SUBTITLE_1, styles.legend)}>
          Personal Information
        </legend>

        <ol>
          <li className={styles.line}>
            <Input name="firstName" label="First Name *" required />
            <Input name="lastName" label="Last Name *" required />
          </li>

          <li className={styles.line}>
            <Input
              name="mobile"
              label="Mobile Number *"
              maxlength={25}
              required
            />
          </li>
        </ol>
      </fieldset>

      <fieldset className={styles.fieldset}>
        <legend className={cx(TEXT.SUBTITLE_1, styles.legend)}>
          Company Information
        </legend>

        <ol>
          <li>
            <Input label="Company Name *" name="company" required />
          </li>
        </ol>
        <ol>
          <li>
            <CountryInput
              label="Country / Region *"
              name="countryCode"
              options={countries}
              defaultValue={countryCode}
              onChange={updateCountry}
              required
            />
          </li>
        </ol>

        <ol className={cx(STYLE.address)}>
          <AddressItem name="address1" />
          <AddressItem name="address2" />
          <AddressItem name="city" />
          <AddressItem name="state" />
          <AddressItem name="zip" />
        </ol>
        <ol>
          <li>
            <Input
              name="phone"
              label="Office Phone Number *"
              maxlength={25}
              required
            />
          </li>
        </ol>
      </fieldset>

      <div className={styles.checkbox}>
        <Checkbox
          name={'saveAddress'}
          label="Save new information to my account"
          defaultChecked={false}
          onChange={(value) => setSaveAddress(value)}
        />
      </div>
      {isThirdParty && (
        <fieldset className={styles.fieldset}>
          <legend className={cx(TEXT.SUBTITLE_1, styles.legend)}>
            Exhibitor Information
          </legend>

          <Input name="thirdPartyCompanyName" label="Company Name *" required />
        </fieldset>
      )}

      <div className={styles.actions}>
        <SubmitButton name="submit" isSubmitting={isSubmitting}>
          {buttonText}
        </SubmitButton>
        <Button text name="back" onClick={onBack}>
          Return to Booth Work
        </Button>
      </div>
    </Form>
  );
};

ContactForm.propTypes = {
  defaultValues: object,
  isSubmitting: bool,
  onSubmit: func,
  buttonText: string,
  children: any,
};

ContactForm.defaultProps = {
  buttonText: 'Submit',
  defaultValues: {},
};

export default ContactForm;
