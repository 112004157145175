import { customerId } from 'data/mock-data';

const mock = () => ({
  data: {
    customerData: {
      id: customerId,
    },
  },
});

export default mock;
