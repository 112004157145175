import { useQuery } from '@tanstack/react-query';

import { getEvent } from 'data/events';

const useEvent = (eventId) => {
  const {
    isLoading,
    data = {},
    error,
    refetch,
  } = useQuery(['eventInfo', eventId], () => getEvent(eventId), {
    enabled: Boolean(eventId),
  });

  return {
    isLoading,
    data,
    error,
    refetch,
  };
};

export default useEvent;
