import cx from 'classnames';
import { number, shape, string, func } from 'prop-types';
import { BORDER, COLOR, TEXT } from 'common/const';

import { Button, Card } from 'common/components';
import { DATE_FORMAT, date } from 'common/utils';
import { useState } from 'react';

import styles from './ReuseCard.module.css';

const ReuseCard = ({ order, onCardClick, onReuse }) => {
  const [hoverItem, setHoverItem] = useState(false);

  const { companyName, ooId, eventName, orderDate, orderItemCount } = order;

  return (
    <div
      onClick={onCardClick}
      className={cx(styles.link, {
        [BORDER.PRIMARY]: hoverItem === ooId,
        [styles.inactive]: hoverItem !== ooId,
      })}
      onMouseLeave={() => setHoverItem(null)}
      onMouseEnter={() => setHoverItem(ooId)}
    >
      <Card className={styles.wrap} variant="outlined">
        <div className={styles.columns}>
          <div className={styles.info}>
            <p className={cx(TEXT.BODY_2, styles.spacing)}>
              Order Date: {date(orderDate).format(DATE_FORMAT.MEDIUM_DATE)}
            </p>

            <div className={styles.spacing}>
              <h3 className={cx(TEXT.SUBTITLE_1, styles.event)}>{eventName}</h3>

              {companyName.length > 0 && (
                <div className={cx(TEXT.BODY_1, COLOR.GREY_600)}>
                  {companyName}
                </div>
              )}
            </div>
            <div>
              <span className={cx(TEXT.BODY_1, COLOR.PRIMARY)}>
                {orderItemCount} items
              </span>
            </div>
          </div>

          <div className={styles.buttons}>
            <Button
              size="medium"
              outlined
              color="info"
              onClick={(e) => {
                e.stopPropagation();
                onReuse();
              }}
            >
              Copy Previous Order
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

ReuseCard.propTypes = {
  order: shape({
    companyName: string,
    ooId: number,
    eventName: string,
    orderDate: string,
    orderItemCount: number,
  }),
  onCardClick: func,
};

export default ReuseCard;
