import { string } from 'prop-types';
import cx from 'classnames';

import { Card } from 'common/components';
import { TEXT } from 'common/const';

import styles from './SectionCard.module.css';

const SectionCard = ({ title, children, ...rest }) => (
  <Card variant="outlined" padded component="section" {...rest}>
    {title && <h1 className={cx(TEXT.H6, styles.header)}>{title}</h1>}

    <div className={cx(styles.content)}>{children}</div>
  </Card>
);

SectionCard.propTypes = {
  title: string,
};

export default SectionCard;
