const EMPTY_CART = {
  ID: 0,
  TOTALS: {
    grandTotal: 0,
    serviceFee: 0,
    subtotal: 0,
    tax: 0,
  },
  ITEMS: [],
};

export default EMPTY_CART;
