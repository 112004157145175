import { useMutation } from '@tanstack/react-query';

import { deleteOrder as deleteOrderData } from 'data/orders';
import { useOrders } from 'app/context';

const useDeleteOrder = ({ onSuccess, id }) => {
  const { refetchIncomplete } = useOrders() ?? {};

  const {
    mutate: deleteOrder,
    isLoading: isDeleting,
    error: deleteError,
  } = useMutation(deleteOrderData, {
    onSuccess: () => {
      refetchIncomplete();
      onSuccess({ id });
    },
  });

  return {
    deleteOrder,
    isDeleting,
    deleteError,
  };
};

export default useDeleteOrder;
