import axios from 'axios';

import { ENV, VALID } from 'common/const';
import { getBaseUrl, log } from 'common/utils';

import mock from './getBankInfo.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get bank info
 * @param ooId - online order id
 * @returns bank data
 */
const getBankInfo = async ({ ooId }) => {
  await VALID.check(ooId, VALID.OO_SUMMARY_ID.required('ooId is required.'));

  try {
    const { data } = ENV.IS_MOCK
      ? mock({ ooId })
      : await axios.get(`${baseUrl}lookup/bank_info/${ooId}`);

    return data.bankData;
  } catch (error) {
    log('getBankInfo', {
      data: { ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getBankInfo;
