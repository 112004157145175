import { number, string } from 'prop-types';
import cx from 'classnames';
import { TextField } from '@mui/material';

import { Icon } from 'common/components';
import { BACKGROUND, BORDER, COLOR } from 'common/const';

import styles from './SearchInput.module.css';

const SearchInput = ({
  id,
  InputProps,
  label,
  maxlength: maxLength, // react can be annoying...
  name,
  placeholder,
  ...rest
}) => {
  // console.log('<SearchInput>', { name, error, rest });

  return (
    <TextField
      // html attributes
      id={id ?? name}
      label={''}
      placeholder={placeholder}
      // options
      className={cx(BACKGROUND.GREY_50, BORDER.GREY_50, styles.input)}
      InputProps={{
        ...InputProps,
        className: cx(BORDER.GREY_50), // set for the fieldset to inherit
        endAdornment: (
          <span className={cx(COLOR.GREY_800, styles.icon)}>
            <Icon name="search" size="medium" />
          </span>
        ),
      }}
      inputProps={{
        maxLength,
        required: false, // remove browser validation
        placeholder: 'Enter event name, venue, location or event ID',
      }}
      aria-label={label}
      // handy defaults
      fullWidth // rely on the wrapper to control width
      autoComplete="on" // turns on auto-fill
      {...rest}
    />
  );
};
SearchInput.propTypes = {
  id: string,
  label: string,
  maxlength: number,
  name: string.isRequired,
  placeholder: string,
};

export default SearchInput;
