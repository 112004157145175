import { Z_INDEX } from 'common/const';

const zIndex = (theme) => ({
  [`.${Z_INDEX.MOBILE_STEPPER}`]: { zIndex: theme.zIndex.mobileStepper },
  [`.${Z_INDEX.SPEED_DIAL}`]: { zIndex: theme.zIndex.speedDial },
  [`.${Z_INDEX.APP_BAR}`]: { zIndex: theme.zIndex.appBar },
  [`.${Z_INDEX.DRAWER}`]: { zIndex: theme.zIndex.drawer },
  [`.${Z_INDEX.MODAL}`]: { zIndex: theme.zIndex.modal },
  [`.${Z_INDEX.SNACKBAR}`]: { zIndex: theme.zIndex.snackbar },
  [`.${Z_INDEX.TOOLTIP}`]: { zIndex: theme.zIndex.tooltip },
});

export default zIndex;
