import axios from 'axios';

import { ENV, VALID } from 'common/const';
import { getBaseUrl, log } from 'common/utils';

import mock from './getCancellationReasons.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get assessment cancellation reasons based given event
 * @param eventId - id for event
 * @returns list of cancellation reasons
 */
const getCancellationReasons = async ({ eventId }) => {
  await VALID.check(eventId, VALID.EVENT_ID.required('eventId is required'));

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}events/cancellation_reasons/${eventId}`);

    const options = [
      { value: -1, label: 'Select a reason' },
      ...data.cancellationReasons.map(({ id, reason }) => ({
        value: id,
        label: reason,
      })),
    ];

    options.push(
      options.splice(options.map(({ value }) => value).indexOf(3), 1)[0],
    );

    return options;
  } catch (error) {
    log('getCancellationReasons', {
      data: { eventId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getCancellationReasons;
