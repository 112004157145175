export const TYPE = {
  LOAD: 'load',
  COVER: 'cover',
  UNCOVER: 'uncover',
};

export const INIT_STATE = {
  chat: undefined,
  coveredBy: new Set(),
  isHidden: false,
};

export const init = (currentState) =>
  Object.assign({}, INIT_STATE, currentState);

const reducer = (state, { type, payload }) => {
  const { coveredBy } = state;

  switch (type) {
    case TYPE.LOAD:
      return {
        ...state,
        chat: payload,
      };
    case TYPE.COVER:
      return {
        ...state,
        coveredBy: coveredBy.add(payload),
        isHidden: true,
      };
    case TYPE.UNCOVER:
      coveredBy.delete(payload); // mutates & returns boolean

      return {
        ...state,
        coveredBy,
        isHidden: coveredBy.size > 0,
      };
    default:
      throw Error('Mismatched type for chat reducer dispatch');
  }
};

export default reducer;
