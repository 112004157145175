import { node, oneOf, oneOfType, string } from 'prop-types';
import { Alert as MuiAlert, AlertTitle } from '@mui/material';

const Alert = ({ title, action, severity = 'info', icon, children }) => {
  // console.log('<Alert>', { title, severity, children });

  return (
    <MuiAlert
      severity={severity}
      icon={icon}
      action={action}
      sx={{
        '& .MuiAlert-icon': {
          alignItems: 'center',
        },
      }}
    >
      <div>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {children}
      </div>
    </MuiAlert>
  );
};

Alert.propTypes = {
  children: oneOfType([node, string]).isRequired,
  severity: oneOf(['error', 'info', 'success', 'warning']),
  title: string,
};

export default Alert;
