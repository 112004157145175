import { merge } from 'lodash';

import ADDRESS from './address';

export const TYPE = {
  LOAD: 'load-countries',
  COUNTRY: 'update-country',
};

const INIT_STATE = {
  countries: [],
  selectedCountry: '',
  states: [],
  DISPLAY: {
    fields: [],
    styles: [],
  },
  FIELD: {
    address1: {},
    address2: {},
    city: {},
    state: {},
    zip: {},
  },
  STYLE: {},
};

const getCountry = ({ countries, selectedCountry, ...rest }) => {
  // console.log('!getCountry', { countries, selectedCountry });

  const { states, code } = countries.find(
    ({ value }) => value === selectedCountry,
  ) ?? { states: [], code: selectedCountry };
  const { DISPLAY, FIELD, STYLE } = Object.assign(
    {},
    ADDRESS.OTHER,
    ADDRESS[code],
  );

  return {
    countries,
    selectedCountry,
    ...rest,
    states,
    DISPLAY,
    FIELD,
    STYLE,
  };
};

export const init = ({ countryCode }) => {
  const initialState = merge({}, INIT_STATE, {
    selectedCountry: countryCode,
  });

  return getCountry({ ...initialState });
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case TYPE.LOAD:
      return getCountry({ ...state, countries: payload });

    case TYPE.COUNTRY:
      return getCountry({ ...state, selectedCountry: payload });

    default:
      throw Error('Mismatched type for useAddress dispatch');
  }
};

export default reducer;
