import { useEffect } from 'react';
import { useState } from 'react';
import { func, string, any, bool } from 'prop-types';
import cx from 'classnames';

import { Requirement } from 'common/components';
import { TEXT } from 'common/const';

import { validatePassword } from './validatePassword';

import styles from './NewPassword.module.css';

const NewPassword = ({ isFailedOnce, password, setIsValid, children }) => {
  const [atLeastEight, setAtLeastEight] = useState(false);
  const [oneUppercase, setOneUppercase] = useState(false);
  const [oneLowercase, setOneLowercase] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneSpecialCh, setOneSpecialCh] = useState(false);

  // Validate the password when it is changed
  useEffect(() => {
    validatePassword(
      password,
      setAtLeastEight,
      setOneUppercase,
      setOneLowercase,
      setOneNumber,
      setOneSpecialCh,
    );
  }, [password]);

  useEffect(() => {
    setIsValid(
      atLeastEight && oneUppercase && oneLowercase && oneNumber && oneSpecialCh,
    );
  }, [
    atLeastEight,
    oneUppercase,
    oneLowercase,
    oneNumber,
    oneSpecialCh,
    setIsValid,
  ]);

  return (
    <div>
      {children}
      <p className={cx(TEXT.BODY_2, styles.title)}>Password must contain:</p>
      <ol className={styles.group}>
        <li>
          <Requirement isFailedOnce={isFailedOnce} isMet={atLeastEight}>
            At least 8 characters
          </Requirement>
        </li>
        <li>
          <Requirement isFailedOnce={isFailedOnce} isMet={oneUppercase}>
            One uppercase letter
          </Requirement>
        </li>
        <li>
          <Requirement isFailedOnce={isFailedOnce} isMet={oneLowercase}>
            One lowercase letter
          </Requirement>
        </li>
        <li>
          <Requirement isFailedOnce={isFailedOnce} isMet={oneNumber}>
            One number
          </Requirement>
        </li>
        <li>
          <Requirement isFailedOnce={isFailedOnce} isMet={oneSpecialCh}>
            One special character _@#.?!/
          </Requirement>
        </li>
      </ol>
    </div>
  );
};

NewPassword.propTypes = {
  children: any,
  isFailedOnce: bool,
  password: string,
  setIsValid: func,
};

export default NewPassword;
