import { createRef, useMemo } from 'react';
import { useParams } from 'react-router';
import { object } from 'prop-types';

import { BOOTH_SETUP, VALID } from 'common/const';
import { remoteSubmit } from 'common/utils';
import {
  Content,
  Form,
  Input as InputComponent,
  Require,
  Select,
} from 'common/components';

import Step from './Step';

import styles from './Input.module.css';

const INPUT_TYPE = {
  ALPHA_NUMERIC: 'Alphanumeric Input',
  ALPHA: 'Alpha Only Input',
  NUMERIC: 'Numeric Only Input',
  DATE: 'Date/Time Input',
};

const Input = ({ findStep }) => {
  // console.log('<Input>', { step });

  const { assessmentId } = useParams();
  const step = findStep(BOOTH_SETUP.INPUT, assessmentId);

  const {
    response,
    options,
    question,
    submit,
    error,
    isLoading,
    isSubmitting,
    back,
    buttonText,
  } = step ?? {};

  const {
    inputHeader,
    inputName,
    isResponseOptional,
    responseType,
    defaultResponseValue,
  } = step?.boothSetupResponseSelections?.singleInputResponseOptions[0] ?? {};

  const getValidationType = (inputType, isResponseOptional) => {
    switch (inputType) {
      case INPUT_TYPE.ALPHA_NUMERIC:
        return isResponseOptional
          ? VALID.STRING
          : VALID.STRING.required('A response is required.');
      case INPUT_TYPE.NUMERIC:
        return isResponseOptional
          ? VALID.NUMBER
          : VALID.NUMBER.required('A response is required.');
      case INPUT_TYPE.ALPHA:
        return isResponseOptional
          ? VALID.ALPHA
          : VALID.ALPHA.required('A response is required.');
      default:
        return isResponseOptional
          ? VALID.STRING
          : VALID.STRING.required('A response is required.');
    }
  };

  const form = createRef();

  // If we get back a list of options, use a Select
  const { Control, ...controlProps } = useMemo(
    () =>
      options?.length
        ? { Control: Select, options }
        : { Control: InputComponent },
    [options],
  );

  return (
    <Require data={[question]}>
      <Content isLoading={!step || isLoading} error={error?.message}>
        <Step
          submit={() => remoteSubmit(form.current)}
          back={back}
          buttonText={buttonText}
          isSubmitting={isSubmitting}
        >
          <h1 className={styles.p}>{question}</h1>
          <p className={styles.p}>{inputHeader}</p>
          <Form
            ref={form}
            defaultValues={{
              inputValue:
                response === defaultResponseValue ? undefined : response,
            }}
            onPass={async (data) => {
              submit(data.inputValue || defaultResponseValue);
            }}
            validation={{
              inputValue: getValidationType(responseType, isResponseOptional),
            }}
          >
            <Control
              {...controlProps}
              name="inputValue"
              label={inputName + (isResponseOptional ? ' (optional)' : '')}
            />
          </Form>
        </Step>
      </Content>
    </Require>
  );
};

Input.propTypes = {
  step: object,
};

export default Input;
