import { useQuery } from '@tanstack/react-query';

import { getAppInfo } from 'data/adapter';

const useAppInfo = () => {
  const { isLoading, error, data } = useQuery(['getAppInfo'], () =>
    getAppInfo(),
  );

  return {
    isLoading,
    data,
    error,
  };
};

export default useAppInfo;
