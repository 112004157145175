import { merge } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useMutation } from '@tanstack/react-query';

import { useCustomer, useShop, useOrders } from 'app/context';
import { findItem, track } from 'common/utils';
import {
  addItemToCart,
  addReviseItemToCart,
  updateReviseQuantity,
  updateQuantity,
} from 'data/orders';

import { getReviseRequiredQuantity } from './util';
import reducer, { TYPE, init } from './reducer';

const Cart = createContext();

export const CartProvider = ({ children }) => {
  const { customerId } = useCustomer();
  const { ooId, eventId, reviseOoId } = useShop();
  const { findOrder, refetchIncomplete, refetchReviseOrder } = useOrders();
  const [cart, dispatch] = useReducer(reducer, init(findOrder(ooId)));

  // update `cart` when `shop` updates
  useEffect(
    () =>
      dispatch({
        type: TYPE.NEW_CART,
        payload: { ooId, eventId },
      }),
    [ooId, eventId],
  );

  const cartCount = useMemo(
    () => cart.items.reduce((count, { quantity }) => count + quantity, 0),
    [cart.items],
  );

  const refreshCart = useCallback(() => {
    // stops all new requests from firing, until the cart is refreshed
    dispatch({
      type: TYPE.REFRESH,
    });

    const refetchOrders = async () => {
      const { data: refetchedOrders } = reviseOoId
        ? await refetchReviseOrder()
        : await refetchIncomplete();

      const order = findOrder(ooId, refetchedOrders);

      // allows queued requests to start firing
      dispatch({
        type: TYPE.REFRESH_SETTLED,
        payload: { order },
      });
    };

    refetchOrders();
  }, [findOrder, ooId, reviseOoId, refetchIncomplete, refetchReviseOrder]);

  useEffect(() => {
    // don't refresh until all requests are complete
    if (
      cart.isTouched &&
      !Object.keys(cart.queue).length &&
      !cart.unsettled.length
    ) {
      refreshCart();
    }
  }, [cart.isTouched, cart.queue, cart.unsettled, refreshCart]);

  const { mutate: updateItem, isLoading: isUpdating } = useMutation(
    ({ item, quantity }) => {
      // console.log('!updateItem', { item, quantity });

      dispatch({
        type: TYPE.UPDATE,
        payload: { item },
      });

      return Boolean(reviseOoId)
        ? findItem(cart?.revisedItems, item)
          ? // items already in the revisedItems
            updateReviseQuantity(
              { ...cart, customerId, reviseOoId },
              { ...item },
              quantity,
            )
          : addReviseItemToCart(
              { ...cart, customerId, reviseOoId },
              { ...item, quantity },
            )
        : Boolean(item?.cartId) // items already in the cart have a cartId
        ? updateQuantity({ ...cart, customerId }, item, quantity)
        : addItemToCart({ ...cart, customerId }, { ...item, quantity });
    },
    {
      onSuccess: ({ item }) => {
        // console.log('!updateItem.onSuccess', { item });

        reviseOoId
          ? dispatch({ type: TYPE.REVISE_UPDATE_SETTLED, payload: { item } })
          : dispatch({
              type: TYPE.UPDATE_SETTLED,
              payload: { item },
            });
      },

      onError: (error) => {
        dispatch({
          type: TYPE.UPDATE_ERROR,
          payload: { error: error.message, item: error.cause?.data?.item },
        });
      },
    },
  );

  // watch queue for changes, then update
  useEffect(() => {
    // console.log('cart.queue', cart.queue);

    // if cart is refreshing, bail
    if (cart.isRefreshing) {
      return;
    }

    // TODO! - Add isEarlyPricing in when feature is added
    const next = Object.values(cart.queue).find(
      (item) => !cart.unsettled.includes(item.id + '-' + item.isRequired),
    );

    if (next) {
      // NOTE: the item in the queue could be from cart OR catalog,
      // so merge with the cartItem when updating to have all info.
      const item = merge({}, next, findItem(cart.items, next));

      updateItem({
        item: { ...item, isRequired: next.isRequired },
        quantity: next.quantity,
      });

      track('add_to_cart', {
        items: [{ item_id: item.id, item_name: item.name }],
      });
    }
  }, [cart.queue, cart.items, cart.unsettled, cart.isRefreshing, updateItem]);

  const updateCart = useCallback((item, quantity) => {
    // console.log('!updateCart', { item, quantity }, item?.id, item?.quantity);

    // Revise

    // TODO! - Early Pricing Quantity Update (Useful if feature is added)
    // if (!cart.isEarlyPricing) {
    //   if (item.advancedPrice !== item.floorPrice) {
    //     // After Advanced Deadline Date and the item was originally ordered with early pricing
    //     const { originalQuantity = 0, quantity: earlyQuantity = 0 } =
    //       findItem(cart.items, {
    //         ...item,
    //         isEarlyPricing: true,
    //       }) ?? {};

    //     const {
    //       originalPricingUpdate: earlyPricingUpdate,
    //       currentPricingUpdate: floorPricingUpdate,
    //     } = getReviseQuantity({
    //       newQuantity: quantity,
    //       currentQuantity: item.quantity,
    //       originalQuantity: originalQuantity,
    //     });

    //     if (earlyPricingUpdate) {
    //       dispatch({
    //         type: TYPE.QUEUE,
    //         payload: {
    //           item: { ...item, isEarlyPricing: true },
    //           quantity: earlyPricingUpdate + earlyQuantity,
    //         },
    //       });
    //     }

    //     // Update the floor pricing
    //     const { quantity: floorQuantity = 0 } =
    //       findItem(cart.items, { ...item, isEarlyPricing: false }) ?? {};

    //     if (floorPricingUpdate) {
    //       dispatch({
    //         type: TYPE.QUEUE,
    //         payload: {
    //           item: { ...item, isEarlyPricing: false },
    //           quantity: floorPricingUpdate + floorQuantity,
    //         },
    //       });
    //     }
    //   } else {
    //     dispatch({
    //       type: TYPE.QUEUE,
    //       payload: {
    //         item: { ...item, isEarlyPricing: item.isEarlyPricing },
    //         quantity,
    //       },
    //     });
    //   }
    // } else {

    // isRequired determination
    // }
    // Normal Incomplete Order

    const newQuantity = getReviseRequiredQuantity({
      newQuantity: quantity,
      requiredQuantity: item?.requiredQuantity,
    });

    dispatch({
      type: TYPE.QUEUE,
      payload: { item, quantity: newQuantity },
    });
  }, []);

  // Return
  return (
    <Cart.Provider
      value={{
        error: cart.error,
        count: cartCount,
        items: cart.items,
        itemDifferences: cart.itemDifferences,
        totals: {
          subtotal:
            cart.totals.subtotal + (cart?.itemDifferencesTotals?.subtotal ?? 0),
        },
        updateCart,
        isUpdating,
      }}
    >
      {children}
    </Cart.Provider>
  );
};

export const useCart = () => useContext(Cart);
