import { func } from 'prop-types';
import cx from 'classnames';

import {
  AddressForm,
  PrivacyPolicyLink,
  TermsOfUseLink,
} from 'common/components';
import { TEXT } from 'common/const';
import styles from './Signup.module.css';

const SignupAddress = ({ onSubmit }) => {
  return (
    <AddressForm onSubmit={onSubmit} buttonText="Sign Up">
      <p className={cx(TEXT.CAPTION, styles.terms)}>
        By signing up, you agree to our <TermsOfUseLink /> and{' '}
        <PrivacyPolicyLink />.
      </p>
    </AddressForm>
  );
};

SignupAddress.propTypes = {
  onSubmit: func,
};

export default SignupAddress;
