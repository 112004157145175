import axios from 'axios';
import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

const baseUrl = getBaseUrl(2);

/**
 * Updates a booths floor plan
 * @param customerId - customer id
 * @param eventId - id for the event
 * @param ooId - online order id
 * @param file - file data
 * @returns "Successful" string
 */

const updateFloorPlan = async ({ customerId, eventId, ooId, file, exhId }) => {
  await VALID.checkAll(
    ['customerId', customerId, VALID.CUSTOMER_ID],
    ['eventId', eventId, VALID.EVENT_ID],
    ['ooId', ooId, VALID.OOID],
    ['file size', file.size, VALID.FILE_SIZE(200)],
    ['file type', file.type, VALID.FILE_TYPE('application/pdf')],
  );
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('customerId', customerId);
    formData.append('eventId', eventId);
    formData.append('ooId', ooId);
    formData.append('exhId', exhId);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    if (!ENV.IS_MOCK) {
      await axios.post(`${baseUrl}document/upload/`, formData, config);
    }
  } catch (error) {
    log('updateFloorPlan', {
      data: { customerId, eventId, ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default updateFloorPlan;
