export const BREADCRUMBS = {
  CATALOG: 'Cart',
  BOOTH_QUESTIONS: 'Booth Work',
  CONTACT: 'Contact',
  PAYMENT: 'Payment',
};

export const STEP = {
  CATALOG: 'catalog',
  BOOTH_QUESTIONS: 'booth-questions',
  CONTACT: 'contact',
  PAYMENT: 'payment',
};

export const ORDER = {
  ADDRESS: 'addressId',
  BILLING: 'billingAddressId',
  CART: 'ooId',
  CONTACT: 'contact',
  CUSTOMER: 'customerId',
  EVENT: 'eventId',
  EXHIBITOR: 'exhibitorAddressId',
  IS_THIRD_PARTY: 'isThirdParty',
  MERCHANT: 'merchantId',
  PAYMENT: 'paymentData',
  TOTALS: 'totals',
};

export const PAYMENT = {
  CVV: 'cvv',
  NAME_ON_CARD: 'name',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  CITY: 'city',
  STATE: 'state',
  ZIP: 'zip',
  COUNTRY: 'country',
  EMAIL: 'email',
  ACCOUNT_NUMBER: 'accountNumber',
  COMPANY_NAME: 'companyName',
  EXPIRATION: 'expiration',
  ACCOUNT_NUMBER_EXP_MM: 'accountNumberExpMM',
  ACCOUNT_NUMBER_EXP_YY: 'accountNumberExpYY',
  TYPE: 'type',
  LAST_4_DIGIT: 'last4Digit',
  METHOD: 'method',
  AMOUNT: 'amount',
  DATE: 'date',
  STATUS: 'status',
};
