import { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { func, object } from 'prop-types';

import {
  BORDER,
  COLOR,
  PAYMENT_DESCRIPTION,
  PAYMENT_METHOD,
  ROUTE,
  TEXT,
} from 'common/const';
import { useCartTotals, useEvent, useEventStatus } from 'common/hooks';
import { processCartPayment } from 'data/orders';
import {
  date,
  DATE_FORMAT,
  track,
  scrubString,
  getCreditCard,
  decoratePhone,
} from 'common/utils';

import cx from 'classnames';
import styles from './Review.module.css';
import {
  AddressDisplay,
  Button,
  Content,
  EventOfflineDialog,
  PrivacyPolicyLink,
  SubmitButton,
  TermsOfUseLink,
  UploadFloorPlan,
  SectionHeader,
  BoothSetup,
  EventDates,
} from 'common/components';
import { useOrders } from 'app/context';

const Review = ({ state, setState }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { findOrder, refetchIncomplete } = useOrders();
  const order = findOrder(state.ooId);
  const history = useHistory();

  const { data } = useEvent(state.eventId);
  const {
    isWireTransferTaxable,
    isServiceFeeTaxable,
    salesTax,
    dateStart,
    dateEnd,
    facilityName,
    name,
  } = data;

  const { recalculateTotals } = useCartTotals();
  const { refetch: refetchEventStatus } = useEventStatus(state.eventId);

  const { total, balanceDue, ccFee, serviceFee, tax, wireFee, subtotal } =
    recalculateTotals({
      paymentMethod: state.paymentMethod,
      totals: state.itemDifferencesTotals,
      isWireTransferTaxable,
      isServiceFeeTaxable,
      salesTax,
    });

  const description = PAYMENT_DESCRIPTION[state.paymentMethod];

  const accountNumber = scrubString(state?.paymentData?.accountNumber);
  const last4 = accountNumber?.substring(accountNumber?.length - 4);

  const { alt, logo, db } = getCreditCard(accountNumber);

  const { mutate: placeOrder, isLoading: isSubmitting } = useMutation(
    processCartPayment,
    {
      onSuccess: () => {
        state.reviseOoId
          ? track('revise_purchase', {
              currency: 'USD',
              transaction_id: order.id,
              value: balanceDue,
            })
          : track('purchase', {
              currency: 'USD',
              transaction_id: order.id,
              value: total,
            });

        history.push(ROUTE.CONFIRMATION + '/' + state.ooId);
      },
      onError: (err) => {
        refetchIncomplete();
        setState({ currentStep: 3, error: err.message });
        history.push(ROUTE.PAYMENT);
      },
    },
  );

  const { mutate, isLoading: isFetchingEventStatus } = useMutation(
    refetchEventStatus,
    {
      onSuccess: ({ data }) => {
        if (data.isOffline) {
          setIsOpen(true);
        } else {
          placeOrder({
            ...state,
            accountNumber,
            last4,
            type: db,
            date: date().format(DATE_FORMAT.SERVER_DATE),
            total: state.reviseOoId ? balanceDue : total,
            reviseTotal: state.reviseOoId
              ? state.paymentMethod === PAYMENT_METHOD.CARD
                ? balanceDue - ccFee
                : balanceDue
              : 0,
            empOrderId: order.empOrderId,
            totals: { ccFee, serviceFee, tax, wireFee, subtotal },
          });
        }
      },
    },
  );

  return (
    <Content>
      <h6 className={cx(TEXT.H6, styles.header)}>Review Your Order</h6>

      <div className={styles.group}>
        <p className={cx(TEXT.H6, styles.title)}>Event Information</p>

        <section className={styles.section}>
          <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>{name}</p>
          <p>{facilityName}</p>
          <EventDates start={dateStart} end={dateEnd} />
        </section>
      </div>

      <div className={cx(COLOR.GREY_100, styles.line)} />

      <div className={styles.group}>
        <SectionHeader
          action={
            state.boothQuestions?.length > 0 || state.showFileUpload
              ? {
                  onClick: () => {
                    setState({ currentStep: 1 });
                    history.push(ROUTE.BOOTH_WORK);
                  },
                }
              : null
          }
        >
          <p className={cx(TEXT.H6, styles.title)}>Booth</p>
        </SectionHeader>

        <section className={styles.section}>
          <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>Booth Setup</p>
          <BoothSetup
            booth={order?.booth}
            showNumber={order?.booth.number !== '1'}
            inline
            isOrder
          />

          <ol>
            {order.assessmentResponseData.map(({ question, assessmentId }) => {
              return assessmentId === 12 ? (
                <li key={assessmentId} className={styles.item}>
                  <div className={styles.align}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: question.slice(3, question.indexOf('<br>') - 4),
                      }}
                    ></p>
                    <strong className={TEXT.BOLD}>
                      {state.isThirdParty ? 'Yes' : 'No'}
                    </strong>
                  </div>
                </li>
              ) : null;
            })}
          </ol>
        </section>

        <section className={styles.section}>
          <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>Booth Work</p>
          {state.boothQuestions?.length === 0 && !state.showFileUpload ? (
            <p>
              Because some Booth Setup details are not available, there are no
              questions at this time. A member of the Edlen team will contact
              you to confirm your booth details.
            </p>
          ) : null}
          <ol className={styles.list}>
            {state.boothQuestions.map(
              ({ question, response, assessmentId, assessmentType }) => {
                // return assessmentId > 4 && assessmentId !== 12 ? (
                return assessmentType === 'Booth Work' ? (
                  <li key={assessmentId}>
                    <p>
                      {question}{' '}
                      <strong className={TEXT.BOLD}>
                        {response ? response : 'N/A'}
                      </strong>
                    </p>
                  </li>
                ) : null;
              },
            )}
            {state.showFileUpload && (
              <li>
                <p>
                  Build your own booth floor plan or upload a PDF. Your booth
                  floor plan must show locations of all services ordered. Labor
                  and material charges apply. You can upload a booth floor plan
                  later in 'Order Details'.{' '}
                  {!state.isFloorPlanUploaded && (
                    <strong className={TEXT.BOLD}>N/A</strong>
                  )}
                </p>
                {state.isFloorPlanUploaded && (
                  <UploadFloorPlan
                    ooId={state.ooId}
                    customerId={state.customerId}
                    eventId={state.eventId}
                    setIsFloorPlanUploaded={setState}
                    isFloorPlanUploaded={state.isFloorPlanUploaded}
                    reupload={false}
                  />
                )}
              </li>
            )}
          </ol>
        </section>
      </div>

      <div className={cx(COLOR.GREY_100, styles.line)} />

      <div className={styles.group}>
        <SectionHeader
          action={{
            onClick: () => {
              setState({ currentStep: 2 });
              history.push(ROUTE.CONTACT);
            },
          }}
        >
          <p className={cx(TEXT.H6, styles.title)}>Contact</p>
        </SectionHeader>

        <section className={styles.section}>
          <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>
            Personal Information
          </p>
          <ol>
            <li>
              {state.contactAddress.firstName} {state.contactAddress.lastName}
            </li>
            <li>{decoratePhone(state.contactAddress.mobile)}</li>
          </ol>
        </section>
        {(state.contactAddress.company ||
          Object.values(state.contactAddress).some((val) => Boolean(val))) && (
          <section className={styles.section}>
            <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>
              {state.contactAddress.company} Information
            </p>
            <AddressDisplay
              address={state.contactAddress}
              displayPhone={true}
            />
          </section>
        )}

        {state.isThirdParty && (
          <section className={styles.section}>
            <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>
              Exhibitor Information
            </p>
            <p>{state.thirdPartyCompanyName}</p>
          </section>
        )}
      </div>

      <div className={cx(COLOR.GREY_100, styles.line)} />

      {accountNumber ? (
        <div className={styles.group}>
          <SectionHeader
            action={{
              onClick: () => {
                setState({ currentStep: 3 });
                history.push(ROUTE.PAYMENT);
              },
            }}
          >
            <p className={cx(TEXT.H6, styles.title)}>Payment</p>
          </SectionHeader>

          <section className={styles.section}>
            <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>
              Payment Method
            </p>
            {description && <p>{description}</p>}
            {state.paymentData && (
              <p>
                {state.paymentData.firstName} {state.paymentData.lastName}
              </p>
            )}
            {logo && (
              <p className={styles.card}>
                <span>{alt && logo && <img alt={alt} src={logo} />}</span>
                <span>•••• {last4}</span>
                <span>Exp. {state.paymentData.expiration}</span>
              </p>
            )}
          </section>

          <section>
            <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>
              Billing Information
            </p>
            <AddressDisplay address={state.billingAddress} />
          </section>
        </div>
      ) : null}
      <div className={cx(styles.actions, BORDER.GREY_100)}>
        <div className={styles.buttons}>
          <Button
            text
            name="back"
            onClick={() => {
              setState({ currentStep: 3 });
              history.push(ROUTE.PAYMENT);
              track('review_back_click');
            }}
          >
            Return to Payment
          </Button>
          <SubmitButton
            name="submit"
            isSubmitting={isSubmitting || isFetchingEventStatus}
            disabled={order?.items.length === 0}
            onClick={() => {
              mutate();
              track('submit_order_click');
            }}
          >
            {state.reviseOoId ? 'Revise Order' : 'Place Order'}
          </SubmitButton>
        </div>
        <legend className={cx(TEXT.CAPTION, COLOR.GREY_600, styles.legend)}>
          By placing this order, you agree to our <TermsOfUseLink /> and{' '}
          <PrivacyPolicyLink />.
        </legend>
      </div>
      <EventOfflineDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Content>
  );
};

Review.propTypes = {
  setState: func,
  state: object,
};

export default Review;
