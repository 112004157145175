import { eventData, webServerDateTime } from 'data/mock-data';

const mock = () => ({
  data: {
    eventData: eventData[0],
    webServerDateTime,
  },
});

export default mock;
