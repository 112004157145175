import React, { useEffect } from 'react';
import cx from 'classnames';

import { AnchorButton, Content, Faqs } from 'common/components';
import { COLOR, ROUTE, TEXT } from 'common/const';
import { useState } from 'react';
import { Page } from 'common/site';

import { getFaqs } from 'data/lookup';

import styles from './Faq.module.css';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';

const Faq = () => {
  const [selectedIndex, setSelectedIndex] = useState(
    decodeURI(window.location.hash.substring(1)),
  );

  const { hash } = useLocation();

  const {
    isLoading,
    data: faqs = {},
    error,
  } = useQuery(['getFaqs'], () => getFaqs());

  // Scroll to anchor in url on load
  useEffect(() => {
    if (!isLoading && !error && hash.length) {
      const element = document.getElementById(decodeURI(hash).substring(1));
      // Offset the top by 4rem
      window.scrollTo({ top: element.offsetTop - 4 * 16, behavior: 'smooth' });
    }
  });

  return (
    <Page
      variant="info"
      title="Frequently Asked Questions"
      isLoading={isLoading}
      error={error}
      sidebar={
        <div className={styles.sidebar}>
          <p className={cx(TEXT.CAPTION, COLOR.GREY_600)}>Sections</p>
          <ul id="sectionButtons">
            {Object.keys(faqs).map((heading, index) => (
              <li key={index + heading}>
                <AnchorButton
                  component="a"
                  href={`${ROUTE.FAQ}#${heading}`}
                  onClick={() => setSelectedIndex(heading)}
                  className={cx(
                    { [styles.active]: selectedIndex === heading },
                    styles.button,
                  )}
                  selected={selectedIndex === heading}
                >
                  <p className={cx(TEXT.BUTTON, COLOR.TEXT, styles.text)}>
                    {heading}
                  </p>
                </AnchorButton>
              </li>
            ))}
          </ul>
        </div>
      }
    >
      <Content error={error?.message}>
        <Faqs className={styles.section} faqs={faqs} />
      </Content>
    </Page>
  );
};

export default Faq;
