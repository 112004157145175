import { any, bool, func, object, string } from 'prop-types';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { startCase } from 'lodash';

const Checkbox = ({
  defaultChecked,
  disabled,
  inputProps,
  label,
  labelProps,
  name,
  onChange,
  required,
  value,
  ...rest
}) => {
  // console.log('<Checkbox>', { name, defaultChecked, value, disabled, rest });

  const { onChange: inputPropsOnChange, ...props } = inputProps; // remove onChange

  return (
    <FormControlLabel
      disabled={disabled}
      label={label ?? startCase(name)}
      {...labelProps}
      control={
        <MuiCheckbox
          defaultChecked={defaultChecked}
          onChange={(evt) => {
            if (typeof inputPropsOnChange === 'function') {
              inputPropsOnChange(evt);
            }
            onChange(evt, {
              isChecked: evt.target.checked,
              name,
            });
          }}
          required={required}
          value={value}
          inputProps={props}
          {...rest}
        />
      }
    />
  );
};
Checkbox.propTypes = {
  defaultChecked: bool,
  disabled: bool,
  inputProps: object,
  label: string,
  name: string.isRequired,
  onChange: func,
  required: bool,
  value: any,
};

Checkbox.defaultProps = {
  inputProps: {},
  onChange: () => null,
};

export default Checkbox;
