import { oneOf, string } from 'prop-types';
import { Avatar as MuiAvatar } from '@mui/material';

import { TEXT } from 'common/const';

const Avatar = ({ size = 96, variant, children }) => {
  // console.log('$Avatar', size, variant, children);

  // Color created randomly from the string
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const text =
    children.split(' ').length === 1
      ? // One word name so use the first two letters
        children.slice(0, 2).toUpperCase()
      : // Get first letter of first two words
        children
          .split(' ')
          .map((word) => word[0])
          .join('')
          .slice(0, 2)
          .toUpperCase();

  return (
    <MuiAvatar
      variant={variant}
      sx={{
        bgcolor: stringToColor(children) + '1A',
        border: 1,
        borderColor: stringToColor(children),
        color: stringToColor(children),
        width: '6rem',
        height: '6rem',
      }}
    >
      <div className={TEXT.H4}>{text}</div>
    </MuiAvatar>
  );
};

Avatar.propTypes = {
  variant: oneOf(['circular', 'rounded', 'square']),
  children: string,
};

export default Avatar;
