import { VALID } from 'common/const';

const FIELD = {
  address1: {
    label: 'Address 1',
    placeholder: 'Street Name, Street Number, etc',
    validation: VALID.ADDRESS.required('Address is required'),
  },
  address2: {
    label: 'Address 2',
    optional: true,
    validation: VALID.ADDRESS_2,
  },
  city: {
    label: 'City',
    validation: VALID.CITY.required('City is required'),
  },
  state: {
    label: 'Canton',
    validation: VALID.PROVINCE.required('Canton is required'),
  },
  zip: {
    label: 'Postal Code',
    validation: VALID.POSTAL_CODE.required('Postal code is required'),
  },
};

export default FIELD;
