import { oneOf, string } from 'prop-types';
import { IconButton as MuiIconButton } from '@mui/material';

import { Icon } from 'common/components';
import { useClick } from 'common/hooks';

import { BUTTON_SIZES } from '../../const';

const IconButton = ({ icon, size = 'medium', ...rest }) => {
  // console.log('<IconButton>', { icon, size, ...rest });

  const props = useClick(rest);

  return (
    <MuiIconButton
      size={size}
      {...props} // goes last so you can override defaults
    >
      <Icon name={icon} size={size} />
    </MuiIconButton>
  );
};

IconButton.propTypes = {
  icon: string.isRequired, // `name` prop of the Icon
  size: oneOf(BUTTON_SIZES),
};

export default IconButton;
