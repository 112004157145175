import axios from 'axios';
import { ENV, VALID } from 'common/const';
import { getBaseUrl, log, scrubString } from 'common/utils';

import { ORDER } from 'routes/checkout/const';

import mock from './updateAddress.mock';

const baseUrl = getBaseUrl(2);

/**
 * Updates an address
 * @param {object} param0 - contains address data and a customerId
 * @returns ID of updated address
 */
const updateAddress = async ({ type, address, customerId, ooId }) => {
  await VALID.checkAll(
    ['customerId', customerId, VALID.CUSTOMER_ID],
    ['ooId', ooId, VALID.OO_SUMMARY_ID],
    ['type', type, VALID.ADDRESS_TYPE],
    ['address', address, VALID.OBJECT],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.post(`${baseUrl}orders/update_address`, {
          address: {
            ...address,
            mobile: scrubString(
              address.mobile.length ? address.mobile : address.phone,
            ),
            phone: scrubString(address.phone),
          },
          customerId,
          ooId,
          type,
        });

    return {
      ...address,
      customerId,
      ooId,
      [ORDER.ADDRESS]: data.addressId,
    };
  } catch (error) {
    log('updateAddress', {
      data: { type, address, customerId, ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default updateAddress;
