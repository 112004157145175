const ROUTE = {
  // onboarding
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SSO: '/sso',

  // account
  DASHBOARD: '/dashboard',
  ACCOUNT: '/account',
  NOTIFICATIONS: '/notifications',

  // orders
  CANCEL: '/cancel',
  ORDER_DETAILS: '/order-details',
  ORDER_INVOICE: '/order-invoice',
  ORDERS: '/orders',
  REVISE_ORDER: '/revise',

  // new order
  NEW_ORDER: '/new-order',
  EVENT_SEARCH: '/event-search',

  // booth setup
  START_ORDER: '/start-order',
  RESUME_ORDER: '/resume-order',
  BOOTH_SETUP: '/booth',
  THIRD_PARTY: '/booth/third-party',
  BOOTH_NUMBER: '/booth/number',
  BOOTH_TYPE: '/booth/type',
  BOOTH_LOCATION: '/booth/location',
  BOOTH_SIZE: '/booth/size',
  //both dynamic setup
  BOOTH_INPUT: '/booth/input',
  BOOTH_LIST: '/booth/list',
  BOOTH_BINARY: '/booth/binary',
  BOOTH_GROUP_LIST: '/booth/group_list',
  BOOTH_RADIO: '/booth/radio',
  REUSE_ORDER: '/reuse-order',

  // shopping
  SHOPPING: '/shopping',
  CATALOG: '/shopping/catalog',
  PRODUCT: '/shopping/product',

  // checkout
  CHECKOUT: '/checkout',
  BOOTH_WORK: '/checkout/booth-work',
  CONTACT: '/checkout/contact',
  PAYMENT: '/checkout/payment',
  REVIEW: '/checkout/review',
  CONFIRMATION: '/checkout-confirmation',
  POST_ORDER: '/questions-post-order',

  // misc
  HOME: '/',
  FLOOR_PLAN_BUILDER: '/floor-plan-builder',
  FAQ: '/faq',
};

export default ROUTE;
