import { object, oneOf } from 'prop-types';

import { Product } from 'common/components';
import { getItemFlag } from 'common/utils';

const OrderItem = ({ item, variant = 'order', children }) => {
  // console.log('<OrderItem>', { item, variant });

  const flag = getItemFlag(item);

  const getQuantity = () => {
    if (variant === 'checkout') {
      return `x${item.quantity}`;
    } else if (variant === 'revise') {
      return `${item.quantity < 0 ? `-` : `+`} x${Math.abs(item.quantity)}`;
    } else {
      return item.quantity;
    }
  };

  return (
    <Product
      variant={variant === 'revise' ? 'checkout' : variant}
      id={item.id}
      name={item.name}
      image={item.image}
      currentPrice={
        variant === 'invoice' ? item.price : item.quantity * item.price
      }
      originalPrice={
        variant === 'checkout' || variant === 'revise'
          ? item.quantity * item.floorPrice
          : undefined
      }
      quantity={getQuantity()}
      flag={flag}
    >
      {children}
    </Product>
  );
};

OrderItem.propTypes = {
  item: object,
  variant: oneOf(['invoice', 'order', 'checkout', 'revise']),
};

export default OrderItem;
