/**
 * Mutate totals data for naming consistency.
 * @returns {object}
 */
const getTotals = (order) => ({
  amountPaid: order?.amountPaid,
  balanceDue: order?.balanceDue,
  ccFee: order?.ccFee,
  discount: order?.discount,
  serviceFee: order?.serviceFee,
  subtotal: order?.subtotal,
  tax: order?.tax,
  total: order?.total,
  wireFee: order?.wireFee,
});

export default getTotals;
