import { merge } from 'lodash';

import { incompleteOrders, itemsList } from 'data/mock-data';

const mock = ({ customerId }) => {
  if (customerId === '3dcf48b3-11b3-437f-8cfb-6b3ff95a94a3') {
    return {
      data: {
        incompleteCartData: incompleteOrders,
      },
    };
  }

  return {
    data: {
      incompleteCartData: [
        merge({}, incompleteOrders[0], {
          cartDetails: itemsList,
        }),
      ],
    },
  };
};

export default mock;
