import { useCallback, useEffect, useReducer } from 'react';
import cx from 'classnames';
import { useMutation, useQuery } from '@tanstack/react-query';

import { getCountries } from 'data/lookup';
import { addAddress } from 'data/orders';

import { DEFAULT_COUNTRY } from './const';

import reducer, { TYPE, init } from './reducer';
import AddressInput from './AddressInput';

const INIT_ADDRESS = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
};

const useAddress = (initialValues = {}, submitOptions) => {
  const { countryCode: initialCountryCode } = initialValues;

  const { data: countriesData } = useQuery(['getCountries'], getCountries);

  const countryCode = initialCountryCode || DEFAULT_COUNTRY; // react-hook-form passes in `null` when undefined
  const [{ countries, states, DISPLAY, FIELD, STYLE }, dispatch] = useReducer(
    reducer,
    init({ countryCode }),
  );

  const loadCountries = useCallback((countries) => {
    // console.log('!useAddress.loadCountries', countries);
    dispatch({ type: TYPE.LOAD, payload: countries });
  }, []);

  const updateCountry = useCallback((countryValue) => {
    dispatch({ type: TYPE.COUNTRY, payload: countryValue });
  }, []);

  const {
    mutateAsync: add,
    error: addAddressError,
    isLoading: addAddressIsLoading,
  } = useMutation(
    ({ ooId, type, address, customerId }) =>
      addAddress({ ooId, type, address, customerId }),
    submitOptions,
  );

  useEffect(
    () => countriesData && loadCountries(countriesData),
    [countriesData, loadCountries],
  ); // load countries on initial render

  const AddressItem = useCallback(
    ({ name, className, ...props }) => {
      const field = FIELD[name];
      return field ? (
        <li className={cx(className, STYLE[name])}>
          <AddressInput name={name} field={field} {...props} />
        </li>
      ) : null;
    },
    [FIELD, STYLE],
  );

  const findCountry = (countryCode) =>
    countries.find(({ code }) => code === countryCode);

  const buildValidation = () => ({
    ...Object.entries(FIELD).reduce(
      (obj, [label]) => ({
        ...obj,
        [label]: FIELD[label].validation,
      }),
      [],
    ),
  });

  return {
    addAddress: add,
    addAddressIsLoading,
    addAddressError,
    INIT_ADDRESS,
    countries,
    countryCode, // only initial countryCode for defaultValue (does not update)
    findCountry,
    updateCountry,
    buildValidation,
    states,
    DISPLAY, // fields & styles for displaying
    FIELD, // object of form fields by name
    STYLE, // object from style module, for form
    AddressItem, // includes li wrapper
    AddressInput, // only the input
  };
};

export default useAddress;
