import { completedOrders, webServerDateTime } from 'data/mock-data';

const mock = () => {
  return {
    data: {
      empOnlineOrderData: completedOrders[0],
      webServerDateTime,
    },
  };
};

export default mock;
