import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './getReusableOrderItems.mock';

const baseUrl = getBaseUrl(2);

/**
 * Gets reusable item list
 * @param {string} empOrderId
 * @returns List of reusable items
 */
const getReusableOrderItems = async ({ empOrderId, newEventId }) => {
  await VALID.checkAll(
    ['empOrderId', empOrderId, VALID.UUID],
    ['newEventId', newEventId, VALID.EVENT_ID],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(
          `${baseUrl}orders/reusable_order_details/${empOrderId}/${newEventId}`,
        );

    return data.reusableOrderDetails.map((item) => ({
      id: item.itemId,
      name: item.itemDescription,
      quantity: item.quantity,
      image: item.itemImageName,
    }));
  } catch (error) {
    log('getReusableOrderItems', {
      data: { empOrderId, newEventId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getReusableOrderItems;
