import '@edlen/floor-plan-builder/dist/index.css';
import { FloorPlanBuilder as FloorPlanBuilderComponent } from '@edlen/floor-plan-builder';

import PrivateRoute from 'routes/PrivateRoute';

import { Require } from 'common/components';
import { Page } from 'common/site';
import { ROUTE } from 'common/const';
import { useCustomer, useOrders, useShop } from 'app/context';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFloorPlanItems } from 'data/orders';
import { decoratePhone } from 'common/utils';

const FloorPlanBuilder = () => {
  const { ooId } = useShop();
  const orders = useOrders();
  const { customerId, ...customer } = useCustomer();
  const order = useMemo(() => orders.findOrder(ooId), [orders, ooId]);

  const { data: items, isFetching: isLoadingItems } = useQuery(
    ['getFloorPlanItems', ooId],
    () => getFloorPlanItems({ ooId }),
    { refetchOnMount: true },
  );

  const infoBlock = {
    location:
      order?.booth.location === 'blank' ? undefined : order?.booth.location,
    boothNumber: order?.booth.number,
    boothType: order?.booth.type,
    name: customer?.fullName,
    email: customer?.email,
    phoneNumber: decoratePhone(customer?.phone),
    length: order?.booth.size?.split(' x ')[0],
    width: order?.booth.size?.split(' x ')[1],
  };

  return (
    <Require shop>
      <PrivateRoute exact path={ROUTE.FLOOR_PLAN_BUILDER}>
        <Page
          variant="builder"
          backTo={ROUTE.BOOTH_WORK}
          title="Floor Plan Builder"
          isLoading={isLoadingItems}
        >
          <FloorPlanBuilderComponent infoBlock={infoBlock} itemList={items} />
        </Page>
      </PrivateRoute>
    </Require>
  );
};

export default FloorPlanBuilder;
