import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

const baseUrl = getBaseUrl(2);

/**
 * Check if reset link is valid
 * @param {string} linkCode
 * @returns Object with users data
 */
const validateResetLink = async (linkCode) => {
  await VALID.checkAll(['linkCode', linkCode, VALID.STRING]);

  try {
    if (!ENV.IS_MOCK) {
      await axios.get(`${baseUrl}customer/is_reset_link_valid/${linkCode}`);
    }
  } catch (error) {
    log('validateResetLink', {
      data: { linkCode },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default validateResetLink;
