import { TEXT } from '..';
import ROUTE from '../route';

const X = ' x ';

const BOOTH_SETUP = {
  INPUT: {
    templateId: 1,
    path: (assessmentId) => ROUTE.BOOTH_INPUT + `/${assessmentId}`,
  },
  BINARY: {
    templateId: 5,
    path: (assessmentId) => ROUTE.BOOTH_BINARY + `/${assessmentId}`,
  },
  LIST: {
    templateId: 2,
    path: (assessmentId) => ROUTE.BOOTH_LIST + `/${assessmentId}`,
    show: ({ boothSetupResponseSelections: options }) =>
      Array.isArray(options.listResponseOptions) &&
      options.listResponseOptions.length > 1,
  },
  GROUP_LIST: {
    templateId: 3,
    path: (assessmentId) => ROUTE.BOOTH_GROUP_LIST + `/${assessmentId}`,
    show: ({ boothSetupResponseSelections: options }) =>
      Array.isArray(options.groupedListResponseOptions) &&
      options.groupedListResponseOptions?.length > 1,
  },
  RADIO: {
    templateId: 4,
    path: (assessmentId) => ROUTE.BOOTH_RADIO + `/${assessmentId}`,
    maskInput: (...values) => [...values].join(X),
    maskOutput: (response) => {
      if (!response) {
        return response;
      }

      if (response?.includes(X)) {
        return response;
      }

      return (
        <>
          {response} ft<sup className={TEXT.OVERLINE}>2</sup>
        </>
      );
    },
    parseValues: (response) => {
      if (!response) {
        return undefined;
      }

      const [length, width] = response.split(X);

      return isNaN(length) || isNaN(width)
        ? { length, width }
        : { area: response };
    },
  },
};

export default BOOTH_SETUP;
