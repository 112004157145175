import { getSiteProps } from 'common/utils';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import TawkTo from 'tawkto-react';

import reducer, { TYPE, init } from './reducer';

const Chat = createContext();

const { CHAT } = getSiteProps();

export const ChatProvider = ({ coveredBy, children }) => {
  const [{ chat, isHidden }, dispatch] = useReducer(
    reducer,
    init({ coveredBy }),
  );

  // init
  useEffect(() => {
    if (!chat && CHAT) {
      dispatch({
        type: TYPE.LOAD,
        payload: new TawkTo(CHAT.propertyId, CHAT.tawkId),
      });
    }
  }, [chat]);

  useEffect(() => {
    if (isHidden) {
      chat?.hideWidget();
    } else {
      chat?.showWidget();
    }
  }, [chat, isHidden]);

  const cover = useCallback(
    (payload) => dispatch({ type: TYPE.COVER, payload }),
    [],
  );
  const uncover = useCallback(
    (payload) => dispatch({ type: TYPE.UNCOVER, payload }),
    [],
  );
  const isOnline = useMemo(() => chat?.getStatus() === 'online', [chat]);
  const open = useCallback(() => chat.maximize(), [chat]);

  return (
    <Chat.Provider value={{ cover, uncover, isOnline, open }}>
      {children}
    </Chat.Provider>
  );
};

export const useChat = () => useContext(Chat);
