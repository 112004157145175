import { createRef, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { object } from 'prop-types';

import { BOOTH_SETUP, VALID } from 'common/const';
import {
  Accordion,
  AccordionGroup,
  Content,
  Form,
  Icon,
  Input,
  Require,
  TextLink,
} from 'common/components';
import { remoteSubmit } from 'common/utils';

import Step from './Step';

import styles from './RadioButton.module.css';

const LXW = 'length-x-width';
const UNSURE = 'unsure';

//Its set up to be dynamic but the page is still hard coded.
const RadioButton = ({ findStep }) => {
  const { assessmentId } = useParams();
  const step = findStep(BOOTH_SETUP.RADIO, assessmentId);

  // console.log('<RadioButton>', { step });

  const {
    response,
    question,
    required,
    submit,
    error,
    isLoading,
    isSubmitting,
    back,
    buttonText,
    maskInput,
  } = step ?? {};

  // const groupListOptions =
  //   step?.boothSetupResponseSelections?.groupedListResponseOptions.sort(
  //     (a, b) => (a.headerSortOrder = b.headerSortOrder),
  //   );

  const answerType = useMemo(() => (response ? LXW : undefined), [response]);

  const defaultActive = useMemo(() => answerType ?? LXW, [answerType]);
  const [active, setActive] = useState(defaultActive);

  const ref = {
    [LXW]: createRef(),
  };

  // const options = groupListOptions?.reduce((list, option) => {
  //   const { header, optionID, optionValue, optionSortorder } = option;
  //   if (!list[header]) {
  //     list[header] = [];
  //   }
  //   list[header].push({ optionID, optionValue, optionSortorder });
  //   return list;
  // }, {});

  return (
    <Require data={[question]}>
      <Content isLoading={!step || isLoading} error={error?.message}>
        <Step
          {...step}
          submit={() => {
            active === UNSURE ? submit('') : remoteSubmit(ref[active].current);
          }}
          isSubmitting={isSubmitting}
          buttonText={buttonText}
          back={back}
        >
          <h1 className={styles.p}>{question}</h1>

          <AccordionGroup
            radios
            defaultActive={defaultActive}
            onChange={(id) => setActive(id)}
          >
            <Accordion id={LXW} summary="Enter length and width">
              <Form
                ref={ref[LXW]}
                defaultValues={response}
                onPass={({ length, width }) => {
                  submit(maskInput(length, width));
                }}
                validation={{
                  length: VALID.NUMBER.required('Length is required').test(
                    'length',
                    'Length must be at least 1.',
                    (l) => Number(l) >= 1,
                  ),
                  width: VALID.NUMBER.required('Width is required').test(
                    'width',
                    'Width must be at least 1.',
                    (w) => Number(w) >= 1,
                  ),
                }}
              >
                <div className={styles.measurements}>
                  <Input number name="length" suffix="ft" maxlength={7} />

                  <div className={styles.x}>
                    <Icon name="close" />
                  </div>

                  <Input number name="width" suffix="ft" maxlength={7} />
                </div>
              </Form>
            </Accordion>

            {!required && (
              <Accordion id={UNSURE} summary="Not Sure / Not Listed" />
            )}
          </AccordionGroup>

          <p className={styles.more}>
            <TextLink href="https://edlen.com/exhibitor-resources/?exhibitor=floor-plans">
              View Size Charts
            </TextLink>
          </p>
        </Step>
      </Content>
    </Require>
  );
};

RadioButton.propTypes = {
  step: object,
};

export default RadioButton;
