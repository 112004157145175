import {
  CornerIcon,
  InlineIcon,
  IslandIcon,
  MeetingRoomIcon,
  PeninsulaIcon,
  TableTopIcon,
  UnsureIcon,
} from 'assets/images';

const BOOTH_TYPE = {
  INLINE: {
    title: 'Inline',
    value: 'Inline',
    description:
      'Located in a straight line alongside a number of other booths in an aisle. Only one side is open to the aisle.',
    image: InlineIcon,
  },
  PENINSULA: {
    title: 'Peninsula or End-Cap',
    value: 'Peninsula',
    description:
      'Exposed to aisles on three sides with its back up to Inline booths.',
    image: PeninsulaIcon,
  },
  ISLAND: {
    title: 'Island',
    value: 'Island',
    description:
      'Booth exposed to aisles on all four sides with no adjacent booths.',
    image: IslandIcon,
  },
  CORNER: {
    title: 'Corner',
    value: 'Corner',
    description:
      'At the end of a row of Inline booths with two sides open to aisles.',
    image: CornerIcon,
  },
  TABLE_TOP: {
    title: 'Table Top',
    value: 'Table Top',
    description:
      'Six to eight foot display table set in an assigned area. (Not within a booth space.)',
    image: TableTopIcon,
  },
  MEETING_ROOM: {
    title: 'Meeting Room',
    value: 'Meeting Room',
    description:
      'Meeting rooms are reserved in advance with Show Management and are used for meetings and demonstrations.',
    image: MeetingRoomIcon,
  },
  UNSURE: {
    title: 'Not Sure / Not Listed',
    value: 'Do Not Know',
    image: UnsureIcon,
  },
};

export default BOOTH_TYPE;
