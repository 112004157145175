import { arrayOf, string } from 'prop-types';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import { COLOR } from 'common/const';

const Tabs = ({ labels, activeTab, setActiveTab }) => {
  const handleChange = (_e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <MuiTabs value={activeTab} onChange={handleChange} aria-label="Tabs">
      {labels.map((label) => {
        return (
          <Tab
            label={
              <span
                className={label === activeTab ? COLOR.BLACK : COLOR.GREY_800}
              >
                {label}
              </span>
            }
            id={`${label}-tab`}
            value={label}
            key={label}
          />
        );
      })}
    </MuiTabs>
  );
};

Tabs.propTypes = {
  labels: arrayOf(string),
};

export default Tabs;
