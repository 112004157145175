import cx from 'classnames';
import { Icon } from 'common/components';
import { COLOR, ROUTE, TEXT } from 'common/const';
import { useClick } from 'common/hooks';
import { date, DATE_FORMAT } from 'common/utils';

import styles from './ResultItem.module.css';

const ResultItem = ({ event, setFacilityName, setHrefUrl, setIsOpen }) => {
  const useClickProps = event.redirectUrl
    ? {
        onClick: () => {
          setFacilityName(event.facilityName);
          setHrefUrl(event.redirectUrl);
          setIsOpen(true);
        },
      }
    : {
        to: ROUTE.START_ORDER + '/' + event.id,
      };

  const { component: ClickableCard, ...clickableCardProps } =
    useClick(useClickProps);

  return (
    <ClickableCard
      {...clickableCardProps}
      className={styles.clickableCard}
      aria-label={`Select ${event.name}`}
    >
      <article>
        <h5 className={cx(TEXT.SUBTITLE, styles.name)}>{event.name}</h5>

        <p aria-label="Event Date">
          {[event.dateStart, event.dateEnd].map((eventDate, i) => (
            <time
              key={event.id + eventDate + i}
              dateTime={date(eventDate).format(DATE_FORMAT.SERVER_DATE)}
              className={cx(COLOR.GREY_800, styles.date)}
            >
              {date(eventDate).format(DATE_FORMAT.FULL_DATE)}
            </time>
          ))}
        </p>

        <address className={cx(COLOR.GREY_800, TEXT.BODY_2)}>
          <span aria-label="Venue Name" className={styles.venue}>
            {event.facilityName}
          </span>
          <span aria-label="Venue Location" className={styles.venue}>
            {event.facilityLocation}
          </span>
        </address>
      </article>
      <Icon className={styles.icon} name="caret-right" size="medium" />
    </ClickableCard>
  );
};

export default ResultItem;
