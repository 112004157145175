import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import mock from './addCustomerAssessmentResponse.mock';

const baseUrl = getBaseUrl(2);

/**
 * Add a customer assessment response
 * @param {object} assessmentResponseData - object containing assessmentId and response
 * @returns {integer} OrderId
 */
const addCustomerAssessmentResponse = async ({
  assessmentResponseData,
  ooId,
  isOptional,
}) => {
  // console.log('$ addCustomerAssessmentResponse', { assessmentResponseData, ooId, isOptional });

  await VALID.checkAll(
    ['ooId', ooId, VALID.OO_SUMMARY_ID],
    ['assessmentId', assessmentResponseData.assessmentId, VALID.NUM],
    [
      'response',
      assessmentResponseData.response,
      VALID.STRING.defined(),
      isOptional,
    ],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.post(`${baseUrl}orders/add_customer_assessment_response`, {
          assessmentResponseData,
          ooId,
        });

    return { responseId: data.responseId };
  } catch (error) {
    log('addCustomerAssessmentResponse', {
      data: { assessmentResponseData, ooId, isOptional },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default addCustomerAssessmentResponse;
