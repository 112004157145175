import { useMemo } from 'react';
import { any, string } from 'prop-types';

import { Content, ProductList } from 'common/components';
import { useCartTotals, useEvent, useCatalogCategoryNames } from 'common/hooks';
import { currency, date, DATE_FORMAT } from 'common/utils';
import { useShop } from 'app/context';

import styles from './Summary.module.css';
import cx from 'classnames';
import { BORDER, COLOR, EVENT, PAYMENT_METHOD, TEXT } from 'common/const';
import { OrderItem } from 'routes/orders/components';
import { INCOMPLETE } from 'routes/orders/const';

const Line = ({ children, value }) =>
  typeof value === 'number' && value !== 0 ? (
    <div className={styles.line}>
      <dt className={cx(COLOR.GREY_600, TEXT.BODY_2, styles.label)}>
        {children}
      </dt>
      <dd>{currency(value)}</dd>
    </div>
  ) : null;

const Summary = ({ paymentMethod, order, totals }) => {
  // console.log('<Summary>', { paymentMethod, order });

  const { eventId } = useShop();

  const {
    isEarlyPricing,
    deadlineDate,
    isWireTransferTaxable,
    isServiceFeeTaxable,
    facilityId,
  } = useEvent(eventId).data;

  const { isLoading, error } = useCartTotals();
  const { id: ooId, booth, items } = useMemo(() => order, [order]);

  const { categories, isCatalogNamesLoading, categoryNamesError } =
    useCatalogCategoryNames({
      ooId: ooId,
      eventId,
      boothLocation: booth?.location,
      boothType: booth?.type,
      items: items,
    });

  const { subtotal, discount, tax, wireFee, serviceFee, ccFee, total } = totals;

  const isTaxable = isWireTransferTaxable || isServiceFeeTaxable;
  const savings = items.reduce(
    (sum, { advancedPrice, floorPrice, quantity }) =>
      sum + quantity * (floorPrice - advancedPrice),
    0,
  );

  return (
    <Content isLoading={isLoading} error={error}>
      <h5 className={cx(TEXT.H6, styles.heading)}>Order Summary</h5>
      <div className={styles.section}>
        <dl>
          <Line value={subtotal}>Item(s) Subtotal</Line>

          <Line value={discount}>Discount</Line>

          {!isTaxable && <Line value={tax}>Sales Tax</Line>}

          {/* if taxable, need to figure out the tax, then add that to tax below */}
          {paymentMethod === PAYMENT_METHOD.WIRE && (
            <Line value={wireFee}>Wire Transfer Fee</Line>
          )}

          {/* if taxable, need to figure out the tax, then add that to tax below */}
          <Line value={serviceFee}>{EVENT.getServiceFeeCopy(facilityId)}</Line>

          {isTaxable && <Line value={tax}>Sales Tax</Line>}

          {/* cc fees are never taxable */}
          {paymentMethod === PAYMENT_METHOD.CARD && (
            <Line value={ccFee}>Credit Card Fee</Line>
          )}

          <div className={cx(BORDER.GREY_200, styles.line)}>
            <dt className={cx(TEXT.BODY_2, styles.label)}>Grand Total</dt>
            {/* add any additional taxes based on payment type fees */}

            <dd className={styles.align}>
              <p className={TEXT.BODY_2}>USD</p>
              <p className={TEXT.H6}>{currency(total)}</p>
            </dd>
          </div>
        </dl>
        {isEarlyPricing && savings ? (
          <p className={cx(COLOR.ERROR, TEXT.BODY_2, styles.date)}>
            You save {currency(savings)} when you order before{' '}
            {date(deadlineDate).format(DATE_FORMAT.MEDIUM_DATE)}!
          </p>
        ) : null}
      </div>

      <section className={styles.section}>
        <p className={cx(TEXT.H6, styles.itemHeader)}>Your Items</p>
        {order.items?.length ? (
          <Content
            isLoading={isCatalogNamesLoading}
            error={categoryNamesError?.message}
          >
            <ProductList
              groupClassName={styles.group}
              categories={categories}
              items={order.items}
              renderItem={(item) => (
                <OrderItem item={item} variant="checkout" />
              )}
            />
          </Content>
        ) : (
          <p className={COLOR.GREY_600}>{INCOMPLETE.ITEMS_MESSAGE}</p>
        )}
      </section>
    </Content>
  );
};

Summary.propTypes = {
  paymentMethod: string,
  children: any,
};

export default Summary;
