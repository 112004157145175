import { useMemo, useState } from 'react';
import cx from 'classnames';
import { useHistory, useParams } from 'react-router';

import { useCustomer, useOrders } from 'app/context';
import { BACKGROUND, COLOR, FLAG, ROUTE, TEXT } from 'common/const';
import {
  AddressDisplay,
  BoothSetup,
  Button,
  Content,
  Dialog,
  EventDates,
  EventLogo,
  Flag,
  OrderSummary,
  PaymentMethod,
  Requirement,
  Require,
  TextLink,
  ReviseButton,
  UploadFloorPlan,
  Toast,
} from 'common/components';
import { Page } from 'common/site';
import {
  currency,
  date,
  DATE_FORMAT,
  decoratePhone,
  getBaseUrl,
  track,
} from 'common/utils';

import { SectionCard, OrderItem, SectionTitle } from '../components';

import styles from './OrderDetails.module.css';

const WIRE_ID = '0900';
const CC_FEE_ID = '0999';

const BASE_URL = getBaseUrl(2);

const OrderDetails = ({ location, backTo }) => {
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);

  const { customerId } = useCustomer();
  const history = useHistory();
  const { id } = useParams();
  const { isFetching, error, findOrder, toast, updateToast } = useOrders();

  const order = useMemo(() => findOrder(id) ?? {}, [findOrder, id]);

  const personal = order?.personalInformation ?? {};
  const billing = order?.billingInformation ?? {};
  const { id: ooId } = order;

  const keywordOrPackageInItems = order?.items?.some(
    (item) => item.isPackage || item.isKeyword,
  );

  return (
    <Require data={[ooId]}>
      <Page variant="order" title="Order Details">
        <div className={styles.header}>
          <div className={styles.back}>
            <Button
              text
              onClick={() =>
                history.push({
                  pathname: history?.location?.state?.from ?? ROUTE.DASHBOARD,
                  state: {
                    scrollTo: location?.state?.scrollTo,
                  },
                })
              }
              startIcon="caret-left"
              size="small"
            >
              Back
            </Button>
          </div>

          <div className={cx(styles.align)}>
            <div className={styles.flag}>
              <h1 className={TEXT.H4}>Order Details</h1>
              {!isFetching && (
                <div>
                  <Flag
                    flag={
                      order?.cancellationStatus === 'A'
                        ? FLAG.CANCELLED
                        : order?.cancellationStatus === 'R'
                        ? FLAG.CANCEL_REQUESTED
                        : FLAG.COMPLETED
                    }
                  />
                </div>
              )}
            </div>
            {!isFetching && (
              <div className={styles.actions}>
                <Button
                  className={styles.action}
                  href={`${BASE_URL}document/order_statement/${order.eventId}/${
                    order.empOrderId
                  }/${order.isPast ? 'Invoice' : 'Statement'}/${
                    order.booth.number
                  }`}
                  fullWidth
                  outlined
                >
                  {order.isPast ? 'View Invoice' : 'View Statement'}
                </Button>
                <div className={styles.helpLink}>
                  <TextLink
                    underline="always"
                    onClick={() => setIsHelpDialogOpen(true)}
                  >
                    Need help with your order?
                  </TextLink>
                </div>
              </div>
            )}
          </div>
        </div>

        <Content error={error} isLoading={isFetching}>
          <div className={cx(styles.column, styles.content)}>
            <SectionCard title="Event & Booth">
              <div className={styles.columns}>
                <section className={cx(styles.section, styles.leftColumn)}>
                  <div className={styles.eventImage}>
                    <EventLogo
                      name={order?.eventName}
                      image={order?.eventImage}
                    />
                  </div>
                  <SectionTitle>{order.eventName}</SectionTitle>
                  <ol>
                    <li>
                      <EventDates
                        start={order?.eventOpenDate}
                        end={order?.eventCloseDate}
                        format={DATE_FORMAT.FULL_DATE}
                      />
                    </li>
                    <li>{order?.facilityName}</li>
                    <li>{order?.facilityLocation}</li>
                  </ol>
                </section>

                <section className={styles.section}>
                  <section className={styles.section}>
                    <SectionTitle>Booth Setup</SectionTitle>
                    <BoothSetup
                      booth={order?.booth}
                      showNumber={order?.booth?.number !== '1'}
                      inline
                      isOrder
                    />
                  </section>

                  <section className={styles.section}>
                    <SectionTitle>Booth Work</SectionTitle>
                    <ul>
                      {order?.assessmentResponseData?.map(
                        ({
                          assessmentId,
                          response,
                          question,
                          assessmentType,
                        }) => {
                          // return assessmentId > 4 && assessmentId !== 12 ? (
                          return assessmentType === 'Booth Work' ? (
                            <li
                              key={assessmentId}
                              className={styles.requirement}
                            >
                              <Requirement
                                textFont={TEXT.BODY_1}
                                isMet={response === 'Yes'}
                              >
                                {question}
                              </Requirement>
                            </li>
                          ) : null;
                        },
                      )}

                      <li>
                        <UploadFloorPlan
                          isFloorPlanUploaded={order.floorPlanExists}
                          customerId={customerId}
                          eventId={order.eventId}
                          ooId={order.id}
                          exhId={order.exhId}
                        />
                      </li>
                    </ul>
                  </section>
                </section>
              </div>
            </SectionCard>

            <SectionCard title="Contact">
              <div className={styles.columns}>
                <div className={styles.leftColumn}>
                  <section className={styles.section}>
                    <SectionTitle>Personal Information</SectionTitle>
                    <p>
                      {personal.firstName} {personal.lastName}
                    </p>
                    <p className={styles.data}>{personal.email}</p>
                    <p className={styles.data}>
                      {decoratePhone(personal.mobile)}
                    </p>
                  </section>

                  {order.isThirdParty && (
                    <section className={styles.section}>
                      <SectionTitle>Exhibitor Information</SectionTitle>
                      <p className={styles.spacing}>
                        {order.thirdPartyCompanyName}
                      </p>
                      <p className={cx(TEXT.CAPTION, COLOR.GREY_600)}>
                        We will not contact the Exhibitor directly unless the
                        Third-Party Contractor fails to pay as agreed.
                      </p>
                    </section>
                  )}
                </div>

                <section className={styles.section}>
                  <SectionTitle>Company Information</SectionTitle>
                  <p className={styles.spacing}>{order?.company}</p>
                  <div className={styles.spacing}>
                    <AddressDisplay address={billing} />
                  </div>
                  <p>{decoratePhone(billing.phone)}</p>
                </section>
              </div>
            </SectionCard>
            <SectionCard>
              <section>
                <div className={styles.heading}>
                  <div>
                    <h1 className={cx(styles.spacing, TEXT.H6)}>Your Items</h1>
                    {/* !TODO: Add when we allow modify/cancel */}
                    {/* <p className={TEXT.BODY_2}>
                    You can modify or cancel this order before
                  </p> */}
                  </div>

                  <div>
                    {(order.isCancellationAllowed ||
                      order.cancellationStatus !== 'None' ||
                      order.isRevisionAvailable) && (
                      <div className={styles.orderActions}>
                        {/* Revise */}
                        {order.isRevisionAvailable && (
                          <ReviseButton
                            isEarlyPricing={order.isEarlyPricing}
                            id={ooId}
                          />
                        )}
                        {/* Cancel */}
                        {(order.isCancellationAllowed ||
                          order.cancellationStatus !== 'None') && (
                          <Button
                            fullWidth
                            outlined
                            size="medium"
                            color="info"
                            disabled={order.cancellationStatus !== 'None'}
                            onClick={() => {
                              track('request_to_cancel', { ooId });
                              history.push(`${ROUTE.CANCEL}/${order.id}`);
                            }}
                          >
                            Cancel Order
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <ul className={styles.itemList}>
                  {order?.items?.map((item) => {
                    return (
                      item.id !== WIRE_ID &&
                      item.id !== CC_FEE_ID && (
                        <li
                          key={item.id + item.quantity}
                          className={
                            keywordOrPackageInItems
                              ? styles.item
                              : styles.twoColumn
                          }
                        >
                          {keywordOrPackageInItems && (
                            <p className={COLOR.GREY_600}>
                              {(item.isKeyword || item.isPackage) &&
                                date(item.orderDate).format(
                                  DATE_FORMAT.MEDIUM_DATE,
                                )}
                            </p>
                          )}

                          <div>
                            {item.isKeyword || item.isPackage ? (
                              <p className={TEXT.BOLD}>{item.name}</p>
                            ) : (
                              <OrderItem item={item} variant="order">
                                <div className={COLOR.GREY_600}>
                                  <p className={styles.smallSpacing}>
                                    Qty {item.quantity}
                                  </p>
                                  <p>
                                    {currency(item.price)}{' '}
                                    {item.isLabor ? '/ hour' : 'each'}
                                  </p>
                                </div>
                              </OrderItem>
                            )}
                          </div>

                          <div
                            className={cx(styles.totalPrice, TEXT.SUBTITLE_1)}
                          >
                            {!item.isKeyword &&
                              !item.isPackage &&
                              currency(item.totalPrice)}{' '}
                          </div>
                        </li>
                      )
                    );
                  })}
                </ul>
              </section>
            </SectionCard>
          </div>
          <div className={cx(styles.column, styles.info)}>
            <SectionCard title="Order Summary" className={styles.summary}>
              <div className={styles.section}>
                {order.totals && (
                  <OrderSummary totals={order.totals} isPast={order.isPast} />
                )}
              </div>
            </SectionCard>
            {order?.payment?.length ? (
              <SectionCard title="Payment History">
                <ul>
                  {order?.payment.map((payment, index) => (
                    <li
                      key={payment.method + index}
                      className={styles.historyItem}
                    >
                      <PaymentMethod
                        amount={payment?.amount}
                        paymentDate={payment?.date}
                        last4={payment?.last4}
                        card={payment?.card}
                        method={payment?.method}
                      />
                    </li>
                  ))}
                </ul>
              </SectionCard>
            ) : null}
            <SectionCard title="Order History" className={styles.summary}>
              {order?.history?.map(({ line, date: lineDate }) => (
                <div key={line + lineDate} className={styles.historyItem}>
                  <p className={styles.spacing}>{line}</p>
                  <p className={cx(TEXT.BODY_2, COLOR.GREY_600)}>
                    {date(lineDate).format(DATE_FORMAT.MEDIUM)}
                  </p>
                </div>
              ))}
            </SectionCard>
          </div>
        </Content>

        <Dialog
          id="help"
          title="Help With Orders"
          close={() => setIsHelpDialogOpen(false)}
          isOpen={isHelpDialogOpen}
          onCancel={() => setIsHelpDialogOpen(false)}
          cancelText="Dismiss"
        >
          <p>Contact your branch manager for help with your order.</p>
          <div className={cx(BACKGROUND.GREY_50, styles.branch)}>
            <p className={TEXT.BOLD}>{order?.branchName}</p>
            <TextLink href={'tel:+1' + order?.branchPhone}>
              <p>{decoratePhone(order?.branchPhone)}</p>
            </TextLink>
            <TextLink href={'mailto:' + order?.branchEmail}>
              <p>{order?.branchEmail}</p>
            </TextLink>
          </div>
        </Dialog>

        <Toast
          alert={toast.alert}
          anchor={{ vertical: 'top', horizontal: 'right' }}
          isOpen={Boolean(toast.alert)}
          close={() => updateToast({})}
        >
          {toast.message}
        </Toast>
      </Page>
    </Require>
  );
};

export default OrderDetails;
