import { string } from 'prop-types';
import DOMPurify from 'dompurify';
import cx from 'classnames';

import { Accordion } from 'common/components';
import { TEXT } from 'common/const';

import styles from './Faqs.module.css';

const Faqs = ({ className, faqs, suffix }) => {
  // console.log({ faqs, suffix });

  return Object.entries(faqs).map(([heading, questions], index) => (
    <div id={heading} key={heading + index} className={className}>
      <h2 className={cx(TEXT.H6, styles.heading)}>{heading + suffix}</h2>
      {questions.map(({ question, htmlAnswer }, index) => (
        <Accordion
          key={heading + 'question' + index}
          id={heading + index}
          summary={question}
        >
          <div
            className={styles.test}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(htmlAnswer),
            }}
          />
        </Accordion>
      ))}
    </div>
  ));
};

Faqs.propTypes = {
  component: string,
};

Faqs.defaultProps = {
  suffix: '',
};

export default Faqs;
