import { useMemo, useEffect, useState, useCallback } from 'react';
import cx from 'classnames';

import { useSetInterval } from 'common/hooks';
import { useOrders } from 'app/context';
import { Page } from 'common/site';
import { BACKGROUND, COLOR, ROUTE, TEXT } from 'common/const';
import { Content, Card, Tabs } from 'common/components';

import { OrdersList, NewOrderButton } from '../components';

import styles from './OrderHistory.module.css';
import { EmptyCart } from 'assets/images';

const OrderHistory = ({ location }) => {
  // console.log('<OrderHistory>');
  const [completedOrderFilter, setCompletedOrderFilter] = useState('Upcoming');

  const {
    refetchCompleted: refetchCompletedOrders,
    incomplete,
    cancelled: cancelledOrders,
    completed,
    past: pastOrders,
    upcoming: upcomingOrders,
    data,
    error,
    isFetching,
  } = useOrders();

  // refresh our context, once
  const [isFresh, setIsFresh] = useState();

  useEffect(() => {
    if (!isFresh) {
      setIsFresh(true);

      refetchCompletedOrders();
    }
  }, [refetchCompletedOrders, isFresh, setIsFresh]);

  // default to `Past` if no upcoming orders
  useEffect(() => {
    if (!isFetching && upcomingOrders.length === 0) {
      setCompletedOrderFilter('Past');
    }
  }, [isFetching, upcomingOrders]);

  // get orders
  const {
    incompleteOrders = [],
    completedOrders = [],
    hasOrders,
  } = useMemo(
    () => ({
      incompleteOrders: incomplete.data,
      completedOrders: completed.data,
      hasOrders: data.length,
    }),
    [incomplete, completed, data],
  );

  // scroll to previous position, on "back" button
  useEffect(() => {
    const scrollTo = location?.state?.scrollTo;

    if (scrollTo && hasOrders) {
      window.scrollTo(0, scrollTo);
    }
  }, [location.state, hasOrders]);

  // refetch for pending orers
  const fetchIfPending = useCallback(() => {
    if (completedOrders.some((order) => order.isPending === true)) {
      refetchCompletedOrders();
    }
  }, [refetchCompletedOrders, completedOrders]);

  useSetInterval(fetchIfPending, 10);

  return (
    <Page variant="account" title="My Orders">
      <h1 className={cx(TEXT.H5, styles.title)}>My Orders</h1>

      <Content isLoading={incomplete.isLoading} error={error?.message}>
        {hasOrders ? (
          <>
            <div className={styles.listHeading}>
              <p className={TEXT.H6}>Order in Progress</p>
              <p className={COLOR.GREY_600}>Pick up where you left off</p>
            </div>
            {incompleteOrders.length ? (
              <section className={styles.section}>
                <OrdersList orders={incompleteOrders} to="ResumeOrder" />
              </section>
            ) : (
              <section className={styles.section}>
                <Card className={styles.empty} variant="outlined">
                  <img src={EmptyCart} alt="EmptyCart" />
                  <p className={styles.message}>
                    No orders created at this time.
                  </p>
                  <NewOrderButton to={ROUTE.NEW_ORDER} />
                </Card>
              </section>
            )}

            {completedOrders.length ? (
              <section className={styles.section}>
                <p className={TEXT.H6}>Completed Orders</p>
                <div className={styles.filters}>
                  <Tabs
                    activeTab={completedOrderFilter}
                    setActiveTab={setCompletedOrderFilter}
                    labels={['Upcoming', 'Past', 'Cancelled']}
                  />
                </div>
                {/* <p className={cx(COLOR.GREY_600, styles.filters)}>
                  Modify or cancel completed orders before end of show
                </p> */}

                {completedOrderFilter === 'Upcoming' &&
                  (upcomingOrders?.length ? (
                    <OrdersList orders={upcomingOrders} />
                  ) : (
                    <div className={cx(BACKGROUND.GREY_50, styles.empty)}>
                      <p className={styles.message}>
                        Upcoming orders will appear here.
                      </p>
                    </div>
                  ))}
                {completedOrderFilter === 'Past' &&
                  (pastOrders?.length ? (
                    <OrdersList orders={pastOrders} />
                  ) : (
                    <div className={cx(BACKGROUND.GREY_50, styles.empty)}>
                      <p className={styles.message}>
                        Past orders will appear here.
                      </p>
                    </div>
                  ))}
                {completedOrderFilter === 'Cancelled' &&
                  (cancelledOrders?.length ? (
                    <OrdersList orders={cancelledOrders} />
                  ) : (
                    <div className={cx(BACKGROUND.GREY_50, styles.empty)}>
                      <p className={styles.message}>
                        Cancelled orders will appear here.
                      </p>
                    </div>
                  ))}
              </section>
            ) : null}
          </>
        ) : (
          <Card className={styles.outlined} variant="outlined">
            <img src={EmptyCart} alt="EmptyCart" />
            <p className={styles.message}>No orders created at this time.</p>
            <NewOrderButton to={ROUTE.NEW_ORDER} />
          </Card>
        )}
      </Content>
    </Page>
  );
};

export default OrderHistory;
