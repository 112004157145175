/**
 * Mutate event data for naming & readability
 */

const getEventDetails = (event = {}) => ({
  branchInfo: event.facilityInfo?.branchInfo,
  dateEnd: event.dateEnd,
  dateStart: event.dateStart,
  deadlineDate: event.deadlineDate,
  facilityId: event?.facilityId,
  facilityLocation: `${event.facilityInfo?.city}, ${event.facilityInfo?.state}`,
  facilityName: event.facilityInfo?.facilityName,
  faqPdf: event.faqFile,
  id: event.id,
  image: event.image,
  isEarlyPricing: event.isEarlyPricing,
  isOffline: event.isOOAvailable === false,
  isServiceFeeTaxable: event.isServiceFeeTaxable,
  isWireTransferTaxable: event.isWireTransferTaxable,
  merchantId: event.facilityInfo?.branchInfo?.merchantId,
  name: event.name,
  redirectUrl: event.redirectUrl,
  salesTax: event.salesTax,
});

export default getEventDetails;
