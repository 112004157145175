import { any, func, number, string } from 'prop-types';

import { MobileStepper } from '@mui/material';

import { Button } from 'common/components';

import styles from './Carousel.module.css';

const Carousel = ({
  activeStep,
  onSubmit,
  setActiveStep,
  stepCount,
  children,
}) => {
  const handleNext = () =>
    activeStep === stepCount - 1
      ? onSubmit()
      : setActiveStep((prevStep) => prevStep + 1);

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <>
      {children}

      <MobileStepper
        variant="dots"
        steps={stepCount}
        position="static"
        activeStep={activeStep}
        nextButton={
          <div className={styles.submitButton}>
            <Button onClick={handleNext} text endIcon="caretRight">
              {activeStep === stepCount - 1 ? 'Done' : 'Next'}
            </Button>
          </div>
        }
        backButton={
          <Button
            onClick={handleBack}
            text
            disabled={activeStep === 0}
            startIcon="caretLeft"
          >
            Back
          </Button>
        }
        sx={{ '&.MuiMobileStepper-root': { padding: 0 } }}
      />
    </>
  );
};

Carousel.propTypes = {
  activeStep: number,
  setActiveStep: func,
  onSubmit: func,
  nextButton: any,
  stepCount: number,
  className: string,
  children: any,
};

export default Carousel;
