import axios from 'axios';
import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

const baseUrl = getBaseUrl(2);

/**
 * Updates the password
 * @param {string} linkCode
 * @param {string} password
 * @returns "Successful" string
 */
const updatePassword = async ({ linkCode, password }) => {
  try {
    await VALID.checkAll(
      ['linkCode', linkCode, VALID.STRING],
      ['password', password, VALID.PASSWORD],
    );

    if (!ENV.IS_MOCK) {
      await axios.post(`${baseUrl}customer/update_password`, {
        linkCode,
        password,
      });
    }
  } catch (error) {
    log('updatePassword', {
      data: { linkCode },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default updatePassword;
