import { Dialog } from 'common/components';
import { ROUTE } from 'common/const';

import styles from './NewOrderDialog.module.css';
import { OrderCard } from 'common/components';
import { useHistory } from 'react-router';
import { useShop } from 'app/context';

const NewOrderDialog = ({ isOpen, setIsOpen, onSubmit, orders }) => {
  const history = useHistory();
  const { reset: resetShop } = useShop();

  return (
    <Dialog
      id="new-order"
      title="Start New Order"
      size="xs"
      isOpen={isOpen}
      close={() => {}}
      onSubmit={onSubmit}
      submitText="Start New Order"
      cancelText="Resume Order"
      onCancel={() => {
        resetShop({
          boothNumber: orders[0]?.booth?.number,
          eventId: orders[0]?.eventId,
          ooId: orders[0]?.id,
        });
        history.push(ROUTE.RESUME_ORDER);
      }}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(false);
      }}
    >
      <p className={styles.dialogText}>
        If you start a new order, we will erase all progress of your current
        order. Do you want to continue?
      </p>
      <OrderCard {...orders[0]} to="ViewDetails" hideButtons={true} />
    </Dialog>
  );
};

export default NewOrderDialog;
