/**
 * Convert a number to US currency
 * Includes `$`, commas, and 2 decimal places
 * @param {number} number
 * @returns {string} currency-formatted amount
 */
const currency = (number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);

export default currency;
