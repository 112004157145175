import { bool, string } from 'prop-types';
import cx from 'classnames';

import { PrivacyPolicyLink, TextLink } from 'common/components';
import { ROUTE, TEXT } from 'common/const';

import styles from './Footer.module.css';
import { getSiteProps } from 'common/utils';
import { useAuth } from 'app/context';
import { useAppInfo } from 'common/hooks';

const { HREF } = getSiteProps();

const Footer = ({ className, stacked, print }) => {
  const inlineClass = cx({
    [styles.inline]: !stacked,
  });

  const { isAuthenticated } = useAuth();
  const { isLoading, data } = useAppInfo();

  return (
    <footer className={cx(TEXT.CAPTION, styles.footer, inlineClass, className)}>
      {!print && (
        <ul className={cx(styles.list, inlineClass)}>
          <li className={cx(styles.item, styles.inline)}>
            <TextLink color="inherit" href={HREF.CONTACT_US}>
              Contact Us
            </TextLink>
          </li>
          {isAuthenticated && (
            <li className={cx(styles.item, styles.inline)}>
              <TextLink color="inherit" to={ROUTE.FAQ}>
                FAQs
              </TextLink>
            </li>
          )}
          <li className={cx(styles.item, styles.inline)}>
            <PrivacyPolicyLink color="inherit" />
          </li>
        </ul>
      )}

      <ul className={cx(styles.list, inlineClass)}>
        <li className={cx(styles.item, inlineClass)}>
          Version{isLoading || !data?.version ? ` #.#.#.#` : ` ${data.version}`}
        </li>
        <li className={cx(styles.item, inlineClass)}>
          Powered by Edlen Event Services
        </li>
      </ul>
    </footer>
  );
};

Footer.propTypes = {
  className: string,
  stacked: bool,
};

export default Footer;
