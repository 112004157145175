import { IMAGE } from 'common/const';

/**
 * Prepends "base image url" onto image path when image path exists,
 * or returns default booth setup  image.
 * @param {string} imageName - image name
 * @returns
 */
const getBoothSetupImage = (imageName) =>
  [IMAGE.BOOTH_SETUP, imageName].join('/');

export default getBoothSetupImage;
