import { useState } from 'react';
import { object } from 'prop-types';
import { useHistory } from 'react-router';

import { Dialog, Button } from 'common/components';
import { ROUTE } from 'common/const';
import { track } from 'common/utils';

const ReviseButton = ({ isEarlyPricing, id }) => {
  // console.log('!ReviseOrder', { isEarlyPricing });
  const [isPricingNoticeOpen, setIsPricingNoticeOpen] = useState(false);

  const history = useHistory();

  return (
    <>
      <Button
        size="medium"
        outlined
        color="info"
        onClick={(e) => {
          e.stopPropagation();
          if (!isEarlyPricing) {
            setIsPricingNoticeOpen(true);
          } else {
            track('revise_order');
            history.push(ROUTE.REVISE_ORDER + '/' + id);
          }
        }}
        fullWidth
      >
        Revise Order
      </Button>

      <Dialog
        id="delete-order"
        title="Floor Pricing Notice"
        close={() => setIsPricingNoticeOpen(false)}
        isOpen={isPricingNoticeOpen}
        onSubmit={() => history.push(ROUTE.REVISE_ORDER + '/' + id)}
        onCancel={() => {
          track('revise_pricing_notice_cancel');
          setIsPricingNoticeOpen(false);
        }}
        onClick={(e) => {
          e.stopPropagation();
          track('revise_pricing_notice_continue');
          setIsPricingNoticeOpen(false);
        }}
        size="xs"
        submitColor="primary"
        submitText="Continue"
        cancelText="Cancel"
      >
        <p>
          Because the Advanced Pricing Deadline has passed, any items added to
          your order of items will reflect Floor Pricing costs.
        </p>
      </Dialog>
    </>
  );
};

ReviseButton.propTypes = {
  order: object,
};

export default ReviseButton;
