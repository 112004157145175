import axios from 'axios';

import { findItem, getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';

import { getCart } from '../utils';
import mock from './addReviseItemToCart.mock';
import { merge } from 'lodash';

const baseUrl = getBaseUrl(2);

/**
 * Add an item to the cart when revising (must not already be in the cart)
 * @param {object} cart - complete cart object
 * @param {object} item - complete item object
 * @returns {object} updated cart & suggested items
 */
const addReviseItemToCart = async (cart, item) => {
  // console.log('$ addReviseItemToCart', { cart, item });

  await VALID.checkAll(
    ['customerId', cart.customerId, VALID.CUSTOMER_ID],
    ['empOrderId', cart.empOrderId, VALID.UUID],
    ['eventId', cart.eventId, VALID.EVENT_ID],
    ['priceListId', item.priceListId, VALID.PRICE_LIST_ID],
    ['itemId', item.id, VALID.NUM],
    ['quantity', item.quantity, VALID.NUM],
    ['isAdvance', item.isEarlyPricing, VALID.BOOL],
    ['reviseOoId', cart.reviseOoId, VALID.OO_SUMMARY_ID],
  );

  try {
    if (findItem(cart.revisedItems, item)) {
      log('addReviseItemToCart.findItem', {
        data: { cart, item: { id: item.id } },
        throw: true,
        type: 'validation',
        error: `You already added ${item.itemDescription} to the cart. Please call updateQuantity if you need to increase the quantity.`,
      });
    }

    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.post(`${baseUrl}orders/add_revised_to_cart`, {
          customerId: cart.customerId,
          empOrderId: cart.empOrderId,
          eventId: cart.eventId,
          isAdvance: item.isEarlyPricing,
          itemId: item.id,
          priceListId: item.priceListId,
          quantity: item.quantity,
          reviseOoId: cart.reviseOoId,
        });

    const cartData = getCart(data.cartData);
    const itemData = findItem(cartData.items, item) ?? item;

    return {
      item: merge({}, item, itemData),
    };
  } catch (error) {
    log('addReviseItemToCart', {
      data: { cart, item },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default addReviseItemToCart;
