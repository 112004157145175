import { useEffect, useState } from 'react';
import { string } from 'prop-types';
import cx from 'classnames';
import { AppBar, Toolbar } from '@mui/material';
import { useTheme } from '@mui/system';
import { useHistory } from 'react-router';

import { useOrders, useShop } from 'app/context';
import { BACKGROUND, COLOR, ROUTE } from 'common/const';
import { Button, HowToVideo, Dialog, IconButton } from 'common/components';
import { useAppInfo } from 'common/hooks';

import Logo from '../Logo';
import UserNav from '../UserNav';
import Footer from '../Footer';
import Drawer from '../../components/Layout/Drawer';

import styles from './Header.module.css';
import { track } from 'common/utils';

export const HEADER_ID = 'app-header';

const Header = ({ backTo, children }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [dialogRoute, setDialogRoute] = useState(backTo);

  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const { header } = useTheme();
  // const { cover, uncover } = useChat();
  const { refetchIncomplete, refetchCompleted, resetOrders } = useOrders();
  const { reviseOoId } = useShop();
  const history = useHistory();
  const { isLoading, error, data } = useAppInfo();

  // Show warning on refresh and page close when revising
  useEffect(() => {
    if (reviseOoId) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [reviseOoId]);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      'Are you sure you want to leave? You will lose all progress on this page.';
    e.returnValue = message;
    return message;
  };

  // useEffect(() => {
  //   const toggle = isUserMenuOpen ? cover : uncover;
  //   toggle('header');
  // }, [isUserMenuOpen, cover, uncover]);

  const displayDiscardWarning = Boolean(reviseOoId);

  return (
    <>
      <AppBar id={HEADER_ID} position="sticky" color="transparent">
        <Toolbar
          className={cx(BACKGROUND.HEADER, COLOR.HEADER, styles.toolbar)}
        >
          {backTo ? (
            <IconButton
              icon="close"
              onClick={() => {
                if (displayDiscardWarning && backTo === ROUTE.DASHBOARD) {
                  setIsExitDialogOpen(true);
                } else {
                  refetchIncomplete();
                  history.push(backTo);
                }
              }}
              edge="start"
              color="inherit"
              aria-label="Go Back"
            />
          ) : (
            <IconButton
              icon="menu"
              onClick={() => setIsUserMenuOpen((prevOpen) => !prevOpen)}
              edge="start"
              color="inherit"
              aria-label="Main Navigation"
            />
          )}

          <div className={styles.logo}>
            <Logo
              name={header?.logo}
              onClick={() => {
                if (displayDiscardWarning) {
                  setDialogRoute(ROUTE.HOME);
                  setIsExitDialogOpen(true);
                } else {
                  history.push(ROUTE.HOME);
                }
              }}
            />
          </div>

          <div className={styles.children}>
            {!isLoading && !error && (
              <Button startIcon="video" onClick={() => setIsVideoOpen(true)}>
                How-To Guide
              </Button>
            )}
            {children}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        close={() => setIsUserMenuOpen(false)}
        hasCloseButton={false}
        isOpen={isUserMenuOpen}
        open={() => setIsUserMenuOpen(true)}
      >
        <div className={styles.drawer}>
          <UserNav
            close={() => setIsUserMenuOpen(false)}
            openVideo={() => setIsVideoOpen(true)}
          />

          <div className={styles.footer}>
            <Footer stacked />
          </div>
        </div>
      </Drawer>

      <Dialog
        id="discard-changes"
        title="Discard Changes"
        size="xs"
        isOpen={isExitDialogOpen}
        close={() => {
          setIsExitDialogOpen(false);
        }}
        onCancel={() => {
          track('discard_revision_dialog_exit');
          setIsExitDialogOpen(false);
        }}
        onSubmit={() => {
          track('discard_revision');
          if (dialogRoute === ROUTE.HOME) {
            resetOrders();
          }

          refetchCompleted();
          history.push(dialogRoute);
        }}
        submitText="Yes, Discard"
        submitColor="error"
        cancelText="No, Go Back"
      >
        If you quit, you will lose all progress for your revised order. This
        process cannot be undone. Do you want to discard?
      </Dialog>

      <HowToVideo
        isOpen={isVideoOpen}
        setIsOpen={setIsVideoOpen}
        link={data?.howToVideoLink}
      />
    </>
  );
};

Header.propTypes = {
  backTo: string,
};

export default Header;
