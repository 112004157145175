import axios from 'axios';
import { ENV } from 'common/const';
import { getBaseUrl, log } from 'common/utils';

import mock from './getCountries.mock';

const baseUrl = getBaseUrl(2);

/**
 * Returns a list of countries
 * @returns Array of objects with country data
 */
const getCountries = async () => {
  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}lookup/countries`);

    const { countries } = data;

    return countries.map(({ name, twoLetterIsoCode: code }) => ({
      label: name,
      value: code,
      code,
    }));
  } catch (error) {
    log('getCountries', {
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getCountries;
