const getAssessment = ({ responseSelections, ...data }) => {
  const options = Array.isArray(responseSelections)
    ? responseSelections.map(({ text }) => text)
    : responseSelections;

  return {
    assessmentId: data.assessmentId,
    id: data.id ?? data.assessmentId,
    options,
    question: data.question,
    required:
      typeof data.isResponseOptional === 'boolean'
        ? !data.isResponseOptional
        : undefined,
    response: data.response,
    boothSetupResponseSelections: data.boothSetupResponseSelections,
    sortOrder: data.sortOrder,
    templateId: data.templateTypeId,
    subQId: data.subAssessmentQId,
    responseType: data.responseType,
    hyperlinkTitle: data.hyperlinkTitle,
    hyperlinkURL: data.hyperlinkURL,
    assessmentType: data.assessmentType,
  };
};

export default getAssessment;
