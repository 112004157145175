const getReviseRequiredQuantity = ({
  newQuantity,
  currentQuantity,
  requiredQuantity,
}) => {
  if (newQuantity < requiredQuantity) return 0;
  else return newQuantity - requiredQuantity;
};

export default getReviseRequiredQuantity;
