import { merge } from 'lodash';

export const TYPE = {
  LOAD: 'load',
  CHANGE_ACCOUNT: 'change-account',
  CHANGE_EMAIL: 'change-email',
  CHANGE_NAME: 'change-name',
  CHANGE_COMPANY: 'change-company',
  CHANGE_PHONE: 'change-phone',
  RESET: 'reset',
  SUCCESS: 'success-toast',
  CHANGE_ADDRESS: 'change-address',
  UPDATE_ERROR: 'update-error',
  ERROR: 'error-toast',
  CLOSE_TOAST: 'close-toast',
};

export const INIT_STATE = {
  customerId: '',
  firstName: '',
  lastName: '',
  displayName: '',
  fullName: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  countryCode: '',
  toast: {},
};

export const init = (state) => merge({}, INIT_STATE, state);

export const getNames = ({ firstName, lastName }) => {
  const lastInitial = lastName?.substring(0, 1) ?? '';

  return {
    displayName: `${firstName} ${lastInitial}.`,
    fullName: `${firstName} ${lastName}`,
  };
};

const reducer = (state, { type, payload }) => {
  // console.log({ state, type, payload });

  switch (type) {
    case TYPE.LOAD:
      return {
        ...state,
        ...payload,
        ...getNames(payload),
      };
    case TYPE.CHANGE_ACCOUNT:
      return {
        ...state,
        ...payload,
        ...getNames(payload),
      };
    case TYPE.CHANGE_EMAIL:
      return {
        ...state,
        ...payload,
      };
    case TYPE.CHANGE_NAME:
      return {
        ...state,
        ...payload,
        ...getNames(payload),
      };
    case TYPE.CHANGE_PHONE:
      return {
        ...state,
        ...payload,
      };
    case TYPE.CHANGE_COMPANY:
      return {
        ...state,
        ...payload,
      };
    case TYPE.CHANGE_ADDRESS:
      return {
        ...state,
        ...payload,
      };
    case TYPE.RESET:
      return init(payload);
    case TYPE.SUCCESS:
      return {
        ...state,
        toast: {
          alert: 'success',
          message: `${payload} successfully updated!`,
        },
      };
    case TYPE.ERROR:
      return {
        ...state,
        toast: {
          alert: 'error',
          message: `${payload} not updated`,
        },
      };
    case TYPE.UPDATE_ERROR:
      return {
        ...state,
        toast: {
          alert: 'error',
          message: payload,
        },
      };
    case TYPE.CLOSE_TOAST:
      return {
        ...state,
        toast: {},
      };
    default:
      throw Error('Mismatched type for customer dispatch');
  }
};

export default reducer;
