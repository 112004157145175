import { string } from 'prop-types';
import cx from 'classnames';

import { BACKGROUND, COLOR } from 'common/const';

const HeaderButton = ({ className, onClick, children }) => {
  return (
    <button
      className={cx(BACKGROUND.HEADER_BUTTON, COLOR.HEADER_BUTTON, className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

HeaderButton.propTypes = {
  backTo: string,
};

export default HeaderButton;
