import { bool, node, number, oneOfType, string } from 'prop-types';
import cx from 'classnames';
import { TextField } from '@mui/material';
import { startCase } from 'lodash';

import { COLOR } from 'common/const';
import { Icon } from 'common/components';

import styles from './Input.module.css';

const Input = ({
  defaultValue,
  disabled,
  error,
  helperText,
  id,
  inputProps,
  label,
  leadingIcon,
  maxlength: maxLength, // react can be annoying...
  name,
  number, // to show numeric keypad
  placeholder,
  prefix,
  required,
  suffix,
  trailingIcon,
  ...rest
}) => {
  // console.log('<Input>', { name, defaultValue, error, rest });

  // shrink label whenever placeholder is present
  const shrinkProps = Boolean(placeholder) ? { shrink: true } : {};

  return (
    <TextField
      // value handling
      defaultValue={defaultValue}
      // html attributes
      id={id ?? name}
      label={label ?? startCase(name)}
      placeholder={placeholder}
      disabled={disabled}
      // feedback
      error={Boolean(error)}
      helperText={error ?? helperText}
      // options
      InputProps={{
        startAdornment: leadingIcon ? (
          <Icon name={leadingIcon} />
        ) : (
          <span className={cx(COLOR.GREY_600, styles.decoration)}>
            {prefix}
          </span>
        ),
        endAdornment: trailingIcon ? (
          <Icon name={trailingIcon} />
        ) : (
          suffix && (
            <span className={cx(COLOR.GREY_600, styles.decoration)}>
              {suffix}
            </span>
          )
        ),
      }}
      InputLabelProps={shrinkProps}
      inputProps={{
        ...inputProps,
        ...(number
          ? {
              pattern: '[0-9]*',
              inputMode: 'numeric',
            }
          : {}),
        maxLength,
        required: false, // remove browser validation
        'aria-required': required, // add for a11y
      }}
      // required={required} // add asterisk to label
      // handy defaults
      fullWidth // rely on the wrapper to control width
      autoComplete="on" // turns on auto-fill
      {...rest}
    />
  );
};
Input.propTypes = {
  defaultValue: string,
  disabled: bool,
  error: string,
  helperText: string,
  id: string,
  label: string,
  leadingIcon: string,
  maxlength: number,
  name: string.isRequired,
  placeholder: string,
  prefix: oneOfType([node, string]),
  required: bool,
  suffix: oneOfType([node, string]),
  trailingIcon: string,
};

Input.defaultProps = {
  helperText: ' ', // provides vertical space for helper text
};

export default Input;
