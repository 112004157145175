import { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import {
  savePDFToClient,
  exportPDFAsBlob,
  clearAll,
} from '@edlen/floor-plan-builder';
import { node, oneOf, string } from 'prop-types';

import { BACKGROUND, BORDER, COLOR, ROUTE } from 'common/const';
import { Button, Content, Dialog, Toast } from 'common/components';
import { date, DATE_FORMAT, getSiteOwnership } from 'common/utils';
import { useEvent } from 'common/hooks';
import Header from './Header';

import { updateFloorPlan } from 'data/documents';
import { useCustomer, useOrders, useShop } from 'app/context';

import styles from './BuilderPage.module.css';
import cx from 'classnames';

const BuilderPage = ({ isLoading, backTo, className, children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [toast, setToast] = useState({ alert: undefined, message: undefined });

  const history = useHistory();
  const { ooId, eventId } = useShop();
  const { data: event } = useEvent(eventId);
  const customer = useCustomer();

  const { findOrder, refetchIncomplete: refetchIncompleteOrders } = useOrders();
  const order = useMemo(() => findOrder(ooId), [findOrder, ooId]);

  // Show warning on refresh and page close
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      'Are you sure you want to leave? You will lose all progress on this page.';
    e.returnValue = message;
    return message;
  };

  const { mutate: upload, isLoading: isUploading } = useMutation(
    ({ file }) =>
      updateFloorPlan({ customerId: customer.customerId, eventId, ooId, file }),
    {
      onSuccess: () => {
        refetchIncompleteOrders();
        history.push(ROUTE.BOOTH_WORK);
      },
      onError: (err) => {
        setToast({ alert: 'error', message: err?.message });
      },
    },
  );

  const handleFile = async (file) => {
    upload({ file });
  };

  const dateFormat = DATE_FORMAT.SHORT_DATE;
  const boothInfo =
    order.booth.type !== 'blank'
      ? order.booth.type
      : null + order.booth.location !== 'blank'
      ? order.booth.location
      : null;

  const info = [
    {
      name: 'Event',
      values: [
        event.name,
        `${date(event.dateStart).format(dateFormat)} - ${date(
          event.dateEnd,
        ).format(dateFormat)}`,
        event.facilityName,
      ],
    },
    {
      name: 'Company/Exhibitor',
      values: [
        order.thirdPartyCompanyName
          ? order.thirdPartyCompanyName
          : customer.company,
        boothInfo,
        order.boothSize,
        `Booth Number: ${order.booth.number}`,
      ],
    },
    {
      name: 'Created By',
      values: [customer.fullName, customer.email, customer.mobile],
    },
  ];

  const exportStyles = () => {
    const owner = getSiteOwnership();
    switch (owner) {
      case 'BJCC':
        return cx(styles.bjcc);

      case 'Cajun':
        return cx(styles.cajun);

      default:
        return cx(BORDER.WHITE, COLOR.WHITE, styles.exportButton);
    }
  };

  return (
    <div className={styles.page}>
      <Header backTo={backTo} className={styles.header}>
        <div className={styles.header}>
          <Button
            className={cx(BACKGROUND.WHITE, COLOR.PRIMARY, styles.saveButton)}
            onClick={() => {
              setIsSaveDialogOpen(true);
            }}
          >
            Save to Order
          </Button>
          <Button
            className={exportStyles()}
            color="inherit"
            outlined
            onClick={() => {
              try {
                savePDFToClient(`${eventId}_${ooId}_FP.pdf`, info);
              } catch (error) {
                setToast({ alert: 'error', message: error.message });
              }
            }}
          >
            Export PDF
          </Button>
          <Button
            className={styles.startOverButton}
            color="inherit"
            text
            onClick={() => setIsDialogOpen(true)}
          >
            Start Over
          </Button>
        </div>
      </Header>

      <Content isLoading={isUploading || isLoading}>
        <Toast
          alert={toast.alert}
          anchor={{ vertical: 'top', horizontal: 'center' }}
          isOpen={Boolean(toast.alert)}
          close={() => setToast({})}
        >
          {toast.message}
        </Toast>

        <main className={cx(styles.main, className)}>{children}</main>

        <Dialog
          id="start-over"
          title="Start Over"
          size="xs"
          isOpen={isDialogOpen}
          close={() => setIsDialogOpen(false)}
          onCancel={() => setIsDialogOpen(false)}
          onSubmit={() => {
            clearAll();
          }}
          submitText="Yes, Start Over"
          cancelText="No, Cancel"
        >
          Are you sure you want to start over? You will lose all progress on
          this page.
        </Dialog>

        <Dialog
          id="submit"
          title="Save to Order"
          size="xs"
          isOpen={isSaveDialogOpen}
          close={() => setIsSaveDialogOpen(false)}
          onCancel={() => setIsSaveDialogOpen(false)}
          onSubmit={() => {
            try {
              const blob = exportPDFAsBlob(info);
              const file = new File([blob], `${eventId}_${ooId}_FP.pdf`, {
                type: 'application/pdf',
              });
              handleFile(file);
            } catch (error) {
              setToast({ alert: 'error', message: error.message });
            }
          }}
          submitText="Yes, Save to Order"
          cancelText="No, Go Back"
        >
          Check to make sure your floor plan is correct. Once you save, you
          cannot make changes to this floor plan.
        </Dialog>
      </Content>
    </div>
  );
};

BuilderPage.propTypes = {
  backTo: oneOf(Object.values(ROUTE)),
  className: string,
  children: node,
};

export default BuilderPage;
