import axios from 'axios';

import { ENV } from 'common/const';
import { getBaseUrl, log } from 'common/utils';

import mock from './getAppInfo.mock';

const baseUrl = getBaseUrl(2);

/**
 * Gets the current adapter version
 * @returns Version
 */
const getAppInfo = async () => {
  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(`${baseUrl}adapter/info`);

    return data;
  } catch (error) {
    log('getAppInfo', {
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getAppInfo;
