import { any, oneOf, string } from 'prop-types';
import cx from 'classnames';
import { Link as MuiLink } from '@mui/material';

import { useClick } from 'common/hooks';

import styles from './TextLink.module.css';

const TextLink = ({
  className,
  id: elId,
  onDelete: ignore, // strange storybook bug
  underline,
  children,
  ...rest
}) => {
  // console.log('<TextLink>', { underline, ...getComponent(rest) });
  const props = useClick(rest);

  return (
    <MuiLink
      className={cx(styles.link, className)}
      underline={underline}
      {...props}
    >
      {children}
    </MuiLink>
  );
};

TextLink.propTypes = {
  className: string,
  id: string,
  underline: oneOf(['none', 'hover', 'always']),
  children: any,
};

TextLink.defaultProps = {
  underline: 'none',
};

export default TextLink;
