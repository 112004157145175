import { useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { kebabCase, startCase } from 'lodash';
import { makeOption } from 'common/utils';

const RadioButtons = ({
  defaultValue,
  error,
  helperText,
  id,
  inputProps,
  label,
  labelProps,
  name,
  onChange,
  options,
  radioProps,
}) => {
  // console.log('<RadioButtons>', { name, defaultValue, options, error });

  const [selectedValue, setSelectedValue] = useState(
    // from props
    defaultValue ||
      // from object
      options[0].value ||
      // from array
      options[0],
  );

  const { onChange: inputPropsOnChange, ...props } = inputProps;

  return (
    <FormControl component="fieldset" error={Boolean(error)}>
      <FormLabel component="legend">{label ?? name}</FormLabel>
      <RadioGroup
        aria-label={label ?? startCase(name)}
        value={selectedValue}
        id={id}
        name={id}
        onChange={(evt) => {
          if (typeof inputPropsOnChange === 'function') {
            inputPropsOnChange(evt);
          }
          setSelectedValue(evt.target.value);
          onChange(evt);
        }}
      >
        {options.map((opt) => {
          const {
            value,
            label,
            disabled,
            labelProps: optLabelProps,
            radioProps: optRadioProps,
          } = makeOption(opt);

          return (
            <FormControlLabel
              key={`${kebabCase(value)}:${label}`}
              disabled={disabled}
              label={label}
              {...labelProps}
              {...optLabelProps}
              control={
                <Radio
                  value={value}
                  {...radioProps}
                  {...optRadioProps}
                  {...props}
                />
              }
            />
          );
        })}
      </RadioGroup>
      <FormHelperText>{error ?? helperText}</FormHelperText>
    </FormControl>
  );
};

RadioButtons.propTypes = {
  defaultValue: string,
  error: string,
  helperText: string,
  id: string,
  inputProps: object,
  labelProps: object,
  name: string.isRequired,
  onChange: func,
  options: oneOfType([
    arrayOf(string),
    arrayOf(
      shape({
        disabled: bool,
        label: string,
        labelProps: object,
        radioProps: object,
        value: string,
      }),
    ),
  ]).isRequired,
  radioProps: object,
  label: string,
};

RadioButtons.defaultProps = {
  helperText: ' ',
  inputProps: {},
  onChange: () => null,
};

export default RadioButtons;
