import { object, shape, string } from 'prop-types';

import { Content } from 'common/components';
import { useBankInfo } from 'common/hooks';
import { PAYMENT_METHOD, TEXT } from 'common/const';

import styles from './TransferInfo.module.css';

const TransferInfo = ({ transferType }) => {
  const { isLoading, data: bankInfo, error } = useBankInfo();

  return (
    <Content isLoading={isLoading} error={error?.message}>
      {bankInfo && (
        <>
          <div className={styles.title}>
            <p className={styles.p}>Send payment to:</p>
          </div>

          {transferType === PAYMENT_METHOD.ACH ? (
            <div className={styles.info}>
              <p className={TEXT.BOLD}>{bankInfo.achBank}</p>
              <p>{bankInfo.achAddress}</p>
              <dl>
                <div className={styles.pair}>
                  <dt className={styles.label}>ABA#</dt>
                  <dd>{bankInfo.achAba}</dd>
                </div>
                <div className={styles.pair}>
                  <dt className={styles.label}>Acct#</dt>
                  <dd>{bankInfo.achAccountNumber}</dd>
                </div>
              </dl>
            </div>
          ) : (
            <>
              <div className={styles.info}>
                <p className={TEXT.BOLD}>{bankInfo.achBank}</p>
                <p className={TEXT.BOLD}>Wire Transfer</p>
                <dl>
                  <div className={styles.pair}>
                    <dt className={styles.label}>ABA#</dt>
                    <dd>{bankInfo.wireAba}</dd>
                  </div>
                  <div className={styles.pair}>
                    <dt className={styles.label}>Acct#</dt>
                    <dd>{bankInfo.wireAccountNumber}</dd>
                  </div>
                </dl>
              </div>
              <div className={styles.info}>
                <p className={TEXT.BOLD}>International Wire Transfer</p>
                <dl>
                  <div className={styles.pair}>
                    <dt className={styles.label}>Swift Code</dt>
                    <dd>{bankInfo.wireSwift}</dd>
                  </div>
                  <div className={styles.pair}>
                    <dt className={styles.label}>Acct#</dt>
                    <dd>{bankInfo.wireAccountNumber}</dd>
                  </div>
                </dl>
              </div>
            </>
          )}
        </>
      )}

      <p className={styles.info}>
        A U.S. based credit card (USD $) is required as a payment guarantee.
      </p>
    </Content>
  );
};

TransferInfo.propTypes = {
  account: shape({
    bankName: string,
    number: string,
    routing: string,
    type: string,
  }),
  address: object,
};

export default TransferInfo;
