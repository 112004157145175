import { any, bool, oneOf, string } from 'prop-types';
import cx from 'classnames';
import { Button as MuiButton } from '@mui/material';
import { v4 } from 'uuid';

import { useClick } from 'common/hooks';
import { Icon, IconButton } from 'common/components';

import { BUTTON_SIZES, BUTTON_COLORS, BUTTON_TYPES } from '../../const';

import styles from './Button.module.css';

/**
 * `contained` is the default variant
 * use props `outlined` or `text` for variations
 */
const VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

const Button = ({
  className,
  color = 'primary',
  endIcon,
  fullWidth,
  id: buttonId,
  inputProps: ignore,
  name,
  outlined,
  size = 'large',
  startIcon,
  swap,
  text,
  type: typeProp = 'button',
  children,
  ...rest
}) => {
  // set `variant` based on `outlined` & `text` flags
  const variant = outlined
    ? VARIANT.OUTLINED
    : text
    ? VARIANT.TEXT
    : VARIANT.CONTAINED;

  const type = BUTTON_TYPES.includes(name) ? name : typeProp;

  // console.log('<Button>', { name, variant, color });

  const props = useClick({ type, ...rest });
  const id = buttonId ?? v4();

  return (
    <>
      {swap && (
        <IconButton
          aria-labelledby={id}
          className={cx(styles.icon, className)}
          icon={swap}
          {...props}
        />
      )}
      <MuiButton
        color={color}
        endIcon={endIcon ? <Icon name={endIcon} /> : null}
        id={id}
        size={size}
        startIcon={startIcon ? <Icon name={startIcon} /> : null}
        variant={variant}
        name={name}
        type={type}
        fullWidth={fullWidth}
        className={cx(
          styles.button,
          { [styles.swap]: Boolean(swap) },
          className,
        )}
        {...props} // goes last so you can override defaults
      >
        {children}
      </MuiButton>
    </>
  );
};

Button.propTypes = {
  children: any,
  className: string,
  color: oneOf(BUTTON_COLORS),
  endIcon: string,
  name: string,
  type: oneOf(BUTTON_TYPES),
  outlined: bool,
  size: oneOf(BUTTON_SIZES),
  startIcon: string,
  swap: string, // swaps Button for IconButton, on small screens
  text: bool,
  fullWidth: bool,
};

export default Button;
