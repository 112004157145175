// Cajundome is a separate organization

const ORG = {
  BJCC: 'Edlen',
  CAJUN: 'Cajundome',
  EDLEN: 'Edlen',
  TEST: 'Edlen',
};

export default ORG;
