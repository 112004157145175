import { deepmerge } from '@mui/utils';
import common from './common';

const bjcc = deepmerge(common, {
  palette: {
    primary: {
      main: '#7a884d',
      light: '#aab87a',
      dark: '#4d5b23',
    },
  },
});

export default bjcc;
