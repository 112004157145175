import { useState } from 'react';
import { object, string } from 'prop-types';
import cx from 'classnames';

import { COLOR, TEXT } from 'common/const';
import {
  BoothSetup,
  EventDates,
  EventLogo,
  Icon,
  Tooltip,
} from 'common/components';
import { useWindowSize } from 'common/hooks';
import { date, DATE_FORMAT } from 'common/utils';

import styles from './EventInfo.module.css';

const EventInfo = ({
  advancedPricing,
  booth,
  className,
  end,
  facilityName,
  id,
  image,
  isCompletedOrder,
  name,
  start,
}) => {
  // console.log('<EventInfo>', { advancedPricing, booth, className, end, facilityName, id, name, start });

  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < 704;

  const [isAdvancedTooltipOpen, setIsAdvancedTooltipOpen] = useState(false);

  return (
    <section className={cx(className)}>
      <div className={cx(styles.image)}>
        <EventLogo name={name} image={image} />
      </div>
      <div className={styles.section}>
        <h4 className={cx(TEXT.SUBTITLE_2)}>{name}</h4>

        <ol>
          <li className={cx(TEXT.BODY_2, styles.item)}>
            <p className={styles.spacing}>
              <EventDates start={start} end={end} />
            </p>
            {facilityName && <p>{facilityName}</p>}
          </li>
        </ol>
      </div>
      <ol className={styles.section}>
        <li className={cx(TEXT.BODY_2, styles.item)}>
          <span className={cx(styles.inline)}>
            <BoothSetup booth={booth} inline isOrder />
          </span>
        </li>
      </ol>

      {Boolean(advancedPricing) && !isCompletedOrder && (
        <div className={cx(styles.section, styles.item)}>
          <Tooltip
            isOpen={isAdvancedTooltipOpen}
            title={
              <p className={TEXT.SUBTITLE_2}>
                The deadline to order with discounted pricing
              </p>
            }
            arrow
            placement={isMobile ? 'top' : 'right'}
          >
            <div
              className={cx(TEXT.BODY_2, styles.deadlineDate)}
              onMouseOver={() => setIsAdvancedTooltipOpen(true)}
              onMouseOut={() => setIsAdvancedTooltipOpen(false)}
            >
              <span className={cx(COLOR.PRIMARY, styles.nowrap)}>
                Advanced Pricing Deadline
              </span>
              <Icon name="info" color="primary" />
            </div>
          </Tooltip>
          <p className={cx(styles.nowrap, styles.spacing)}>
            {date(advancedPricing).format(DATE_FORMAT.MEDIUM_DATE)}
          </p>
        </div>
      )}
    </section>
  );
};

EventInfo.propTypes = {
  advancedPricing: string,
  booth: object,
  className: string,
  end: string,
  facilityName: string,
  id: string,
  name: string,
  start: string,
};

export default EventInfo;
