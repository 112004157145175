import { background, border, color, text, zIndex } from 'themes/globalStyles';

/**
 * Get list of styles to output as global CSS
 * @param {object} theme - MUI theme, using createTheme
 * @return {object} styles to apply to GlobalStyles component
 */
const getGlobalStyles = (theme) => {
  const globalStyles = {
    ...background(theme),
    ...border(theme),
    ...color(theme),
    ...text(theme),
    ...zIndex(theme),
  };

  console.info('[Theme]', { theme, globalStyles });

  return globalStyles;
};

export default getGlobalStyles;
