import { TextLink } from 'common/components';
import cx from 'classnames';

import { ENV } from 'common/const';

import styles from './TermsOfUseLink.module.css';

const TermsOfUseLink = ({ className, ...props }) => (
  <TextLink
    href={ENV.PUBLIC_URL + '/LegalNotices/TermsAndConditions.pdf'}
    className={cx(className, styles.link)}
    {...props}
  >
    Terms of Use
  </TextLink>
);

export default TermsOfUseLink;
