import { useState } from 'react';
import { useParams } from 'react-router';
import { object } from 'prop-types';
import cx from 'classnames';
import { BOOTH_SETUP, BORDER, TEXT } from 'common/const';
import { Content, OptionButton, Require } from 'common/components';

import ErrorMessage from '../ErrorMessage';
import Step from './Step';

import styles from './GroupList.module.css';

const NO_AREA = 'no-name-area-name-for-error-handling';

const GroupList = ({ findStep }) => {
  const { assessmentId } = useParams();
  const step = findStep(BOOTH_SETUP.GROUP_LIST, assessmentId);

  // console.log('<GroupList>', { step });
  const {
    response,
    question,
    required,
    submit,
    error,
    isLoading,
    isSubmitting,
    back,
    buttonText,
  } = step ?? {};

  const [hasError, setHasError] = useState();
  const [selected, setSelected] = useState(response);

  const groupListOptions =
    step?.boothSetupResponseSelections?.groupedListResponseOptions?.sort(
      (a, b) => (a.headerSortOrder = b.headerSortOrder),
    );

  const headers = groupListOptions?.reduce((list, option) => {
    const { header, optionID, optionValue, optionSortorder } = option;
    if (!list[header]) {
      list[header] = [];
    }
    list[header].push({ optionID, optionValue, optionSortorder });
    return list;
  }, {});

  return (
    <Require data={[question]}>
      <Content isLoading={!step || isLoading} error={error?.message}>
        <Step
          submit={() => {
            if (required && !selected) {
              setHasError(true);
            } else {
              submit(selected);
            }
          }}
          isSubmitting={isSubmitting}
          back={back}
          buttonText={buttonText}
        >
          <h1 className={styles.p}>{question}</h1>

          <ul className={styles.GroupLists}>
            {Object.entries(headers ?? {})?.map(([header, listOptions]) => (
              <li className={cx(BORDER.DIVIDER, styles.area)} key={header}>
                {header !== NO_AREA && (
                  <h2 className={cx(TEXT.BODY_2, styles.heading)}>{header}</h2>
                )}

                <ul className={styles.list}>
                  {listOptions.map(({ optionValue }) => (
                    <li key={header + optionValue}>
                      {/* {console.log(optionValue, selected)} */}
                      <OptionButton
                        value={optionValue}
                        selected={selected === optionValue}
                        onClick={(value) => setSelected(value)}
                      />
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>

          <ErrorMessage isOpen={hasError} close={() => setHasError(false)}>
            Please select an option to continue.
          </ErrorMessage>
        </Step>
      </Content>
    </Require>
  );
};

GroupList.propTypes = {
  step: object,
};

export default GroupList;
