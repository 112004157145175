import { Tooltip as MuiTooltip } from '@mui/material';
import { useState } from 'react';

const Tooltip = ({
  arrow,
  className,
  hover,
  isOpen,
  placement,
  title,
  tracking,
  width,
  children,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <MuiTooltip
      open={hover ? isTooltipOpen : isOpen}
      title={title}
      arrow={arrow}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'grey.800',
            '& .MuiTooltip-arrow': {
              color: 'grey.800',
            },
            width: { width },
          },
        },
      }}
    >
      {hover ? (
        <div
          onMouseOver={() => {
            tracking();
            setIsTooltipOpen(true);
          }}
          onMouseOut={() => setIsTooltipOpen(false)}
        >
          <div
            className={className}
            onClick={() => {
              tracking();
              setIsTooltipOpen(!isTooltipOpen);
            }}
          >
            {children}
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </MuiTooltip>
  );
};

export default Tooltip;
