import { useState } from 'react';
import {
  any,
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { kebabCase, startCase } from 'lodash';
import { makeOption } from 'common/utils';

const Select = ({
  defaultValue,
  error,
  helperText,
  id,
  inputProps,
  label,
  labelProps,
  menuProps,
  multiple,
  name,
  onChange,
  options,
}) => {
  // console.log('<Select>', { name, defaultValue, multiple, options });
  const [selectedValue, setSelectedValue] = useState(
    defaultValue || (multiple ? [] : ''),
  );

  const { onChange: inputPropsOnChange, ...props } = inputProps; // remove onChange

  return (
    <FormControl component="fieldset" fullWidth error={error}>
      <InputLabel id={`${name}-label`}>{label ?? name}</InputLabel>
      <MuiSelect
        labelId={`${name}-label`}
        id={id ?? name}
        label={label ?? startCase(name)}
        multiple={multiple}
        value={selectedValue}
        onChange={(evt) => {
          if (typeof inputPropsOnChange === 'function') {
            inputPropsOnChange(evt);
          }
          setSelectedValue(evt.target.value);
          onChange(evt);
        }}
        MenuProps={menuProps}
        inputProps={props}
      >
        {options.map((opt) => {
          const {
            value,
            label: optLabel,
            disabled,
            labelProps: optLabelProps,
          } = makeOption(opt);

          return (
            <MenuItem
              key={`${kebabCase(value ?? optLabel)}:${optLabel}`}
              value={value ?? optLabel}
              disabled={disabled}
              {...labelProps}
              {...optLabelProps}
            >
              {optLabel}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

Select.propTypes = {
  defaultValue: oneOfType([string, number, array]),
  id: string,
  inputProps: object,
  label: string,
  labelProps: object,
  multiple: bool,
  name: string.isRequired,
  onChange: func,
  options: oneOfType([
    arrayOf(any),
    arrayOf(
      shape({
        disabled: bool,
        label: string,
        labelProps: object,
        value: string,
      }),
    ),
  ]).isRequired,
};

Select.defaultProps = {
  helperText: ' ',
  inputProps: {},
  onChange: () => null,
};

export default Select;
