import { useEffect, useMemo, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useHistory } from 'react-router';

import {
  Alert,
  BoothSetup,
  Button,
  Content,
  Error,
  EventOfflineDialog,
  FloorPlan,
  Form,
  OptionButton,
  SubmitButton,
} from 'common/components';
import { ROUTE, TEXT } from 'common/const';
import { getAssessmentData, track } from 'common/utils';
import { useEventStatus, useBoothAssessment } from 'common/hooks';

import { useShop, useAuth, useOrders } from 'app/context';

import cx from 'classnames';
import styles from './BoothQuestions.module.css';

const BoothQuestions = ({
  isLoading,
  setState,
  isFloorPlanUploaded,
  documentId,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const { customerId } = useAuth();
  const { ooId, eventId, reviseOoId } = useShop();
  const { isOffline } = useEventStatus(eventId);
  const { findOrder } = useOrders();
  const order = findOrder(ooId);

  useEffect(() => {
    if (isOffline) {
      setIsOpen(true);
    }
  }, [isOffline]);

  // Go to top of page on intial load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isFetchingQuestions, questions } = useBoothAssessment(ooId, {
    questionType: 2,
    refetchOnMount: true,
  });

  const data = useMemo(() => {
    if (!isFetchingQuestions) {
      setDisabled(false);
      return getAssessmentData(questions, order.assessmentResponseData);
    }
  }, [questions, order, setDisabled, isFetchingQuestions]);

  const {
    submit: submitAssessment,
    isLoading: isLoadingIncomplete,
    isSubmitting: isSubmittingAssessment,
    error: submitError,
  } = useBoothAssessment(ooId, {
    questionType: 2,
  });

  const handleSubmit = () => {
    setState({
      currentStep: 2,
      showFileUpload: order?.showFileUpload,
      boothQuestions: data,
    });
    history.push(ROUTE.CONTACT);
    track('booth_work_next_click');
  };

  useEffect(() => {
    if (isSubmittingAssessment || isLoadingIncomplete) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isSubmittingAssessment, isLoadingIncomplete]);

  return (
    <Content isLoading={isLoading || isFetchingQuestions}>
      <h6 className={cx(styles.header, TEXT.H6)}>Booth</h6>
      <section className={styles.section}>
        <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>Booth Setup</p>
        <BoothSetup
          booth={order?.booth}
          showNumber={order?.booth.number !== '1'}
          inline
          isOrder
        />
      </section>

      <section className={styles.section}>
        <p className={cx(TEXT.SUBTITLE_1, styles.subtitle)}>Booth Work</p>
        {data?.length > 0 || order?.showFileUpload ? (
          <p className={TEXT.BODY_1}>
            The following questions are{' '}
            <span className={styles.underline}>optional</span> and are not
            required to place your order.
          </p>
        ) : null}

        {submitError && (
          <div className={styles.error}>
            <Error>{submitError?.message}</Error>
          </div>
        )}
      </section>

      <Form onPass={handleSubmit}>
        <ol className={styles.list}>
          {data?.map(
            ({ question, response, id, assessmentId, options = [] }) => (
              <li key={assessmentId} className={styles.item}>
                <p className={styles.question}>{question}</p>

                {options?.map((value) => (
                  <OptionButton
                    key={`${id}-${value}`}
                    value={value}
                    selected={value === response}
                    onClick={(responseValue) => {
                      // If the response doesn't exist, then add
                      if (!response) {
                        submitAssessment({
                          question: { assessmentId, id },
                          response: responseValue,
                          onSuccess: () => {},
                        });
                      } else if (response !== responseValue) {
                        submitAssessment({
                          question: { assessmentId, id },
                          response: responseValue,
                          onSuccess: () => {},
                        });
                      }
                    }}
                    disabled={disabled}
                    className={styles.option}
                  />
                ))}
              </li>
            ),
          )}

          {order?.showFileUpload && order?.floorPlanExists && reviseOoId ? (
            <div className={styles.warning}>
              <Alert severity="warning">
                Upload a new floor plan that reflects the changes of your order.
              </Alert>
            </div>
          ) : null}

          <FloorPlan
            order={order}
            isFloorPlanUploaded={isFloorPlanUploaded}
            customerId={customerId}
            documentId={documentId}
            setState={setState}
          />
        </ol>
        {data?.length === 0 && !order?.showFileUpload && (
          <div className={styles.defaultText}>
            <p>
              Because some Booth Setup details are not available, there are no
              questions at this time. A member of the Edlen team will contact
              you to confirm your booth details.
            </p>
          </div>
        )}

        <div className={styles.actions}>
          <SubmitButton name="submit">
            {reviseOoId ? 'Continue to Revise' : 'Continue to Contact'}
          </SubmitButton>
          <Button
            text
            name="back"
            to={{
              pathname: ROUTE.SHOPPING,
              state: { from: ROUTE.BOOTH_WORK },
            }}
            onClick={() => track('booth_work_back_click')}
          >
            Return to Cart
          </Button>
        </div>
      </Form>

      <EventOfflineDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Content>
  );
};

BoothQuestions.propTypes = {
  isFloorPlanUploaded: bool,
  isLoading: bool,
  setIsFloorPlanUploaded: func,
  setStep: func,
  documentId: string,
};

BoothQuestions.defaultProps = {
  isFloorPlanUploaded: false,
};

export default BoothQuestions;
