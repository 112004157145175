import { useState } from 'react';
import { string } from 'prop-types';
import cx from 'classnames';
import { Box } from '@mui/material';
import { Z_INDEX } from 'common/const';

const withShadow =
  (Component) =>
  ({ className, boxShadow = 2, ...props }) => {
    const [isActive, setIsActive] = useState(false);

    return (
      <Box
        className={cx(className, Z_INDEX.MODAL)}
        sx={{
          boxShadow,
        }}
      >
        <Component {...props} isActive={isActive} setIsActive={setIsActive} />
      </Box>
    );
  };

withShadow.propTypes = {
  className: string,
};

export default withShadow;
