import { CssBaseline, GlobalStyles } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { TEXT } from 'common/const';
import { useEffect } from 'react';
import { getGlobalStyles } from 'themes';

// TODO: move reset.css to the top of head
import '../css'; // global css

const Styles = ({ theme, children }) => {
  // console.log('<Styles>', { theme });

  useEffect(() => {
    const root = document.getElementById('root');

    root.classList.add(TEXT.BODY);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* TODO: move global styles to bottom of head */}
        <GlobalStyles styles={getGlobalStyles(theme)} />

        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Styles;
