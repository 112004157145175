import { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';

import { Content, Dialog, Loading } from 'common/components';
import { BORDER, COLOR, TEXT } from 'common/const';
import ResultItem from './ResultItem';

import styles from './Results.module.css';

const Results = ({ results, isLoading, error }) => {
  // console.log('<Results>', { results, isLoading, error });

  const list = useMemo(() => results ?? [], [results]);
  const isRerender = useRef(false); // as opposed to initial render
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hrefUrl, setHrefUrl] = useState('');
  const [facilityName, setFacilityName] = useState('');

  useEffect(() => {
    if (isRerender.current) {
      setIsUpdating(true);
    } else {
      // only set to true if we got a list or an error
      isRerender.current = Boolean(list.length) || error;
    }

    const timeout = setTimeout(() => setIsUpdating(false), 500);

    return () => clearTimeout(timeout); // cancel timeout if another update starts
  }, [list, error]);

  return (
    <Content
      isLoading={isLoading}
      error={error?.message}
      waitFor={() => Boolean(results)}
    >
      <h4 className={cx(TEXT.CAPTION, COLOR.GREY_600, styles.feedback)}>
        {isUpdating ? (
          <span className={styles.loading}>
            <Loading size="button" />
          </span>
        ) : (
          `${list.length} Results`
        )}
      </h4>

      <ol className={styles.list}>
        {list.map((event) => (
          <li key={event.id} className={cx(BORDER.GREY_100, styles.item)}>
            <ResultItem
              event={event}
              setFacilityName={setFacilityName}
              setHrefUrl={setHrefUrl}
              setIsOpen={setIsOpen}
            />
          </li>
        ))}
      </ol>

      <Dialog
        id="third-party-redirect"
        title="Leaving Online Ordering"
        size="xs"
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        onSubmit={() => {}}
        submitText="Yes, Continue"
        cancelText="Cancel"
        href={hrefUrl}
      >
        You will be redirected to{' '}
        <span className={TEXT.BOLD}>{facilityName} Exhibitor Services</span> to
        place your order. Do you want to continue?
      </Dialog>
    </Content>
  );
};

export default Results;
