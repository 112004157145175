import * as yup from 'yup';
import * as EmailValidation from 'email-validator';
import Payment from 'payment';

import { validate as uuidValidate } from 'uuid';

import { ADDRESS_TYPE } from 'common/const';
import { UNITED_STATES, CANADA } from 'common/const/states';

import { log } from 'common/utils';

/**
 * Centralized group of validation rules.
 * These can be further extended in forms using method chaining.
 * https://www.npmjs.com/package/yup#user-content-api
 *
 * Example usage for required email:
 *   Email: VALID.EMAIL.required(),
 */

const VALID = {
  /**
   * Check if a value is valid with a specific test
   * @param value - value to test Ex. example@gmail.com
   * @param test - VALID function to test value on Ex. VALID.EMAIL
   * @returns true if valid OR throws an error if invalid
   */
  check: async (value, test) => {
    const schema = yup.object().shape({ value: test });

    await schema
      .validate({ value })
      .then(() => true)
      .catch(({ errors }) => {
        log('validation check fail', {
          error: errors,
          data: test, // do not log value, for security
          throw: true,
          type: 'validation',
        });
      });
  },

  /**
   * Check if an array of values is valid with specific tests
   * @param args - Checks to make format: [name, value, test], [name, value, test, isOptional], [...]
   *               Ex. ['customerId', cart.customerId, VALID.CUSTOMER_ID],
   * @returns true if valid OR throws an error if invalid
   */
  checkAll: async (...args) => {
    // Create an array from args
    // Reduce each element of the array into an object (obj)
    const { shape, values } = Array.from(args).reduce(
      (obj, [name, value, test, isOptional]) => ({
        shape: {
          ...obj.shape,
          [name]: isOptional
            ? test.notRequired()
            : test.required(`${name} is required!`),
        },
        values: {
          ...obj.values,
          [name]: value,
        },
      }),
      {},
    );
    const schema = yup.object().shape(shape);

    await schema
      .validate(values)
      .then(() => true)
      .catch(({ errors }) => {
        log('validation checkAll fail', {
          error: errors,
          data: shape, // do not log values, for security
          throw: true,
          type: 'validation',
        });
      });
  },

  checkAllObject: async (values, test) => {
    const shape = Object.entries(test).reduce(
      (previous, [label, validation]) => {
        return { ...previous, [label]: validation };
      },
      [],
    );

    const schema = yup.object().shape(shape);

    return await schema
      .validate(values)
      .then(() => {
        return true;
      })
      .catch(({ errors }) => {
        return false;
      });
  },

  // Generic Types
  BOOL: yup.bool(),
  STRING: yup.string(),
  NUM: yup.number(),
  NUMBER: yup
    .string()
    .test('number', 'Must be a number', (value) => !value || !isNaN(value)),
  OBJECT: yup.object(),
  UUID: yup.string().test('uuid', 'value must be a uuid value', uuidValidate),
  ALPHA: yup.string().matches(/^[A-Za-z]+$/, 'value must only contain letters'),

  // --------------------------------------------------------
  // Customer Facing
  // --------------------------------------------------------

  // login, sign up
  EMAIL: yup
    .string()
    .test('email', 'Invalid Email', (email) => EmailValidation.validate(email)),
  PASSWORD: yup.string(),

  // addresses
  COUNTRY: yup.string().nullable(),
  CITY: yup.string().nullable(),
  TOWN: yup.string().nullable(),
  STATE: yup.string().nullable(),
  US_STATE: yup
    .string()
    .test('state', 'Invalid state', (state) =>
      UNITED_STATES.some(
        (input) => input.toLowerCase() === state.toLowerCase(),
      ),
    ),
  CAN_PROVINCE: yup
    .string()
    .test('province', 'Invalid province', (province) =>
      CANADA.some((prov) => prov.toLowerCase() === province.toLowerCase()),
    ),
  REGION: yup.string().nullable(),
  ADDRESS: yup.string().nullable(),
  ADDRESS_2: yup.string().nullable(),
  ZIP: yup
    .string()
    .nullable()
    .matches(/\b\d{5}\b/g, 'Zip code must be exactly 5 numbers'),
  CANDIAN_POSTAL: yup
    .string()
    .matches(
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]\d[ABCEGHJ-NPRSTV-Z]\d$/i,
      'Invalid postal code',
    ),
  LOCALITY: yup.string().nullable(),
  PROVINCE: yup.string().nullable(),
  DISTRICT: yup.string().nullable(),
  POSTAL_CODE: yup.string().nullable(),

  // Name
  FIRST_NAME: yup.string(),
  LAST_NAME: yup.string(),
  FULL_NAME: yup.string(),

  // Phone Number
  PHONE_NUMBER: yup.string().nullable().max(25, 'Phone number is too long'),

  // Further card types validation added directly in the form
  CARD_NUMBER: yup
    .string()
    .test('card', 'Invalid Credit Card Number', (num) =>
      Payment.fns.validateCardNumber(num),
    ),

  EXPIRATION_DATE: yup
    .string()
    .test('exp', 'Invalid Expiration Date', (exp) =>
      Payment.fns.validateCardExpiry(exp),
    ),

  CVV: yup
    .string()
    .test('cvv', 'Invalid Security Code', (cvv, form) =>
      Payment.fns.validateCardCVC(
        cvv,
        Payment.fns.cardType(form.parent.accountNumber),
      ),
    ),

  FILE_SIZE: (max) =>
    yup.number().max(max * 1000000, `File size must be less than ${max}MB`),

  FILE_TYPE: (type) => yup.string().matches(type, `File must be a '${type}'`),

  // --------------------------------------------------------
  // Internal
  // --------------------------------------------------------

  // Addresses
  BILLING_ADDRESS_ID: yup
    .string()
    .test('uuid', 'billingAddressId must be a uuid value', uuidValidate),
  EXHIBITOR_ADDRESS_ID: yup
    .string()
    .test('uuid', 'exhibitorAddressId must be a uuid value', uuidValidate),
  ADDRESS_ID: yup
    .string()
    .test('uuid', 'addressId must be a uuid value', uuidValidate),

  ADDRESS_TYPE: yup
    .string()
    .test('oneof', 'Invalid Address Type', (addressType) =>
      Object.values(ADDRESS_TYPE).includes(addressType),
    ),

  // Customer
  CUSTOMER_ID: yup
    .string()
    .test('uuid', 'customerId must be a uuid value', uuidValidate),

  COMPANY_NAME: yup.string(),

  // Location
  COUNTRY_ID: yup.number(),

  // Customer
  EXHIBITOR_ID: yup
    .string()
    .test('uuid', 'exhibitorId must be a uuid value', uuidValidate),

  // Event
  EVENT_ID: yup.string(),

  // Booth Questions
  ASSESSMENT_RESPONSE_ID: yup
    .string()
    .test('uuid', 'Assessment Response ID must be a uuid value', uuidValidate),

  // Ordering
  PRICE_LIST_ID: yup
    .string()
    .test('uuid', 'priceListId must be a uuid value', uuidValidate),
  OO_SUMMARY_ID: yup.number(),
  OOID: yup.number(),

  DOCUMENT_ID: yup
    .string()
    .test('uuid', 'documentId must be a uuid value', uuidValidate),

  CART_ID: yup
    .string()
    .test('uuid', 'cartId must be a uuid value', uuidValidate),
};

export default VALID;
