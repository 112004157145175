import cx from 'classnames';

import { useCustomer } from 'app/context';
import { VALID } from 'common/const';
import {
  Dialog,
  Form,
  CountryInput,
  SubmitButton,
  Input,
} from 'common/components';
import styles from './AddressDialog.module.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAddress } from 'common/hooks';

const AddressDialog = () => {
  const [isValidAddress, setIsValidAddress] = useState(undefined);

  const customer = useCustomer();

  const {
    buildValidation,
    countries,
    countryCode,
    FIELD,
    updateCountry,
    STYLE,
    AddressItem,
    INIT_ADDRESS,
  } = useAddress({ countryCode: customer?.countryCode });

  const defaultValues = useMemo(
    () => ({
      ...customer,
      countryCode,
    }),
    [customer, countryCode],
  );

  const isCompleteAddress = useCallback(
    async () =>
      await VALID.checkAllObject(customer, {
        ...buildValidation(),
        phone: VALID.STRING.required(),
      }).then((data) => {
        setIsValidAddress(data);
      }),
    [customer, buildValidation],
  );

  useEffect(() => {
    if (isValidAddress === undefined) {
      isCompleteAddress();
    }
  }, [isValidAddress, isCompleteAddress]);

  return (
    <Dialog
      id="address"
      title="Missing Required Information"
      size="sm"
      isOpen={!isValidAddress}
      close={() => {}}
    >
      <p>
        For a seamless Checkout experience, fill out your Company's information
        below and we'll save it to your account for future orders.
      </p>
      <Form
        validation={{
          ...buildValidation(),
          countryCode: VALID.COUNTRY.required('Country is required'),
          phone: VALID.PHONE_NUMBER.required('Office phone number is required'),
        }}
        onPass={(data) => {
          const address = Object.keys(FIELD).reduce(
            (previous, current) => ({
              ...previous,
              [current]: data[current] ?? '',
            }),
            {
              ...INIT_ADDRESS,
              countryCode: data.countryCode,
              phone: data.phone,
            },
          );

          customer.changeAddress({
            address: { ...defaultValues, ...address },
          });
        }}
        onFail={(errors) => {
          // console.error({ errors });
        }}
        defaultValues={defaultValues}
      >
        <ol>
          <li className={styles.country}>
            <CountryInput
              label="Country / Region"
              name="countryCode"
              options={countries}
              defaultValue={countryCode}
              onChange={updateCountry}
            />
          </li>
        </ol>
        <ol className={cx(STYLE.address)}>
          <AddressItem name="address1" />
          <AddressItem name="address2" />
          <AddressItem name="city" />
          <AddressItem name="state" />
          <AddressItem name="zip" />
        </ol>

        <ol>
          <li>
            <Input
              name="phone"
              label="Office Phone Number *"
              maxlength={25}
              required
            />
          </li>
        </ol>
        <div className={styles.submit}>
          <SubmitButton>Submit</SubmitButton>
        </div>
      </Form>
    </Dialog>
  );
};

export default AddressDialog;
