import { useQuery } from '@tanstack/react-query';

import { getEventCatalogCategoryNames } from 'data/events';

const useCatalogCategoryNames = ({
  ooId,
  eventId,
  boothType,
  boothLocation,
  items,
}) => {
  const {
    isLoading: isCatalogNamesLoading,
    error: categoryNamesError,
    data: categories = [],
  } = useQuery(
    ['getEventCatalogCategoryNames', { eventId, ooId }],
    () =>
      getEventCatalogCategoryNames({
        eventId,
        ooId,
        boothType: boothType,
        boothLocation: boothLocation,
      }),
    {
      enabled: Boolean(eventId) && Boolean(ooId) && Boolean(items.length),
      cacheTime: 0,
    },
  );

  return {
    categories,
    isCatalogNamesLoading,
    categoryNamesError,
  };
};

export default useCatalogCategoryNames;
