import { useQuery } from '@tanstack/react-query';

import { getAssessmentQuestions } from 'data/events';

const useQuestionsData = (ooId, options = {}) => {
  // console.log('!useQuestionsData', { ooId, options });

  const { questionType, refetchOnMount = false } = options;

  // get booth setup questions
  const { data, refetch, isFetching, error } = useQuery(
    ['getAssessmentQuestions', { ooId, questionType }],
    () => getAssessmentQuestions({ ooId, questionType }),
    {
      enabled: Boolean(ooId),
      refetchOnMount,
    },
  );

  return {
    questions: data?.list,
    isFetching,
    refetch,
    error,
  };
};

export default useQuestionsData;
