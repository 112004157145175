import { useState } from 'react';
import { object } from 'prop-types';
import { useParams } from 'react-router';
import cx from 'classnames';

import { BOOTH_SETUP, TEXT } from 'common/const';
import { Content, OptionButton, Require } from 'common/components';
import { getBoothSetupImage } from 'common/utils';

import ErrorMessage from '../ErrorMessage';
import Step from './Step';

import styles from './List.module.css';

const List = ({ findStep }) => {
  const { assessmentId } = useParams();
  const step = findStep(BOOTH_SETUP.LIST, assessmentId);

  // console.log('<List>', { step });

  const {
    response,
    question,
    required,
    submit,
    error,
    isLoading,
    isSubmitting,
    back,
    buttonText,
  } = step ?? {};

  const [hasError, setHasError] = useState();
  const [selected, setSelected] = useState(response);

  const options = step?.boothSetupResponseSelections?.listResponseOptions;

  return (
    <Require data={[question]}>
      <Content isLoading={!step || isLoading} error={error?.message}>
        <Step
          submit={() => {
            if (required && !selected) {
              setHasError(true);
            } else {
              submit(selected);
            }
          }}
          isSubmitting={isSubmitting}
          back={back}
          buttonText={buttonText}
        >
          <h1 className={styles.p}>{question}</h1>

          <ul className={styles.types}>
            {options
              ?.sort((a, b) => a.sortOrder - b.sortorder)
              .map(({ title, subtitle, image }) => (
                <li key={title}>
                  <OptionButton
                    value={title}
                    selected={selected === title}
                    onClick={(title) => setSelected(title)}
                    className={styles.type}
                  >
                    <img
                      src={getBoothSetupImage(image)}
                      alt={title}
                      className={styles.icon}
                    />
                    <div>
                      <h2 className={TEXT.SUBTITLE}>{title}</h2>
                      {subtitle && (
                        <p className={cx(TEXT.REGULAR, TEXT.BODY_2)}>
                          {subtitle}
                        </p>
                      )}
                    </div>
                  </OptionButton>
                </li>
              ))}
          </ul>

          <ErrorMessage isOpen={hasError} close={() => setHasError(false)}>
            Please select an option to continue.
          </ErrorMessage>
        </Step>
      </Content>
    </Require>
  );
};

List.propTypes = {
  step: object,
};

export default List;
