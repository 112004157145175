import {
  AmexLogo,
  DiscoverLogo,
  MastercardLogo,
  VisaLogo,
} from 'assets/images';

const CREDIT_CARD = {
  AMEX: {
    firstDigit: [3],
    alt: 'American Express',
    db: 'AMEX',
    logo: AmexLogo,
  },
  DISCOVER: {
    firstDigit: [6],
    alt: 'Discover',
    db: 'DISCOVER',
    logo: DiscoverLogo,
  },
  MASTERCARD: {
    firstDigit: [5, 2],
    alt: 'Mastercard',
    db: 'MASTERCARD',
    logo: MastercardLogo,
  },
  VISA: {
    firstDigit: [4],
    alt: 'Visa',
    db: 'VISA',
    logo: VisaLogo,
  },
};

export default CREDIT_CARD;
