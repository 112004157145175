import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';
import { getTotals } from 'data/utils';

import mock from './getCartTotals.mock';

const baseUrl = getBaseUrl(2);

/**
 * Gets totals for an incomplete order specified by parameters
 * @param {string} customerId - ID for customer
 * @param {string} eventId - ID for event
 * @param {string} ooId - ID for order
 * @returns
 */
const getCartTotals = async ({ customerId, eventId, ooId }) => {
  await VALID.checkAll(
    ['customerId', customerId, VALID.CUSTOMER_ID],
    ['eventId', eventId, VALID.EVENT_ID],
    ['ooId', ooId, VALID.OOID],
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock()
      : await axios.get(
          `${baseUrl}orders/cart_totals/${customerId}/${eventId}/${ooId}`,
        );

    return getTotals(data.cartTotals);
  } catch (error) {
    log('getCartTotals', {
      data: { customerId, eventId, ooId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getCartTotals;
