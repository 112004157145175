import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router';

import { useOrders } from 'app/context';

import { cancelOrder } from 'data/orders';
import { getCancellationReasons } from 'data/events';
import { ROUTE } from 'common/const';
import { track } from 'common/utils';

const useCancelOrder = ({ ooId, eventId, onError }) => {
  const history = useHistory();
  const { refetchCompleted, updateToast } = useOrders();

  const { data: responseOptions, isLoading: isLoadingCancelOptions } = useQuery(
    ['getCancelResponseOptions', eventId],
    () => getCancellationReasons({ eventId }),
    { enabled: Boolean(eventId) },
  );

  const {
    mutate: cancel,
    isLoading: isCancelling,
    error: cancelError,
  } = useMutation(cancelOrder, {
    onSuccess: () => {
      updateToast({
        alert: 'success',
        message: 'Your cancellation request was submitted',
      });
      track('cancel_submitted', { ooId });
      refetchCompleted();
      history.push(ROUTE.ORDER_DETAILS + `/${ooId}`);
    },
    onError: onError,
  });

  return {
    responseOptions,
    isLoadingCancelOptions,
    cancel,
    isCancelling,
    cancelError,
  };
};

export default useCancelOrder;
