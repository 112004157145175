import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import { updatePassword, validateResetLink } from 'data/customers';

import {
  Form,
  SubmitButton,
  Error,
  Password,
  NewPassword,
  Button,
} from 'common/components';
import { ROUTE, VALID } from 'common/const';
import { Page } from 'common/site';
import { useSearchParams } from 'common/hooks';

import styles from './ResetPassword.module.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isFailedOnce, setIsFailedOnce] = useState(false);
  const history = useHistory();

  const { linkCode } = useSearchParams();

  const {
    mutate: update,
    isLoading: isUpdating,
    error: updateError,
    isSuccess: updateSuccess,
  } = useMutation(updatePassword);

  const {
    mutate: validateCode,
    isLoading: isValidating,
    error: validateError,
    isSuccess: validateSuccess,
  } = useMutation(validateResetLink);

  async function handleSubmit({ password }) {
    update({ linkCode, password });
  }

  useEffect(() => {
    if (!validateError && !validateSuccess) validateCode(linkCode);
  }, [validateError, validateSuccess, validateCode, linkCode]);

  useEffect(() => {
    if (validateError) {
      // redirect to forgot password with error
      history.push({
        pathname: ROUTE.FORGOT_PASSWORD,
        state: { error: validateError },
      });
    }
  }, [history, validateError]);

  return !updateSuccess || !validateSuccess ? (
    // Password reset component
    <Page variant="island" title="Reset Password">
      {updateError && <Error>{updateError?.message}</Error>}
      <Form
        validation={{
          password: VALID.PASSWORD.required('Please enter a valid password.'),
        }}
        onPass={({ password }) => {
          if (isPasswordValid) {
            handleSubmit({ password });
          } else {
            setIsFailedOnce(true);
          }
        }}
        onFail={(errors) => {
          setIsFailedOnce(true);
          console.log(errors);
        }}
        isSubmitting={isValidating || isUpdating}
      >
        <div className={styles.spacing} />
        <NewPassword
          isFailedOnce={isFailedOnce}
          password={password}
          setIsValid={setIsPasswordValid}
        >
          <Password
            name="password"
            onChange={({ target }) => setPassword(target.value)}
            error={isFailedOnce && !isPasswordValid ? ' ' : undefined}
          />
        </NewPassword>

        <SubmitButton
          name="submit"
          fullWidth
          isSubmitting={isValidating || isUpdating}
        >
          Reset Password
        </SubmitButton>
      </Form>
    </Page>
  ) : (
    // Redirect Page
    <Page
      variant="island"
      title="Password Changed"
      subtitle="Your password was changed successfully."
    >
      <Button name="submit" fullWidth to={ROUTE.LOGIN}>
        Return to login
      </Button>
    </Page>
  );
};

export default ResetPassword;
