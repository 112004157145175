import { useCustomer } from 'app/context';
import {
  Button,
  Content,
  Form,
  Input,
  SectionHeader,
  SubmitButton,
} from 'common/components';
import { TEXT, VALID } from 'common/const';
import { useState } from 'react';

import styles from './Email.module.css';

const Email = () => {
  const customer = useCustomer();
  const [active, setActive] = useState(false);
  const [error, setError] = useState(undefined);

  return active ? (
    <Content isLoading={customer.isUpdating} error={error}>
      <Form
        validation={{
          newEmail: VALID.EMAIL.required('Please enter your new email.'),
          emailDuplicate: VALID.EMAIL.required('Please re-enter new email.'),
        }}
        onPass={({ newEmail, emailDuplicate }) => {
          if (newEmail !== emailDuplicate) {
            setError('Emails must match.');
          } else {
            setError(undefined);
            customer.changeEmail({ email: newEmail });
          }
        }}
        isSubmitting={customer.isUpdating}
      >
        <SectionHeader el="legend">Edit Email</SectionHeader>

        <fieldset className={styles.fieldset}>
          <Input name="newEmail" />
          <Input name="emailDuplicate" label="Re-Enter New Email" />
        </fieldset>

        <div className={styles.actions}>
          <SubmitButton name="submit" isSubmitting={customer.isUpdating}>
            Update
          </SubmitButton>
          <Button text name="cancel" onClick={() => setActive(false)}>
            Cancel
          </Button>
        </div>
      </Form>
    </Content>
  ) : (
    <Content isLoading={customer.isUpdating}>
      <SectionHeader action={{ onClick: () => setActive(true) }}>
        <p className={TEXT.SUBTITLE}>Email</p>
      </SectionHeader>

      <p>{customer.email}</p>
    </Content>
  );
};

export default Email;
