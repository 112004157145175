import getStyles from '../getStyles';

import styles from './display.module.css';

const DISPLAY = {
  fields: [
    // each sub-array represents a line
    ['address2'],
    ['address1'],
    ['zip', 'city', 'state'],
    ['country'],
  ],
  styles: getStyles(styles),
};

export default DISPLAY;
