import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import { useAuth, useOrders, useShop } from 'app/context';
import { ROUTE } from 'common/const';
import { Button, Content, NewOrderDialog } from 'common/components';
import { ssoAuthenticate } from 'data/customers';
import { useSearchParams } from 'common/hooks';
import { Page } from 'common/site';

import styles from './SSO.module.css';
import { deleteOrder } from 'data/orders';

const SSO = () => {
  const { partnerId, token } = useSearchParams();
  const history = useHistory();
  const { customerId, login } = useAuth();
  const {
    incomplete: { isLoading: isLoadingOrders, data: orders },
  } = useOrders();
  const { reset: resetShop, ...shop } = useShop();
  const [error, setError] = useState('');
  const [incompletePopup, setIncompletePopup] = useState(false);

  useEffect(() => {
    if (history.action === 'PUSH') {
      history.replace(ROUTE.DASHBOARD);
    }
  }, [history]);

  const {
    isLoading,
    data = {},
    remove,
  } = useQuery(
    ['ssoAuthenticate', partnerId, token],
    () => ssoAuthenticate({ partnerId, token }),
    {
      enabled: Boolean(partnerId) && Boolean(token),
      onSuccess: (loadedData) => {
        // console.log({ loadedData });
        login(loadedData);
        shop.update({ eventId: loadedData.eventId });
      },
      onError: (err) => {
        // console.log({ err });
        setError(err.message);
      },
      // onSettled: () => remove(),
      retry: 1,
    },
  );

  // handle errors
  const isSuccess = useMemo(() => {
    const { actionResult } = data.resultInformation ?? {};
    const isSuccess = actionResult === 'Successful';

    if (isSuccess) {
      setError('');
    } else if (actionResult && !isSuccess) {
      setError(error?.message);
    }

    return isSuccess;
  }, [data, setError, error]);

  // handle success (after context updates)
  useEffect(() => {
    if (customerId && shop.eventId && isSuccess && !isLoadingOrders) {
      if (orders.length === 0) {
        history.push(`${ROUTE.START_ORDER}/${shop.eventId}`);
        remove(); // remove the cached results
      } else {
        setIncompletePopup(true);
        // Display Popup
      }
    }
  }, [customerId, history, shop, isSuccess, orders, isLoadingOrders, remove]);

  // show isLoading once complete, if there are no errors
  const showLoading = useMemo(() => isLoading || !error, [isLoading, error]);

  return (
    <Page variant="island" title={'Single Sign-On' + (error ? ' Error' : '')}>
      <Content error={error} isLoading={showLoading}>
        <p className={styles.p}>
          Please check with your administrator for details, or sign up for an
          account instead.
        </p>
        <Button fullWidth to={ROUTE.SIGNUP}>
          Sign Up for an Account
        </Button>
      </Content>

      <NewOrderDialog
        isOpen={incompletePopup}
        setIsOpen={setIncompletePopup}
        onSubmit={() => {
          deleteOrder(orders[0].id);
          history.push(`${ROUTE.START_ORDER}/${shop.eventId}`);
        }}
        orders={orders}
      />
    </Page>
  );
};

export default SSO;
