import getStyles from '../getStyles';

import styles from './display.module.css';

const DISPLAY = {
  fields: [
    // each sub-array represents a line
    ['country'],
    ['city', 'address2', 'address1'],
    ['zip'],
  ],
  styles: getStyles(styles),
};

export default DISPLAY;
