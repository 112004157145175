import { useMemo } from 'react';

import { getAssessmentData } from 'common/utils';

import useResponseData from './useResponseData';
import useQuestionsData from './useQuestionsData';
import useSubmitResponse from './useSubmitResponse';

const useBoothAssessment = (ooId, options = {}) => {
  // console.log('!useBoothAssessment', { ooId, options });

  const { questionType, refetchOnMount } = options;

  const {
    questions,
    isFetching: isFetchingQuestions,
    refetch: refetchQuestions,
    error: questionsError,
  } = useQuestionsData(ooId, { questionType, refetchOnMount });

  const {
    responses,
    isFetching: isFetchingResponses,
    refetch: refetchResponses,
    error: responsesError,
  } = useResponseData(ooId);

  const {
    submit,
    isSubmitting,
    error: submitError,
  } = useSubmitResponse(ooId, { refetchResponses, refetchQuestions });

  const list = useMemo(
    () =>
      questions && responses
        ? getAssessmentData(questions, responses)
        : undefined,
    [questions, responses],
  );

  const error = useMemo(
    () => submitError ?? questionsError ?? responsesError,
    [questionsError, submitError, responsesError],
  );

  return {
    error,
    isLoading: isFetchingQuestions || isFetchingResponses,
    isSubmitting,
    isFetchingQuestions,
    list,
    questions,
    responses,
    submit,
  };
};

export default useBoothAssessment;
