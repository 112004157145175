const CONAGG_ID = '033001LV';
const MARRIOTT_FACILITY_ID = 69;

const CONAGG_FORM = 'https://forms.office.com/r/BSUrWc91eX';
const FEEDBACK_FORM =
  'https://forms.office.com/pages/responsepage.aspx?id=23aqbqeU9kidDYSRYwhlm3xFK3lHHfFAlheumZ7_8uxUMUVGNFZVSzNXMThBTk9FUTZJNUtWUFVZNCQlQCN0PWcu';

const EVENT = {
  getConfirmationForm: (eventId) =>
    eventId === CONAGG_ID ? CONAGG_FORM : FEEDBACK_FORM,
  getServiceFeeCopy: (facilityId) =>
    facilityId === MARRIOTT_FACILITY_ID ? 'Facility Fee' : 'Service Fee',
};

export default EVENT;
