const getCustomer = (customerData) => {
  // console.log('!getCustomer', { data });

  const { addressInformation, companyInformation } = customerData;

  return {
    address1: addressInformation.address1,
    address2: addressInformation.address2,
    city: addressInformation.city,
    company: companyInformation.name,
    countryCode: addressInformation.countryCode,
    customerId: customerData.id,
    email: customerData.emailAddress,
    firstName: customerData.firstName,
    lastName: customerData.lastName,
    mobile: addressInformation.mobile,
    phone: addressInformation.phone,
    state: addressInformation.state,
    zip: addressInformation.zip,
  };
};

export default getCustomer;
