import { appVersion } from 'data/mock-data';

const mock = () => ({
  data: {
    version: appVersion,
    floorPlanBuilderEvents: 'ALL',
    floorPlanBuilderMinSize: 3,
    floorPlanBuilderMaxSize: 50,
  },
});

export default mock;
