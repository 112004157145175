import {
  bool,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import cx from 'classnames';

import { TEXT } from 'common/const';
import { Button, ProductPrices } from 'common/components';

import withVariant from './withVariant';
import styles from './Product.module.css';

const Product = ({
  addToCart, // Add to cart component
  blurb,
  children,
  currentPrice,
  flag,
  image,
  inlinePrices,
  isActive,
  name,
  originalPrice,
  quantity,
  variant,
}) => {
  // console.log('<Product>', { blurb, currentPrice, flag, image, isActive, name, originalPrice, quantity, variant });

  return (
    <section
      className={cx(styles.product, variant.product, {
        [variant.active]: isActive,
      })}
    >
      <div className={cx(styles.imageContainer, variant.image)}>
        {image && (
          <img
            src={image}
            alt={name}
            className={cx(styles.image, variant.image)}
          />
        )}
        <div className={styles.addToCart}>{addToCart}</div>
      </div>

      <div className={cx(styles.details, variant.details)}>
        {flag && (
          <div
            className={cx(
              TEXT.SUBTITLE_2,
              styles.flag,
              flag.className,
              variant.flag,
            )}
          >
            {flag.content}
          </div>
        )}

        <div className={cx(styles.name, variant.name)}>{name}</div>

        {children}
      </div>

      <div className={cx(styles.quantity, variant.quantity)}>{quantity}</div>

      <div className={variant.prices}>
        <ProductPrices
          inline={inlinePrices}
          className={variant.prices}
          currentPrice={currentPrice}
          originalPrice={originalPrice}
        />
      </div>

      <p className={cx(styles.blurb, variant.blurb)}>{blurb}</p>

      <div className={cx(styles.detailsButton, variant.detailsButton)}>
        <Button outlined fullWidth>
          See Details
        </Button>
      </div>
    </section>
  );
};

Product.propTypes = {
  blurb: string,
  className: string,
  currentPrice: number,
  flag: shape({
    className: string,
    content: string,
  }),
  image: string,
  inlinePrices: bool,
  name: string,
  originalPrice: number,
  quantity: oneOfType([node, number]),
  variant: object,
  children: oneOfType([string, node]),
};

Product.defaultProps = {};

export default withVariant(Product);
