const PAYMENT_METHOD = {
  CARD: 'CARD',
  ACH: 'ACH',
  WIRE: 'WIRE',
  FEE: 'FEE',
  REFUND: 'REFUND',
  CASH: 'CASH',
  CHECK: 'CHECK',
  SPLIT: 'SPLIT',
  SETTLEMENT: 'SETTLEMENT',
  COMMISSION: 'COMMISSION',
  WRITE: 'WRITE',
};

// this helps translate the payment methods returned
// from the DB call via getEventPaymentMethods to the
// PAYMENT_METHOD our code uses.
const PAYMENT_METHOD_TX = {
  CC: 'CARD',
  ACH: 'ACH',
  'WIRE TRANSFER': 'WIRE',
  'PROCESSING FEE': 'FEE',
  REFUND: 'REFUND',
  CASH: 'CASH',
  CHECK: 'CHECK',
  'WRITE OFF': 'WRITE',
  SETTLEMENT: 'SETTLEMENT',
  COMMISSION: 'COMMISSION',
  SPLIT: 'SPLIT',
};

const PAYMENT_DESCRIPTION = {
  CARD: 'Credit Card',
  ACH: 'ACH Transfer w/ Credit Card guarantee',
  WIRE: 'Wire Transfer w/ Credit Card guarantee',
  FEE: 'Processing Fee',
  REFUND: 'Refund',
  CASH: 'Cash Payment',
  CHECK: 'Check Payment',
};

const PAYMENT_DETAIL_DESCRIPTION = {
  CARD_REFUND: 'Credit Card Refund',
  CARD: 'Credit Card',
  ACH: 'ACH Transfer',
  WIRE: 'Wire Transfer ',
  FEE: 'Processing Fee',
  REFUND: 'Refund',
  CASH: 'Cash Payment',
  CHECK: 'Check Payment',
  WRITE: 'Write Off',
  SETTLEMENT: 'Settlement',
  COMMISSION: 'Commission',
  SPLIT: 'Split Payment',
};

export {
  PAYMENT_METHOD,
  PAYMENT_METHOD_TX,
  PAYMENT_DESCRIPTION,
  PAYMENT_DETAIL_DESCRIPTION,
};
