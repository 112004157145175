import axios from 'axios';

import { getBaseUrl, log } from 'common/utils';
import { ENV, VALID } from 'common/const';
import { getIsEarlyPricing, getOrder } from 'data/utils';

import mock from './getCompletedOrders.mock';

const baseUrl = getBaseUrl(2);

/**
 * Get a list of completed orders
 * @param {string} customerId
 * @returns Array of completed orders
 */
const getCompletedOrders = async (customerId) => {
  await VALID.check(
    customerId,
    VALID.CUSTOMER_ID.required('customerId is required'),
  );

  try {
    const { data } = ENV.IS_MOCK
      ? mock({ customerId })
      : await axios.get(`${baseUrl}orders/emp_completed_orders/${customerId}`);
    const orders = data.empOnlineOrdersData;

    return orders.map((order) =>
      getOrder({
        ...order,
        id: order.ooId,
        isCompleted: true,
        currentDate: data.webServerDateTime,
        isEarlyPricing: getIsEarlyPricing({
          deadlineDate: order.eventDeadlineDate,
          serverDate: data.webServerDateTime,
        }),
      }),
    );
  } catch (error) {
    log('getCompletedOrders', {
      data: { customerId },
      error,
      throw: true,
      type: 'request',
    });
  }
};

export default getCompletedOrders;
