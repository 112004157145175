import getStyles from '../getStyles';

import styles from './display.module.css';

const DISPLAY = {
  fields: [
    // each sub-array represents a line
    ['country', 'zip'],
    ['state', 'city', 'address2', 'address1'],
  ],
  styles: getStyles(styles),
};

export default DISPLAY;
